import React, { useState, useEffect } from "react";
import axios from "axios";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../../lib/constants/loadingScreen";
import { message, Select, Modal } from "antd";
import UpdatePrescription from "./update_prescription"; // Adjust path as needed

const { Option } = Select;

const AddPrescription = ({ appointmentId, full_name, problem, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [followUp, setFollowUp] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [doctorDepartment, setDoctorDepartment] = useState("");
  const [singleAppointmentData, setSingleAppointmentData] = useState(null);
  const [hasEditablePrescription, setHasEditablePrescription] = useState(false);
  const [details, setDetails] = useState([]);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(null);

  const NUMBER_OPTIONS = [
    { value: "1 (1-0-0)", label: "Once (1-0-0)" },
    { value: "1 (0-1-0)", label: "Once (0-1-0)" },
    { value: "1 (0-0-1)", label: "Once (0-0-1)" },
    { value: "2 (1-1-0)", label: "Twice (1-1-0)" },
    { value: "2 (1-0-1)", label: "Twice (1-0-1)" },
    { value: "2 (0-1-1)", label: "Twice (0-1-1)" },
    { value: "3", label: "Thrice" },
    { value: "4", label: "Four times" },
    { value: "5", label: "Five times" },
    { value: "6", label: "Six times" },
  ];
  const PERIOD_OPTIONS = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ];
  const DURATION_NUMBER_OPTIONS = Array.from({ length: 32 }, (_, i) => ({
    value: `${i + 1}`,
    label: `${i + 1}`,
  }));
  const TABLET_NUMBER_OPTIONS = Array.from({ length: 4 }, (_, i) => ({
    value: `${i + 1}`,
    label: `${i + 1}`,
  }));
  const DURATION_PERIOD_OPTIONS = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ];
  const MEAL_OPTIONS = [
    { value: "after", label: "After" },
    { value: "before", label: "Before" },
  ];
  const DAYS_OPTIONS = [
    { value: "sunday", label: "Sunday" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
  ];

  const [medicineList, setMedicineList] = useState([
    {
      medicineName: "",
      affiliateName: "",
      comment: "",
      details: [
        {
          dose: "",
          tablet: "",
          frequency: { number: "", period: "", selectedDays: [] },
          Meal: "",
          duration: { number: "", period: "" },
        },
      ],
      selectedMedicine: null,
    },
  ]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") || localStorage.getItem("staffticket")),
    },
  };
  const doctorConfig = {
    headers: { Authorization: "Bearer " + localStorage.getItem("doctorticket") },
  };

  useEffect(() => {
    fetchData();
    fetchSingleAppointment();
    if (localStorage.getItem("doctorticket")) fetchDoctorDepartment();
    fetchMedicine();
  }, [appointmentId]);

  useEffect(() => {
    const checkEditablePrescription = () => {
      if (!details || details.length === 0) return false;
      return details.some((item) => {
        if (!item.appointmentId?.appointmentHistory) return false;
        const lastHistory =
          item.appointmentId.appointmentHistory[
          item.appointmentId.appointmentHistory.length - 1
          ];
        return lastHistory?.prescriptions?.some(
          (prescriptionItem) => prescriptionItem._id === item._id
        );
      });
    };
    setHasEditablePrescription(checkEditablePrescription());
  }, [details]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/doctor/get_prescription/${appointmentId}`, config)
      .then((response) => setDetails(response.data.data))
      .catch((e) => {
        console.error("Failed to fetch prescription details:", e);
        message.error("Failed to get prescription details! Please try again.");
      })
      .finally(() => setLoading(false));
  };

  const fetchSingleAppointment = () => {
    axios
      .get(`${apiBaseUrl}/staff/getBookedDoctorAppointment/${appointmentId}`, config)
      .then((response) => setSingleAppointmentData(response.data.data))
      .catch((e) => {
        console.error("Failed to fetch appointment details:", e);
        message.error("Failed to get appointment details! Please try again.");
      });
  };

  const fetchDoctorDepartment = () => {
    axios
      .get(`${apiBaseUrl}/doctor/profile`, doctorConfig)
      .then((response) => {
        if (response.data.success)
          setDoctorDepartment(response.data.data.department.department);
      })
      .catch((e) => {
        console.error("Failed to fetch doctor department:", e);
        message.error("Failed to get doctor details! Please try again.");
      });
  };

  const fetchMedicine = () => {
    axios
      .get(`${apiBaseUrl}/get/doctor/department/medicine`)
      .then((response) => {
        if (response.data.success) setMedicineOptions(response.data.data);
      })
      .catch((error) => console.error("Error fetching medicine:", error));
  };

  const addPrescription = async (e) => {
    e.preventDefault();
    setActionLoading(true);

    const isFollowUpEmpty = !followUp;
    const isMedicineListEmpty =
      medicineList.length === 0 ||
      JSON.stringify(medicineList[0]) ===
      JSON.stringify({
        medicineName: "",
        affiliateName: "",
        comment: "",
        details: [
          {
            dose: "",
            tablet: "",
            frequency: { number: "", period: "", selectedDays: [] },
            Meal: "",
            duration: { number: "", period: "" },
          },
        ],
        selectedMedicine: null,
      });

    if (isFollowUpEmpty && isMedicineListEmpty) {
      setActionLoading(false);
      message.error("Both medicine and follow-up cannot be empty");
      return;
    }

    let validationError = "";
    for (const medicine of medicineList) {
      if (!medicine.medicineName?.trim()) {
        validationError = "Medicine Name is required for all entries.";
        break;
      }
      if (!medicine.affiliateName?.trim()) {
        validationError = "Affiliate Name is required for all entries.";
        break;
      }
      for (const detail of medicine.details) {
        if (
          !detail.dose ||
          !detail.tablet ||
          !detail.frequency.number ||
          !detail.frequency.period ||
          !detail.Meal ||
          !detail.duration.number ||
          !detail.duration.period
        ) {
          validationError =
            "All detail fields (Dose, Tablet, Frequency, Meal, Duration) are required.";
          break;
        }
        if (
          detail.frequency.period === "week" &&
          detail.frequency.selectedDays.length === 0
        ) {
          validationError = "Please select days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          ["1 (1-0-0)", "1 (0-1-0)", "1 (0-0-1)"].includes(detail.frequency.number) &&
          detail.frequency.selectedDays.length !== 1
        ) {
          validationError = "Please select one day for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          ["2 (1-1-0)", "2 (1-0-1)", "2 (0-1-1)"].includes(detail.frequency.number) &&
          detail.frequency.selectedDays.length !== 2
        ) {
          validationError = "Please select two days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          detail.frequency.number === "3" &&
          detail.frequency.selectedDays.length !== 3
        ) {
          validationError = "Please select three days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          detail.frequency.number === "4" &&
          detail.frequency.selectedDays.length !== 4
        ) {
          validationError = "Please select four days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          detail.frequency.number === "5" &&
          detail.frequency.selectedDays.length !== 5
        ) {
          validationError = "Please select five days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          detail.frequency.number === "6" &&
          detail.frequency.selectedDays.length !== 6
        ) {
          validationError = "Please select six days for weekly frequency.";
          break;
        }
      }
      if (validationError) break;
    }

    if (validationError) {
      setActionLoading(false);
      message.error(validationError);
      return;
    }

    const data = { medicine: medicineList, follow_up: followUp, comment };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.post(
          `${apiBaseUrl}/doctor/prescription/create/${appointmentId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.post(
          `${apiBaseUrl}/staff/prescription/create/${appointmentId}`,
          data,
          config
        );
      }

      if (response.data.success) {
        message.success("Prescription Added", 0.6, () => {
          fetchData();
          setMedicineList([
            {
              medicineName: "",
              affiliateName: "",
              comment: "",
              details: [
                {
                  dose: "",
                  tablet: "",
                  frequency: { number: "", period: "", selectedDays: [] },
                  Meal: "",
                  duration: { number: "", period: "" },
                },
              ],
              selectedMedicine: null,
            },
          ]);
          setFollowUp("");
          setComment("");
        });
      } else {
        message.error("Failed to add prescription");
      }
    } catch (error) {
      console.error("Failed to add prescription:", error);
      message.error("Failed to add prescription! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const deletePrescription = async (prescription_id) => {
    if (window.confirm("Are you sure you want to delete this prescription?")) {
      setActionLoading(true);
      try {
        let response;
        if (localStorage.getItem("doctorticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/doctor/delete_prescription/${prescription_id}`,
            config
          );
        } else if (localStorage.getItem("staffticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/staff/delete_prescription/${prescription_id}`,
            config
          );
        }

        if (response.data.success) {
          message.success("Prescription Deleted", 0.6, () => fetchData());
        } else {
          message.error("Failed to delete prescription.");
        }
      } catch (e) {
        console.error("Failed to delete prescription:", e);
        message.error("Failed to delete prescription! Please try again.");
      } finally {
        setActionLoading(false);
      }
    }
  };

  const addMedicine = () => {
    setMedicineList([
      ...medicineList,
      {
        medicineName: "",
        affiliateName: "",
        comment: "",
        details: [
          {
            dose: "",
            tablet: "",
            frequency: { number: "", period: "", selectedDays: [] },
            Meal: "",
            duration: { number: "", period: "" },
          },
        ],
        selectedMedicine: null,
      },
    ]);
  };

  const addDetailRow = (medicineIndex) => {
    const updatedList = [...medicineList];
    updatedList[medicineIndex].details.push({
      dose: "",
      tablet: "",
      frequency: { number: "", period: "", selectedDays: [] },
      Meal: "",
      duration: { number: "", period: "" },
    });
    setMedicineList(updatedList);
  };

  const removeDetailRow = (medicineIndex, detailIndex) => {
    const updatedList = [...medicineList];
    updatedList[medicineIndex].details.splice(detailIndex, 1);
    setMedicineList(updatedList);
  };

  const removeMedicine = (index) => {
    setMedicineList((prevList) => {
      const updatedList = prevList.filter((_, idx) => idx !== index);
      return updatedList.length === 0
        ? [
          {
            medicineName: "",
            affiliateName: "",
            comment: "",
            details: [
              {
                dose: "",
                tablet: "",
                frequency: { number: "", period: "", selectedDays: [] },
                Meal: "",
                duration: { number: "", period: "" },
              },
            ],
            selectedMedicine: null,
          },
        ]
        : updatedList;
    });
  };

  const handleInputChange = (medicineIndex, key, value, detailIndex = null) => {
    const updatedList = [...medicineList];
    if (detailIndex === null) {
      if (key === "medicineName") {
        const selectedMed = medicineOptions.find(
          (med) => med.name + " [" + med.affiliateName + "]" === value
        );
        updatedList[medicineIndex][key] = value;
        updatedList[medicineIndex].selectedMedicine = selectedMed || null;
      } else {
        updatedList[medicineIndex][key] = value;
      }
    } else {
      if (key === "frequencyNumber" || key === "frequencyPeriod") {
        updatedList[medicineIndex].details[detailIndex].frequency[
          key === "frequencyNumber" ? "number" : "period"
        ] = value;
        if (key === "frequencyPeriod" && value !== "month") {
          updatedList[medicineIndex].details[detailIndex].frequency.selectedDays = [];
        }
      } else if (key === "durationNumber" || key === "durationPeriod") {
        updatedList[medicineIndex].details[detailIndex].duration[
          key === "durationNumber" ? "number" : "period"
        ] = value;
      } else if (key === "selectedDays") {
        const currentDays = updatedList[medicineIndex].details[detailIndex].frequency.selectedDays;
        const maxDays = parseInt(
          updatedList[medicineIndex].details[detailIndex].frequency.number
        ) || 1;
        if (currentDays.includes(value)) {
          updatedList[medicineIndex].details[detailIndex].frequency.selectedDays =
            currentDays.filter((day) => day !== value);
        } else if (currentDays.length < maxDays) {
          updatedList[medicineIndex].details[detailIndex].frequency.selectedDays = [
            ...currentDays,
            value,
          ];
        }
      } else {
        updatedList[medicineIndex].details[detailIndex][key] = value;
      }
    }
    setMedicineList(updatedList);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const created = new Date(dateString);
    const msPerDay = 1000 * 60 * 60 * 24;
    const diffMs = now - created;
    const diffDays = Math.floor(diffMs / msPerDay);
    if (diffDays === 0) return "today";
    if (diffDays < 30) return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
    const diffMonths = Math.floor(diffDays / 30);
    return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`;
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}
      <div className="mb-3">
        <h1 className="text-3xl font-bold decoration-gray-400">Prescription</h1>
      </div>

      <div className="flex items-start">
        {!hasEditablePrescription && (
          <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow">
            <p className="text-xl mb-1 font-bold tracking-tight text-gray-800">
              Add Prescription
            </p>
            <form
              className="pb-10 pt-5 mt-5 rounded-lg flex flex-col gap-4"
              onSubmit={addPrescription}
            >
              {medicineList.map((medicine, index) => (
                <div
                  className="flex flex-wrap border rounded-lg mx-3 mb-2 px-5 py-5"
                  key={index}
                >
                  <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                      Medicine Name
                    </label>
                    <Select
                      placeholder="Select a medicine"
                      showSearch
                      allowClear
                      required
                      className="w-full h-10 mt-0.2"
                      popupMatchSelectWidth={false}
                      dropdownStyle={{ zIndex: 1051 }}
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      value={medicine.medicineName || undefined}
                      onChange={(value) =>
                        handleInputChange(index, "medicineName", value || "")
                      }
                      filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {medicineOptions.map((med, idx) => (
                        <Option
                          key={idx}
                          value={med.name + " [" + med.affiliateName + "]"}
                          style={{ whiteSpace: "normal", padding: "8px" }}
                        >
                          {med.name + " [" + med.affiliateName + "]"}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                      Affiliate Name
                    </label>
                    {medicine.selectedMedicine?.affiliateName && (
                      <Select
                        placeholder="Select affiliate"
                        value={medicine.affiliateName || undefined}
                        onChange={(value) =>
                          handleInputChange(index, "affiliateName", value || "")
                        }
                        className="w-full h-10 mt-0.2"
                        popupMatchSelectWidth={false}
                        dropdownStyle={{ zIndex: 1051 }}
                        style={{ width: "100%" }}
                        required
                      >
                        {medicine.selectedMedicine.affiliateName.map((affiliateName, idx) => (
                          <Option key={idx} value={affiliateName}>
                            {affiliateName}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>
                  <div className="w-full md:w-2/4 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                      Comment
                    </label>
                    <input
                      className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                      type="text"
                      placeholder="eg.. Remarks"
                      value={medicine.comment}
                      onChange={(e) => handleInputChange(index, "comment", e.target.value)}
                    />
                  </div>
                  <div className="mt-4 w-full overflow-x-auto">
                    <table className="w-full min-w-full border-collapse border rounded-lg">
                      <thead>
                        <tr className="bg-gray-50">
                          <th className="border p-2 w-1/6">Dose</th>
                          <th className="border p-2 w-1/6">Tablet</th>
                          <th className="border p-2 w-3/6">Frequency</th>
                          <th className="border p-2 w-1/6">Meal</th>
                          <th className="border p-2 w-1/6">Duration</th>
                          <th className="border p-2 w-1/12">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {medicine.details.map((detail, detailIndex) => (
                          <tr key={detailIndex}>
                            <td className="border p-2">
                              {medicine.selectedMedicine?.dose && (
                                <Select
                                  placeholder="Select dose"
                                  value={detail.dose || undefined}
                                  onChange={(value) =>
                                    handleInputChange(index, "dose", value, detailIndex)
                                  }
                                  className="w-full h-10 mt-0.2"
                                  popupMatchSelectWidth={false}
                                  dropdownStyle={{ zIndex: 1051 }}
                                  style={{ width: "100%" }}
                                  required
                                >
                                  {medicine.selectedMedicine.dose.map((dose, idx) => (
                                    <Option key={idx} value={dose}>
                                      {dose}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </td>
                            <td className="border p-2">
                              <Select
                                placeholder="Number"
                                value={detail.tablet || undefined}
                                onChange={(value) =>
                                  handleInputChange(index, "tablet", value, detailIndex)
                                }
                                className="w-full h-10 mt-0.2"
                                popupMatchSelectWidth={false}
                                dropdownStyle={{ zIndex: 1051 }}
                                style={{ width: "100%" }}
                                required
                              >
                                {TABLET_NUMBER_OPTIONS.map((opt) => (
                                  <Option key={opt.value} value={opt.value}>
                                    {opt.label}
                                  </Option>
                                ))}
                              </Select>
                            </td>
                            <td className="border p-2">
                              <div className="flex gap-2">
                                <Select
                                  placeholder="Number"
                                  value={detail.frequency.number || undefined}
                                  onChange={(value) =>
                                    handleInputChange(index, "frequencyNumber", value, detailIndex)
                                  }
                                  className="w-full h-10 mt-0.2"
                                  popupMatchSelectWidth={false}
                                  dropdownStyle={{ zIndex: 1051 }}
                                  style={{ width: "100%" }}
                                  required
                                >
                                  {NUMBER_OPTIONS.map((opt) => (
                                    <Option key={opt.value} value={opt.value}>
                                      {opt.label}
                                    </Option>
                                  ))}
                                </Select>
                                <Select
                                  placeholder="Period"
                                  value={detail.frequency.period || undefined}
                                  onChange={(value) =>
                                    handleInputChange(index, "frequencyPeriod", value, detailIndex)
                                  }
                                  className="w-full h-10 mt-0.2"
                                  popupMatchSelectWidth={false}
                                  dropdownStyle={{ zIndex: 1051 }}
                                  style={{ width: "100%" }}
                                  required
                                >
                                  {PERIOD_OPTIONS.map((opt) => (
                                    <Option key={opt.value} value={opt.value}>
                                      {opt.label}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                              {detail.frequency.period === "week" && (
                                <div className="mt-2">
                                  <div className="flex flex-wrap gap-1">
                                    {DAYS_OPTIONS.map((day) => (
                                      <button
                                        key={day.value}
                                        type="button"
                                        onClick={() =>
                                          handleInputChange(index, "selectedDays", day.value, detailIndex)
                                        }
                                        className={`px-2 py-1 text-xs rounded ${detail.frequency.selectedDays.includes(day.value)
                                          ? "bg-blue-500 text-white"
                                          : "bg-gray-200"
                                          }`}
                                        required
                                      >
                                        {day.label}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="border p-2">
                              <Select
                                placeholder="Before or After"
                                value={detail.Meal || undefined}
                                onChange={(value) =>
                                  handleInputChange(index, "Meal", value, detailIndex)
                                }
                                className="w-full h-10 mt-0.2"
                                popupMatchSelectWidth={false}
                                dropdownStyle={{ zIndex: 1051 }}
                                style={{ width: "100%" }}
                                required
                              >
                                {MEAL_OPTIONS.map((opt) => (
                                  <Option key={opt.value} value={opt.value}>
                                    {opt.label}
                                  </Option>
                                ))}
                              </Select>
                            </td>
                            <td className="border p-2">
                              <div className="flex gap-2">
                                <Select
                                  placeholder="Number"
                                  value={detail.duration.number || undefined}
                                  onChange={(value) =>
                                    handleInputChange(index, "durationNumber", value, detailIndex)
                                  }
                                  className="w-full h-10 mt-0.2"
                                  popupMatchSelectWidth={false}
                                  dropdownStyle={{ zIndex: 1051 }}
                                  style={{ width: "100%" }}
                                  required
                                >
                                  {DURATION_NUMBER_OPTIONS.map((opt) => (
                                    <Option key={opt.value} value={opt.value}>
                                      {opt.label}
                                    </Option>
                                  ))}
                                </Select>
                                <Select
                                  placeholder="Period"
                                  value={detail.duration.period || undefined}
                                  onChange={(value) =>
                                    handleInputChange(index, "durationPeriod", value, detailIndex)
                                  }
                                  className="w-full h-10 mt-0.2"
                                  popupMatchSelectWidth={false}
                                  dropdownStyle={{ zIndex: 1051 }}
                                  style={{ width: "100%" }}
                                  required
                                >
                                  {DURATION_PERIOD_OPTIONS.map((opt) => (
                                    <Option key={opt.value} value={opt.value}>
                                      {opt.label}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </td>
                            <td className="border p-2">
                              <IconButton
                                onClick={() => removeDetailRow(index, detailIndex)}
                                color="danger"
                                aria-label="remove detail"
                                disabled={medicine.details.length === 1}
                                style={{
                                  color: "#ef4444",
                                  opacity: medicine.details.length === 1 ? 0.5 : 1,
                                }}
                              >
                                <AiFillMinusCircle />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="mt-2">
                      <IconButton
                        onClick={() => addDetailRow(index)}
                        color="primary"
                        aria-label="add detail"
                      >
                        Add Details
                        <HiPlusCircle />
                      </IconButton>
                    </div>
                  </div>
                  <div className="w-full md:w-2/4 px-3 mb-6 md:mb-0">
                    <IconButton
                      onClick={() => removeMedicine(index)}
                      color="danger"
                      aria-label="remove medicine field"
                      disabled={medicineList.length === 1}
                      style={{
                        color: "#ef4444",
                        opacity: medicineList.length === 1 ? 0.5 : 1,
                      }}
                    >
                      Remove Medicine Field
                      <AiFillMinusCircle />
                    </IconButton>
                  </div>
                </div>
              ))}
              <IconButton onClick={addMedicine} color="primary" aria-label="add button">
                Add medicine field
                <HiPlusCircle />
              </IconButton>
              <div className="w-full md:w-4/4 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                  Comment
                </label>
                <textarea
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                  placeholder="eg.. Another Medicines"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div>
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                  Follow Up
                </label>
                <input
                  id="followUp"
                  value={followUp}
                  type="date"
                  onChange={(e) => setFollowUp(e.target.value)}
                  min={new Date().toISOString().split("T")[0]}
                  className="border-solid border-gray-300 border h-10 py-2 px-2 w-full rounded-md text-gray-700 date-picker"
                  name="followUp"
                  placeholder="Select Date"
                />
              </div>
              <button
                id="addStaffBtn"
                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        )}
      </div>

      <div className="flex items-start">
        <div className="me-2 p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
          {loading && <LoadingScreen />}
          {!loading && (
            <>
              <p>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name: <span className="font-semibold">{` ${full_name}`}</span>
                </h5>
              </p>
              <p className="mb-1 font-semibold text-gray-700">
                Problem: <span className="font-normal">{` ${problem}`}</span>
              </p>
              {details.length > 0 ? (
                details.map((item) => (
                  <div
                    key={item._id}
                    className="me-2 mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex-grow"
                  >
                    <p className="mb-1 font-semibold text-gray-700">
                      Prescription added on:{" "}
                      <span className="font-normal">{formatDate(item.createdAt)}</span>
                      <span className="font-normal"> ({timeAgo(item.createdAt)})</span>
                    </p>
                    <p className="mb-1 font-semibold text-gray-700">
                      Comment:{" "}
                      <span className="font-normal">{item.comment || "---"}</span>
                    </p>
                    <p className="mb-1 font-semibold text-gray-700">
                      Follow Up:{" "}
                      <span className="font-normal">
                        {item.follow_up ? formatDate(item.follow_up) : "---"}
                      </span>
                    </p>
                    <p className="mb-1 font-semibold text-gray-700">
                      Added By Doctor:{" "}
                      <span className="font-normal">{item.doctorId.fullname}</span>
                    </p>
                    <div className="overflow-x-auto mt-4">
                      <table className="table-auto w-full text-sm text-left text-gray-700 border border-gray-200">
                        <thead className="bg-gray-100 text-gray-700 font-semibold">
                          <tr>
                            <th className="px-4 py-2 border">Medicine Name</th>
                            <th className="px-4 py-2 border">Affiliate Name</th>
                            <th className="px-4 py-2 border">Dose</th>
                            <th className="px-4 py-2 border">Tablet</th>
                            <th className="px-4 py-2 border">Frequency</th>
                            <th className="px-4 py-2 border">Meal</th>
                            <th className="px-4 py-2 border">Duration</th>
                            <th className="px-4 py-2 border">Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.medicine.map((medicineItem, index) =>
                            medicineItem.details.map((detail, detailIndex) => (
                              <tr key={`${index}-${detailIndex}`} className="border-b">
                                {detailIndex === 0 && (
                                  <>
                                    <td
                                      className="px-4 py-2 border"
                                      rowSpan={medicineItem.details.length}
                                    >
                                      {medicineItem.medicineName.split("[")[0]}
                                    </td>
                                    <td
                                      className="px-4 py-2 border"
                                      rowSpan={medicineItem.details.length}
                                    >
                                      {medicineItem.affiliateName}
                                    </td>
                                  </>
                                )}
                                <td className="px-4 py-2 border">{detail.dose}</td>
                                <td className="px-4 py-2 border">{detail.tablet}</td>
                                <td className="px-4 py-2 border">
                                  {`${detail.frequency.number} times per ${detail.frequency.period}`}
                                  {detail.frequency.period === "week" &&
                                    detail.frequency.selectedDays.length > 0 && (
                                      <div className="text-xs mt-1">
                                        ({detail.frequency.selectedDays
                                          .map(
                                            (day) => day.charAt(0).toUpperCase() + day.slice(1)
                                          )
                                          .join(", ")})
                                      </div>
                                    )}
                                </td>
                                <td className="px-4 py-2 border">{detail.Meal}</td>
                                <td className="px-4 py-2 border">
                                  {`${detail.duration.number} ${detail.duration.period}${detail.duration.number > 1 ? "s" : ""
                                    }`}
                                </td>
                                {detailIndex === 0 && (
                                  <td
                                    className="px-4 py-2 border"
                                    rowSpan={medicineItem.details.length}
                                  >
                                    {medicineItem.comment || (
                                      <span className="text-red-500">No Comment</span>
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex justify-center mt-4">
                      {item.appointmentId.appointmentHistory[
                        item.appointmentId.appointmentHistory.length - 1
                      ]?.prescriptions.some((prescription) => prescription._id === item._id) && (
                          <>
                            <button
                              onClick={() => {
                                setSelectedPrescriptionId(item._id);
                                setIsUpdateModalOpen(true);
                              }}
                              className="px-4 py-1 me-5 rounded-md bg-gray-600 text-sky-100 hover:bg-gray-700"
                            >
                              Update
                            </button>
                            <button
                              onClick={() => deletePrescription(item._id)}
                              className="px-4 py-1 rounded-md bg-red-700 text-sky-100 hover:bg-red-800"
                            >
                              Delete
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-3">
                  <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight">
                    Prescription not found!!
                  </h5>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {isUpdateModalOpen && (
        <Modal
          title="Update Prescription"
          open={isUpdateModalOpen}
          onCancel={() => setIsUpdateModalOpen(false)}
          footer={null}
          width={1500} // Increase width for better visibility
          style={{ top: 20 }}
          bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
        >
          <UpdatePrescription
            prescriptionId={selectedPrescriptionId}
            onClose={() => setIsUpdateModalOpen(false)}
            onUpdateSuccess={() => {
              setIsUpdateModalOpen(false);
              fetchData();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default AddPrescription;