import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Select, Button, Radio, Checkbox } from "antd";

const EnhancedFormField = ({
    fieldConfig,
    form,
    formId,
    formData,
    fieldOptions,
    handleChange,
    handleMultiSelectChange,
    handleCompositeChange,
    handleClear
}) => {
    const [activeOptionInput, setActiveOptionInput] = useState(null);
    const [inputValues, setInputValues] = useState({});
    const [hoveredOptions, setHoveredOptions] = useState({});
    const [multiSelectValues, setMultiSelectValues] = useState({});
    // Toggle a multi-select value
    const toggleMultiSelectValue = (option, value) => {
        setMultiSelectValues(prev => {
            const currentValues = prev[option] || [];

            // If value exists in array, remove it; otherwise add it
            const newValues = currentValues.includes(value)
                ? currentValues.filter(v => v !== value)
                : [...currentValues, value];

            return {
                ...prev,
                [option]: newValues
            };
        });
    };
    // Function to find trigger option by value
    const findTriggerOption = (value) => {
        if (!fieldConfig.extraOptions) return null;
        return fieldConfig.extraOptions.find(opt =>
            Array.isArray(opt.trigger) ? opt.trigger.includes(value) : opt.trigger === value
        );
    };

    // Check if trigger has a single select placeholder
    const hasSingleSelectPlaceholder = (triggerOption) => {
        return triggerOption &&
            triggerOption.placeholders &&
            triggerOption.placeholders.length === 1 &&
            ['select', 'multiselect'].includes(triggerOption.placeholders[0].type);
    };

    // Check if placeholder is multiselect type
    const isMultiSelectPlaceholder = (placeholder) => {
        return placeholder && placeholder.type === 'multiselect';
    };

    // Get currently selected values
    const getSelectedValues = () => {
        if (fieldConfig.type === "multiselect") {
            return formData[fieldConfig.field] || [];
        }
        return formData[fieldConfig.field] ? [formData[fieldConfig.field]] : [];
    };

    // Handle select all non-trigger options for multiselect
    const handleSelectAll = () => {
        if (fieldConfig.type !== "multiselect") return;

        const options = fieldOptions[fieldConfig.field] || fieldConfig.options;
        // Filter out options that have trigger words
        const nonTriggerOptions = options.filter(opt => !findTriggerOption(opt));

        // Combine current selections with non-trigger options
        const currentSelections = formData[fieldConfig.field] || [];
        const newSelections = [...currentSelections];

        // Add non-trigger options that aren't already selected
        nonTriggerOptions.forEach(opt => {
            if (!newSelections.includes(opt)) {
                newSelections.push(opt);
            }
        });

        // Update form data
        handleMultiSelectChange(formId, fieldConfig.field, newSelections);
    };

    // Apply a direct selection value
    const applyHoverValue = (triggerValue, inputValue, isMultiSelect = false) => {
        const triggerOption = findTriggerOption(triggerValue);
        if (!triggerOption) return;

        const placeholder = triggerOption.placeholders[0];
        const template = triggerOption.template || triggerValue;

        let selectedValues = inputValue;

        // If it's a multiselect, join values with commas
        if (isMultiSelect && Array.isArray(inputValue)) {
            selectedValues = inputValue.join(", ");
        }

        const finalString = template.replace(`{${placeholder.key}}`, selectedValues);

        // Update form data based on field type
        if (fieldConfig.type === "select") {
            handleChange(formId, fieldConfig.field, finalString);
        } else {
            const current = formData[fieldConfig.field];
            const currentArray = Array.isArray(current) ? current : current ? [current] : [];

            // Remove original trigger option if it exists
            const cleanedArray = currentArray.filter(val => val !== triggerValue);

            // Look for any values that might have been derived from this base option
            const baseText = triggerValue.split('{')[0].trim();
            const furtherCleanedArray = cleanedArray.filter(val => !val.startsWith(baseText));

            if (!furtherCleanedArray.includes(finalString)) {
                furtherCleanedArray.push(finalString);
            }

            handleMultiSelectChange(formId, fieldConfig.field, furtherCleanedArray);
        }

        // Reset hovered option state
        setHoveredOptions(prev => ({
            ...prev,
            [triggerValue]: false
        }));

        // Clear multi-select values for this option
        setMultiSelectValues(prev => ({
            ...prev,
            [triggerValue]: []
        }));
    };

    // Handle when a trigger option is selected
    const handleTriggerSelection = (value) => {
        const triggerOption = findTriggerOption(value);
        if (!triggerOption) return;

        // For simple select triggers, we don't need to do anything special
        if (hasSingleSelectPlaceholder(triggerOption)) {
            return; // Will be handled by hover
        }

        // For complex triggers, use the expanded form
        const initialValues = {};
        if (Array.isArray(triggerOption.placeholders)) {
            triggerOption.placeholders.forEach(p => {
                initialValues[p.key] = p.type === "multiselect" ? [] : "";
            });
        }

        setActiveOptionInput(value);
        setInputValues({ ...initialValues });
    };

    // Handle input value change
    const handleInputValueChange = (placeholderKey, value) => {
        setInputValues(prev => ({
            ...prev,
            [placeholderKey]: value
        }));
    };

    // Apply input values for complex forms
    const applyInputValues = () => {
        if (!activeOptionInput) return;

        const triggerOption = findTriggerOption(activeOptionInput);
        if (!triggerOption) return;

        let finalString = triggerOption.template || activeOptionInput;

        if (triggerOption.placeholders && Array.isArray(triggerOption.placeholders)) {
            triggerOption.placeholders.forEach(placeholder => {
                let replacement = inputValues[placeholder.key];
                if (Array.isArray(replacement)) {
                    replacement = replacement.join(", ");
                }
                finalString = finalString.replace(`{${placeholder.key}}`, replacement);
            });
        }

        // Update form data based on field type
        if (fieldConfig.type === "select") {
            handleChange(formId, fieldConfig.field, finalString);
        } else {
            const current = formData[fieldConfig.field];
            const currentArray = Array.isArray(current) ? current : current ? [current] : [];
            const cleanedArray = currentArray.filter(val => val !== activeOptionInput);

            if (!cleanedArray.includes(finalString)) {
                cleanedArray.push(finalString);
            }

            handleMultiSelectChange(formId, fieldConfig.field, cleanedArray);
        }

        // Reset active input
        setActiveOptionInput(null);
        setInputValues({});
    };

    // Cancel active option input
    const cancelActiveOptionInput = () => {
        if (fieldConfig.type === "multiselect" && activeOptionInput) {
            const current = formData[fieldConfig.field] || [];
            const filtered = current.filter(val => val !== activeOptionInput);
            handleMultiSelectChange(formId, fieldConfig.field, filtered);
        }
        setActiveOptionInput(null);
        setInputValues({});
    };

    // Render active option input UI
    const renderActiveOptionInput = () => {
        if (!activeOptionInput) return null;

        const triggerOption = findTriggerOption(activeOptionInput);
        if (!triggerOption) return null;

        // Extract the base text (before the first placeholder)
        const baseText = activeOptionInput.split('{')[0].trim();

        return (
            <div
                className="active-option-input"
                style={{
                    backgroundColor: '#f0f9ff',
                    padding: '12px',
                    margin: '8px 0',
                    borderRadius: '4px',
                    border: '1px solid #d9d9d9'
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px'
                }}>
                    <span style={{ fontWeight: 'bold' }}>{baseText}</span>
                    <Button
                        type="text"
                        onClick={cancelActiveOptionInput}
                        style={{ padding: '0 4px' }}
                    >
                        ×
                    </Button>
                </div>

                {triggerOption.placeholders && triggerOption.placeholders.map((placeholder, index) => (
                    <div
                        key={placeholder.key}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: index < triggerOption.placeholders.length - 1 ? '8px' : '0'
                        }}
                    >
                        <div style={{ width: '100px', marginRight: '8px' }}>
                            {placeholder.label}:
                        </div>
                        {placeholder.type === 'select' ? (
                            <Select
                                style={{ flex: 1 }}
                                placeholder={`Select ${placeholder.label}`}
                                value={inputValues[placeholder.key] || undefined}
                                onChange={value => handleInputValueChange(placeholder.key, value)}
                                autoFocus={index === 0}
                            >
                                {placeholder.options?.map(opt => (
                                    <Select.Option key={opt} value={opt}>{opt}</Select.Option>
                                ))}
                            </Select>
                        ) : placeholder.type === 'multiselect' ? (
                            <Select
                                mode="multiple"
                                style={{ flex: 1 }}
                                placeholder={`Select ${placeholder.label}`}
                                value={inputValues[placeholder.key] || []}
                                onChange={value => handleInputValueChange(placeholder.key, value)}
                                autoFocus={index === 0}
                            >
                                {placeholder.options?.map(opt => (
                                    <Select.Option key={opt} value={opt}>{opt}</Select.Option>
                                ))}
                            </Select>
                        ) : placeholder.type === 'checkbox' ? (
                            <Checkbox.Group
                                style={{ flex: 1 }}
                                options={placeholder.options}
                                value={inputValues[placeholder.key] || []}
                                onChange={value => handleInputValueChange(placeholder.key, value)}
                            />
                        ) : (
                            <Input
                                style={{ flex: 1 }}
                                placeholder={`Enter ${placeholder.label}`}
                                value={inputValues[placeholder.key] || ""}
                                onChange={e => handleInputValueChange(placeholder.key, e.target.value)}
                                onPressEnter={applyInputValues}
                                autoFocus={index === 0}
                            />
                        )}
                    </div>
                ))}

                <div style={{ marginTop: '12px', textAlign: 'right' }}>
                    <Button
                        type="primary"
                        onClick={applyInputValues}
                    >
                        Apply
                    </Button>
                </div>
            </div>
        );
    };

    // Check if an option should be shown in the dropdown
    const shouldShowOption = (option) => {
        const selectedValues = getSelectedValues();

        // If it's already selected
        if (selectedValues.includes(option)) {
            return false;
        }

        // For trigger options, check if a derived value exists
        const triggerOption = findTriggerOption(option);
        if (triggerOption) {
            if (activeOptionInput === option) {
                return true;
            }

            // Check if any selected value is derived from this option
            const baseText = option.split('{')[0].trim();
            return !selectedValues.some(val => val.startsWith(baseText) && val !== option);
        }

        return true;
    };

    // Filter options for display in dropdown
    const filterDropdownOptions = () => {
        const currentOptions = fieldOptions[fieldConfig.field] || fieldConfig.options;
        return currentOptions.filter(shouldShowOption);
    };

    // Custom option renderer for Select components
    const renderOption = (option, index, totalOptions) => {
        const triggerOption = findTriggerOption(option);

        if (!triggerOption) {
            return (
                <Select.Option key={option} value={option}>
                    {option}
                </Select.Option>
            );
        }

        // For options with placeholders, show visual indicator
        // Get the text part before the first placeholder
        let displayText = option;
        let placeholderText = "";

        if (option.includes('{')) {
            displayText = option.split('{')[0].trim();
            placeholderText = option.substring(displayText.length).trim();
        }

        const isSimpleSelect = hasSingleSelectPlaceholder(triggerOption);

        if (isSimpleSelect) {
            const placeholder = triggerOption.placeholders[0];
            const isMultiSelect = isMultiSelectPlaceholder(placeholder);

            return (
                <Select.Option
                    key={option}
                    value={option}
                    className="hoverable-option"
                    onMouseEnter={() => setHoveredOptions(prev => ({ ...prev, [option]: true }))}
                    onMouseLeave={() => {
                        // Small delay to allow clicking on input
                        setTimeout(() => {
                            if (document.activeElement.tagName !== 'INPUT' &&
                                document.activeElement.tagName !== 'SELECT' &&
                                document.activeElement.tagName !== 'BUTTON') {
                                setHoveredOptions(prev => ({ ...prev, [option]: false }));
                            }
                        }, 100);
                    }}
                    onClick={(e) => {
                        // Prevent event propagation to avoid closing the dropdown
                        e.stopPropagation();
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>{displayText}</span>
                        {hoveredOptions[option] && (
                            <div
                                onClick={e => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }}
                                style={{
                                    marginLeft: '8px',
                                    display: 'flex',
                                    flexDirection: isMultiSelect ? 'column' : 'row',
                                    flexWrap: 'wrap',
                                    gap: '4px'
                                }}
                            >
                                {isMultiSelect ? (
                                    <>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                            {placeholder.options?.map(opt => {
                                                const isSelected = (multiSelectValues[option] || []).includes(opt);
                                                return (
                                                    <Checkbox
                                                        key={opt}
                                                        checked={isSelected}
                                                        onChange={() => toggleMultiSelectValue(option, opt)}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                        }}
                                                        style={{ margin: '2px' }}
                                                    >
                                                        {opt}
                                                    </Checkbox>
                                                );
                                            })}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '4px' }}>
                                            <Button
                                                size="small"
                                                type="primary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();
                                                    applyHoverValue(option, multiSelectValues[option] || [], true);
                                                }}
                                                disabled={(multiSelectValues[option] || []).length === 0}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    placeholder.options?.map(opt => (
                                        <Button
                                            key={opt}
                                            size="small"
                                            style={{
                                                padding: '0 8px',
                                                margin: '0 2px'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.nativeEvent.stopImmediatePropagation();
                                                applyHoverValue(option, opt);
                                            }}
                                        >
                                            {opt}
                                        </Button>
                                    ))
                                )}
                            </div>
                        )}
                    </div>
                </Select.Option>
            );
        } else {
            // Complex option with multiple placeholders
            const placeholderCount = triggerOption.placeholders?.length || 0;

            return (
                <Select.Option
                    key={option}
                    value={option}
                    className="hoverable-option"
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '4px',
                        width: '100%'
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '6px' }}>{displayText}</span>
                            <span style={{ color: '#888', fontStyle: 'italic', fontSize: '12px' }}>
                                {placeholderText}
                            </span>
                        </div>
                        <span
                            style={{
                                color: '#1890ff',
                                fontSize: '12px',
                                background: '#e6f7ff',
                                padding: '2px 6px',
                                borderRadius: '2px',
                                whiteSpace: 'nowrap',
                                marginLeft: '8px'
                            }}
                        >
                            {placeholderCount > 1 ? `${placeholderCount} inputs` : 'Click to edit'}
                        </span>
                    </div>
                </Select.Option>
            );
        }
    };

    // Custom tag render function for multiselect
    const tagRender = ({ label, value, closable, onClose }) => {
        // Check if this is a base option (not a processed one)
        const isPureOption = fieldConfig.options.includes(value);
        const triggerOption = isPureOption ? findTriggerOption(value) : null;

        // If it's not a trigger option or not pure, render normal tag
        if (!isPureOption || !triggerOption) {
            return (
                <div style={{
                    display: 'inline-flex',
                    alignItems: 'flex-start',
                    background: '#f0f0f0',
                    border: '1px solid #d9d9d9',
                    borderRadius: '2px',
                    margin: '2px',
                    padding: '4px 8px',
                    maxWidth: '100%',
                    wordBreak: 'break-word',
                    height: 'auto',
                    minHeight: '24px',
                }}>
                    <span style={{
                        flex: '1',
                        paddingRight: closable ? '4px' : '0',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }}>
                        {label}
                    </span>
                    {closable && (
                        <Button
                            size="small"
                            type="text"
                            onClick={onClose}
                            style={{
                                marginLeft: '4px',
                                display: 'inline-flex',
                                alignSelf: 'center',
                                flexShrink: 0
                            }}
                        >
                            ×
                        </Button>
                    )}
                </div>
            );
        }

        return null; // Don't render tags for trigger options that need input
    };

    // Handle dropdown visible change
    const handleDropdownVisibleChange = (open) => {
        if (!open) {
            setTimeout(() => {
                setHoveredOptions({});
                setMultiSelectValues({});
            }, 200); // Add a small delay to allow direct selection to complete
        }
    };

    // Add click outside handler to close dropdowns
    useEffect(() => {
        const handleClickOutside = () => {
            setHoveredOptions({});
            setMultiSelectValues({});
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Main render method based on field type
    switch (fieldConfig.type) {
        case "composite":
            return (
                <Form.Item label={fieldConfig.label}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {fieldConfig.fields.map(subField => (
                            <div key={subField.field} style={{ flex: 1, marginRight: '16px' }}>
                                <Form.Item label={subField.label} style={{ marginBottom: 0 }}>
                                    <Select
                                        placeholder={`Select ${subField.label}`}
                                        value={(formData[fieldConfig.field] && formData[fieldConfig.field][subField.field]) || undefined}
                                        onChange={value => handleCompositeChange(formId, fieldConfig.field, subField.field, value)}
                                        style={{ width: '100%' }}
                                    >
                                        {subField.options.map(opt => (
                                            <Select.Option key={opt} value={opt}>{opt}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        ))}
                        <Button
                            type="link"
                            onClick={() => handleClear(formId, fieldConfig.field, fieldConfig.type)}
                        >
                            Clear
                        </Button>
                    </div>
                </Form.Item>
            );

        case "multiselect":
            return (
                <Form.Item label={fieldConfig.label}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                <Select
                                    mode="tags"
                                    placeholder={`Select ${fieldConfig.label}`}
                                    value={formData[fieldConfig.field] || []}
                                    onChange={valueArray => handleMultiSelectChange(formId, fieldConfig.field, valueArray)}
                                    onSelect={value => {
                                        const triggerOption = findTriggerOption(value);
                                        if (triggerOption && !hasSingleSelectPlaceholder(triggerOption)) {
                                            handleTriggerSelection(value);
                                            return; // Prevent adding raw option
                                        }
                                    }}
                                    onFocus={() => setHoveredOptions({})}
                                    style={{ width: '100%' }}
                                    tagRender={tagRender}
                                    optionLabelProp="children"
                                    listItemHeight={45}
                                    listHeight={320}
                                    onDropdownVisibleChange={handleDropdownVisibleChange}
                                >
                                    {filterDropdownOptions().map((option, index, array) =>
                                        renderOption(option, index, array.length)
                                    )}
                                </Select>
                            </div>
                            <div>
                                <Button
                                    type="link"
                                    onClick={() => handleClear(formId, fieldConfig.field, fieldConfig.type)}
                                >
                                    Clear
                                </Button>
                                <Button
                                    type="link"
                                    onClick={handleSelectAll}
                                >
                                    Select All
                                </Button>
                            </div>
                        </div>

                        <div style={{ position: 'relative', width: '100%' }}>
                            {activeOptionInput && renderActiveOptionInput()}
                        </div>
                    </div>
                </Form.Item>
            );

        case "checkbox":
            return (
                <Form.Item label={fieldConfig.label}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <Radio.Group
                                options={fieldConfig.options || ["Yes", "No"]}
                                value={formData[fieldConfig.field] || ""}
                                onChange={e => handleChange(formId, fieldConfig.field, e.target.value)}
                            />
                        </div>
                        <Button
                            type="link"
                            onClick={() => handleClear(formId, fieldConfig.field, fieldConfig.type)}
                        >
                            Clear
                        </Button>
                    </div>
                </Form.Item>
            );

        case "type":
            return (
                <Form.Item label={fieldConfig.label}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <Input
                                placeholder={`Enter ${fieldConfig.label}`}
                                value={formData[fieldConfig.field] || ""}
                                onChange={e => handleChange(formId, fieldConfig.field, e.target.value)}
                            />
                        </div>
                        <Button
                            type="link"
                            onClick={() => handleClear(formId, fieldConfig.field, fieldConfig.type)}
                        >
                            Clear
                        </Button>
                    </div>
                </Form.Item>
            );

        default: // select
            return (
                <Form.Item label={fieldConfig.label}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                <Select
                                    showSearch
                                    placeholder={`Select ${fieldConfig.label}`}
                                    value={formData[fieldConfig.field] || undefined}
                                    onChange={value => {
                                        const triggerOption = findTriggerOption(value);
                                        if (triggerOption && !hasSingleSelectPlaceholder(triggerOption)) {
                                            handleTriggerSelection(value);
                                            return; // Prevent setting raw option
                                        } else if (!triggerOption) {
                                            handleChange(formId, fieldConfig.field, value);
                                        }
                                    }}
                                    onFocus={() => setHoveredOptions({})}
                                    filterOption={(input, option) => {
                                        if (!option.children) return false;
                                        if (typeof option.children === 'string') {
                                            return option.children.toLowerCase().includes(input.toLowerCase());
                                        }
                                        // For options with complex children (React elements)
                                        const text = option.children.props?.children?.[0]?.props?.children;
                                        return text && typeof text === 'string' &&
                                            text.toLowerCase().includes(input.toLowerCase());
                                    }}
                                    style={{ width: '100%' }}
                                    optionLabelProp="children"
                                    listItemHeight={45}
                                    listHeight={320}
                                    onDropdownVisibleChange={handleDropdownVisibleChange}
                                >
                                    {filterDropdownOptions().map((option, index, array) =>
                                        renderOption(option, index, array.length)
                                    )}
                                </Select>
                            </div>
                            <Button
                                type="link"
                                onClick={() => handleClear(formId, fieldConfig.field, fieldConfig.type)}
                            >
                                Clear
                            </Button>
                        </div>

                        <div style={{ position: 'relative', width: '100%' }}>
                            {activeOptionInput && renderActiveOptionInput()}
                        </div>
                    </div>
                </Form.Item>
            );
    }
};

// Add this CSS to your styles
/*
.custom-hover-dropdown .hoverable-option:hover {
background-color: #f0f0f0;
}

.ant-select-dropdown.custom-hover-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
background-color: transparent;
}
*/

export default EnhancedFormField;