export const GOUTY_ARTHRITIS = [
    /// Total Duration of Illness
    {
        field: "gaDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Initial Attack
    {
        field: "ga-initialAttack",
        label: "Initial Attack",
        type: "multiselect",
        options: [
            "initial attacks {value} {months/years} back",
            "total {value} attacks in last 6 months",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "initial attacks {value} {months/years} back",
                ],
                placeholders: [

                    {
                        key: "value",
                        type: "select",
                        label: "Duration Value",

                        options: Array.from({ length: 50 }, (_, i) => i + 1),
                    },
                    {
                        key: "months/years",
                        type: "select",
                        label: "Duration Unit",

                        options: [
                            "months",
                            "years",
                        ]
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "total {value} attacks in last 6 months",
                ],
                placeholders: [

                    {
                        key: "value",
                        type: "select",
                        label: "Total Attacks",
                        options: Array.from({ length: 30 }, (_, i) => i + 1),
                    },
                ]
            },
        ],
    },
    /// Current Attack
    {
        field: "ga-currentAttack",
        label: "Current Attack",
        type: "select",
        options: [
            "{value} days back",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "{value} days back",
                ],
                placeholders: [

                    {
                        key: "value",
                        type: "select",
                        label: "Days",
                        options: Array.from({ length: 100 }, (_, i) => i + 1),
                    },
                ]
            },
        ],
    },
    /// Family History
    {
        field: "ga-familyHistory",
        label: "Family History",
        type: "multiselect",
        options: [
            "Positive in Father",
            "Positive in brother",
            "Positive in GrandFather",
            "Negative",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },

    /// Current Alcohol Consumption
    {
        field: "ga-currentAlcoholConsumption",
        label: "Current Alcohol Consumption",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Screening for
    {
        field: "ga-screeningFor",
        label: "Screening for",
        type: "multiselect",
        options: [
            "Diabetes {Done/Not Done/Refused}",
            "Dyslipidemia {Done/Not Done/Refused}",
            "Cardiac issues {Done/Not Done/Refused}",
            "Fatty liver {Done/Not Done/Refused}",
            "Nephrolithiasis {Done/Not Done/Refused}",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "Diabetes {Done/Not Done/Refused}",
                    "Dyslipidemia {Done/Not Done/Refused}",
                    "Cardiac issues {Done/Not Done/Refused}",
                    "Fatty liver {Done/Not Done/Refused}",
                    "Nephrolithiasis {Done/Not Done/Refused}",
                ],
                placeholders: [

                    {
                        key: "Done/Not Done/Refused",
                        type: "select",
                        label: "Condition",

                        options: [
                            "Done",
                            "Not Done",
                            "Refused",
                        ]
                    }
                ]
            },
        ]
    },
    /// Comorbidities Field
    {
        field: "ga-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
            "{ Write Something Here }"
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Conception Plan Field
    {
        field: "ga-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "ga-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "ga-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "ga-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "ga-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Symptoms suggestive of increased disease activity",
            "Symptoms suggestive of side effects of drugs",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    }
]