import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, Flex, Spin, Row, Col, message } from "antd";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

const UpdateMedicine = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { medId } = useParams();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [expDate, setExpDate] = useState("");
  const [manfDate, setManufactureDate] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
    },
  };

  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/medicine/${medId}`, config)
      .then((response) => {
        const data = response.data.data;
        const fetchedExpDate = data.expDate.split("T")[0];
        const fetchedManfDate = data.manfDate.split("T")[0];
        setExpDate(fetchedExpDate);
        setManufactureDate(fetchedManfDate);
        form.setFieldsValue({
          name: data.name,
          type: data.type,
          affiliateName: data.affiliateName,
          quantity: data.quantity,
          batch: data.batch,
          price: data.price,
          costPrice: data.costPrice, // Add costPrice to pre-filled fields
          dosage: data.dosage,
        });
      })
      .catch((error) => {
        console.error("Error fetching medicine details:", error);
        message.error("Error fetching medicine details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMedicine = async (values) => {
    setActionLoading(true);

    const { affiliateName, batch, dosage, name, price, costPrice, quantity, type } = values;

    const data = {
      name,
      type,
      affiliateName,
      quantity,
      batch,
      price,
      costPrice, // Add costPrice to payload
      dosage,
      expDate,
      manfDate,
    };

    try {
      const response = await axios.put(
        `${apiBaseUrl}/update/medicine/${medId}`,
        data,
        config
      );

      if (response.data.success === true) {
        message.success("Medicine Updated.", 0.6, function onClose() {
          window.location.replace("/dashboard/medicine");
        });
      } else {
        message.error(response?.data?.error?.msg || "Failed to update medicine.");
      }
    } catch (error) {
      console.error("Failed to update medicine:", error);
      message.error("Failed to update medicine! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {(actionLoading || loading) && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </div>
      )}

      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={updateMedicine}
          className="forbox w-full bg-white max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-2"
        >
          <div className="font-medium text-3xl mb-4">Update Medicine</div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Medicine Name"
                name="name"
                rules={[
                  { required: true, message: "Please input medicine name!" },
                ]}
              >
                <Input placeholder="Medicine name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Medicine Type"
                name="type"
                rules={[
                  { required: true, message: "Please input medicine type!" },
                ]}
              >
                <Input placeholder="Medicine type (e.g., Tablets/Capsules...)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Affiliate Name"
                name="affiliateName"
                rules={[
                  { required: true, message: "Please input affiliate name!" },
                ]}
              >
                <Input placeholder="Affiliate Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Batch"
                name="batch"
                rules={[
                  { required: true, message: "Please input batch number!" },
                ]}
              >
                <Input placeholder="Batch number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label>Manufacture Date</label>
              <input
                id="manufactureDate"
                value={manfDate}
                type="date"
                onChange={(e) => {
                  setManufactureDate(e.target.value);
                }}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3"
                name="title"
                placeholder="Select Manufacture Date"
                required
              />
            </Col>
            <Col span={12}>
              <label>Expiry Date</label>
              <input
                id="expDate"
                type="date"
                value={expDate}
                onChange={(e) => {
                  setExpDate(e.target.value);
                }}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3"
                name="title"
                placeholder="Select Expiry Date"
                required
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please input medicine quantity!",
                  },
                ]}
              >
                <Input type="number" placeholder="Medicine quantity" min={1} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Price/Quantity"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please input price per quantity!",
                  },
                ]}
              >
                <Input type="number" placeholder="Selling price per quantity" min={0} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Cost Price/Quantity"
                name="costPrice"
                rules={[
                  {
                    required: true,
                    message: "Please input cost price per quantity!",
                  },
                ]}
              >
                <Input type="number" placeholder="Cost price per quantity" min={0} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Dosage/Milligram (MG)"
                name="dosage"
                rules={[{ required: true, message: "Please input dosage!" }]}
              >
                <Input placeholder="Enter with MG" />
              </Form.Item>
            </Col>
          </Row>

          <button
            id="updateMedicine"
            className="w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
};

export default UpdateMedicine;