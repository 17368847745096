import React, { useState, useEffect } from "react";
import { Button, Divider, message, Spin } from "antd";
import axios from "axios";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import "./consultationPrintout.css";
import moment from "moment-timezone";
import { calculateAge } from "../../lib/constants/calculateAge";

const ConsultationPrintout = ({ appointmentId }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [appointmentHistory, setAppointmentHistory] = useState("");
  const [DOB, setDOB] = useState("");
  const [age, setAge] = useState("");
  const [loading, setLoading] = useState(true); // Changed from "" to true for clarity
  const [details, setDetails] = useState(null); // Changed to null for better type checking

  // Calculate age whenever DOB changes
  useEffect(() => {
    const calculatedAge = calculateAge(DOB);
    if (calculatedAge !== undefined && calculatedAge !== "Invalid date of birth") {
      setAge(calculatedAge);
    } else {
      setAge("");
    }
  }, [DOB]);

  // Fetch data on mount or when appointmentId changes
  useEffect(() => {
    fetchData();
  }, [appointmentId]);

  // Dynamic auth config
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];
    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} };
    }
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
      },
    };
  };

  const config = getAuthConfig();

  const fetchData = async () => {
    setLoading(true);
    const url = `${apiBaseUrl}/appointment/${appointmentId}`;

    try {
      const response = await axios.get(url, config);
      if (response.data.success) {
        const data = response.data.data;
        setDetails(data);
        setDOB(data.DOB || "");
        setAppointmentHistory(data.appointmentHistory || "");
        console.log(data);
      } else {
        message.error("Failed to fetch appointment details.");
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
      message.error("Failed to get appointment details! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    const printableContent = document.getElementById("printableContent").innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Consultation Printout</title>
          <style>
            @media print {
              body { font-family: Arial, sans-serif; margin: 20px; }
              .print-content { width: 100%; }
              .text-2xl { font-size: 24px; }
              .flex { display: flex; }
              .justify-between { justify-content: space-between; }
              .flex-col { flex-direction: column; }
              .text-start { text-align: left; }
              .text-end { text-align: right; }
              .mb-3 { margin-bottom: 1rem; }
              .mb-8 { margin-bottom: 2rem; }
              .mt-3 { margin-top: 1rem; }
              .me-5 { margin-right: 1.25rem; }
              .p-4 { padding: 1rem; }
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${printableContent}
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const convertToNepaliTime = (utcTime) => {
    return utcTime
      ? moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A")
      : "N/A";
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin size="large" />
      </div>
    );
  }

  if (!details) {
    return <div>No appointment data available.</div>;
  }

  return (
    <div>
      <div id="printableContent" className="print-content">
        <div className="container p-4 place-content-center">
          <div className="mb-3">
            <div className="text-center">
              <span className="text-2xl">Tulsi MultiSpeciality Clinic</span>
              <p>
                <EnvironmentOutlined /> Avm chowk 05, Lalitpur 44600
              </p>
              <p>
                <PhoneOutlined /> 01-5914294
              </p>
            </div>
          </div>

          <div className="flex justify-between mb-8">
            <div className="flex flex-col text-start">
              <p>Name: {details.fullname || "N/A"}</p>
              <p>
                Age/Sex: {age || "N/A"} / {details.gender || "N/A"}
              </p>
              <p>Address: {details.address || "N/A"}</p>
            </div>
            <div className="flex flex-col text-end">
              <p>Appointment No: {details.appointmentNumber || "N/A"}</p>
              <p>Date: {convertToNepaliTime(details.date)}</p>
            </div>
          </div>

          <Divider />
        </div>
      </div>

      {/* Print Button */}
      <div className="flex justify-center mt-4">
        <Button
          type="primary"
          onClick={handlePrint}
          className="bg-sky-600 hover:bg-sky-700 text-white border shadow font-semibold text-md rounded-md"
        >
          Print Consultation
        </Button>
      </div>
    </div>
  );
};

export default ConsultationPrintout;