import React, { useState, useEffect } from "react";
import axios from "axios";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../../lib/constants/loadingScreen";
import { message, Select } from "antd";
import moment from "moment";

const { Option } = Select;

const UpdatePrescription = ({ prescriptionId, onClose, onUpdateSuccess }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [followUp, setFollowUp] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [doctorDepartment, setDoctorDepartment] = useState("");

  // Predefined options for select fields
  const NUMBER_OPTIONS = [
    { value: "1 (1-0-0)", label: "Once (1-0-0)" },
    { value: "1 (0-1-0)", label: "Once (0-1-0)" },
    { value: "1 (0-0-1)", label: "Once (0-0-1)" },
    { value: "2 (1-1-0)", label: "Twice (1-1-0)" },
    { value: "2 (1-0-1)", label: "Twice (1-0-1)" },
    { value: "2 (0-1-1)", label: "Twice (0-1-1)" },
    { value: "3", label: "Thrice" },
    { value: "4", label: "Four times" },
    { value: "5", label: "Five times" },
    { value: "6", label: "Six times" },
  ];
  const PERIOD_OPTIONS = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ];
  const DURATION_NUMBER_OPTIONS = Array.from({ length: 11 }, (_, i) => ({
    value: `${i + 1}`,
    label: `${i + 1}`,
  }));
  const TABLET_NUMBER_OPTIONS = Array.from({ length: 4 }, (_, i) => ({
    value: `${i + 1}`,
    label: `${i + 1}`,
  }));
  const DURATION_PERIOD_OPTIONS = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ];
  const MEAL_OPTIONS = [
    { value: "after", label: "After" },
    { value: "before", label: "Before" },
  ];
  const DAYS_OPTIONS = [
    { value: "sunday", label: "Sunday" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
  ];

  const [medicineList, setMedicineList] = useState([
    {
      medicineName: "",
      affiliateName: "",
      comment: "",
      details: [
        {
          dose: "",
          tablet: "",
          frequency: { number: "", period: "", selectedDays: [] },
          Meal: "",
          duration: { number: "", period: "" },
        },
      ],
      selectedMedicine: null,
    },
  ]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") || localStorage.getItem("staffticket")),
    },
  };
  const doctorConfig = {
    headers: { Authorization: "Bearer " + localStorage.getItem("doctorticket") },
  };

  useEffect(() => {
    fetchPrescription();
    if (localStorage.getItem("doctorticket")) fetchDoctorDepartment();
    fetchMedicine();
  }, [prescriptionId]);

  const fetchPrescription = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/doctor/get_gingle_prescription/${prescriptionId}`)
      .then((response) => {
        const prescriptionData = response.data.data;
        setFollowUp(moment(prescriptionData.follow_up).format("YYYY-MM-DD"));
        setComment(prescriptionData.comment || "");
        const transformedMedicineList = prescriptionData.medicine.map((med) => ({
          medicineName: med.medicineName,
          affiliateName: med.affiliateName,
          comment: med.comment || "",
          details: med.details.map((detail) => ({
            dose: detail.dose || "",
            tablet: detail.tablet || "",
            frequency: {
              number: detail.frequency.number || "",
              period: detail.frequency.period || "",
              selectedDays: detail.frequency.selectedDays || [],
            },
            Meal: detail.Meal || "",
            duration: {
              number: detail.duration.number || "",
              period: detail.duration.period || "",
            },
          })),
          selectedMedicine: med.selectedMedicine || null,
        }));
        setMedicineList(transformedMedicineList);
      })
      .catch((e) => {
        console.error("Failed to get prescription details:", e);
        message.error("Failed to get prescription details! Please try again.");
      })
      .finally(() => setLoading(false));
  };

  const fetchDoctorDepartment = () => {
    axios
      .get(`${apiBaseUrl}/doctor/profile`, doctorConfig)
      .then((response) => {
        if (response.data.success)
          setDoctorDepartment(response.data.data.department.department);
      })
      .catch((e) => {
        console.error("Failed to fetch doctor department:", e);
        message.error("Failed to get doctor details! Please try again.");
      });
  };

  const fetchMedicine = () => {
    axios
      .get(`${apiBaseUrl}/get/doctor/department/medicine`)
      .then((response) => {
        if (response.data.success) setMedicineOptions(response.data.data);
      })
      .catch((error) => console.error("Error fetching medicine:", error));
  };

  const updatePrescription = async (e) => {
    e.preventDefault();
    setActionLoading(true);

    const isFollowUpEmpty = !followUp;
    const isMedicineListEmpty =
      medicineList.length === 0 ||
      JSON.stringify(medicineList[0]) ===
      JSON.stringify({
        medicineName: "",
        affiliateName: "",
        comment: "",
        details: [
          {
            dose: "",
            tablet: "",
            frequency: { number: "", period: "", selectedDays: [] },
            Meal: "",
            duration: { number: "", period: "" },
          },
        ],
        selectedMedicine: null,
      });

    if (isFollowUpEmpty && isMedicineListEmpty) {
      setActionLoading(false);
      message.error("Both medicine and follow-up cannot be empty");
      return;
    }

    let validationError = "";
    for (const medicine of medicineList) {
      if (!medicine.medicineName?.trim()) {
        validationError = "Medicine Name is required for all entries.";
        break;
      }
      if (!medicine.affiliateName?.trim()) {
        validationError = "Affiliate Name is required for all entries.";
        break;
      }
      for (const detail of medicine.details) {
        if (
          !detail.dose ||
          !detail.tablet ||
          !detail.frequency.number ||
          !detail.frequency.period ||
          !detail.Meal ||
          !detail.duration.number ||
          !detail.duration.period
        ) {
          validationError =
            "All detail fields (Dose, Tablet, Frequency, Meal, Duration) are required.";
          break;
        }
        if (
          detail.frequency.period === "week" &&
          detail.frequency.selectedDays.length === 0
        ) {
          validationError = "Please select days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          ["1 (1-0-0)", "1 (0-1-0)", "1 (0-0-1)"].includes(detail.frequency.number) &&
          detail.frequency.selectedDays.length !== 1
        ) {
          validationError = "Please select one day for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          ["2 (1-1-0)", "2 (1-0-1)", "2 (0-1-1)"].includes(detail.frequency.number) &&
          detail.frequency.selectedDays.length !== 2
        ) {
          validationError = "Please select two days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          detail.frequency.number === "3" &&
          detail.frequency.selectedDays.length !== 3
        ) {
          validationError = "Please select three days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          detail.frequency.number === "4" &&
          detail.frequency.selectedDays.length !== 4
        ) {
          validationError = "Please select four days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          detail.frequency.number === "5" &&
          detail.frequency.selectedDays.length !== 5
        ) {
          validationError = "Please select five days for weekly frequency.";
          break;
        } else if (
          detail.frequency.period === "week" &&
          detail.frequency.number === "6" &&
          detail.frequency.selectedDays.length !== 6
        ) {
          validationError = "Please select six days for weekly frequency.";
          break;
        }
      }
      if (validationError) break;
    }

    if (validationError) {
      setActionLoading(false);
      message.error(validationError);
      return;
    }

    const data = { medicine: medicineList, follow_up: followUp, comment };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.put(
          `${apiBaseUrl}/doctor/prescription/update/${prescriptionId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.put(
          `${apiBaseUrl}/staff/prescription/update/${prescriptionId}`,
          data,
          config
        );
      }

      if (response.data.success) {
        message.success("Prescription Updated", 0.6, () => {
          onUpdateSuccess();
          onClose();
        });
      } else {
        message.error("Failed to update! Please try again.");
      }
    } catch (error) {
      console.error("Error updating prescription:", error);
      message.error("Failed to update prescription! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const addMedicine = () => {
    setMedicineList([
      ...medicineList,
      {
        medicineName: "",
        affiliateName: "",
        comment: "",
        details: [
          {
            dose: "",
            tablet: "",
            frequency: { number: "", period: "", selectedDays: [] },
            Meal: "",
            duration: { number: "", period: "" },
          },
        ],
        selectedMedicine: null,
      },
    ]);
  };

  const addDetailRow = (medicineIndex) => {
    const updatedList = [...medicineList];
    updatedList[medicineIndex].details.push({
      dose: "",
      tablet: "",
      frequency: { number: "", period: "", selectedDays: [] },
      Meal: "",
      duration: { number: "", period: "" },
    });
    setMedicineList(updatedList);
  };

  const removeDetailRow = (medicineIndex, detailIndex) => {
    const updatedList = [...medicineList];
    updatedList[medicineIndex].details.splice(detailIndex, 1);
    setMedicineList(updatedList);
  };

  const removeMedicine = (index) => {
    setMedicineList((prevList) => {
      const updatedList = prevList.filter((_, idx) => idx !== index);
      return updatedList.length === 0
        ? [
          {
            medicineName: "",
            affiliateName: "",
            comment: "",
            details: [
              {
                dose: "",
                tablet: "",
                frequency: { number: "", period: "", selectedDays: [] },
                Meal: "",
                duration: { number: "", period: "" },
              },
            ],
            selectedMedicine: null,
          },
        ]
        : updatedList;
    });
  };

  const handleInputChange = (medicineIndex, key, value, detailIndex = null) => {
    const updatedList = [...medicineList];
    if (detailIndex === null) {
      if (key === "medicineName") {
        const selectedMed = medicineOptions.find(
          (med) => med.name + " [" + med.affiliateName + "]" === value
        );
        updatedList[medicineIndex][key] = value;
        updatedList[medicineIndex].selectedMedicine = selectedMed || null;
        updatedList[medicineIndex].affiliateName = ""; // Reset affiliate when medicine changes
      } else {
        updatedList[medicineIndex][key] = value;
      }
    } else {
      if (key === "frequencyNumber" || key === "frequencyPeriod") {
        updatedList[medicineIndex].details[detailIndex].frequency[
          key === "frequencyNumber" ? "number" : "period"
        ] = value;
        if (key === "frequencyPeriod" && value !== "week") {
          updatedList[medicineIndex].details[detailIndex].frequency.selectedDays = [];
        }
      } else if (key === "durationNumber" || key === "durationPeriod") {
        updatedList[medicineIndex].details[detailIndex].duration[
          key === "durationNumber" ? "number" : "period"
        ] = value;
      } else if (key === "selectedDays") {
        const currentDays = updatedList[medicineIndex].details[detailIndex].frequency.selectedDays;
        const maxDays = parseInt(
          updatedList[medicineIndex].details[detailIndex].frequency.number
        ) || 1;
        if (currentDays.includes(value)) {
          updatedList[medicineIndex].details[detailIndex].frequency.selectedDays =
            currentDays.filter((day) => day !== value);
        } else if (currentDays.length < maxDays) {
          updatedList[medicineIndex].details[detailIndex].frequency.selectedDays = [
            ...currentDays,
            value,
          ];
        }
      } else {
        updatedList[medicineIndex].details[detailIndex][key] = value;
      }
    }
    setMedicineList(updatedList);
  };

  return (
    <>
      {(actionLoading || loading) && <LoadingScreen />}
      <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow">
        <p className="text-xl mb-1 font-bold tracking-tight text-gray-800">
          Update Prescription
        </p>
        <form
          className="pb-10 pt-5 mt-5 rounded-lg flex flex-col gap-4"
          onSubmit={updatePrescription}
        >
          {medicineList.map((medicine, index) => (
            <div
              className="flex flex-wrap border rounded-lg mx-3 mb-2 px-5 py-5"
              key={index}
            >
              <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                  Medicine Name
                </label>
                <Select
                  placeholder="Select a medicine"
                  showSearch
                  allowClear
                  required
                  className="w-full h-10 mt-0.2"
                  popupMatchSelectWidth={false}
                  dropdownStyle={{ zIndex: 1051, position: 'fixed' }}
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  value={medicine.medicineName || undefined}
                  onChange={(value) =>
                    handleInputChange(index, "medicineName", value || "")
                  }
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {medicineOptions.map((med, idx) => (
                    <Option
                      key={idx}
                      value={med.name + " [" + med.affiliateName + "]"}
                      style={{ whiteSpace: "normal", padding: "8px" }}
                    >
                      {med.name + " [" + med.affiliateName + "]"}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                  Affiliate Name
                </label>
                {medicine.selectedMedicine?.affiliateName && (
                  <Select
                    placeholder="Select affiliate"
                    value={medicine.affiliateName || undefined}
                    onChange={(value) =>
                      handleInputChange(index, "affiliateName", value || "")
                    }
                    className="w-full h-10 mt-0.2"
                    popupMatchSelectWidth={false}
                    dropdownStyle={{ zIndex: 1051, position: 'fixed' }}
                    style={{ width: "100%" }}
                    required
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {medicine.selectedMedicine.affiliateName.map((affiliateName, idx) => (
                      <Option key={idx} value={affiliateName}>
                        {affiliateName}
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
              <div className="w-full md:w-2/4 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                  Comment
                </label>
                <input
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                  type="text"
                  placeholder="eg.. Remarks"
                  value={medicine.comment}
                  onChange={(e) => handleInputChange(index, "comment", e.target.value)}
                />
              </div>
              <div className="mt-4 w-full overflow-x-auto">
                <table className="w-full min-w-full border-collapse border rounded-lg">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border p-2 w-1/6">Dose</th>
                      <th className="border p-2 w-1/6">Tablet</th>
                      <th className="border p-2 w-3/6">Frequency</th>
                      <th className="border p-2 w-1/6">Meal</th>
                      <th className="border p-2 w-1/6">Duration</th>
                      <th className="border p-2 w-1/12">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medicine.details.map((detail, detailIndex) => (
                      <tr key={detailIndex}>
                        <td className="border p-2">
                          {medicine.selectedMedicine?.dose && (
                            <Select
                              placeholder="Select dose"
                              value={detail.dose || undefined}
                              onChange={(value) =>
                                handleInputChange(index, "dose", value, detailIndex)
                              }
                              className="w-full h-10 mt-0.2"
                              popupMatchSelectWidth={false}
                              dropdownStyle={{ zIndex: 1051, position: 'fixed' }}
                              style={{ width: "100%" }}
                              required
                              getPopupContainer={(trigger) => trigger.parentNode}
                            >
                              {medicine.selectedMedicine.dose.map((dose, idx) => (
                                <Option key={idx} value={dose}>
                                  {dose}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </td>
                        <td className="border p-2">
                          <Select
                            placeholder="Number"
                            value={detail.tablet || undefined}
                            onChange={(value) =>
                              handleInputChange(index, "tablet", value, detailIndex)
                            }
                            className="w-full h-10 mt-0.2"
                            popupMatchSelectWidth={false}
                            dropdownStyle={{ zIndex: 1051, position: 'fixed' }}
                            style={{ width: "100%" }}
                            required
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {TABLET_NUMBER_OPTIONS.map((opt) => (
                              <Option key={opt.value} value={opt.value}>
                                {opt.label}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        <td className="border p-2">
                          <div className="flex gap-2">
                            <Select
                              placeholder="Number"
                              value={detail.frequency.number || undefined}
                              onChange={(value) =>
                                handleInputChange(index, "frequencyNumber", value, detailIndex)
                              }
                              className="w-full h-10 mt-0.2"
                              popupMatchSelectWidth={false}
                              dropdownStyle={{ zIndex: 1051, position: 'fixed' }}
                              style={{ width: "100%" }}
                              required
                              getPopupContainer={(trigger) => trigger.parentNode}
                            >
                              {NUMBER_OPTIONS.map((opt) => (
                                <Option key={opt.value} value={opt.value}>
                                  {opt.label}
                                </Option>
                              ))}
                            </Select>
                            <Select
                              placeholder="Period"
                              value={detail.frequency.period || undefined}
                              onChange={(value) =>
                                handleInputChange(index, "frequencyPeriod", value, detailIndex)
                              }
                              className="w-full h-10 mt-0.2"
                              popupMatchSelectWidth={false}
                              dropdownStyle={{ zIndex: 1051, position: 'fixed' }}
                              style={{ width: "100%" }}
                              required
                              getPopupContainer={(trigger) => trigger.parentNode}
                            >
                              {PERIOD_OPTIONS.map((opt) => (
                                <Option key={opt.value} value={opt.value}>
                                  {opt.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          {detail.frequency.period === "week" && (
                            <div className="mt-2">
                              <div className="flex flex-wrap gap-1">
                                {DAYS_OPTIONS.map((day) => (
                                  <button
                                    key={day.value}
                                    type="button"
                                    onClick={() =>
                                      handleInputChange(index, "selectedDays", day.value, detailIndex)
                                    }
                                    className={`px-2 py-1 text-xs rounded ${detail.frequency.selectedDays.includes(day.value)
                                      ? "bg-blue-500 text-white"
                                      : "bg-gray-200"
                                      }`}
                                  >
                                    {day.label}
                                  </button>
                                ))}
                              </div>
                            </div>
                          )}
                        </td>
                        <td className="border p-2">
                          <Select
                            placeholder="Before or After"
                            value={detail.Meal || undefined}
                            onChange={(value) =>
                              handleInputChange(index, "Meal", value, detailIndex)
                            }
                            className="w-full h-10 mt-0.2"
                            popupMatchSelectWidth={false}
                            dropdownStyle={{ zIndex: 1051, position: 'fixed' }}
                            style={{ width: "100%" }}
                            required
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {MEAL_OPTIONS.map((opt) => (
                              <Option key={opt.value} value={opt.value}>
                                {opt.label}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        <td className="border p-2">
                          <div className="flex gap-2">
                            <Select
                              placeholder="Number"
                              value={detail.duration.number || undefined}
                              onChange={(value) =>
                                handleInputChange(index, "durationNumber", value, detailIndex)
                              }
                              className="w-full h-10 mt-0.2"
                              popupMatchSelectWidth={false}
                              dropdownStyle={{ zIndex: 1060, position: 'fixed' }}
                              style={{ width: "100%" }}
                              required
                              getPopupContainer={(trigger) => trigger.parentNode}
                            >
                              {DURATION_NUMBER_OPTIONS.map((opt) => (
                                <Option key={opt.value} value={opt.value}>
                                  {opt.label}
                                </Option>
                              ))}
                            </Select>
                            <Select
                              placeholder="Period"
                              value={detail.duration.period || undefined}
                              onChange={(value) =>
                                handleInputChange(index, "durationPeriod", value, detailIndex)
                              }
                              className="w-full h-10 mt-0.2"
                              popupMatchSelectWidth={false}
                              dropdownStyle={{ zIndex: 1051, position: 'fixed' }}
                              style={{ width: "100%" }}
                              required
                              getPopupContainer={(trigger) => trigger.parentNode}
                            >
                              {DURATION_PERIOD_OPTIONS.map((opt) => (
                                <Option key={opt.value} value={opt.value}>
                                  {opt.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </td>
                        <td className="border p-2">
                          <IconButton
                            onClick={() => removeDetailRow(index, detailIndex)}
                            color="danger"
                            aria-label="remove detail"
                            disabled={medicine.details.length === 1}
                            style={{
                              color: "#ef4444",
                              opacity: medicine.details.length === 1 ? 0.5 : 1,
                            }}
                          >
                            <AiFillMinusCircle />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-2">
                  <IconButton
                    onClick={() => addDetailRow(index)}
                    color="primary"
                    aria-label="add detail"
                  >
                    Add Details
                    <HiPlusCircle />
                  </IconButton>
                </div>
              </div>
              <div className="w-full md:w-2/4 px-3 mb-6 md:mb-0">
                <IconButton
                  onClick={() => removeMedicine(index)}
                  color="danger"
                  aria-label="remove medicine field"
                  disabled={medicineList.length === 1}
                  style={{
                    color: "#ef4444",
                    opacity: medicineList.length === 1 ? 0.5 : 1,
                  }}
                >
                  Remove Medicine Field
                  <AiFillMinusCircle />
                </IconButton>
              </div>
            </div>
          ))}
          <IconButton onClick={addMedicine} color="primary" aria-label="add button">
            Add medicine field
            <HiPlusCircle />
          </IconButton>
          <div className="w-full md:w-4/4 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
              Comment
            </label>
            <textarea
              className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
              placeholder="eg.. Another Medicines"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div>
            <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
              Follow Up
            </label>
            <input
              id="followUp"
              value={followUp}
              type="date"
              onChange={(e) => setFollowUp(e.target.value)}
              min={new Date().toISOString().split("T")[0]}
              className="border-solid border-gray-300 border h-10 py-2 px-2 w-full rounded-md text-gray-700 date-picker"
              name="followUp"
              placeholder="Select Date"
            />
          </div>
          <button
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Update
          </button>
        </form>
      </div>
    </>
  );
};

export default UpdatePrescription;