import React, { useEffect, useState } from "react";
import { Button, Divider, message } from "antd";
import axios from "axios";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import tulasiSmallLogo from "../../tulasi_small_logo.png"; // Watermark logo
import tulasiLogo from "../../tulasi_logo.png"; // Header logo
import { Handler, registerHandlers } from "pagedjs";

const ReportPrintout = ({ reportId, onClose }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState(null);

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
        },
    };

    useEffect(() => {
        fetchData();

        // Register Paged.js handlers for pagination and page numbering
        const handler = new Handler();
        registerHandlers(handler);
    }, [reportId]);

    const fetchData = async () => {
        setLoading(true);
        const url = `${apiBaseUrl}/view/single/report/${reportId}`;

        try {
            const response = await axios.get(url, config);
            if (response.data.success) {
                setDetails(response.data.data);
            } else {
                message.error("Failed to get report details! Please try again.");
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            message.error("Failed to get report details! Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handlePrint = () => {
        const printableContent = document.getElementById("printableContent").innerHTML;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = printableContent;
        document.body.innerHTML = tempDiv.innerHTML;
        window.print();
        document.body.innerHTML = ""; // Clear the body to avoid lingering content
        // onClose(); // Close the modal after printing
        window.location.reload();
    };

    const convertToNepaliTime = (utcTime) => {
        return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
    };

    const renderReportTable = () => {
        if (!details?.report || details.report.length === 0) return null;

        const groupedReports = {};
        let currentCategory = null;

        details.report.forEach((item) => {
            if (!item.result && !item.unit && !item.refRange) {
                currentCategory = item.test;
                groupedReports[currentCategory] = [];
            } else if (currentCategory) {
                groupedReports[currentCategory].push(item);
            }
        });

        return (
            <div className="mt-6">
                {Object.keys(groupedReports).map((category, index) => {
                    const rows = groupedReports[category];
                    const estimatedHeight = 50 + rows.length * 40;
                    return (
                        <div
                            key={index}
                            className="report-section-wrapper"
                            style={{
                                breakInside: "avoid",
                                pageBreakInside: "avoid",
                                marginBottom: "20px",
                                pageBreakBefore: index > 0 && estimatedHeight > 650 ? "always" : "auto",
                            }}
                        >
                            <div className="report-section">
                                <h2 className="text-lg font-bold mb-2">{category}</h2>
                                <table
                                    className="w-full border-collapse compact-table report-table"
                                    style={{
                                        breakInside: "avoid",
                                        pageBreakInside: "avoid",
                                    }}
                                >
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="border px-2 text-left">Test Name</th>
                                            <th className="border px-2 text-left">Result</th>
                                            <th className="border px-2 text-left">Unit</th>
                                            <th className="border px-2 text-left">Reference Range</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((item, idx) => (
                                            <tr key={idx}>
                                                <td className="border px-2 px-4">{item.test}</td>
                                                <td className="border px-2">{item.result || "-"}</td>
                                                <td className="border px-2">{item.unit || "-"}</td>
                                                <td className="border px-2">{item.refRange || "-"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {index === Object.keys(groupedReports).length - 1 && (
                                <div className="report-footer-section">
                                    <div className="flex justify-center items-center my-4">
                                        <h1 className="text-m font-medium">
                                            <br />
                                            PAWAN SHRESTHA<br />
                                            LAB TECHNOLOGIST<br />
                                            NHPC NO: A-1675 MLT
                                        </h1>
                                    </div>
                                    <div className="flex justify-center items-center mb-4">
                                        <h1 className="text-m font-medium">
                                            ------------End of Report-------------
                                        </h1>
                                    </div>
                                    <div className="generated-by text-end me-5">
                                        {details?.doctorId && (
                                            <p className="mt-2 mb-0">
                                                Dr. {details.doctorId.fullname}
                                                <br />
                                                {details.appointmentId?.department?.department}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            <div className="flex justify-end mb-4">
                <Button
                    type="primary"
                    onClick={handlePrint}
                    className="bg-sky-600 hover:bg-sky-700 text-white border shadow font-semibold text-md rounded-md"
                >
                    Print Report
                </Button>
                <Button
                    type="default"
                    onClick={onClose}
                    className="mr-2 border shadow font-semibold text-md rounded-md"
                >
                    Close
                </Button>
            </div>

            <div id="printableContent" style={{ position: "relative" }}>
                {/* Watermark Background */}
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(${tulasiSmallLogo})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "400px 400px",
                        opacity: 0.03,
                        zIndex: -1,
                    }}
                />
                {/* Content */}
                <div className="print-content" style={{ position: "relative", zIndex: 1 }}>
                    <div className="container p-4 place-content-center">
                        <div className="mb-3">
                            <div className="flex items-start">
                                <img
                                    src={tulasiLogo}
                                    alt="Tulsi Clinic Logo"
                                    className="w-[120px] h-auto mb-4"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center text-center">
                                    <span className="text-2xl font-bold">Tulsi MultiSpeciality Clinic</span>
                                    <p className="my-1 text-sm">
                                        <EnvironmentOutlined /> Avm chowk 05, Lalitpur 44600
                                    </p>
                                    <p className="my-1 text-sm">
                                        <PhoneOutlined /> 01-5914294
                                    </p>
                                </div>
                                <div className="w-[120px] h-auto mb-4"></div>
                            </div>
                        </div>

                        <div className="flex justify-between mb-8">
                            <div className="flex flex-col text-start">
                                <p>Name: {details?.patientId?.fullname || "N/A"}</p>
                                <p>
                                    Age/Sex: {details?.patientId?.age || "N/A"} /{" "}
                                    {details?.patientId?.gender || "N/A"}
                                </p>
                                <p>Address: {details?.patientId?.address || "N/A"}</p>
                            </div>
                            <div className="flex flex-col text-end">
                                <p>Phone: {details?.patientId?.phone || "N/A"}</p>
                                <p>Report Date: {convertToNepaliTime(details?.createdAt)}</p>
                            </div>
                        </div>

                        <Divider />
                        {details?.reportName && (
                            <div className="mb-4">
                                <h1 className="text-xl font-bold">{details.reportName} Report</h1>
                            </div>
                        )}

                        {renderReportTable()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportPrintout;