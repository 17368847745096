import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    message,
    Space,
    InputNumber,
    Select,
    Row,
    Col,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const InventoryCostsPage = () => {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    const fetchRecords = async (page = 1, pageSize = 10) => {
        setLoading(true);
        try {
            const params = {
                page,
                limit: pageSize,
                search: searchText || undefined,
                startPurchaseDate: dateRange[0] ? dateRange[0].startOf("day").toISOString() : undefined,
                endPurchaseDate: dateRange[1] ? dateRange[1].endOf("day").toISOString() : undefined,
            };
            const response = await axios.get(`${apiBaseUrl}/inventory-costs`, { ...config, params });
            setRecords(response.data.data || []);
            setPagination({
                current: page,
                pageSize,
                total: response.data.total || 0,
            });
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to fetch records");
            setRecords([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecords(pagination.current, pagination.pageSize);
    }, [searchText, dateRange]);

    const handleSaveRecord = async (values) => {
        const recordData = {
            ...values,
            purchaseDate: values.purchaseDate.toISOString(),
        };
        setLoading(true);
        try {
            if (editingRecord) {
                await axios.put(`${apiBaseUrl}/inventory-costs/${editingRecord._id}`, recordData, config);
                message.success("Record updated successfully");
            } else {
                await axios.post(`${apiBaseUrl}/inventory-costs`, recordData, config);
                message.success("Record created successfully");
            }
            fetchRecords(pagination.current, pagination.pageSize);
            setIsModalOpen(false);
            setEditingRecord(null);
            form.resetFields();
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to save record");
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteRecord = (id) => {
        Modal.confirm({
            title: "Are you sure you want to delete this record?",
            onOk: async () => {
                setLoading(true);
                try {
                    await axios.delete(`${apiBaseUrl}/inventory-costs/${id}`, config);
                    message.success("Record deleted successfully");
                    fetchRecords(pagination.current, pagination.pageSize);
                } catch (error) {
                    message.error(error.response?.data?.error || "Failed to delete record");
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    const showModal = (record = null) => {
        setEditingRecord(record);
        if (record) {
            form.setFieldsValue({
                ...record,
                purchaseDate: moment(record.purchaseDate),
            });
        } else {
            form.resetFields();
        }
        setIsModalOpen(true);
    };

    const columns = [
        { title: "Item", dataIndex: ["item", "name"], sorter: (a, b) => a.item.name.localeCompare(b.item.name) },
        { title: "Item Type", dataIndex: "itemType", sorter: (a, b) => a.itemType.localeCompare(b.itemType) },
        { title: "Cost", dataIndex: "cost", sorter: (a, b) => a.cost - b.cost, render: (cost) => `$${cost.toFixed(2)}` },
        { title: "Quantity", dataIndex: "quantity", sorter: (a, b) => a.quantity - b.quantity },
        { title: "Total Cost", dataIndex: "totalCost", sorter: (a, b) => a.totalCost - b.totalCost, render: (cost) => `$${cost.toFixed(2)}` },
        { title: "Purchase Date", dataIndex: "purchaseDate", sorter: (a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate), render: (date) => moment(date).format("YYYY-MM-DD") },
        { title: "Depreciation", dataIndex: "depreciation", render: (val) => `$${val.toFixed(2)}` },
        {
            title: "Actions",
            render: (_, record) => (
                <Space>
                    <Button onClick={() => showModal(record)}>Edit</Button>
                    <Button danger onClick={() => handleDeleteRecord(record._id)}>Delete</Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Inventory Costs</h1>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col>
                    <Space>
                        <Input
                            placeholder="Search by item type or description"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            prefix={<SearchOutlined />}
                            style={{ width: 250 }}
                        />
                        <DatePicker.RangePicker
                            value={dateRange}
                            onChange={(dates) => setDateRange(dates)}
                            format="YYYY-MM-DD"
                        />
                        <Button type="default" onClick={() => { setSearchText(""); setDateRange([null, null]); }}>
                            Reset Filters
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
                        Add Record
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={records}
                rowKey="_id"
                loading={loading}
                pagination={{
                    ...pagination,
                    onChange: (page, pageSize) => {
                        setPagination((prev) => ({ ...prev, current: page, pageSize }));
                        fetchRecords(page, pageSize);
                    },
                }}
            />
            <Modal
                title={editingRecord ? "Edit Record" : "Add Record"}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    setEditingRecord(null);
                    form.resetFields();
                }}
                footer={null}
                width={600}
            >
                <Form form={form} layout="vertical" onFinish={handleSaveRecord}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="item" label="Item ID" rules={[{ required: true, message: "Please enter item ID" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="itemType" label="Item Type" rules={[{ required: true, message: "Please select item type" }]}>
                                <Select>
                                    <Option value="Medicine">Medicine</Option>
                                    <Option value="Equipment">Equipment</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="cost" label="Cost" rules={[{ required: true, message: "Please enter cost" }]}>
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: "Please enter quantity" }]}>
                                <InputNumber min={1} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="purchaseDate" label="Purchase Date" rules={[{ required: true, message: "Please select purchase date" }]}>
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="depreciation" label="Depreciation" initialValue={0}>
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            {editingRecord ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default InventoryCostsPage;