import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    message,
    Space,
    InputNumber,
    Select,
    Row,
    Col,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const IncomesPage = () => {
    // State declarations
    const [incomes, setIncomes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingIncome, setEditingIncome] = useState(null);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [totalIncomes, setTotalIncomes] = useState(0);

    // Axios config with authentication
    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    // Fetch incomes from the backend
    const fetchIncomes = async (page = 1, pageSize = 10) => {
        setLoading(true);
        try {
            const params = {
                page,
                limit: pageSize,
                search: searchText || undefined,
                startDate: dateRange[0]
                    ? dateRange[0].startOf("day").toISOString()
                    : undefined,
                endDate: dateRange[1]
                    ? dateRange[1].endOf("day").toISOString()
                    : undefined,
            };
            const response = await axios.get(`${apiBaseUrl}/incomes`, {
                ...config,
                params,
            });
            // Assuming response.data is structured as { data: incomes, total: number }
            const fetchedIncomes = response.data.data || [];
            setIncomes(fetchedIncomes);
            setTotalIncomes(
                fetchedIncomes.reduce((sum, income) => sum + (income.amount || 0), 0)
            );
            setPagination({
                current: page,
                pageSize,
                total: response.data.total || fetchedIncomes.length,
            });
        } catch (error) {
            message.error(
                error.response?.data?.message || "Failed to fetch incomes"
            );
            setIncomes([]);
            console.error("Error fetching incomes:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch incomes on mount and when filters change
    useEffect(() => {
        fetchIncomes(pagination.current, pagination.pageSize);
    }, [searchText, dateRange]);

    // Handle adding/updating an income
    const handleSaveIncome = async (values) => {
        const incomeData = {
            ...values,
            date: values.date.toISOString(),
        };

        setLoading(true);
        try {
            if (editingIncome) {
                await axios.put(
                    `${apiBaseUrl}/incomes/${editingIncome._id}`,
                    incomeData,
                    config
                );
                message.success("Income updated successfully");
            } else {
                await axios.post(`${apiBaseUrl}/incomes`, incomeData, config);
                message.success("Income added successfully");
            }
            await fetchIncomes(pagination.current, pagination.pageSize);
            setIsModalOpen(false);
            setEditingIncome(null);
            form.resetFields();
        } catch (error) {
            message.error(
                error.response?.data?.message || "Failed to save income"
            );
            console.error("Error saving income:", error);
        } finally {
            setLoading(false);
        }
    };

    // Handle deleting an income
    const handleDeleteIncome = (id) => {
        Modal.confirm({
            title: "Are you sure you want to delete this income?",
            onOk: async () => {
                setLoading(true);
                try {
                    await axios.delete(`${apiBaseUrl}/incomes/${id}`, config);
                    message.success("Income deleted successfully");
                    await fetchIncomes(pagination.current, pagination.pageSize);
                } catch (error) {
                    message.error(
                        error.response?.data?.message || "Failed to delete income"
                    );
                    console.error("Error deleting income:", error);
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    // Open modal for adding/editing an income
    const showModal = (income = null) => {
        setEditingIncome(income);
        if (income) {
            form.setFieldsValue({
                ...income,
                date: moment(income.date),
            });
        } else {
            form.resetFields();
        }
        setIsModalOpen(true);
    };

    // Income sources options (customize as needed)
    const incomeSources = [
        "Consultation Fees",
        "Partnership Revenue",
        "Insurance Claims",
        "Other",
    ];

    // Table columns
    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: (date) => moment(date).format("YYYY-MM-DD"),
        },
        {
            title: "Source",
            dataIndex: "source",
            sorter: (a, b) => a.source.localeCompare(b.source),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            sorter: (a, b) => a.amount - b.amount,
            render: (amount) => `$${amount.toFixed(2)}`,
        },
        {
            title: "Description",
            dataIndex: "description",
        },
        {
            title: "Payment Method",
            dataIndex: "paymentMethod",
        },
        {
            title: "Tax Amount",
            dataIndex: "taxAmount",
            render: (tax) => (tax ? `$${tax.toFixed(2)}` : "-"),
        },
        {
            title: "Actions",
            render: (_, record) => (
                <Space>
                    <Button onClick={() => showModal(record)}>Edit</Button>
                    <Button danger onClick={() => handleDeleteIncome(record._id)}>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>
                Incomes
            </h1>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col>
                    <Space>
                        <Input
                            placeholder="Search by source, description, or payment method"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            prefix={<SearchOutlined />}
                            style={{ width: 250 }}
                        />
                        <DatePicker.RangePicker
                            value={dateRange}
                            onChange={(dates) => setDateRange(dates)}
                            format="YYYY-MM-DD"
                        />
                        <Button
                            type="default"
                            onClick={() => {
                                setSearchText("");
                                setDateRange([null, null]);
                            }}
                        >
                            Reset Filters
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => showModal()}
                    >
                        Add Income
                    </Button>
                </Col>
            </Row>
            <div style={{ marginBottom: 16, fontSize: 16, fontWeight: "bold" }}>
                Total Incomes: ${totalIncomes.toFixed(2)}
            </div>
            <Table
                columns={columns}
                dataSource={incomes}
                rowKey="_id"
                loading={loading}
                pagination={{
                    ...pagination,
                    onChange: (page, pageSize) => {
                        setPagination((prev) => ({ ...prev, current: page, pageSize }));
                        fetchIncomes(page, pageSize);
                    },
                }}
            />
            <Modal
                title={editingIncome ? "Edit Income" : "Add Income"}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    setEditingIncome(null);
                    form.resetFields();
                }}
                footer={null}
                width={600}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSaveIncome}
                    initialValues={{ taxAmount: 0, taxRate: 0 }}
                    onValuesChange={(changedValues, allValues) => {
                        if (changedValues.amount || changedValues.taxRate) {
                            const amount = allValues.amount || 0;
                            const taxRate = allValues.taxRate || 0;
                            form.setFieldsValue({ taxAmount: amount * (taxRate / 100) });
                        }
                        if (changedValues.taxAmount) {
                            const amount = allValues.amount || 0;
                            const taxAmount = changedValues.taxAmount || 0;
                            const taxRate = amount > 0 ? (taxAmount / amount) * 100 : 0;
                            form.setFieldsValue({ taxRate });
                        }
                    }}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="date"
                                label="Date"
                                rules={[
                                    { required: true, message: "Please select a date" },
                                ]}
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="source"
                                label="Source"
                                rules={[
                                    { required: true, message: "Please select a source" },
                                ]}
                            >
                                <Select placeholder="Select a source" style={{ width: "100%" }}>
                                    {incomeSources.map((src) => (
                                        <Option key={src} value={src}>
                                            {src}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label="Amount"
                                rules={[
                                    { required: true, message: "Please enter an amount" },
                                ]}
                            >
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="description" label="Description">
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="paymentMethod" label="Payment Method">
                                <Select placeholder="Select method" style={{ width: "100%" }}>
                                    <Option value="Cash">Cash</Option>
                                    <Option value="Bank Transfer">Bank Transfer</Option>
                                    <Option value="Credit Card">Credit Card</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="taxRate"
                                label="Tax Rate (%)"
                                rules={[
                                    {
                                        type: "number",
                                        min: 0,
                                        max: 100,
                                        message: "Tax rate must be between 0 and 100",
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={100} step={0.1} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="taxAmount" label="Tax Amount">
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            {editingIncome ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default IncomesPage;
