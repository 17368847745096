import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    message,
    Space,
    InputNumber,
    Select,
    Row,
    Col,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const PayrollPage = () => {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    const fetchRecords = async (page = 1, pageSize = 10) => {
        setLoading(true);
        try {
            const params = {
                page,
                limit: pageSize,
                search: searchText || undefined,
            };
            const response = await axios.get(`${apiBaseUrl}/payroll`, { ...config, params });
            setRecords(response.data.data || []);
            setPagination({
                current: page,
                pageSize,
                total: response.data.total || 0,
            });
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to fetch records");
            setRecords([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecords(pagination.current, pagination.pageSize);
    }, [searchText]);

    const handleSaveRecord = async (values) => {
        const recordData = {
            ...values,
            paymentDate: values.paymentDate ? values.paymentDate.toISOString() : null,
        };
        setLoading(true);
        try {
            if (editingRecord) {
                await axios.put(`${apiBaseUrl}/payroll/${editingRecord._id}`, recordData, config);
                message.success("Record updated successfully");
            } else {
                await axios.post(`${apiBaseUrl}/payroll`, recordData, config);
                message.success("Record created successfully");
            }
            fetchRecords(pagination.current, pagination.pageSize);
            setIsModalOpen(false);
            setEditingRecord(null);
            form.resetFields();
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to save record");
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteRecord = (id) => {
        Modal.confirm({
            title: "Are you sure you want to delete this record?",
            onOk: async () => {
                setLoading(true);
                try {
                    await axios.delete(`${apiBaseUrl}/payroll/${id}`, config);
                    message.success("Record deleted successfully");
                    fetchRecords(pagination.current, pagination.pageSize);
                } catch (error) {
                    message.error(error.response?.data?.error || "Failed to delete record");
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    const showModal = (record = null) => {
        setEditingRecord(record);
        if (record) {
            form.setFieldsValue({
                ...record,
                paymentDate: record.paymentDate ? moment(record.paymentDate) : null,
            });
        } else {
            form.resetFields();
        }
        setIsModalOpen(true);
    };

    const columns = [
        { title: "Employee", dataIndex: ["employee", "fullname"], sorter: (a, b) => a.employee.fullname.localeCompare(b.employee.fullname) },
        { title: "Period", dataIndex: "period", sorter: (a, b) => a.period.localeCompare(b.period) },
        { title: "Base Salary", dataIndex: "baseSalary", sorter: (a, b) => a.baseSalary - b.baseSalary, render: (val) => `$${val.toFixed(2)}` },
        { title: "Bonuses", dataIndex: "bonuses", render: (val) => `$${val.toFixed(2)}` },
        { title: "Deductions", dataIndex: "deductions", render: (val) => `$${val.toFixed(2)}` },
        { title: "Tax Amount", dataIndex: "taxAmount", render: (val) => `$${val.toFixed(2)}` },
        { title: "Net Pay", dataIndex: "netPay", sorter: (a, b) => a.netPay - b.netPay, render: (val) => `$${val.toFixed(2)}` },
        { title: "Payment Date", dataIndex: "paymentDate", render: (date) => date ? moment(date).format("YYYY-MM-DD") : "-" },
        { title: "Status", dataIndex: "status" },
        {
            title: "Actions",
            render: (_, record) => (
                <Space>
                    <Button onClick={() => showModal(record)}>Edit</Button>
                    <Button danger onClick={() => handleDeleteRecord(record._id)}>Delete</Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Payroll</h1>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col>
                    <Space>
                        <Input
                            placeholder="Search by employee name or period"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            prefix={<SearchOutlined />}
                            style={{ width: 250 }}
                        />
                        <Button type="default" onClick={() => setSearchText("")}>
                            Reset Filters
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
                        Add Record
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={records}
                rowKey="_id"
                loading={loading}
                pagination={{
                    ...pagination,
                    onChange: (page, pageSize) => {
                        setPagination((prev) => ({ ...prev, current: page, pageSize }));
                        fetchRecords(page, pageSize);
                    },
                }}
            />
            <Modal
                title={editingRecord ? "Edit Record" : "Add Record"}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    setEditingRecord(null);
                    form.resetFields();
                }}
                footer={null}
                width={600}
            >
                <Form form={form} layout="vertical" onFinish={handleSaveRecord}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="employee" label="Employee ID" rules={[{ required: true, message: "Please enter employee ID" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="period" label="Period" rules={[{ required: true, message: "Please enter period (e.g., YYYY-MM)" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="baseSalary" label="Base Salary" rules={[{ required: true, message: "Please enter base salary" }]}>
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="bonuses" label="Bonuses" initialValue={0}>
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="deductions" label="Deductions" initialValue={0}>
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="taxAmount" label="Tax Amount" initialValue={0}>
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="taxRate" label="Tax Rate (%)" initialValue={0}>
                                <InputNumber min={0} max={100} step={0.1} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="paymentDate" label="Payment Date">
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="paymentMethod" label="Payment Method">
                                <Select style={{ width: "100%" }}>
                                    <Option value="Cash">Cash</Option>
                                    <Option value="Bank Transfer">Bank Transfer</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            {editingRecord ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default PayrollPage;