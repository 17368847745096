import React, { useState, useEffect, useCallback } from "react";
import {
    Button,
    Space,
    message,
    DatePicker,
    Select,
    Collapse,
    Descriptions,
    Card,
    Table,
    Tooltip,
    Tabs,
    Modal,
} from "antd";
import axios from "axios";
import moment from "moment";
import { InfoCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, BarElement, PointElement, LinearScale, CategoryScale, Title, Tooltip as ChartTooltip, Legend } from "chart.js";

// Apply autoTable to jsPDF
jsPDF.autoTable = autoTable;

// Register Chart.js components
ChartJS.register(LineElement, BarElement, PointElement, LinearScale, CategoryScale, Title, ChartTooltip, Legend);

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const FinancialReportsPage = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [expenseCategory, setExpenseCategory] = useState(null);
    const [incomeSource, setIncomeSource] = useState(null);
    const [taxType, setTaxType] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [itemType, setItemType] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [dueDetailsModalVisible, setDueDetailsModalVisible] = useState(false);

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                const employeeResponse = await axios.get(`${apiBaseUrl}/staff`, config);
                if (employeeResponse.data.data) {
                    setEmployees(employeeResponse.data.data);
                } else {
                    setEmployees([]); // Fallback to empty array
                }
            } catch (error) {
                console.error("Error fetching staff data:", error);
                setEmployees([]); // Fallback to empty array on error
            }

            try {
                const vendorResponse = await axios.get(`${apiBaseUrl}/accounts-payable`, config);
                if (vendorResponse.data.data) {
                    const uniqueVendors = [...new Set(vendorResponse.data.data.map(record => record.vendor))];
                    setVendors(uniqueVendors);
                } else {
                    setVendors([]); // Fallback to empty array
                }
            } catch (error) {
                console.error("Error fetching accounts payable data:", error);
                setVendors([]); // Fallback to empty array on error
            }
        };
        fetchFilterData();
    }, []);

    const fetchReportData = async () => {
        setLoading(true);
        try {
            const params = {};
            if (dateRange[0] && dateRange[1]) {
                params.startDate = dateRange[0].format("YYYY-MM-DD");
                params.endDate = dateRange[1].format("YYYY-MM-DD");
            }
            if (paymentMethod) params.paymentMethod = paymentMethod;
            if (expenseCategory) params.expenseCategory = expenseCategory;
            if (incomeSource) params.incomeSource = incomeSource;
            if (taxType) params.taxType = taxType;
            if (employee) params.employee = employee;
            if (vendor) params.vendor = vendor;
            if (itemType) params.itemType = itemType;

            const response = await axios.get(`${apiBaseUrl}/financial-reports`, {
                params,
                ...config,
            });
            if (response.data.success) {
                setReportData(response.data.data);
                prepareCsvData(response.data.data);
            } else {
                message.error("Failed to fetch financial report");
                setReportData(null);
            }
        } catch (error) {
            console.error("Error fetching financial report:", error);
            message.error("Failed to fetch financial report");
            setReportData(null);
        } finally {
            setLoading(false);
        }
    };

    const prepareCsvData = useCallback((data) => {
        if (!data) return;

        const csvRows = [
            ["Financial Report", "", "", ""],
            ["Report Period", `${data.dateRange.startDate} to ${data.dateRange.endDate}`, "", ""],
            ["", "", "", ""],
            ["Summary", "", "", ""],
            ["Overall Net Profit", `$${data.netProfit?.toFixed(2) || "0.00"}`, "", ""],
            ["Total Sales from Patients", `$${data.revenue.patientBillings.totalNet?.toFixed(2) || "0.00"}`, "", ""],
            ["Profit from Patient Billings", `$${data.revenue.patientBillings.profit?.toFixed(2) || "0.00"}`, "", ""],
            ["Other Income", `$${data.revenue.additionalIncome.totalAmount?.toFixed(2) || "0.00"}`, "", ""],
            ["Total Expenses", `$${data.expenses.total?.toFixed(2) || "0.00"}`, "", ""],
            ["", "", "", ""],
            ["Revenue Details", "", "", ""],
            ["Total Sales from Patients", `$${data.revenue.patientBillings.totalNet?.toFixed(2) || "0.00"}`, "", ""],
            ["Money Received from Patients", `$${data.revenue.patientBillings.amountPaid?.toFixed(2) || "0.00"}`, "", ""],
            ["Money Still Owed by Patients", `$${data.revenue.patientBillings.dueAmount?.toFixed(2) || "0.00"}`, "", ""],
            ["Profit from Patient Billings", `$${data.revenue.patientBillings.profit?.toFixed(2) || "0.00"}`, "", ""],
            ["", "Pharmacy Sales", "", ""],
            ["Total Sales", `$${data.revenue.patientBillings.pharmacyBillings.totalNet?.toFixed(2) || "0.00"}`, "", ""],
            ["Profit", `$${data.revenue.patientBillings.pharmacyBillings.profit?.toFixed(2) || "0.00"}`, "", ""],
            ...Object.entries(data.revenue.patientBillings.pharmacyBillings.profitBreakdown || {}).map(([medicine, profit]) => [
                "", "Medicine Profit", medicine, `$${profit?.toFixed(2) || "0.00"}`,
            ]),
            ["", "Tests", "", ""],
            ["Total Sales", `$${data.revenue.patientBillings.testBillings.totalNet?.toFixed(2) || "0.00"}`, "", ""],
            ["Profit", `$${data.revenue.patientBillings.testBillings.profit?.toFixed(2) || "0.00"}`, "", ""],
            ...Object.entries(data.revenue.patientBillings.testBillings.profitBreakdown || {}).map(([test, profit]) => [
                "", "Test Profit", test, `$${profit?.toFixed(2) || "0.00"}`,
            ]),
            ["Other Income", `$${data.revenue.additionalIncome.totalAmount?.toFixed(2) || "0.00"}`, "", ""],
            ...Object.entries(data.revenue.additionalIncome.bySource || {}).map(([source, amount]) => [
                "", "Income Source", source, `$${amount?.toFixed(2) || "0.00"}`,
            ]),
            ["", "", "", ""],
            ["Patient Dues", "", "", ""],
            ...Object.entries(data.revenue.patientBillings.dueDetails || []).map(detail => [
                "", "Patient Due",
                detail.patientName || "Unknown",
                detail.dueAmount != null ? `$${Number(detail.dueAmount).toFixed(2)}` : "N/A",
                detail.billType || "Unknown"
            ]),
            ["", "", "", ""],
            ["Expenses Details", "", "", ""],
            ["Clinic Running Costs", `$${data.expenses.operational.totalAmount?.toFixed(2) || "0.00"}`, "", ""],
            ...Object.entries(data.expenses.operational.byCategory || {}).map(([category, amount]) => [
                "", "Cost Category", category, `$${amount?.toFixed(2) || "0.00"}`,
            ]),
            ["Accounts Payable", `$${data.expenses.accountsPayable.totalAmount?.toFixed(2) || "0.00"}`, "", ""],
            ...Object.entries(data.expenses.accountsPayable.byVendor || {}).map(([vendor, amount]) => [
                "", "Vendor", vendor, `$${amount?.toFixed(2) || "0.00"}`,
            ]),
            ["", "", "", ""],
            ["Cash Flow Details", "", "", ""],
            ["Cash Coming In", `$${data.cashFlow.cash.inflows?.toFixed(2) || "0.00"}`, "", ""],
            ["Cash Going Out", `$${data.cashFlow.cash.outflows?.toFixed(2) || "0.00"}`, "", ""],
            ...Object.entries(data.cashFlow.cash.byDescription || {}).map(([desc, amount]) => [
                "", "Cash Transaction", desc, `$${amount?.toFixed(2) || "0.00"}`,
            ]),
            ["Bank Deposits", `$${data.cashFlow.bank.deposits?.toFixed(2) || "0.00"}`, "", ""],
            ["Bank Withdrawals", `$${data.cashFlow.bank.withdrawals?.toFixed(2) || "0.00"}`, "", ""],
            ...Object.entries(data.cashFlow.bank.byAccount || {}).map(([account, amount]) => [
                "", "Bank Account", account, `$${amount?.toFixed(2) || "0.00"}`,
            ]),
        ];
        setCsvData(csvRows);
    }, []);

    const exportToPDF = () => {
        if (!reportData) return;

        const doc = new jsPDF();
        doc.setFontSize(16);
        doc.text("Clinic Financial Report", 14, 20);
        doc.setFontSize(12);
        doc.text(`Report Period: ${reportData.dateRange.startDate} to ${reportData.dateRange.endDate}`, 14, 30);

        doc.text("Summary", 14, 40);
        doc.autoTable({
            startY: 45,
            head: [["Metric", "Value"]],
            body: [
                ["Overall Net Profit", `$${reportData.netProfit?.toFixed(2) || "0.00"}`],
                ["Total Sales from Patients", `$${reportData.revenue.patientBillings.totalNet?.toFixed(2) || "0.00"}`],
                ["Profit from Patient Billings", `$${reportData.revenue.patientBillings.profit?.toFixed(2) || "0.00"}`],
                ["Other Income", `$${reportData.revenue.additionalIncome.totalAmount?.toFixed(2) || "0.00"}`],
                ["Total Expenses", `$${reportData.expenses.total?.toFixed(2) || "0.00"}`],
            ],
        });

        let finalY = doc.lastAutoTable.finalY + 10;
        doc.text("Revenue Details", 14, finalY);
        doc.autoTable({
            startY: finalY + 5,
            head: [["Metric", "Value"]],
            body: [
                ["Total Sales from Patients", `$${reportData.revenue.patientBillings.totalNet?.toFixed(2) || "0.00"}`],
                ["Money Received from Patients", `$${reportData.revenue.patientBillings.amountPaid?.toFixed(2) || "0.00"}`],
                ["Money Still Owed by Patients", `$${reportData.revenue.patientBillings.dueAmount?.toFixed(2) || "0.00"}`],
                ["Profit from Patient Billings", `$${reportData.revenue.patientBillings.profit?.toFixed(2) || "0.00"}`],
            ],
        });

        finalY = doc.lastAutoTable.finalY + 10;
        doc.text("Pharmacy Sales", 14, finalY);
        doc.autoTable({
            startY: finalY + 5,
            head: [["Metric", "Value"]],
            body: [
                ["Total Sales", `$${reportData.revenue.patientBillings.pharmacyBillings.totalNet?.toFixed(2) || "0.00"}`],
                ["Profit", `$${reportData.revenue.patientBillings.pharmacyBillings.profit?.toFixed(2) || "0.00"}`],
            ],
        });

        finalY = doc.lastAutoTable.finalY + 10;
        doc.text("Tests", 14, finalY);
        doc.autoTable({
            startY: finalY + 5,
            head: [["Metric", "Value"]],
            body: [
                ["Total Sales", `$${reportData.revenue.patientBillings.testBillings.totalNet?.toFixed(2) || "0.00"}`],
                ["Profit", `$${reportData.revenue.patientBillings.testBillings.profit?.toFixed(2) || "0.00"}`],
            ],
        });

        finalY = doc.lastAutoTable.finalY + 10;
        doc.text("Other Income", 14, finalY);
        doc.autoTable({
            startY: finalY + 5,
            head: [["Source", "Amount"]],
            body: Object.entries(reportData.revenue.additionalIncome.bySource || {}).map(([source, amount]) => [
                source,
                `$${amount?.toFixed(2) || "0.00"}`,
            ]),
        });

        doc.save(`Financial_Report_${moment().format("YYYYMMDD")}.pdf`);
    };

    const handleGenerateReport = () => {
        fetchReportData();
    };

    const handleClearFilters = () => {
        setDateRange([null, null]);
        setPaymentMethod(null);
        setExpenseCategory(null);
        setIncomeSource(null);
        setTaxType(null);
        setEmployee(null);
        setVendor(null);
        setItemType(null);
        setReportData(null);
        setDueDetailsModalVisible(false); // Close modal when clearing filters
    };

    const showDueDetailsModal = () => {
        if (reportData && reportData.revenue.patientBillings.dueDetails) {
            setDueDetailsModalVisible(true);
        } else {
            message.info("No patient dues available to display.");
        }
    };

    const hideDueDetailsModal = () => {
        setDueDetailsModalVisible(false);
    };

    const renderBreakdownTable = (data, keyLabel, valueLabel) => {
        const tableData = Object.entries(data || {}).map(([key, value]) => ({
            key,
            value: value?.toFixed(2) || "0.00",
        }));
        return (
            <Table
                dataSource={tableData}
                columns={[
                    { title: keyLabel, dataIndex: "key", key: "key" },
                    {
                        title: valueLabel,
                        dataIndex: "value",
                        key: "value",
                        render: (text) => (
                            <span style={{ fontWeight: "bold" }}>${text}</span>
                        ),
                        align: "right",
                    },
                ]}
                pagination={false}
                size="small"
                bordered
                rowClassName={() => "table-row-hover"}
                style={{ marginTop: 16 }}
            />
        );
    };

    const renderDueDetailsTable = () => {
        if (!reportData || !reportData.revenue.patientBillings.dueDetails || reportData.revenue.patientBillings.dueDetails.length === 0) {
            return <p>No patient dues found.</p>;
        }

        const tableData = reportData.revenue.patientBillings.dueDetails.map((detail, index) => ({
            key: index,
            patientName: detail.patientName || "Unknown",
            billNo: detail.billNo || "N/A",
            dueAmount: detail.dueAmount != null ? Number(detail.dueAmount).toFixed(2) : "N/A",
            billType: detail.billType || "Unknown",
        }));

        return (
            <Table
                dataSource={tableData}
                columns={[
                    { title: "Patient Name", dataIndex: "patientName", key: "patientName" },
                    { title: "Bill Number", dataIndex: "billNo", key: "billNo" },
                    { title: "Due Amount ($)", dataIndex: "dueAmount", key: "dueAmount", align: "right" },
                    { title: "Bill Type", dataIndex: "billType", key: "billType" },
                ]}
                pagination={false}
                size="small"
                bordered
                style={{ marginTop: 16 }}
            />
        );
    };

    const chartData = reportData
        ? {
            labels: ["Revenue", "Expenses"],
            datasets: [
                {
                    label: "Amount ($)",
                    data: [
                        reportData.revenue.total || 0,
                        reportData.expenses.total || 0,
                    ],
                    backgroundColor: ["#2f54eb", "#f5222d"],
                    borderColor: ["#1a3c94", "#a8071a"],
                    borderWidth: 1,
                },
            ],
        }
        : {};

    return (
        <div style={{ padding: "24px", backgroundColor: "#f5f5f5" }}>
            <h1
                style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    marginBottom: 24,
                    color: "#1a1a1a",
                }}
            >
                Clinic Financial Dashboard
            </h1>

            <Card style={{ marginBottom: 24, borderRadius: 8, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
                <Space style={{ marginBottom: 16, flexWrap: "wrap" }}>
                    <RangePicker
                        value={dateRange}
                        onChange={(dates) => setDateRange(dates)}
                        format="YYYY-MM-DD"
                        placeholder={["Start Date (Optional)", "End Date (Optional)"]}
                        style={{ width: 300 }}
                    />
                    <Select
                        placeholder="Payment Method (Optional)"
                        value={paymentMethod}
                        onChange={setPaymentMethod}
                        allowClear
                        style={{ width: 200 }}
                    >
                        <Option value="Cash">Cash</Option>
                        <Option value="Card">Card</Option>
                        <Option value="Bank Transfer">Bank Transfer</Option>
                        <Option value="Credit Card">Credit Card</Option>
                    </Select>
                    <Select
                        placeholder="Expense Type (Optional)"
                        value={expenseCategory}
                        onChange={setExpenseCategory}
                        allowClear
                        style={{ width: 200 }}
                    >
                        <Option value="Rent">Rent</Option>
                        <Option value="Utilities">Utilities</Option>
                        <Option value="Salaries">Salaries</Option>
                        <Option value="Supplies">Supplies</Option>
                        <Option value="Equipment Maintenance">Equipment Maintenance</Option>
                        <Option value="Medical Supplies">Medical Supplies</Option>
                    </Select>
                    <Select
                        placeholder="Income Source (Optional)"
                        value={incomeSource}
                        onChange={setIncomeSource}
                        allowClear
                        style={{ width: 200 }}
                    >
                        <Option value="Grants">Grants</Option>
                        <Option value="Donations">Donations</Option>
                        <Option value="Investments">Investments</Option>
                    </Select>
                    <Select
                        placeholder="Tax Type (Optional)"
                        value={taxType}
                        onChange={setTaxType}
                        allowClear
                        style={{ width: 200 }}
                    >
                        <Option value="Income Tax">Income Tax</Option>
                        <Option value="Sales Tax">Sales Tax</Option>
                    </Select>
                    <Select
                        placeholder="Employee (Optional)"
                        value={employee}
                        onChange={setEmployee}
                        allowClear
                        style={{ width: 200 }}
                    >
                        {employees.map(emp => (
                            <Option key={emp._id} value={emp._id}>{emp.fullname}</Option>
                        ))}
                    </Select>
                    <Select
                        placeholder="Vendor (Optional)"
                        value={vendor}
                        onChange={setVendor}
                        allowClear
                        style={{ width: 200 }}
                    >
                        {vendors.map(v => (
                            <Option key={v} value={v}>{v}</Option>
                        ))}
                    </Select>
                    <Select
                        placeholder="Item Type (Optional)"
                        value={itemType}
                        onChange={setItemType}
                        allowClear
                        style={{ width: 200 }}
                    >
                        <Option value="Medicine">Medicine</Option>
                        <Option value="Equipment">Equipment</Option>
                    </Select>
                    <Button
                        type="primary"
                        onClick={handleGenerateReport}
                        loading={loading}
                        style={{ borderRadius: 4 }}
                    >
                        Generate Report
                    </Button>
                    <Button
                        type="default"
                        onClick={handleClearFilters}
                        style={{ borderRadius: 4 }}
                    >
                        Clear Filters
                    </Button>
                </Space>
            </Card>

            {loading ? (
                <div style={{ textAlign: "center", fontSize: "16px", color: "#555" }}>
                    Loading your financial report...
                </div>
            ) : reportData ? (
                <div>
                    <Space direction="horizontal" style={{ width: "100%", marginBottom: 24, flexWrap: "wrap" }}>
                        <Card
                            title="Overall Net Profit"
                            style={{
                                width: 250,
                                borderRadius: 8,
                                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: reportData.netProfit >= 0 ? "green" : "red",
                                }}
                            >
                                ${reportData.netProfit?.toFixed(2) || "0.00"}
                            </span>
                        </Card>
                        <Card
                            title="Patient Dues"
                            style={{
                                width: 250,
                                borderRadius: 8,
                                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                            }}
                            onClick={showDueDetailsModal}
                        >
                            <span
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#f5222d",
                                }}
                            >
                                ${reportData.revenue.patientBillings.dueAmount?.toFixed(2) || "0.00"}
                            </span>
                        </Card>
                        <Card
                            title="Payroll Expenses"
                            style={{
                                width: 250,
                                borderRadius: 8,
                                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#f5222d",
                                }}
                            >
                                ${reportData.expenses.payroll.totalNetPay?.toFixed(2) || "0.00"}
                            </span>
                        </Card>
                        <Card
                            title="Tax Liabilities Due"
                            style={{
                                width: 250,
                                borderRadius: 8,
                                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#f5222d",
                                }}
                            >
                                ${reportData.expenses.taxLiabilities.dueAmount?.toFixed(2) || "0.00"}
                            </span>
                        </Card>
                    </Space>

                    <Space style={{ marginBottom: 24 }}>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={exportToPDF}
                            style={{ borderRadius: 4 }}
                            disabled={!reportData}
                        >
                            Export to PDF
                        </Button>
                        <CSVLink
                            data={csvData}
                            filename={`Financial_Report_${moment().format("YYYYMMDD")}.csv`}
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                type="default"
                                icon={<DownloadOutlined />}
                                style={{ borderRadius: 4 }}
                                disabled={!reportData}
                            >
                                Export to CSV
                            </Button>
                        </CSVLink>
                    </Space>

                    <Card
                        title="Revenue vs Expenses Overview"
                        style={{
                            marginBottom: 24,
                            borderRadius: 8,
                            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        }}
                    >
                        <Bar
                            data={chartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: { position: "top" },
                                    title: { display: true, text: "Revenue vs Expenses" },
                                },
                                scales: {
                                    y: { beginAtZero: true, title: { display: true, text: "Amount ($)" } },
                                },
                            }}
                            height={100}
                        />
                    </Card>

                    <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 24 }}>
                        <Tabs.TabPane tab="Summary" key="1">
                            <Card
                                title={
                                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                                        Report Period: {reportData.dateRange.startDate} to{" "}
                                        {reportData.dateRange.endDate}
                                    </span>
                                }
                                style={{
                                    borderRadius: 8,
                                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                                }}
                            >
                                <Descriptions bordered>
                                    <Descriptions.Item
                                        label={
                                            <Tooltip title="Overall profit after expenses">
                                                <span>
                                                    Overall Net Profit{" "}
                                                    <InfoCircleOutlined style={{ color: "#888" }} />
                                                </span>
                                            </Tooltip>
                                        }
                                        span={3}
                                    >
                                        <span
                                            style={{
                                                color: reportData.netProfit >= 0 ? "green" : "red",
                                                fontSize: "22px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            ${reportData.netProfit?.toFixed(2) || "0.00"}
                                        </span>
                                    </Descriptions.Item>
                                </Descriptions>
                                <div style={{ marginTop: 16, fontSize: "16px", color: "#333" }}>
                                    <h4 style={{ fontSize: "18px", marginBottom: 8 }}>Quick Summary:</h4>
                                    <ul style={{ listStyleType: "none", paddingLeft: 0, lineHeight: "1.8" }}>
                                        <li>
                                            <strong>Total Sales from Patients:</strong>{" "}
                                            <span style={{ color: "#2f54eb" }}>
                                                ${reportData.revenue.patientBillings.totalNet?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Profit from Patient Billings:</strong>{" "}
                                            <span style={{ color: "#2f54eb" }}>
                                                ${reportData.revenue.patientBillings.profit?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Other Income:</strong>{" "}
                                            <span style={{ color: "#2f54eb" }}>
                                                ${reportData.revenue.additionalIncome.totalAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Total Expenses:</strong>{" "}
                                            <span style={{ color: "#f5222d" }}>
                                                ${reportData.expenses.total?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Cash Movement:</strong>{" "}
                                            <span style={{ color: "#2f54eb" }}>
                                                ${(reportData.cashFlow.cash.net + reportData.cashFlow.bank.net)?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Patient Dues:</strong>{" "}
                                            <span style={{ color: "#f5222d" }}>
                                                ${reportData.revenue.patientBillings.dueAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Payroll Expenses:</strong>{" "}
                                            <span style={{ color: "#f5222d" }}>
                                                ${reportData.expenses.payroll.totalNetPay?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Tax Liabilities Due:</strong>{" "}
                                            <span style={{ color: "#f5222d" }}>
                                                ${reportData.expenses.taxLiabilities.dueAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Inventory Costs:</strong>{" "}
                                            <span style={{ color: "#f5222d" }}>
                                                ${reportData.expenses.inventoryCosts.totalCost?.toFixed(2) || "0.00"}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                {reportData.budgetComparison && (
                                    <div style={{ marginTop: 24 }}>
                                        <h4 style={{ fontSize: "18px", marginBottom: 8 }}>Budget vs Actual vs Forecast:</h4>
                                        <Table
                                            dataSource={[
                                                {
                                                    key: "revenue",
                                                    metric: "Revenue",
                                                    budget: reportData.budgetComparison.budget.revenue?.toFixed(2) || "0.00",
                                                    actual: reportData.budgetComparison.actual.revenue?.toFixed(2) || "0.00",
                                                    forecast: reportData.budgetComparison.forecast.revenue?.toFixed(2) || "0.00",
                                                },
                                                {
                                                    key: "expenses",
                                                    metric: "Expenses",
                                                    budget: reportData.budgetComparison.budget.expenses?.toFixed(2) || "0.00",
                                                    actual: reportData.budgetComparison.actual.expenses?.toFixed(2) || "0.00",
                                                    forecast: reportData.budgetComparison.forecast.expenses?.toFixed(2) || "0.00",
                                                },
                                                {
                                                    key: "net",
                                                    metric: "Net",
                                                    budget: reportData.budgetComparison.budget.net?.toFixed(2) || "0.00",
                                                    actual: reportData.budgetComparison.actual.net?.toFixed(2) || "0.00",
                                                    forecast: reportData.budgetComparison.forecast.net?.toFixed(2) || "0.00",
                                                },
                                            ]}
                                            columns={[
                                                { title: "Metric", dataIndex: "metric", key: "metric" },
                                                {
                                                    title: "Budget",
                                                    dataIndex: "budget",
                                                    key: "budget",
                                                    render: (text) => `$${text}`,
                                                    align: "right",
                                                },
                                                {
                                                    title: "Actual",
                                                    dataIndex: "actual",
                                                    key: "actual",
                                                    render: (text) => `$${text}`,
                                                    align: "right",
                                                },
                                                {
                                                    title: "Forecast",
                                                    dataIndex: "forecast",
                                                    key: "forecast",
                                                    render: (text) => `$${text}`,
                                                    align: "right",
                                                },
                                            ]}
                                            pagination={false}
                                            bordered
                                            style={{ marginTop: 16 }}
                                        />
                                    </div>
                                )}
                            </Card>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Revenue" key="2">
                            <Collapse
                                defaultActiveKey={["1"]}
                                expandIconPosition="right"
                                style={{ backgroundColor: "#fff", borderRadius: 8 }}
                            >
                                <Panel
                                    header={<span style={{ fontSize: "18px", fontWeight: "600" }}>Revenue (Money Coming In)</span>}
                                    key="1"
                                    style={{ borderRadius: 8, marginBottom: 16 }}
                                >
                                    <Descriptions bordered size="middle">
                                        <Descriptions.Item label="Total Sales from Patients" span={3}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#2f54eb" }}>
                                                ${reportData.revenue.patientBillings.totalNet?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Money Received from Patients" span={2}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#2f54eb" }}>
                                                ${reportData.revenue.patientBillings.amountPaid?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={
                                                <Tooltip title="Click to view details of patients with unpaid amounts">
                                                    <span
                                                        style={{ cursor: "pointer" }}
                                                        onClick={showDueDetailsModal}
                                                    >
                                                        Money Still Owed by Patients
                                                    </span>
                                                </Tooltip>
                                            }
                                        >
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.revenue.patientBillings.dueAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Profit from Patient Billings" span={3}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#2f54eb" }}>
                                                ${reportData.revenue.patientBillings.profit?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>

                                    <Collapse
                                        defaultActiveKey={["pharmacy", "tests"]}
                                        style={{ marginTop: 16, backgroundColor: "#fafafa", borderRadius: 8 }}
                                    >
                                        <Panel
                                            header={<span style={{ fontSize: "16px", fontWeight: "500" }}>From Pharmacy Sales</span>}
                                            key="pharmacy"
                                            style={{ borderRadius: 8 }}
                                        >
                                            <Descriptions bordered size="small">
                                                <Descriptions.Item label="Total Sales" span={2}>
                                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                                                        ${reportData.revenue.patientBillings.pharmacyBillings.totalNet?.toFixed(2) || "0.00"}
                                                    </span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Profit">
                                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                                                        ${reportData.revenue.patientBillings.pharmacyBillings.profit?.toFixed(2) || "0.00"}
                                                    </span>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            {Object.keys(reportData.revenue.patientBillings.pharmacyBillings.profitBreakdown || {}).length > 0 && (
                                                <div style={{ marginTop: 16 }}>
                                                    <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                        Profit from Each Medicine:
                                                    </h4>
                                                    {renderBreakdownTable(
                                                        reportData.revenue.patientBillings.pharmacyBillings.profitBreakdown,
                                                        "Medicine",
                                                        "Profit ($)"
                                                    )}
                                                </div>
                                            )}
                                        </Panel>
                                        <Panel
                                            header={<span style={{ fontSize: "16px", fontWeight: "500" }}>From Tests</span>}
                                            key="tests"
                                            style={{ borderRadius: 8 }}
                                        >
                                            <Descriptions bordered size="small">
                                                <Descriptions.Item label="Total Sales" span={2}>
                                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                                                        ${reportData.revenue.patientBillings.testBillings.totalNet?.toFixed(2) || "0.00"}
                                                    </span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Profit">
                                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                                                        ${reportData.revenue.patientBillings.testBillings.profit?.toFixed(2) || "0.00"}
                                                    </span>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            {Object.keys(reportData.revenue.patientBillings.testBillings.profitBreakdown || {}).length > 0 && (
                                                <div style={{ marginTop: 16 }}>
                                                    <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                        Profit from Each Test:
                                                    </h4>
                                                    {renderBreakdownTable(
                                                        reportData.revenue.patientBillings.testBillings.profitBreakdown,
                                                        "Test",
                                                        "Profit ($)"
                                                    )}
                                                </div>
                                            )}
                                        </Panel>
                                    </Collapse>

                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item label="Other Income" span={2}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                                ${reportData.revenue.additionalIncome.totalAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Taxes on Other Income">
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.revenue.additionalIncome.taxAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.revenue.additionalIncome.bySource || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Other Income Sources:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.revenue.additionalIncome.bySource,
                                                "Source",
                                                "Amount ($)"
                                            )}
                                        </div>
                                    )}
                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item label="Total Revenue" span={3}>
                                            <span style={{ fontSize: "18px", fontWeight: "bold", color: "#2f54eb" }}>
                                                ${reportData.revenue.total?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>

                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item label="Accounts Receivable Due (External)" span={2}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.accountsReceivable.dueAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Accounts Receivable Paid">
                                            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                                ${reportData.accountsReceivable.paidAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.accountsReceivable.byEntity || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Accounts Receivable by Entity:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.accountsReceivable.byEntity,
                                                "Entity",
                                                "Amount ($)"
                                            )}
                                        </div>
                                    )}
                                </Panel>
                            </Collapse>

                            <Modal
                                title="Patients with Unpaid Amounts"
                                open={dueDetailsModalVisible}
                                onCancel={hideDueDetailsModal}
                                footer={null}
                                width={800}
                            >
                                {renderDueDetailsTable()}
                            </Modal>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Expenses" key="3">
                            <Collapse
                                defaultActiveKey={["1"]}
                                expandIconPosition="right"
                                style={{ backgroundColor: "#fff", borderRadius: 8 }}
                            >
                                <Panel
                                    header={<span style={{ fontSize: "18px", fontWeight: "600" }}>Expenses (Money Going Out)</span>}
                                    key="1"
                                    style={{ borderRadius: 8, marginBottom: 16 }}
                                >
                                    <Descriptions bordered size="middle">
                                        <Descriptions.Item label="Clinic Running Costs" span={2}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.operational.totalAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Taxes on Costs">
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.operational.taxAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.expenses.operational.byCategory || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Cost Breakdown:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.expenses.operational.byCategory,
                                                "Type",
                                                "Amount ($)"
                                            )}
                                        </div>
                                    )}

                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item
                                            label={
                                                <Tooltip title="Bills owed to vendors">
                                                    <span>
                                                        Accounts Payable{" "}
                                                        <InfoCircleOutlined style={{ color: "#888" }} />
                                                    </span>
                                                </Tooltip>
                                            }
                                            span={2}
                                        >
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.accountsPayable.totalAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Paid Bills">
                                            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                                ${reportData.expenses.accountsPayable.paidAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Unpaid Bills" span={3}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.accountsPayable.dueAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.expenses.accountsPayable.byVendor || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Bills by Vendor:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.expenses.accountsPayable.byVendor,
                                                "Vendor",
                                                "Amount ($)"
                                            )}
                                        </div>
                                    )}

                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item label="Payroll Expenses" span={2}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.payroll.totalNetPay?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Payroll Taxes">
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.payroll.taxAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.expenses.payroll.byEmployee || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Payroll by Employee:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.expenses.payroll.byEmployee,
                                                "Employee",
                                                "Net Pay ($)"
                                            )}
                                        </div>
                                    )}

                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item label="Tax Liabilities Due" span={2}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.taxLiabilities.dueAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Tax Payments Made">
                                            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                                ${reportData.expenses.taxLiabilities.paidAmount?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.expenses.taxLiabilities.byType || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Tax Liabilities by Type:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.expenses.taxLiabilities.byType,
                                                "Tax Type",
                                                "Amount ($)"
                                            )}
                                        </div>
                                    )}

                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item label="Inventory Costs" span={3}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.inventoryCosts.totalCost?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.expenses.inventoryCosts.byItemType || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Inventory Costs by Item Type:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.expenses.inventoryCosts.byItemType,
                                                "Item Type",
                                                "Cost ($)"
                                            )}
                                        </div>
                                    )}

                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item label="Total Expenses" span={3}>
                                            <span style={{ fontSize: "18px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.expenses.total?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                            </Collapse>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Cash Flow" key="4">
                            <Collapse
                                defaultActiveKey={["1"]}
                                expandIconPosition="right"
                                style={{ backgroundColor: "#fff", borderRadius: 8 }}
                            >
                                <Panel
                                    header={<span style={{ fontSize: "18px", fontWeight: "600" }}>Cash Flow</span>}
                                    key="1"
                                    style={{ borderRadius: 8, marginBottom: 16 }}
                                >
                                    <Descriptions bordered size="middle">
                                        <Descriptions.Item label="Cash Coming In" span={2}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#2f54eb" }}>
                                                ${reportData.cashFlow.cash.inflows?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Cash Going Out">
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.cashFlow.cash.outflows?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Net Cash" span={3}>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    color: (reportData.cashFlow.cash.net || 0) >= 0 ? "green" : "red",
                                                }}
                                            >
                                                ${(reportData.cashFlow.cash.net || 0).toFixed(2)}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.cashFlow.cash.byDescription || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Cash Details:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.cashFlow.cash.byDescription,
                                                "Reason",
                                                "Amount ($)"
                                            )}
                                        </div>
                                    )}

                                    <Descriptions bordered style={{ marginTop: 24 }} size="middle">
                                        <Descriptions.Item label="Bank Deposits" span={2}>
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#2f54eb" }}>
                                                ${reportData.cashFlow.bank.deposits?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Bank Withdrawals">
                                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#f5222d" }}>
                                                ${reportData.cashFlow.bank.withdrawals?.toFixed(2) || "0.00"}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Net Bank" span={3}>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    color: (reportData.cashFlow.bank.net || 0) >= 0 ? "green" : "red",
                                                }}
                                            >
                                                ${(reportData.cashFlow.bank.net || 0).toFixed(2)}
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {Object.keys(reportData.cashFlow.bank.byAccount || {}).length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "500", marginBottom: 8 }}>
                                                Bank Details by Account:
                                            </h4>
                                            {renderBreakdownTable(
                                                reportData.cashFlow.bank.byAccount,
                                                "Account",
                                                "Amount ($)"
                                            )}
                                        </div>
                                    )}
                                </Panel>
                            </Collapse>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            ) : (
                <p style={{ fontSize: "16px", color: "#555" }}>
                    Select a date range or leave it blank to view the full financial report.
                </p>
            )}
        </div>
    );
};

// Add custom CSS for table hover effect and styling
const styles = `
    .table-row-hover:hover {
        background-color: #f0f5ff !important;
    }
    .ant-collapse-header {
        padding: 12px 16px !important;
        border-radius: 8px 8px 0 0 !important;
    }
    .ant-collapse-content {
        border-radius: 0 0 8px 8px !important;
    }
    .ant-descriptions-item-label {
        font-weight: 500 !important;
        background-color: #fafafa !important;
    }
    .ant-descriptions-item-content {
        padding: 12px !important;
    }
    .ant-tabs-tab {
        font-size: 16px !important;
        padding: 12px 16px !important;
    }
    .ant-card-head {
        background-color: #fafafa !important;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
    }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default FinancialReportsPage;