// testData.js
export const testTypesData = {
    HEMATOLOGY: {
        reportName: "HEMATOLOGY",
        fields: [
            {
                test: "Complete Blood Count (CBC)",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "Total Leucocytes Count",
                result: "9400",
                unit: "/cumm",
                refRange: "4000-11000"
            },
            {
                test: "Differential count",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "Neutrophils",
                result: "57",
                unit: "%",
                refRange: "40-70"
            },
            {
                test: "Lymphocytes",
                result: "22",
                unit: "%",
                refRange: "20-40"
            },
            {
                test: "Monocytes",
                result: "15",
                unit: "%",
                refRange: "2-10"
            },
            {
                test: "Eosinophils",
                result: "06",
                unit: "%",
                refRange: "1-6"
            },
            {
                test: "Basophils",
                result: "00",
                unit: "%",
                refRange: "0-1"
            },
            {
                test: "Hb",
                result: "10.4",
                unit: "g/dl",
                refRange: "M: 12-16 , F: 11-14"
            },
            {
                test: "RBC count",
                result: "3.9",
                unit: "millions/cumm",
                refRange: "M: 4.5-6.0 , F: 4.2-5.4"
            },
            {
                test: "Platelets",
                result: "266000",
                unit: "/cumm",
                refRange: "150000-450000"
            },
            {
                test: "PCV",
                result: "26.2",
                unit: "%",
                refRange: "M: 41-52% , F: 37-47"
            },
            {
                test: "MCV",
                result: "66.0",
                unit: "fl",
                refRange: "78-96"
            },
            {
                test: "MCH",
                result: "26.3",
                unit: "pg",
                refRange: "27-31"
            },
            {
                test: "MCHC",
                result: "39.9",
                unit: "gm/dl",
                refRange: "32-36"
            },
            {
                test: "ESR",
                result: "50",
                unit: "Mm/hr",
                refRange: "Upto20"
            },

            {
                test: "Blood Grouping",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "Blood Grouping",
                result: "'B'Positive",
                unit: "",
                refRange: ""
            },
        ]
    },
    BIOCHEMISTRY: {
        reportName: "BIOCHEMISTRY",
        fields: [
            {
                test: "LFT(LIVER FUNCTION TEST)",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "T. Bilirubin",
                result: "0.4",
                unit: "Mg/dl",
                refRange: "0-1.0"
            },
            {
                test: "D. Bilirubin",
                result: "0.1",
                unit: "Mg/dl",
                refRange: "0.0-0.3"
            },
            {
                test: "SGOT",
                result: "16",
                unit: "IU/L",
                refRange: "0-37"
            },
            {
                test: "SGPT",
                result: "24",
                unit: "IU/L",
                refRange: "0-40"
            },
            {
                test: "APL",
                result: "183",
                unit: "IU/L",
                refRange: "80-306"
            },
            {
                test: "RFT(Renal FUNCTION TEST)",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "Urea",
                result: "17",
                unit: "Mg/dl",
                refRange: "15-45"
            },
            {
                test: "Creatinine",
                result: "0.8",
                unit: "Mg/dl",
                refRange: "0.6-1.4"
            },
            {
                test: "Sodium",
                result: "138",
                unit: "MEq/l",
                refRange: "135-145"
            },
            {
                test: "Potassium",
                result: "3.8",
                unit: "MEq/l",
                refRange: "3.5-5.2"
            },
            {
                test: "LIPID PROFILE TEST",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "Total Cholesterol",
                result: "153",
                unit: "Mg/dl",
                refRange: "Desirable: <200,\nBorderline: 200-239,\nHigh: >240"
            },
            {
                test: "Triglycerides",
                result: "191",
                unit: "Mg/dl",
                refRange: "Optimal: <150,\nBorderline: 150-199,\nHigh: 200-499,\nVery High: >500"
            },
            {
                test: "HDL-C, DIRECT",
                result: "46",
                unit: "IU/L",
                refRange: "Low: <40,\nHigh: >60"
            },
            {
                test: "LDL-C, Direct",
                result: "68",
                unit: "IU/L",
                refRange: "Optimal: <100,\nNear Optimum: 100-129,\nBorderline: 130-159,\nHigh: 160-189,\nVery High: >190"
            },
            {
                test: "VLDL",
                result: "38",
                unit: "IU/L",
                refRange: "<30"
            },
            {
                test: "HBA1C",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "HBA1C",
                result: "5.8",
                unit: "Between 4-5%",
                refRange: "4-5.7"
            },
            {
                test: "Sugar",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "Sugar(Random)",
                result: "156",
                unit: "Mg/dl",
                refRange: "<140"
            },
            {
                test: "CRP",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "CRP(Q)",
                result: "6.90",
                unit: "Mg/l",
                refRange: "Upto6"
            },
            {
                test: "VITAMIN D 25-OH",
                result: "16.3",
                unit: "ng/ml",
                refRange: "Deficiency: <20,\nInsufficiency: <29,\nSufficient: 30-100,\nToxicity: >100"
            },
            {
                test: "Rheumatoid Factor(rf Titre)",
                result: "2.54",
                unit: "IU/ML",
                refRange: "0-20"
            },

            {
                test: "Uric Acid",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "Uric Acid",
                result: "4.8",
                unit: "%",
                refRange: "Male: 3.4-7.0,\nFemale: 2.4-5.7"
            },



        ]
    },
    SERELOGY: {
        reportName: "SERELOGY",
        fields: [
            {
                test: "HIV",
                result: "NEGATIVE",
                unit: "",
                refRange: ""
            },
            {
                test: "HCV",
                result: "NEGATIVE",
                unit: "",
                refRange: ""
            }, {
                test: "VDRL",
                result: "NEGATIVE",
                unit: "",
                refRange: ""
            }, {
                test: "HBsAG",
                result: "NEGATIVE",
                unit: "",
                refRange: ""
            },
        ]
    },

    PARASITOLOGY: {
        reportName: "PARASITOLOGY",
        fields: [
            {
                test: "URINE ROUTINE EXAMINATION",
                result: "",
                unit: "",
                refRange: ""
            },
            {
                test: "PHYSICAL",
                result: "",
                unit: "",
                refRange: ""
            }, {
                test: "Color",
                result: "Yellow",
                unit: "",
                refRange: ""
            }, {
                test: "Transparency",
                result: "CLEAR",
                unit: "",
                refRange: ""
            },
            {
                test: "CHEMICAL",
                result: "",
                unit: "",
                refRange: ""
            }, {
                test: "pH",
                result: "Nil",
                unit: "",
                refRange: ""
            }, {
                test: "Sugar",
                result: "Nil",
                unit: "",
                refRange: ""
            },
            {
                test: "Albumin",
                result: "Nil",
                unit: "",
                refRange: ""
            },

            {
                test: "Microscopic",
                result: "",
                unit: "",
                refRange: ""
            }, {
                test: "Pus Cells",
                result: "4-6",
                unit: "/Hpf",
                refRange: ""
            }, {
                test: "Epithelial Cells",
                result: "0-2",
                unit: "/Hpf",
                refRange: ""
            },
            {
                test: "RBC",
                result: "Nil",
                unit: "/Hpf",
                refRange: ""
            }, {
                test: "Casts",
                result: "Nil",
                unit: "/Hpf",
                refRange: ""
            },
            {
                test: "Crystals",
                result: "Nil",
                unit: "/Hpf",
                refRange: ""
            },

            {
                test: "Urinary Pregnancy Test(UPT)",
                result: "",
                unit: "",
                refRange: ""
            },

            {
                test: "Urinary Pregnancy Test(UPT)",
                result: "Positive",
                unit: "",
                refRange: ""
            },
        ]
    }
};