import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Row, Col, Typography } from "antd";

const { Title } = Typography;

const ReferDepartmentByDoctor = ({
  appointmentId,
  full_name,
  problem,
  departmentCheckIfInReferred, // Current department to filter out
  onSuccess, // Callback to notify parent of success
}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [department, setDepartment] = useState("");
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  // Dynamic auth config
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];
    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    return {
      headers: {
        Authorization: `Bearer ${availableTicket ? localStorage.getItem(availableTicket.key) : ""}`,
      },
    };
  };

  const config = getAuthConfig();

  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiBaseUrl}/viewDepartment`, config);
        if (response.data.success) {
          setDetails(response.data.data);
        }
      } catch (e) {
        console.error("Failed to get department details:", e);
        message.error("Failed to get department details! Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  const referDepartment = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      assigningDepartment: department,
    };

    try {
      const response = await axios.post(
        `${apiBaseUrl}/doctor/appointment/referDepartment/${appointmentId}`,
        data,
        config
      );
      if (response.data.msg === "Department assigned") {
        message.success("Patient referred successfully", 0.6, () => {
          if (onSuccess) {
            window.location.reload();
          }
        });
      } else {
        console.log("Response", response);
        message.error("Failed To Refer");
      }
    } catch (err) {
      console.error("Error caught: ", err);
      message.error("Internal Server error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <form
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={referDepartment}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Refer Department</Title>
          </div>
          <p className="text-base -mb-3 -mt-1">
            Patient Name: <span className="text-gray-700 italic">{full_name}</span>
          </p>
          <p className="text-base">
            Problem: <span className="text-gray-700 italic">{problem}</span>
          </p>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Select Department</label>
              <select
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                className="border-solid border-gray-200 border mt-1 py-2 px-3 w-full rounded-md text-gray-600"
                name="department"
                required
                disabled={loading}
              >
                <option value="">Select Department</option>
                {details
                  .filter(
                    (singleDepartment) =>
                      singleDepartment.department !== departmentCheckIfInReferred
                  )
                  .map((singleDepartment, index) => (
                    <option key={index} value={singleDepartment.department}>
                      {singleDepartment.department}
                    </option>
                  ))}
              </select>
            </Col>
          </Row>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
};

export default ReferDepartmentByDoctor;