import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Form,
    Input,
    message,
    Button,
    Modal,
    Select,
    Tag,
} from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";

const { Option } = Select;

const testList = [
    "LIPID Profile", "LFT", "RFT", "CBC", "Differential Count", "Blood Grouping",
    "HBA1C", "Sugar", "CRP", "Uric Acid", "Serology", "URINE ROUTINE EXAMINATION",
    "ESR", "Rheumatoid Factor", "Serum Vit D", "HCV", "HBsAg", "Lipid Profile",
    "X ray left knee", "X ray right knee", "X ray D-L Spine", "X ray pelvis",
    "X ray cervical spine", "X ray right ankle and left foot", "X ray left ankle and right foot",
    "X ray right ankle and right foot", "X ray left ankle and left foot", "Chest x ray",
    "ECG", "USG abdomen and pelvis", "X ray thyroid and neck", "ECHO", "ANA by IFA",
    "ENA", "Vasculitis pane", "Myositis panel", "Anti CCP", "APLA Profile", "HLAB27",
    "ACE LEVEL", "TFT",
];

// Reusable CustomMultiSelect component
const CustomMultiSelect = ({ form, field, label, options }) => {
    const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
    const [customInput, setCustomInput] = useState("");

    const handleAddCustom = () => {
        setIsCustomModalVisible(true);
    };

    const handleCustomOk = () => {
        if (customInput.trim() !== "") {
            const currentValue = form.getFieldValue(field) || [];
            const newValue = [...currentValue, customInput.trim()];
            form.setFieldsValue({ [field]: newValue });
        }
        setCustomInput("");
        setIsCustomModalVisible(false);
    };

    const handleCustomCancel = () => {
        setCustomInput("");
        setIsCustomModalVisible(false);
    };

    return (
        <>
            <Form.Item
                label={label}
                name={field}
                rules={[{ required: true, message: `Please enter ${label.toLowerCase()}.` }]}
            >
                <Select
                    mode="multiple"
                    placeholder={`Select ${label} options`}
                    style={{ width: "100%" }}
                >
                    {options.map((opt) => (
                        <Option key={opt.value} value={opt.value}>
                            {opt.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Button onClick={handleAddCustom}>Add Custom {label}</Button>
            <Modal
                title={`Enter Custom ${label}`}
                visible={isCustomModalVisible}
                onOk={handleCustomOk}
                onCancel={handleCustomCancel}
            >
                <Input
                    placeholder={`Type your custom ${label.toLowerCase()}`}
                    value={customInput}
                    onChange={(e) => setCustomInput(e.target.value)}
                />
            </Modal>
        </>
    );
};

const ViewInvestigation = ({
    appointmentId,
    full_name,
    problem,
    appointmentNumber,
    appointmentHistory,
    onClose,
}) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [fullNameData, setFullNameData] = useState(full_name || "");
    const [problemData, setProblemData] = useState(problem || "");
    const [appointmentNumberData, setAppointmentNumberData] = useState(appointmentNumber || "");
    const [hasEditableInvestigation, setHasEditableInvestigation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [currentInvestigationId, setCurrentInvestigationId] = useState(null);

    const investigationOptions = testList.map((test) => ({ value: test, label: test }));

    const config = {
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("doctorticket") || localStorage.getItem("staffticket")),
        },
    };

    useEffect(() => {
        setFullNameData(full_name);
        setProblemData(problem);
        setAppointmentNumberData(appointmentNumber);
        fetchData();
    }, [full_name, problem, appointmentNumber]);

    useEffect(() => {
        const checkEditableInvestigation = () => {
            if (!details || details.length === 0 || !appointmentHistory) return false;
            return details.some((item) => {
                if (!item.appointmentId?.appointmentHistory) return false;
                const lastHistory = item.appointmentId.appointmentHistory[
                    item.appointmentId.appointmentHistory.length - 1
                ];
                return lastHistory?.investigations?.some(
                    (investigationItem) => investigationItem._id === item._id
                );
            });
        };
        setHasEditableInvestigation(checkEditableInvestigation());
    }, [details, appointmentHistory]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiBaseUrl}/investigation/${appointmentId}`, config);
            if (response.data.success) {
                setDetails(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching investigation:", error);
            message.error("Failed to fetch investigations!");
        } finally {
            setLoading(false);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showUpdateModal = (investigation, investigationId) => {
        updateForm.setFieldsValue({ investigation });
        setCurrentInvestigationId(investigationId);
        setIsUpdateModalOpen(true);
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                addInvestigation(values);
                form.resetFields();
                setIsModalOpen(false);
            })
            .catch((info) => console.error("Validation Failed:", info));
    };

    const handleUpdateOk = () => {
        updateForm.validateFields()
            .then((values) => {
                updateInvestigation(values);
                updateForm.resetFields();
                setIsUpdateModalOpen(false);
            })
            .catch((info) => console.error("Validation Failed:", info));
    };

    const handleCancel = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: "Are you sure you want to discard changes?",
                content: "Your changes will not be saved.",
                okText: "Yes, discard",
                cancelText: "No, keep editing",
                onOk: () => {
                    form.resetFields();
                    setIsModalOpen(false);
                },
            });
        } else {
            setIsModalOpen(false);
        }
    };

    const handleUpdateCancel = () => {
        if (updateForm.isFieldsTouched()) {
            Modal.confirm({
                title: "Are you sure you want to discard changes?",
                content: "Your changes will not be saved.",
                okText: "Yes, discard",
                cancelText: "No, keep editing",
                onOk: () => {
                    updateForm.resetFields();
                    setIsUpdateModalOpen(false);
                },
            });
        } else {
            setIsUpdateModalOpen(false);
        }
    };

    const addInvestigation = async (values) => {
        setActionLoading(true);
        const data = { investigation: values.investigation };
        try {
            const endpoint = localStorage.getItem("doctorticket")
                ? `${apiBaseUrl}/doctor/investigation/${appointmentId}`
                : `${apiBaseUrl}/staff/investigation/${appointmentId}`;
            const response = await axios.post(endpoint, data, config);
            if (response.data.success) {
                message.success(response.data.message, 0.6, fetchData);
            } else {
                message.error(response?.data?.error?.error || "Failed to add investigation.");
            }
        } catch (error) {
            console.error("Failed to add investigation:", error);
            message.error("Failed to add investigation! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    const updateInvestigation = async (values) => {
        setActionLoading(true);
        const data = { investigation: values.investigation };
        try {
            const endpoint = localStorage.getItem("doctorticket")
                ? `${apiBaseUrl}/doctor/investigation/${currentInvestigationId}`
                : `${apiBaseUrl}/staff/investigation/${currentInvestigationId}`;
            const response = await axios.put(endpoint, data, config);
            if (response.data.success) {
                message.success(response.data.message, 0.6, fetchData);
            } else {
                message.error(response?.data?.error?.error || "Failed to update investigation.");
            }
        } catch (error) {
            console.error("Failed to update investigation:", error);
            message.error("Failed to update investigation! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    const deleteInvestigation = (investigationId) => {
        Modal.confirm({
            title: "Are you sure you want to delete this investigation?",
            content: "This action cannot be undone.",
            okText: "Yes, delete",
            okType: "danger",
            cancelText: "No, cancel",
            onOk: async () => {
                setActionLoading(true); // Start loading only after confirmation
                try {
                    const endpoint = localStorage.getItem("doctorticket")
                        ? `${apiBaseUrl}/doctor/investigation/${investigationId}`
                        : `${apiBaseUrl}/staff/investigation/${investigationId}`;
                    const response = await axios.delete(endpoint, config);
                    if (response.data.success) {
                        message.success(response.data.message, 0.6, fetchData);
                    } else {
                        message.error(response?.data?.error?.message || "Failed to delete investigation.");
                    }
                } catch (error) {
                    console.error("Failed to delete investigation:", error);
                    message.error("Failed to delete investigation! Please try again.");
                } finally {
                    setActionLoading(false); // Stop loading after deletion
                }
            },
            onCancel: () => {
                console.log("Deletion canceled"); // No need to reset loading state
            },
        });
    };

    function timeAgo(dateString) {
        const now = new Date();
        const created = new Date(dateString);
        const msPerDay = 1000 * 60 * 60 * 24;
        const diffMs = now - created;
        const diffDays = Math.floor(diffMs / msPerDay);
        if (diffDays === 0) return "today";
        if (diffDays < 30) return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
        const diffMonths = Math.floor(diffDays / 30);
        return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`;
    }

    function formatDate(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}/${month}/${day}`;
    }

    return (
        <>
            {actionLoading && <LoadingScreen />}
            <div className="p-6 bg-white border border-gray-200 rounded-lg shadow">
                {/* Appointment Details - Centered */}
                <div className="text-center">
                    <h5 className="text-xl font-bold text-gray-900">
                        Appointment Number: <span className="font-semibold italic">{appointmentNumberData}</span>
                    </h5>
                    <h5 className="mb-2 text-xl font-bold text-gray-900">
                        Patient Name: <span className="font-semibold italic">{fullNameData}</span>
                    </h5>
                    <p className="mb-1 font-semibold text-gray-700">
                        Problem: <span className="font-normal">{problemData}</span>
                    </p>
                </div>
                <hr className="my-4 border-black" />

                {loading ? (
                    <LoadingScreen />
                ) : details.length > 0 ? (
                    details.map((item) => (
                        <div
                            key={item._id}
                            className="flex flex-col items-center mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow"
                        >
                            <div className="text-center">
                                <p className="mb-1 font-semibold text-gray-700">
                                    Investigations added on: <span className="font-normal">{formatDate(item.createdAt)}</span>
                                    <span className="font-normal"> ({timeAgo(item.createdAt)})</span>
                                </p>
                                <p className="mb-1 font-semibold text-gray-700">
                                    Investigations Report:
                                    <div className="flex flex-wrap gap-1 justify-center">
                                        {item.investigation.map((inv) => (
                                            <Tag key={inv} color="blue">{inv}</Tag>
                                        ))}
                                    </div>
                                </p>
                                <p className="mb-1 font-semibold text-gray-700">
                                    Added by: Dr. {item.doctorId.fullname}
                                </p>
                            </div>
                            {item.appointmentId.appointmentHistory[item.appointmentId.appointmentHistory.length - 1]?.investigations.some(
                                (inv) => inv._id === item._id
                            ) && (
                                    <div className="mt-4 flex flex-col items-center">
                                        <Button
                                            className="mb-2 bg-gray-600 text-white hover:bg-gray-700"
                                            onClick={() => showUpdateModal(item.investigation, item._id)}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            type="primary"
                                            danger
                                            onClick={() => deleteInvestigation(item._id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                )}
                        </div>
                    ))
                ) : (
                    <p className="mt-3 text-center">
                        <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                            No Investigations added!
                        </h5>
                    </p>
                )}

                {!hasEditableInvestigation && (
                    <div className="mt-4 text-center">
                        <Button type="primary" onClick={showModal}>
                            Add Investigation
                        </Button>
                    </div>
                )}
            </div>

            <Modal
                title="Add Investigation"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form form={form} layout="vertical">
                    <CustomMultiSelect
                        form={form}
                        field="investigation"
                        label="Investigations"
                        options={investigationOptions}
                    />
                </Form>
            </Modal>

            <Modal
                title="Update Investigation"
                visible={isUpdateModalOpen}
                onOk={handleUpdateOk}
                onCancel={handleUpdateCancel}
            >
                <Form form={updateForm} layout="vertical">
                    <CustomMultiSelect
                        form={updateForm}
                        field="investigation"
                        label="Investigations"
                        options={investigationOptions}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default ViewInvestigation;