import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Button,
  Modal,
  Tag,
  Tabs,
} from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";

const { Option } = Select;
const { TabPane } = Tabs;

// Reusable multi-select with custom input modal
const CustomMultiSelect = ({ form, field, label, options }) => {
  const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
  const [customInput, setCustomInput] = useState("");

  const handleAddCustom = () => {
    setIsCustomModalVisible(true);
  };

  const handleCustomOk = () => {
    if (customInput.trim() !== "") {
      const currentValue = form.getFieldValue(field) || [];
      const newValue = [...currentValue, customInput.trim()];
      form.setFieldsValue({ [field]: newValue });
    }
    setCustomInput("");
    setIsCustomModalVisible(false);
  };

  const handleCustomCancel = () => {
    setCustomInput("");
    setIsCustomModalVisible(false);
  };

  return (
    <>
      <Form.Item
        label={label}
        name={field}
        rules={[{ required: true, message: `Please enter ${label.toLowerCase()}.` }]}
      >
        <Select
          mode="multiple"
          placeholder={`Select ${label} options`}
          style={{ width: "100%" }}
        >
          {options.map((opt) => (
            <Option key={opt.value} value={opt.value}>
              {opt.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Button onClick={handleAddCustom}>Add Custom {label}</Button>
      <Modal
        title={`Enter Custom ${label}`}
        visible={isCustomModalVisible}
        onOk={handleCustomOk}
        onCancel={handleCustomCancel}
      >
        <Input
          placeholder={`Type your custom ${label.toLowerCase()}`}
          value={customInput}
          onChange={(e) => setCustomInput(e.target.value)}
        />
      </Modal>
    </>
  );
};

const ViewAdvice = ({
  appointmentId,
  full_name,
  problem,
  appointmentNumber,
  onClose,
}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  // Common appointment info
  const [fullNameData, setFullNameData] = useState("");
  const [problemData, setProblemData] = useState("");
  const [appointmentNumberData, setAppointmentNumberData] = useState("");

  // Loading and action states
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  // Advice states
  const [adviceDetails, setAdviceDetails] = useState([]);
  const [isAdviceModalOpen, setIsAdviceModalOpen] = useState(false);
  const [isAdviceUpdateModalOpen, setIsAdviceUpdateModalOpen] = useState(false);
  const [currentAdviceId, setCurrentAdviceId] = useState(null);
  const [adviceForm] = Form.useForm();
  const [adviceUpdateForm] = Form.useForm();
  const [hasEditableAdvice, setHasEditableAdvice] = useState(false);

  // Physiotherapy states
  const [physioDetails, setPhysioDetails] = useState([]);
  const [isPhysioModalOpen, setIsPhysioModalOpen] = useState(false);
  const [isPhysioUpdateModalOpen, setIsPhysioUpdateModalOpen] = useState(false);
  const [currentPhysioId, setCurrentPhysioId] = useState(null);
  const [physioForm] = Form.useForm();
  const [physioUpdateForm] = Form.useForm();
  const [hasEditablePhysio, setHasEditablePhysio] = useState(false);

  // Options for selectable values
  const adviceOptions = [
    { value: "नियमित रूपमा औषधी सेवन गर्नुहोस् र चिकित्सकको सल्लाह बिना मात्रा परिवर्तन नगर्नुहोस्।", label: "नियमित रूपमा औषधी सेवन गर्नुहोस् र चिकित्सकको सल्लाह बिना मात्रा परिवर्तन नगर्नुहोस्।" },
    { value: "रोगको सक्रियता अनुगमन गर्न नियमित जाँच गराउनुहोस् र खोपहरू लगाउनुहोस्।", label: "रोगको सक्रियता अनुगमन गर्न नियमित जाँच गराउनुहोस् र खोपहरू लगाउनुहोस्।" },
    { value: "सूर्यको प्रत्यक्ष किरण, धूम्रपान, र अत्यधिक मदिरा सेवनबाट जोगिनुहोस्।", label: "सूर्यको प्रत्यक्ष किरण, धूम्रपान, र अत्यधिक मदिरा सेवनबाट जोगिनुहोस्।" },
    { value: "स्वस्थ तौल कायम राखी जोर्नीमा तनाव कम गर्नुहोस् र हल्का शारीरिक व्यायाम जारी राख्नुहोस्।", label: "स्वस्थ तौल कायम राखी जोर्नीमा तनाव कम गर्नुहोस् र हल्का शारीरिक व्यायाम जारी राख्नुहोस्।" },
    {
      value: "छाला तथा अंगहरूलाई चिसो र चोटबाट जोगाउनुहोस्, राम्रोसंग मोइस्चराइज गर्नुहोस्, र गर्भधारण योजना भएमा चिकित्सकसँग परामर्श गर्नुहोस्।",
      label: "छाला तथा अंगहरूलाई चिसो र चोटबाट जोगाउनुहोस्, राम्रोसंग मोइस्चराइज गर्नुहोस्, र गर्भधारण योजना भएमा चिकित्सकसँग परामर्श गर्नुहोस्।",
    },
  ];

  const physioOptions = [
    { value: "Physiotherapy for osteoarthritis knees.", label: "Physiotherapy for osteoarthritis knees." },
    { value: "Physiotherapy for golfer's elbow.", label: "Physiotherapy for golfer's elbow." },
    { value: "Physiotherapy for tennis elbow.", label: "Physiotherapy for tennis elbow." },
    { value: "Physiotherapy for lumbar spondylosis.", label: "Physiotherapy for lumbar spondylosis." },
    { value: "Physiotherapy for cervical spondylosis.", label: "Physiotherapy for cervical spondylosis." },
    { value: "Physiotherapy for osteoarthritis of hip.", label: "Physiotherapy for osteoarthritis of hip." },
    { value: "Physiotherapy for small joints of hands.", label: "Physiotherapy for small joints of hands." },
  ];

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  useEffect(() => {
    setFullNameData(full_name || "");
    setProblemData(problem || "");
    setAppointmentNumberData(appointmentNumber || "");
    fetchAdvice();
    fetchPhysiotherapy();
  }, [appointmentId, full_name, problem, appointmentNumber]);

  // Compute "editable" flags
  useEffect(() => {
    const checkEditableAdvice = () => {
      if (!adviceDetails || adviceDetails.length === 0) return false;
      return adviceDetails.some((item) => {
        if (!item.appointmentId?.appointmentHistory) return false;
        const lastHistory =
          item.appointmentId.appointmentHistory[
          item.appointmentId.appointmentHistory.length - 1
          ];
        return lastHistory?.advice?.some((a) => a._id === item._id);
      });
    };
    setHasEditableAdvice(checkEditableAdvice());
  }, [adviceDetails]);

  useEffect(() => {
    const checkEditablePhysio = () => {
      if (!physioDetails || physioDetails.length === 0) return false;
      return physioDetails.some((item) => {
        if (!item.appointmentId?.appointmentHistory) return false;
        const lastHistory =
          item.appointmentId.appointmentHistory[
          item.appointmentId.appointmentHistory.length - 1
          ];
        return lastHistory?.physiotherapy?.some((p) => p._id === item._id);
      });
    };
    setHasEditablePhysio(checkEditablePhysio());
  }, [physioDetails]);

  // Fetch Advice from BE
  const fetchAdvice = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/advice/${appointmentId}`, config)
      .then((response) => {
        if (response.data.success) {
          setAdviceDetails(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching advice:", error))
      .finally(() => setLoading(false));
  };

  // Fetch Physiotherapy from BE
  const fetchPhysiotherapy = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/physiotherapy/${appointmentId}`, config)
      .then((response) => {
        if (response.data.success) {
          setPhysioDetails(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching physiotherapy:", error))
      .finally(() => setLoading(false));
  };

  // Advice functions
  const addAdvice = async (values) => {
    setActionLoading(true);
    const data = { advice: values.advice };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.post(
          `${apiBaseUrl}/doctor/advice/${appointmentId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.post(
          `${apiBaseUrl}/staff/advice/${appointmentId}`,
          data,
          config
        );
      }
      if (response.data.success) {
        message.success(response.data.message, 0.6, fetchAdvice);
      } else {
        message.error(response?.data?.error?.error || "Failed to add advice.");
      }
    } catch (error) {
      console.error("Failed to add advice:", error);
      message.error("Failed to add advice! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const updateAdvice = async (values) => {
    setActionLoading(true);
    const data = { advice: values.advice };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.put(
          `${apiBaseUrl}/doctor/advice/${currentAdviceId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.put(
          `${apiBaseUrl}/staff/advice/${currentAdviceId}`,
          data,
          config
        );
      }
      if (response.data.success) {
        message.success(response.data.message, 0.6, fetchAdvice);
      } else {
        message.error(response?.data?.error?.error || "Failed to update advice.");
      }
    } catch (error) {
      console.error("Failed to update advice:", error);
      message.error("Failed to update advice! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const deleteAdvice = async (id) => {
    setActionLoading(true);
    try {
      if (window.confirm("Are you sure want to delete this advice?")) {
        let response;
        if (localStorage.getItem("doctorticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/doctor/advice/${id}`,
            config
          );
        } else if (localStorage.getItem("staffticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/staff/advice/${id}`,
            config
          );
        }
        if (response.data.success) {
          message.success(response.data.message, 0.6, fetchAdvice);
        } else {
          message.error(
            response?.data?.error?.message || "Failed to delete advice."
          );
        }
      }
    } catch (error) {
      console.error("Failed to delete advice:", error);
      message.error("Failed to delete! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  // Physiotherapy functions
  const addPhysiotherapy = async (values) => {
    setActionLoading(true);
    const data = { physiotherapy: values.physiotherapy };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.post(
          `${apiBaseUrl}/doctor/physiotherapy/${appointmentId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.post(
          `${apiBaseUrl}/staff/physiotherapy/${appointmentId}`,
          data,
          config
        );
      }
      if (response.data.success) {
        message.success(response.data.message, 0.6, fetchPhysiotherapy);
      } else {
        message.error(
          response?.data?.error?.error || "Failed to add physiotherapy."
        );
      }
    } catch (error) {
      console.error("Failed to add physiotherapy:", error);
      message.error("Failed to add physiotherapy! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const updatePhysiotherapy = async (values) => {
    setActionLoading(true);
    const data = { physiotherapy: values.physiotherapy };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.put(
          `${apiBaseUrl}/doctor/physiotherapy/${currentPhysioId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.put(
          `${apiBaseUrl}/staff/physiotherapy/${currentPhysioId}`,
          data,
          config
        );
      }
      if (response.data.success) {
        message.success(response.data.message, 0.6, fetchPhysiotherapy);
      } else {
        message.error(
          response?.data?.error?.error || "Failed to update physiotherapy."
        );
      }
    } catch (error) {
      console.error("Failed to update physiotherapy:", error);
      message.error("Failed to update physiotherapy! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const deletePhysiotherapy = async (id) => {
    setActionLoading(true);
    try {
      if (window.confirm("Are you sure want to delete this physiotherapy?")) {
        let response;
        if (localStorage.getItem("doctorticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/doctor/physiotherapy/${id}`,
            config
          );
        } else if (localStorage.getItem("staffticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/staff/physiotherapy/${id}`,
            config
          );
        }
        if (response.data.success) {
          message.success(response.data.message, 0.6, fetchPhysiotherapy);
        } else {
          message.error(
            response?.data?.error?.message || "Failed to delete physiotherapy."
          );
        }
      }
    } catch (error) {
      console.error("Failed to delete physiotherapy:", error);
      message.error("Failed to delete! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const handleGoBack = () => {
    onClose();
  };

  function timeAgo(dateString) {
    const now = new Date();
    const created = new Date(dateString);
    const diffDays = Math.floor((now - created) / (1000 * 60 * 60 * 24));
    if (diffDays === 0) return "today";
    if (diffDays < 30) return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
    const diffMonths = Math.floor(diffDays / 30);
    return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`;
  }

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  return (
    <>
      {actionLoading && <LoadingScreen />}
      <div className="mb-3">
        <h1 className="text-3xl font-bold">Advices and Physiotherapy</h1>
      </div>

      <div className="text-center mb-6">
        {/* <Button
          className="px-4 py-1 rounded-md bg-blue-600 text-white hover:bg-blue-700 mb-2"
          onClick={handleGoBack}
        >
          Go Back
        </Button> */}
        <h5 className="text-xl font-bold">
          Appointment Number:{" "}
          <span className="italic">{appointmentNumberData}</span>
        </h5>
        <h5 className="text-xl font-bold">
          Patient Name: <span className="italic">{fullNameData}</span>
        </h5>
        <p className="font-semibold">
          Problem: <span className="font-normal">{problemData}</span>
        </p>
      </div>

      <Tabs defaultActiveKey="1">
        {/* Advice Tab */}
        <TabPane tab="Advice" key="1">
          {loading ? (
            <LoadingScreen />
          ) : (
            <>
              {adviceDetails.length > 0 ? (
                adviceDetails.map((item) => (
                  <div
                    key={item._id}
                    className="p-4 mb-2 bg-white border rounded shadow"
                  >
                    <p className="font-semibold">
                      Added on:{" "}
                      <span className="font-normal">
                        {formatDate(item.createdAt)} ({timeAgo(item.createdAt)})
                      </span>
                    </p>
                    <p className="font-semibold">
                      Advice:
                      <span className="font-normal ml-2">
                        {item.advice.map((str, idx) => (
                          <Tag key={idx} color="blue">
                            {str}
                          </Tag>
                        ))}
                      </span>
                    </p>
                    <p className="font-semibold">
                      Added by:{" "}
                      <span className="font-normal">
                        Dr. {item.doctorId.fullname}
                      </span>
                    </p>
                    <div className="mt-2 flex flex-col items-start">
                      <Button
                        className="px-4 py-1 rounded-md bg-gray-600 text-white hover:bg-gray-700 mr-2"
                        onClick={() => {
                          const adviceItem = adviceDetails.find(
                            (a) => a._id === item._id
                          );
                          if (adviceItem) {
                            adviceUpdateForm.setFieldsValue({
                              advice: adviceItem.advice,
                            });
                          }
                          setCurrentAdviceId(item._id);
                          setIsAdviceUpdateModalOpen(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        type="primary"
                        danger
                        onClick={() => deleteAdvice(item._id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-red-500 text-center">No Advice added!!</p>
              )}
              {!hasEditableAdvice && (
                <Button
                  type="primary"
                  onClick={() => setIsAdviceModalOpen(true)}
                >
                  Add Advice
                </Button>
              )}
            </>
          )}
        </TabPane>

        {/* Physiotherapy Tab */}
        <TabPane tab="Physiotherapy" key="2">
          {loading ? (
            <LoadingScreen />
          ) : (
            <>
              {physioDetails.length > 0 ? (
                physioDetails.map((item) => (
                  <div
                    key={item._id}
                    className="p-4 mb-2 bg-white border rounded shadow"
                  >
                    <p className="font-semibold">
                      Added on:{" "}
                      <span className="font-normal">
                        {formatDate(item.createdAt)} ({timeAgo(item.createdAt)})
                      </span>
                    </p>
                    <p className="font-semibold">
                      Physiotherapy:
                      <span className="font-normal ml-2">
                        {item.physiotherapy.map((str, idx) => (
                          <Tag key={idx} color="blue">
                            {str}
                          </Tag>
                        ))}
                      </span>
                    </p>
                    <p className="font-semibold">
                      Added by:{" "}
                      <span className="font-normal">
                        Dr. {item.doctorId.fullname}
                      </span>
                    </p>
                    <div className="mt-2 flex flex-col items-start">
                      <Button
                        className="px-4 py-1 rounded-md bg-gray-600 text-white hover:bg-gray-700 mr-2"
                        onClick={() => {
                          const physioItem = physioDetails.find(
                            (p) => p._id === item._id
                          );
                          if (physioItem) {
                            physioUpdateForm.setFieldsValue({
                              physiotherapy: physioItem.physiotherapy,
                            });
                          }
                          setCurrentPhysioId(item._id);
                          setIsPhysioUpdateModalOpen(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        type="primary"
                        danger
                        onClick={() => deletePhysiotherapy(item._id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-red-500 text-center">
                  No Physiotherapy added!!
                </p>
              )}
              {!hasEditablePhysio && (
                <Button
                  type="primary"
                  onClick={() => setIsPhysioModalOpen(true)}
                >
                  Add Physiotherapy
                </Button>
              )}
            </>
          )}
        </TabPane>
      </Tabs>

      {/* Add Advice Modal */}
      <Modal
        title="Add Advice"
        visible={isAdviceModalOpen}
        onOk={() => {
          adviceForm
            .validateFields()
            .then((values) => {
              addAdvice(values);
              adviceForm.resetFields();
              setIsAdviceModalOpen(false);
            })
            .catch((info) => console.error("Validation Failed:", info));
        }}
        onCancel={() => setIsAdviceModalOpen(false)}
      >
        <Form form={adviceForm} layout="vertical">
          <CustomMultiSelect
            form={adviceForm}
            field="advice"
            label="Advice"
            options={adviceOptions}
          />
        </Form>
      </Modal>

      {/* Update Advice Modal */}
      <Modal
        title="Update Advice"
        visible={isAdviceUpdateModalOpen}
        onOk={() => {
          adviceUpdateForm
            .validateFields()
            .then((values) => {
              updateAdvice(values);
              adviceUpdateForm.resetFields();
              setIsAdviceUpdateModalOpen(false);
            })
            .catch((info) => console.error("Validation Failed:", info));
        }}
        onCancel={() => {
          setIsAdviceUpdateModalOpen(false);
          adviceUpdateForm.resetFields();
        }}
      >
        <Form form={adviceUpdateForm} layout="vertical">
          <CustomMultiSelect
            form={adviceUpdateForm}
            field="advice"
            label="Advice"
            options={adviceOptions}
          />
        </Form>
      </Modal>

      {/* Add Physiotherapy Modal */}
      <Modal
        title="Add Physiotherapy"
        visible={isPhysioModalOpen}
        onOk={() => {
          physioForm
            .validateFields()
            .then((values) => {
              addPhysiotherapy(values);
              physioForm.resetFields();
              setIsPhysioModalOpen(false);
            })
            .catch((info) => console.error("Validation Failed:", info));
        }}
        onCancel={() => setIsPhysioModalOpen(false)}
      >
        <Form form={physioForm} layout="vertical">
          <CustomMultiSelect
            form={physioForm}
            field="physiotherapy"
            label="Physiotherapy"
            options={physioOptions}
          />
        </Form>
      </Modal>

      {/* Update Physiotherapy Modal */}
      <Modal
        title="Update Physiotherapy"
        visible={isPhysioUpdateModalOpen}
        onOk={() => {
          physioUpdateForm
            .validateFields()
            .then((values) => {
              updatePhysiotherapy(values);
              physioUpdateForm.resetFields();
              setIsPhysioUpdateModalOpen(false);
            })
            .catch((info) => console.error("Validation Failed:", info));
        }}
        onCancel={() => {
          setIsPhysioUpdateModalOpen(false);
          physioUpdateForm.resetFields();
        }}
      >
        <Form form={physioUpdateForm} layout="vertical">
          <CustomMultiSelect
            form={physioUpdateForm}
            field="physiotherapy"
            label="Physiotherapy"
            options={physioOptions}
          />
        </Form>
      </Modal>
    </>
  );
};

export default ViewAdvice;