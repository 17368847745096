import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import LoadingScreen from "../lib/constants/loadingScreen";

const ViewPrescription = ({
  appointmentId,
  full_name,
  problem,
  appointmentHistory, // Included for consistency, though not used yet
}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [details, setDetails] = useState([]);

  // Dynamic auth config based on localStorage ticket
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];

    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} };
    }

    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
      },
    };
  };

  const config = getAuthConfig();

  // Fetch prescription data when component mounts or appointmentId changes
  useEffect(() => {
    fetchData();
  }, [appointmentId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiBaseUrl}/doctor/get_prescription/${appointmentId}`,
        config
      );
      setDetails(response.data.data);
      console.log(response.data.data);
    } catch (e) {
      console.error("Failed to fetch prescription details:", e);
      message.error("Failed to get prescription details! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="mb-4">
        <h1 className="text-3xl font-bold decoration-gray-400">Prescription</h1>
      </div>

      <div className="flex items-start">
        <div
          className="me-2 p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow 
              lg:max-w-[100%] lg:w-[100%]"
        >
          {loading && (
            <div>
              <LoadingScreen />
            </div>
          )}

          {!loading && (
            <>
              <p>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name:{" "}
                  <span className="font-semibold">{full_name}</span>
                </h5>
              </p>
              <p className="mb-1 font-semibold text-gray-700">
                Problem: <span className="font-normal">{problem}</span>
              </p>

              {details.length > 0 ? (
                details.map((item) => (
                  <div
                    key={item._id}
                    className="me-2 mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex-grow"
                  >
                    {/* General Prescription Details */}
                    <div className="mb-4">
                      <p className="mb-1 font-semibold text-gray-700">
                        Prescription added on:{" "}
                        <span className="font-normal">{formatDate(item.createdAt)}</span>
                      </p>
                      <p className="mb-1 font-semibold text-gray-700">
                        Comment:{" "}
                        <span className="font-normal">
                          {item.comment ? item.comment : "---"}
                        </span>
                      </p>
                      <p className="mb-1 font-semibold text-gray-700">
                        Follow Up:{" "}
                        <span className="font-normal">
                          {item.follow_up ? formatDate(item.follow_up) : "---"}
                        </span>
                      </p>
                      <p className="mb-1 font-semibold text-gray-700">
                        Added By:{" "}
                        <span className="font-normal">{item.doctorId?.fullname}</span>
                      </p>
                    </div>

                    {/* Medicines Table */}
                    <div className="overflow-x-auto mt-4">
                      <table className="w-full text-sm text-left text-gray-700 border border-gray-200">
                        <thead className="bg-gray-100 text-gray-700 font-semibold">
                          <tr>
                            <th className="px-4 py-2 border">Medicine</th>
                            <th className="px-4 py-2 border">Affiliate Name</th>
                            <th className="px-4 py-2 border">Tablet</th>
                            <th className="px-4 py-2 border">Dose</th>
                            <th className="px-4 py-2 border">Frequency</th>
                            <th className="px-4 py-2 border">Duration</th>
                            <th className="px-4 py-2 border">Meal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.medicine.map((medicineItem, index) =>
                            medicineItem.details.map((detail, detailIndex) => (
                              <tr
                                key={`${index}-${detailIndex}`}
                                className="border-b hover:bg-gray-50"
                              >
                                {detailIndex === 0 && (
                                  <td
                                    className="px-4 py-2 border"
                                    rowSpan={medicineItem.details.length}
                                  >
                                    <div className="font-medium">
                                      {medicineItem.medicineName.split("[")[0]}
                                    </div>
                                    {medicineItem.comment && (
                                      <div className="text-xs text-gray-500 mt-1">
                                        {medicineItem.comment}
                                      </div>
                                    )}
                                  </td>
                                )}
                                {detailIndex === 0 && (
                                  <td
                                    className="px-4 py-2 border"
                                    rowSpan={medicineItem.details.length}
                                  >
                                    {medicineItem.affiliateName}
                                  </td>
                                )}
                                <td className="px-4 py-2 border">{detail.tablet}</td>
                                <td className="px-4 py-2 border">{detail.dose}</td>
                                <td className="px-4 py-2 border">
                                  <div className="flex items-center gap-1">
                                    <span>•</span>
                                    {`${detail.frequency.number}x/${detail.frequency.period}`}
                                    {detail.frequency.period === "week" && (
                                      <span className="text-xs text-gray-500 ml-2">
                                        (
                                        {detail.frequency.selectedDays
                                          ?.map(
                                            (day) => day.charAt(0).toUpperCase() + day.slice(1)
                                          )
                                          .join(", ")}
                                        )
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td className="px-4 py-2 border">
                                  {detail.duration.number} {detail.duration.period}
                                  {detail.duration.number > 1 && "s"}
                                </td>
                                <td className="px-4 py-2 border capitalize">{detail.Meal}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-3">
                  <h5 className="text-xl text-red-500 font-semibold">
                    Prescription not found!
                  </h5>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewPrescription;