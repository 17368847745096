import React, { useState, useRef } from "react";
import {
    Button,
    Space,
    message,
    DatePicker,
    Select,
    Descriptions,
    Card,
    Table,
} from "antd";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const { RangePicker } = DatePicker;
const { Option } = Select;

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// Print-specific CSS
const printStyles = `
  @media print {
    body {
      margin: 0;
      padding: 0;
    }
    .print-container {
      width: 100%;
      padding: 30mm 25mm; /* 30mm (~1.2 inches) top/bottom, 25mm (~1 inch) left/right */
      box-sizing: border-box;
    }
    .ant-card {
      border: none; /* Remove card border for a cleaner look */
      box-shadow: none;
      margin-bottom: 16px;
    }
    .ant-card-head {
      border-bottom: 1px solid #e8e8e8;
      padding: 0 0 8px 0;
    }
    .ant-card-body {
      padding: 16px 0;
    }
    .ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-bordered .ant-descriptions-item-content {
      padding: 8px;
      font-size: 12pt;
    }
    .ant-table {
      font-size: 12pt;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 6px;
    }
    h3, h4 {
      margin-top: 16px;
      margin-bottom: 8px;
      font-size: 14pt;
    }
    /* Hide buttons and other UI elements not needed in print */
    .print-hidden {
      display: none !important;
    }
  }
`;

const PrintRecords = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [expenseCategory, setExpenseCategory] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(false);
    const printRef = useRef();

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    // Fetch financial report data
    const fetchReportData = async () => {
        setLoading(true);
        try {
            const params = {};
            if (dateRange[0] && dateRange[1]) {
                params.startDate = dateRange[0].format("YYYY-MM-DD");
                params.endDate = dateRange[1].format("YYYY-MM-DD");
            }
            if (paymentMethod) params.paymentMethod = paymentMethod;
            if (expenseCategory) params.category = expenseCategory;

            const response = await axios.get(`${apiBaseUrl}/financial-reports`, {
                params,
                ...config,
            });
            if (response.data.success) {
                setReportData(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching records:", error);
            message.error("Failed to fetch records");
            setReportData(null);
        } finally {
            setLoading(false);
        }
    };

    // Handle generate records button click
    const handleGenerateRecords = () => {
        fetchReportData();
    };

    // Handle print button click
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `Clinic Records - ${moment().format("YYYY-MM-DD")}`,
        onBeforePrint: () => {
            // Inject print styles into the document
            const style = document.createElement("style");
            style.innerHTML = printStyles;
            document.head.appendChild(style);
        },
    });

    // Clear filters
    const handleClearFilters = () => {
        setDateRange([null, null]);
        setPaymentMethod(null);
        setExpenseCategory(null);
        setReportData(null);
    };

    // Helper to render breakdown tables
    const renderBreakdownTable = (data, keyLabel, valueLabel) => {
        const tableData = Object.entries(data).map(([key, value]) => ({
            key,
            value: value.toFixed(2),
        }));
        return (
            <Table
                dataSource={tableData}
                columns={[
                    { title: keyLabel, dataIndex: "key", key: "key" },
                    { title: valueLabel, dataIndex: "value", key: "value", render: (text) => `$${text}` },
                ]}
                pagination={false}
                size="small"
            />
        );
    };

    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: 16 }}>
                Print Clinic Records
            </h1>
            <Space style={{ marginBottom: 16 }} wrap className="print-hidden">
                <RangePicker
                    value={dateRange}
                    onChange={(dates) => setDateRange(dates)}
                    format="YYYY-MM-DD"
                    placeholder={["Start Date (Optional)", "End Date (Optional)"]}
                    style={{ width: 300 }}
                />
                <Select
                    placeholder="Payment Method (Optional)"
                    value={paymentMethod}
                    onChange={setPaymentMethod}
                    allowClear
                    style={{ width: 200 }}
                >
                    <Option value="Cash">Cash</Option>
                    <Option value="Card">Card</Option>
                    <Option value="Bank Transfer">Bank Transfer</Option>
                </Select>
                <Select
                    placeholder="Expense Type (Optional)"
                    value={expenseCategory}
                    onChange={setExpenseCategory}
                    allowClear
                    style={{ width: 200 }}
                >
                    <Option value="Rent">Rent</Option>
                    <Option value="Utilities">Utilities</Option>
                    <Option value="Salaries">Salaries</Option>
                    <Option value="Supplies">Supplies</Option>
                    <Option value="Equipment Maintenance">Equipment Maintenance</Option>
                    <Option value="Medical Supplies">Medical Supplies</Option>
                </Select>
                <Button type="primary" onClick={handleGenerateRecords} loading={loading}>
                    Show Records
                </Button>
                <Button type="default" onClick={handleClearFilters}>
                    Clear Options
                </Button>
                {reportData && (
                    <Button type="primary" onClick={handlePrint}>
                        Print Records
                    </Button>
                )}
            </Space>

            {loading ? (
                <div>Loading records...</div>
            ) : reportData ? (
                <div ref={printRef}>
                    <div className="print-container">
                        <Card
                            title={`Clinic Records: ${reportData.dateRange.startDate} to ${reportData.dateRange.endDate}`}
                            style={{ marginBottom: 16 }}
                        >
                            <Descriptions bordered>
                                <Descriptions.Item label="Total Money Left (Profit)" span={3}>
                                    <span style={{ color: reportData.netProfit >= 0 ? "green" : "red", fontSize: "18px" }}>
                                        ${reportData.netProfit.toFixed(2)}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Total Money from Patients" span={2}>
                                    ${reportData.revenue.patientBillings.totalNet.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Profit from Patients">
                                    ${reportData.revenue.patientBillings.profit.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Other Income" span={2}>
                                    ${reportData.revenue.additionalIncome.totalAmount.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Total Expenses">
                                    ${reportData.expenses.total.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Money Received from Patients" span={2}>
                                    ${reportData.revenue.patientBillings.amountPaid.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Money Owed by Patients">
                                    <span style={{ color: "red" }}>
                                        ${reportData.revenue.patientBillings.dueAmount.toFixed(2)}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Total Money Coming In" span={2}>
                                    ${reportData.revenue.total.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Cash Movement">
                                    ${(reportData.cashFlow.cash.net + reportData.cashFlow.bank.net).toFixed(2)}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>

                        <Card title="Details">
                            <Descriptions bordered size="small">
                                <Descriptions.Item label="Pharmacy Sales" span={2}>
                                    ${reportData.revenue.patientBillings.pharmacyBillings.totalNet.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Pharmacy Profit">
                                    ${reportData.revenue.patientBillings.pharmacyBillings.profit.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Test Sales" span={2}>
                                    ${reportData.revenue.patientBillings.testBillings.totalNet.toFixed(2)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Test Profit">
                                    ${reportData.revenue.patientBillings.testBillings.profit.toFixed(2)}
                                </Descriptions.Item>
                            </Descriptions>

                            {Object.keys(reportData.revenue.patientBillings.pharmacyBillings.profitBreakdown).length > 0 && (
                                <div style={{ marginTop: 16 }}>
                                    <h4>Pharmacy Profit Breakdown:</h4>
                                    {renderBreakdownTable(
                                        reportData.revenue.patientBillings.pharmacyBillings.profitBreakdown,
                                        "Medicine",
                                        "Profit ($)"
                                    )}
                                </div>
                            )}
                            {Object.keys(reportData.revenue.patientBillings.testBillings.profitBreakdown).length > 0 && (
                                <div style={{ marginTop: 16 }}>
                                    <h4>Test Profit Breakdown:</h4>
                                    {renderBreakdownTable(
                                        reportData.revenue.patientBillings.testBillings.profitBreakdown,
                                        "Test",
                                        "Profit ($)"
                                    )}
                                </div>
                            )}
                            {Object.keys(reportData.revenue.additionalIncome.bySource).length > 0 && (
                                <div style={{ marginTop: 16 }}>
                                    <h4>Other Income Sources:</h4>
                                    {renderBreakdownTable(
                                        reportData.revenue.additionalIncome.bySource,
                                        "Source",
                                        "Amount ($)"
                                    )}
                                </div>
                            )}
                            {Object.keys(reportData.expenses.operational.byCategory).length > 0 && (
                                <div style={{ marginTop: 16 }}>
                                    <h4>Expense Breakdown:</h4>
                                    {renderBreakdownTable(
                                        reportData.expenses.operational.byCategory,
                                        "Type",
                                        "Amount ($)"
                                    )}
                                </div>
                            )}
                        </Card>
                    </div>
                </div>
            ) : (
                <div className="print-hidden">
                    <p>Select a date range or leave it blank to print all records.</p>
                </div>
            )}
        </div>
    );
};

export default PrintRecords;