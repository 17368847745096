import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, message, Button, Modal, Select, Tag } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";

const { TextArea } = Input;

const ViewDiagnosis = ({
  appointmentId,
  full_name,
  problem,
  appointmentNumber,
  appointmentHistory,
  onClose,
}) => {
  const diseaseOptions = {
    RHEUMATOID_ARTHRITIS: "",
    SYSTEMIC_LUPUS_ERYTHEMATOSUS: "",
    SPONDYLOARTHRITIS: "",
    SYSTEMIC_SCLEROSIS: "",
    MYOSITIS: "",
    OSTEOARTHRITIS: "",
    VASCULITIS: "",
    MIXED_CONNECTIVE_TISSUE_DISEASE: "",
    OVERLAP_SYNDROMES: "",
    UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE: "",
    GOUT_POLYARTICULAR_GOUTY_ARTHRITIS: "",
    PRIMARY_SJÖGREN_DISEASE: "",
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [hasEditableDiagnosis, setHasEditableDiagnosis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentDiagnosisId, setCurrentDiagnosisId] = useState(null);
  const [selectedDiseases, setSelectedDiseases] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") || localStorage.getItem("staffticket")),
    },
  };

  useEffect(() => {
    fetchData();
  }, [appointmentId]);

  useEffect(() => {
    const checkEditableDiagnosis = () => {
      if (!details || details.length === 0 || !appointmentHistory) return false;
      return details.some((item) => {
        if (!item.appointmentId?.appointmentHistory) return false;
        const lastHistory = item.appointmentId.appointmentHistory[
          item.appointmentId.appointmentHistory.length - 1
        ];
        return lastHistory?.diagnosis?.some(
          (diagnosisItem) => diagnosisItem._id === item._id
        );
      });
    };
    setHasEditableDiagnosis(checkEditableDiagnosis());
  }, [details, appointmentHistory]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiBaseUrl}/diagnosis/${appointmentId}`, config);
      if (response.data.success) {
        setDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching diagnosis:", error);
      message.error("Failed to fetch diagnosis!");
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setSelectedDiseases([]);
    setIsModalOpen(true);
  };

  const showUpdateModal = (diagnosis, diagnosisId) => {
    updateForm.setFieldsValue({ diagnosis });
    setCurrentDiagnosisId(diagnosisId);
    setSelectedDiseases([]);
    setIsUpdateModalOpen(true);
  };

  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        addDiagnosis(values);
        form.resetFields();
        setSelectedDiseases([]);
        setIsModalOpen(false);
      })
      .catch((info) => console.error("Validation Failed:", info));
  };

  const handleUpdateOk = () => {
    updateForm.validateFields()
      .then((values) => {
        updateDiagnosis(values);
        updateForm.resetFields();
        setSelectedDiseases([]);
        setIsUpdateModalOpen(false);
      })
      .catch((info) => console.error("Validation Failed:", info));
  };

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          form.resetFields();
          setSelectedDiseases([]);
          setIsModalOpen(false);
        },
      });
    } else {
      setIsModalOpen(false);
    }
  };

  const handleUpdateCancel = () => {
    if (updateForm.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          updateForm.resetFields();
          setSelectedDiseases([]);
          setIsUpdateModalOpen(false);
        },
      });
    } else {
      setIsUpdateModalOpen(false);
    }
  };

  const handleDiseaseSelect = (category) => {
    if (!selectedDiseases.includes(category)) {
      const newDiseases = [...selectedDiseases, category];
      setSelectedDiseases(newDiseases);
      const currentText = form.getFieldValue("diagnosis") || "";
      const diseaseText = diseaseOptions[category];
      const newText = currentText
        ? `${currentText}\n\n${category}:\n${diseaseText}`
        : `${category}:\n${diseaseText}`;
      form.setFieldsValue({ diagnosis: newText });
    }
  };

  const handleDiseaseRemove = (categoryToRemove) => {
    const newDiseases = selectedDiseases.filter((category) => category !== categoryToRemove);
    setSelectedDiseases(newDiseases);
    const currentText = form.getFieldValue("diagnosis") || "";
    const textParts = currentText.split("\n\n");
    const newText = textParts
      .filter((part) => !part.startsWith(`${categoryToRemove}:`))
      .join("\n\n");
    form.setFieldsValue({ diagnosis: newText });
  };

  const handleDiseaseSelectUpdate = (category) => {
    if (!selectedDiseases.includes(category)) {
      const newDiseases = [...selectedDiseases, category];
      setSelectedDiseases(newDiseases);
      const currentText = updateForm.getFieldValue("diagnosis") || "";
      const diseaseText = diseaseOptions[category];
      const newText = currentText
        ? `${currentText}\n\n${category}:\n${diseaseText}`
        : `${category}:\n${diseaseText}`;
      updateForm.setFieldsValue({ diagnosis: newText });
    }
  };

  const handleDiseaseRemoveUpdate = (categoryToRemove) => {
    const newDiseases = selectedDiseases.filter((category) => category !== categoryToRemove);
    setSelectedDiseases(newDiseases);
    const currentText = updateForm.getFieldValue("diagnosis") || "";
    const textParts = currentText.split("\n\n");
    const newText = textParts
      .filter((part) => !part.startsWith(`${categoryToRemove}:`))
      .join("\n\n");
    updateForm.setFieldsValue({ diagnosis: newText });
  };

  const addDiagnosis = async (values) => {
    setActionLoading(true);
    const data = { diagnosis: values.diagnosis };
    try {
      const endpoint = localStorage.getItem("doctorticket")
        ? `${apiBaseUrl}/doctor/diagnosis/${appointmentId}`
        : `${apiBaseUrl}/staff/diagnosis/${appointmentId}`;
      const response = await axios.post(endpoint, data, config);
      if (response.data.success) {
        message.success(response.data.message, 0.6, fetchData);
      } else {
        message.error(response?.data?.error?.error || "Failed to add diagnosis.");
      }
    } catch (error) {
      console.error("Failed to add diagnosis:", error);
      message.error("Failed to add diagnosis! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const updateDiagnosis = async (values) => {
    setActionLoading(true);
    const data = { diagnosis: values.diagnosis };
    try {
      const endpoint = localStorage.getItem("doctorticket")
        ? `${apiBaseUrl}/doctor/diagnosis/${currentDiagnosisId}`
        : `${apiBaseUrl}/staff/diagnosis/${currentDiagnosisId}`;
      const response = await axios.put(endpoint, data, config);
      if (response.data.success) {
        message.success(response.data.message, 0.6, fetchData);
      } else {
        message.error(response?.data?.error?.error || "Failed to update diagnosis.");
      }
    } catch (error) {
      console.error("Failed to update diagnosis:", error);
      message.error("Failed to update diagnosis! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const deleteDiagnosis = (diagnosisId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this diagnosis?",
      content: "This action cannot be undone.",
      okText: "Yes, delete",
      okType: "danger",
      cancelText: "No, cancel",
      onOk: async () => {
        setActionLoading(true);
        try {
          const endpoint = localStorage.getItem("doctorticket")
            ? `${apiBaseUrl}/doctor/diagnosis/${diagnosisId}`
            : `${apiBaseUrl}/staff/diagnosis/${diagnosisId}`;
          const response = await axios.delete(endpoint, config);
          if (response.data.success) {
            message.success(response.data.message, 0.6, fetchData);
          } else {
            message.error(response?.data?.error?.message || "Failed to delete diagnosis.");
          }
        } catch (error) {
          console.error("Failed to delete diagnosis:", error);
          message.error("Failed to delete diagnosis! Please try again.");
        } finally {
          setActionLoading(false);
        }
      },
    });
  };

  function timeAgo(dateString) {
    const now = new Date();
    const created = new Date(dateString);
    const msPerDay = 1000 * 60 * 60 * 24;
    const diffMs = now - created;
    const diffDays = Math.floor(diffMs / msPerDay);
    if (diffDays === 0) return "today";
    if (diffDays < 30) return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
    const diffMonths = Math.floor(diffDays / 30);
    return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`;
  }

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  return (
    <>
      {actionLoading && <LoadingScreen />}
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow">
        {/* Appointment Details - Centered */}
        <div className="text-center">
          <h5 className="text-xl font-bold text-gray-900">
            Appointment Number: <span className="font-semibold italic">{appointmentNumber}</span>
          </h5>
          <h5 className="mb-2 text-xl font-bold text-gray-900">
            Patient Name: <span className="font-semibold italic">{full_name}</span>
          </h5>
          <p className="mb-1 font-semibold text-gray-700">
            Problem: <span className="font-normal">{problem}</span>
          </p>
        </div>
        <hr className="my-4 border-black" />

        {loading ? (
          <LoadingScreen />
        ) : details.length > 0 ? (
          details.map((item) => (
            <div
              key={item._id}
              className="flex flex-col items-center mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow"
            >
              <div className="text-center">
                <p className="mb-1 font-semibold text-gray-700">
                  Diagnosis added on: <span className="font-normal">{formatDate(item.createdAt)}</span>
                  <span className="font-normal"> ({timeAgo(item.createdAt)})</span>
                </p>
                <p
                  className="mb-1 font-semibold text-gray-700"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  Diagnosis Report: <span className="font-normal">{item.diagnosis}</span>
                </p>
                <p className="mb-1 font-semibold text-gray-700">
                  Added by: Dr. {item.doctorId.fullname}
                </p>
              </div>
              {item.appointmentId.appointmentHistory[
                item.appointmentId.appointmentHistory.length - 1
              ]?.diagnosis.some((diagnosisItem) => diagnosisItem._id === item._id) && (
                  <div className="mt-4 flex flex-col items-center">
                    <Button
                      className="mb-2 bg-gray-600 text-white hover:bg-gray-700"
                      onClick={() => showUpdateModal(item.diagnosis, item._id)}
                    >
                      Update
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={() => deleteDiagnosis(item._id)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
            </div>
          ))
        ) : (
          <p className="mt-3 text-center">
            <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
              No Diagnosis added!
            </h5>
          </p>
        )}

        {!hasEditableDiagnosis && (
          <div className="mt-4 text-center">
            <Button type="primary" onClick={showModal}>
              Add Diagnosis
            </Button>
          </div>
        )}
      </div>

      <Modal title="Add Diagnosis" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item label="Select Disease Category">
            <Select
              placeholder="Select disease category"
              onChange={handleDiseaseSelect}
            >
              {Object.keys(diseaseOptions).map((category) => (
                <Select.Option key={category} value={category}>
                  {category}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="mb-4 flex flex-wrap gap-2 justify-center">
            {selectedDiseases.map((category) => (
              <Tag
                key={category}
                closable
                onClose={() => handleDiseaseRemove(category)}
              >
                {category}
              </Tag>
            ))}
          </div>
          <Form.Item
            label="Diagnosis"
            name="diagnosis"
            rules={[{ required: true, message: "Please enter diagnosis." }]}
          >
            <TextArea rows={13} placeholder="Enter diagnosis..." />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Diagnosis"
        open={isUpdateModalOpen}
        onOk={handleUpdateOk}
        onCancel={handleUpdateCancel}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item label="Select Disease Category">
            <Select
              placeholder="Select disease category"
              onChange={handleDiseaseSelectUpdate}
            >
              {Object.keys(diseaseOptions).map((category) => (
                <Select.Option key={category} value={category}>
                  {category}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="mb-4 flex flex-wrap gap-2 justify-center">
            {selectedDiseases.map((category) => (
              <Tag
                key={category}
                closable
                onClose={() => handleDiseaseRemoveUpdate(category)}
              >
                {category}
              </Tag>
            ))}
          </div>
          <Form.Item
            label="Diagnosis"
            name="diagnosis"
            rules={[{ required: true, message: "Please enter diagnosis." }]}
          >
            <TextArea rows={13} placeholder="Enter diagnosis..." />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ViewDiagnosis;