export const SYSTEMIC_SCLEROSIS = [
    /// Duration of illness field
    {
        field: "ssDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Initial Symptom Field
    {
        field: "ssInitialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "skin thickening",
            "Raynaud’s phenomenon {value} phase",
            "sclerodactyly",
            "Digital tip ulcer",
            "digital gangrene",
            "puffiness of hands",
            "stiffness of hands",
            "shortness of breath",
            "palpitations",
            "GERD",
            "Small bowel bacterial overgrowth syndrome",
            "calcinosis"
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: "Raynaud’s phenomenon {value} phase",
                placeholders: [
                    {
                        key: "value",
                        type: "multiselect", // or "text" for free input
                        label: "Raynaud's phenomenon phase",
                        options: ["Single", "Double", "Triple"]
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
            // {
            //     // For example, an extra option for digital gangrene:
            //     trigger: "digital gangrene",
            //     placeholders: [
            //         {
            //             key: "extra",
            //             type: "text",
            //             label: "Digital gangrene extra info"
            //         }
            //     ],
            //     // In this case, a template is provided.
            //     template: "digital gangrene ({extra})"
            // }
        ]
    },
    /// Subsequent Organ Involvement Field
    {
        field: "ssSubsequentOrganInvolvement",
        label: "Subsequent Organ Involvement",
        type: "multiselect",
        options: [
            "lungs- ILD (UIP)",
            "lungs- ILD (NSIP)",
            "Pulmonary artery hypertension - PAP {value} mmof Hg",
            "Contracture at {value}",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: "Pulmonary artery hypertension - PAP {value} mmof Hg",
                placeholders: [
                    {
                        key: "value",
                        type: "text",
                        label: "Value of MMofHg"
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: "Contracture at {value}",
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Value of MMofHg",
                        options: [
                            "DIP",
                            "PIP",
                            "Elbow",
                            "DIP & PIP",
                            "DIP & Elbow",
                            "PIP & Elbow",
                            "DIP, PIP & Elbow",
                        ]
                    }
                ]
            },
        ]
    },
    /// Amputation of Field
    {
        field: "ssAmputationOf",
        label: "Amputation of",
        type: "multiselect",
        options: [
            "{right} {rightValue} {checkBox} {left} {leftValue} distal phalanges",
            "{ Write Something Here }",

        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },

            {
                // When the user selects this trigger value…
                trigger: "{right} {rightValue} {checkBox} {left} {leftValue} distal phalanges",
                placeholders: [
                    {
                        key: "right",
                        type: "checkbox",
                        label: "Right",
                        options: [
                            "Right-",
                        ]
                    },
                    {
                        key: "rightValue",
                        type: "multiselect",
                        label: "2nd, 3rd, 4th, 5th",
                        options: [
                            "2nd",
                            "3rd",
                            "4th",
                            "5th",
                        ]
                    },
                    {
                        key: "checkBox",
                        type: "checkbox",
                        label: "CheckBox",
                        options: ["&"],
                    },
                    {
                        key: "left",
                        type: "checkbox",
                        label: "Left",
                        options: [
                            "Left-",
                        ]
                    },
                    {
                        key: "leftValue",
                        type: "multiselect",
                        label: "2nd, 3rd, 4th, 5th",
                        options: [
                            "2nd",
                            "3rd",
                            "4th",
                            "5th",
                        ]
                    },
                ]
            },
        ]
    },
    /// Digital tip scar Field
    {
        field: "ssDigitalTipScar",
        label: "Digital tip scar",
        type: "multiselect",
        options: [
            "{right} {rightValue} {checkBox} {left} {leftValue} finger tips",
            "{ Write Something Here }",

        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },

            {
                // When the user selects this trigger value…
                trigger: "{right} {rightValue} {checkBox} {left} {leftValue} finger tips",
                placeholders: [
                    {
                        key: "right",
                        type: "checkbox",
                        label: "Right",
                        options: [
                            "Right-",
                        ]
                    },
                    {
                        key: "rightValue",
                        type: "multiselect",
                        label: "2nd, 3rd, 4th, 5th",
                        options: [
                            "2nd",
                            "3rd",
                            "4th",
                            "5th",
                        ]
                    },
                    {
                        key: "checkBox",
                        type: "checkbox",
                        label: "CheckBox",
                        options: ["&"],
                    },
                    {
                        key: "left",
                        type: "checkbox",
                        label: "Left",
                        options: [
                            "Left-",
                        ]
                    },
                    {
                        key: "leftValue",
                        type: "multiselect",
                        label: "2nd, 3rd, 4th, 5th",
                        options: [
                            "2nd",
                            "3rd",
                            "4th",
                            "5th",
                        ]
                    },
                ]
            },
        ]
    },
    /// Digital tip ulcer Field
    {
        field: "ssDigitalTipUlcer",
        label: "Digital tip ulcer",
        type: "multiselect",
        options: [
            "{right} {rightValue} {checkBox} {left} {leftValue} finger tips",
            "{ Write Something Here }",

        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },

            {
                // When the user selects this trigger value…
                trigger: "{right} {rightValue} {checkBox} {left} {leftValue} finger tips",
                placeholders: [
                    {
                        key: "right",
                        type: "checkbox",
                        label: "Right",
                        options: [
                            "Right-",
                        ]
                    },
                    {
                        key: "rightValue",
                        type: "multiselect",
                        label: "2nd, 3rd, 4th, 5th",
                        options: [
                            "2nd",
                            "3rd",
                            "4th",
                            "5th",
                        ]
                    },
                    {
                        key: "checkBox",
                        type: "checkbox",
                        label: "CheckBox",
                        options: ["&"],
                    },
                    {
                        key: "left",
                        type: "checkbox",
                        label: "Left",
                        options: [
                            "Left-",
                        ]
                    },
                    {
                        key: "leftValue",
                        type: "multiselect",
                        label: "2nd, 3rd, 4th, 5th",
                        options: [
                            "2nd",
                            "3rd",
                            "4th",
                            "5th",
                        ]
                    },
                ]
            },
        ]
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "ssAnaByIfaIntensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "ssAnaByIfaTiter",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "{ Write Something Here }",
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "ssAnaByIfaPattern",
        label: "ANA by-IFA : Pattern",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
            "AC 1", "AC 2", "AC 3", "AC 4", "AC 5", "AC 6", "AC 7", "AC 8", "AC 9", "AC 10",
            "AC 11", "AC 12", "AC 13", "AC 14", "AC 15", "AC 16", "AC 17", "AC 18", "AC 19", "AC 20",
            "AC 21", "AC 22", "AC 23", "AC 24", "AC 25", "AC 26", "AC 27", "AC 28", "AC 29", "AC 30",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Antibody Profile Field
    {
        field: "ssAntibodyProfile",
        label: "Antibody Profile",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Anti-dsDNA : {+1/+2/+3}",
            "Anti-Sm : {+1/+2/+3}",
            "Anti-U1RNP : {+1/+2/+3}",
            "Anti-nucleosome : {+1/+2/+3}",
            "Anti-histones : {+1/+2/+3}",
            "Anti-Sjögren syndrome-A/Ro(anti-SSA/Ro) : {+1/+2/+3}",
            "Anti-RNP : {+1/+2/+3}",
            "Anti-Ku and Ki antibodies : {+1/+2/+3}",
            "Anti-ribosomal P protein (RPP) antibody : {+1/+2/+3}",
            "Anti- polymyositis-systemic scleroderma antibody (PM-Scl) : {+1/+2/+3}",
            "Anti-Sjögren's syndrome antigen-B (anti-SS-B/LA) : {+1/+2/+3}",
            "Anti-systemic sclerosis-70 (Scl-70) antibody : {+1/+2/+3}",
            "Anti-histidyl-tRNA synthetase antibody(Jo-1) : {+1/+2/+3}",

        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },

            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-dsDNA : {+1/+2/+3}",
                    "Anti-Sm : {+1/+2/+3}",
                    "Anti-U1RNP : {+1/+2/+3}",
                    "Anti-nucleosome : {+1/+2/+3}",
                    "Anti-histones : {+1/+2/+3}",
                    "Anti-Sjögren syndrome-A/Ro(anti-SSA/Ro) : {+1/+2/+3}",
                    "Anti-RNP : {+1/+2/+3}",
                    "Anti-Ku and Ki antibodies : {+1/+2/+3}",
                    "Anti-ribosomal P protein (RPP) antibody : {+1/+2/+3}",
                    "Anti- polymyositis-systemic scleroderma antibody (PM-Scl) : {+1/+2/+3}",
                    "Anti-Sjögren's syndrome antigen-B (anti-SS-B/LA) : {+1/+2/+3}",
                    "Anti-systemic sclerosis-70 (Scl-70) antibody : {+1/+2/+3}",
                    "Anti-histidyl-tRNA synthetase antibody(Jo-1) : {+1/+2/+3}",
                ],
                placeholders: [

                    {
                        key: "+1/+2/+3",
                        type: "select",
                        label: "Strength",

                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ]
                    }
                ]
            },
        ]
    },
    /// Specific Autoantibodies Field
    {
        field: "ssSpecificAutoAntibodies",
        label: "Specific Autoantibodies",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Anti-centromere Antibodies (ACA)",
            "Anti-topoisomerase I (Anti-Scl-70)",
            "Anti-RNA Polymerase III Antibodies",
            "Anti-U1 RNP Antibodies",
            "Anti-Th/To Antibodies",
            "Anti-PM/Scl Antibodies",
            "Anti-fibrillarin (Anti-U3 RNP)",
            "Anti-Ku Antibodies",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]

    },
    /// Initially treated with Field
    {
        field: "ssInitiallyTreatedWith",
        label: "Initially treated with",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Pulse methylprednisolone {value1} mg for {duration} Days",
            "Cyclophosphamide {value1} mg for {duration} months",
            "Rituximab {value}",
            "Methotrexate {value} mg per week",
            "Mycophenolate {value} mg twice a day",
            "Azathioprine {value} mg once a day",
            "Prednisolone {value} mg per day",
            "Hydroxychloroquine {value} mg",
            "Tadalafil 10mg",
            "Nifedipine 20mg SR",
            "Nintadinib 150mg BD",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },

            {
                // When the user selects this trigger value…
                trigger: [
                    "Pulse methylprednisolone {value1} mg for {duration} Days",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "select",
                        label: "Dose",

                        options: [
                            "100",
                            "250",
                            "500",
                            "750",
                            "1000",
                        ]
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Days",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                        ]
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Cyclophosphamide {value1} mg for {duration} months",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "type",
                        label: "Dose",
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Months",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Rituximab {value}",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500 mg",
                            "1 g",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Methotrexate {value} mg per week",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "7.5",
                            "10",
                            "12.5",
                            "15",
                            "17.5",
                            "20",
                            "25",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Mycophenolate {value} mg twice a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500",
                            "1000",
                            "1500",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Azathioprine {value} mg once a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "25",
                            "50",
                            "75",
                            "100",
                            "125",
                            "150",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Prednisolone {value} mg per day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: Array.from({ length: 32 }, (_, i) => 2.5 * (i + 1)),
                    }
                ]
            },
            {
                trigger: [
                    "Hydroxychloroquine {value} mg",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "200",
                            "300",
                            "400",
                        ],
                    }
                ]
            },
        ]
    },
    /// Comorbidities Field
    {
        field: "ssComorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension",
            "Cardio Vascular Disease",
            "COPD",
            "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Conception Plan Field
    {
        field: "ssConceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "ssCompliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "ssVaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "ssSmoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "ssCurrentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Respiratory tract infection",
            "Urinary tract infection",
            "Incomplete control of symptoms related to Raynauds",
            "Incomplete control of symptoms related to ILD",
            "Incomplete control of symptoms related to GERD",
            "Incomplete control of symptoms  related to small bowels",
            "Joint pain",
            "Skin thickening",
            "Contracture",
            "Calcinosis",
            "PAH",
            "Sideeffect of drugs",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
]