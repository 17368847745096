import React, { useContext, useState } from "react";
import axios from "axios";
import { appointmentContext } from "../../pages/ViewCancelStatusAppointments";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { message } from "antd";

const CancelStatusActionAppointment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId, full_name, problem, status, openDynamicModal } = useContext(appointmentContext);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") || localStorage.getItem("staffticket")),
    },
  };

  const revertAppointmentStatus = () => {
    const appointment_status = status === "Cancel In Pending" ? "Pending" : status;
    if (window.confirm("Are you sure want to change this appointment status?")) {
      setActionLoading(true);
      const data = { appointmentStatus: appointment_status };
      axios
        .put(`${apiBaseUrl}/staff/updateAppointmentStatus/${appointmentId}`, data, config)
        .then((response) => {
          if (response.data.msg === "Updated Status") {
            message.success("Appointment status reverted", 0.6, () => window.location.reload());
          } else {
            message.error("Failed to update status");
          }
        })
        .catch((err) => {
          console.error("Error updating appointment status:", err);
          message.error("Failed to update appointment status! Please try again.");
        })
        .finally(() => setActionLoading(false));
    }
  };

  const updateAppointmentStatus = () => {
    const appointment_status = status === "Cancel In Pending" ? "Cancelled" : status;
    if (window.confirm("Are you sure want to change this appointment status?")) {
      setActionLoading(true);
      const data = { appointmentStatus: appointment_status };
      axios
        .put(`${apiBaseUrl}/staff/updateAppointmentStatus/${appointmentId}`, data, config)
        .then((response) => {
          if (response.data.msg === "Updated Status") {
            message.success("Appointment cancelled", 0.6, () => window.location.reload());
          } else {
            message.error("Failed to update status");
          }
        })
        .catch((err) => {
          console.error("Error updating appointment status:", err);
          message.error("Failed to update appointment status! Please try again.");
        })
        .finally(() => setActionLoading(false));
    }
  };

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="bg-white px-4 pt-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:text-left">
            <h1 className="text-3xl leading-6 font-medium text-center text-gray-900" id="modal-title">
              Actions
            </h1>
            <div>
              <p className="text-sm text-gray-500 mx-5">
                <div className="py-2 mx-5">
                  <ul className="my-4 space-y-3 mx-8">
                    <li>
                      <button
                        onClick={revertAppointmentStatus}
                        className="flex items-center px-12 py-4 text-center font-bold text-red-600 shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">Revert Appointment Status</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={updateAppointmentStatus}
                        className="flex items-center px-12 py-4 text-center font-bold text-red-600 shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">Cancel Appointment</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelStatusActionAppointment;