import React, { useContext, useState } from "react";
import axios from "axios";
import { appointmentContext } from "./ViewAppointments";
import LoadingScreen from "../lib/constants/loadingScreen";
import { message } from "antd";
import UpdateReferredAndFollowUpAppointmentDateTime from "./updateReferAndFollowupDateTime";
import HistoryPrintout from "../pages/printout/historyPrintout"; // Assuming this exists
import ConsultationPrintout from "../pages/printout/consultationPrintout"; // Assuming this exists

const ActionAppointmentStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    appointmentId,
    appointmentNumber,
    appointmentHistory,
    DOB,
    address,
    mobile,
    gender,
    appointmentDate,
    appointmentTime,
    department,
    full_name,
    problem,
    report,
    status,
    isDoctorAssignedAvailable,
    openDynamicModal,
    refresh, // Added from context
  } = useContext(appointmentContext);

  const [actionLoading, setActionLoading] = useState(false);

  // Dynamic auth config
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];
    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    return {
      headers: {
        Authorization: `Bearer ${availableTicket ? localStorage.getItem(availableTicket.key) : ""}`,
      },
    };
  };

  const config = getAuthConfig();

  const updateAppointmentStatus = () => {
    const appointment_status = "Cancelled";
    if (window.confirm("Are you sure want to change this appointment status?")) {
      setActionLoading(true);
      const data = { appointmentStatus: appointment_status };

      axios
        .put(`${apiBaseUrl}/staff/updateAppointmentStatus/${appointmentId}`, data, config)
        .then((response) => {
          if (response.data.msg === "Updated Status") {
            message.success("Appointment Cancelled", 0.6, () => refresh());
          } else {
            message.error("Failed To Update");
          }
        })
        .catch((err) => {
          console.error("Error updating appointment status:", err);
          message.error("Failed to update appointment status! Please try again.");
        })
        .finally(() => setActionLoading(false));
    }
  };

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="bg-white px-4 pt-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:text-left">
            <h1 className="text-3xl leading-6 font-medium text-center text-gray-900" id="modal-title">
              Actions
            </h1>
            <div>
              <p className="text-sm text-gray-500 mx-5">
                <div className="py-2 mx-5">
                  <ul className="my-4 space-y-3 mx-8">
                    {status === "Ongoing" ? (
                      <>
                        <li>
                          <button
                            onClick={() =>
                              openDynamicModal("Update Appointment", (
                                <UpdateReferredAndFollowUpAppointmentDateTime
                                  appointmentId={appointmentId}
                                  full_name={full_name}
                                  problem={problem}
                                />
                              ))
                            }
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">Update Appointment</span>
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() =>
                              openDynamicModal("Consultation Printout", (
                                <ConsultationPrintout appointmentId={appointmentId} />
                              ))
                            }
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">Consultation Printout</span>
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() =>
                              openDynamicModal("History Printout", (
                                <HistoryPrintout
                                  full_name={full_name}
                                  problem={problem}
                                  status={status}
                                  sendTo="Staff"
                                  appointmentId={appointmentId}
                                />
                              ))
                            }
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">History Printout</span>
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={updateAppointmentStatus}
                            className="flex items-center px-12 py-4 text-center font-bold text-red-600 shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">Cancel Appointment</span>
                          </button>
                        </li>
                        <div className="text-center">
                          <p>For other actions, please redirect to:</p>
                          <a href="/dashboard/view/inprogress/appointment" className="text-sky-500 underline">
                            InProgress section
                          </a>
                        </div>
                      </>
                    ) : status === "FollowUp" ? (
                      <>
                        {!isDoctorAssignedAvailable ? (
                          <>
                            <li>
                              <button
                                onClick={() =>
                                  openDynamicModal("Consultation Printout", (
                                    <ConsultationPrintout appointmentId={appointmentId} />
                                  ))
                                }
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span className="flex-1 whitespace-nowrap">Consultation Printout</span>
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() =>
                                  openDynamicModal("History Printout", (
                                    <HistoryPrintout
                                      full_name={full_name}
                                      problem={problem}
                                      status={status}
                                      sendTo="Staff"
                                      appointmentId={appointmentId}
                                    />
                                  ))
                                }
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span className="flex-1 whitespace-nowrap">History Printout</span>
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() =>
                                  openDynamicModal("Edit FollowUp DateTime", (
                                    <UpdateReferredAndFollowUpAppointmentDateTime
                                      appointmentId={appointmentId}
                                      full_name={full_name}
                                      problem={problem}
                                      status={status}
                                    />
                                  ))
                                }
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span className="flex-1 whitespace-nowrap">Edit FollowUp DateTime</span>
                              </button>
                            </li>
                            <div className="text-center">
                              <p>For other actions, please redirect to:</p>
                              <a href="/dashboard/view/inprogress/appointment" className="text-sky-500 underline">
                                InProgress section
                              </a>
                            </div>
                          </>
                        ) : (
                          <div className="text-center">
                            <p>Please assign a doctor first, Redirect to:</p>
                            <a href="/dashboard/refer" className="text-sky-500 underline">
                              Refer Section
                            </a>
                          </div>
                        )}
                      </>
                    ) : status === "Referred" ? (
                      <>
                        {!isDoctorAssignedAvailable ? (
                          <>
                            <li>
                              <button
                                onClick={() =>
                                  openDynamicModal("Consultation Printout", (
                                    <ConsultationPrintout appointmentId={appointmentId} />
                                  ))
                                }
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span className="flex-1 whitespace-nowrap">Consultation Printout</span>
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() =>
                                  openDynamicModal("History Printout", (
                                    <HistoryPrintout
                                      full_name={full_name}
                                      problem={problem}
                                      status={status}
                                      sendTo="Staff"
                                      appointmentId={appointmentId}
                                    />
                                  ))
                                }
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span className="flex-1 whitespace-nowrap">History Printout</span>
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() =>
                                  openDynamicModal("Edit Refer DateTime", (
                                    <UpdateReferredAndFollowUpAppointmentDateTime
                                      appointmentId={appointmentId}
                                      full_name={full_name}
                                      problem={problem}
                                      status={status}
                                    />
                                  ))
                                }
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span className="flex-1 whitespace-nowrap">Edit Refer DateTime</span>
                              </button>
                            </li>
                            <div className="text-center">
                              <p>For other actions, please redirect to:</p>
                              <a href="/dashboard/view/referred/appointment" className="text-sky-500 underline">
                                Referred section
                              </a>
                            </div>
                          </>
                        ) : (
                          <>
                            <li>
                              <button
                                onClick={() =>
                                  openDynamicModal("Consultation Printout", (
                                    <ConsultationPrintout appointmentId={appointmentId} />
                                  ))
                                }
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span className="flex-1 whitespace-nowrap">Consultation Printout</span>
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() =>
                                  openDynamicModal("History Printout", (
                                    <HistoryPrintout
                                      full_name={full_name}
                                      problem={problem}
                                      status={status}
                                      sendTo="Staff"
                                      appointmentId={appointmentId}
                                    />
                                  ))
                                }
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span className="flex-1 whitespace-nowrap">History Printout</span>
                              </button>
                            </li>
                            <div className="text-center">
                              <p>Please assign a doctor first, Redirect to:</p>
                              <a href="/dashboard/refer" className="text-sky-500 underline">
                                Refer Section
                              </a>
                            </div>
                          </>
                        )}
                      </>
                    ) : status === "Ended" ? (
                      <>
                        <li>
                          <button
                            onClick={() =>
                              openDynamicModal("Consultation Printout", (
                                <ConsultationPrintout appointmentId={appointmentId} />
                              ))
                            }
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">Consultation Printout</span>
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() =>
                              openDynamicModal("History Printout", (
                                <HistoryPrintout
                                  full_name={full_name}
                                  problem={problem}
                                  status={status}
                                  sendTo="Staff"
                                  appointmentId={appointmentId}
                                />
                              ))
                            }
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">History Printout</span>
                          </button>
                        </li>
                        <div className="text-center">
                          <p>For ended actions, please redirect to:</p>
                          <a href="/dashboard/view/completed/appointment" className="text-sky-500 underline">
                            Completed section
                          </a>
                        </div>
                      </>
                    ) : status === "Pending" ? (
                      <div className="text-center">
                        <p>For pending actions, please redirect to:</p>
                        <a href="/dashboard/pending/user/appointments" className="text-sky-500 underline">
                          Appt.. Request section
                        </a>
                      </div>
                    ) : (
                      <div className="text-center">
                        <p>For cancelled actions, please redirect to:</p>
                        <a href="/dashboard/view/cancelstatus/appointment" className="text-sky-500 underline">
                          Cancel Status section
                        </a>
                      </div>
                    )}
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionAppointmentStaff;