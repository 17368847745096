import React, { useState, useEffect } from "react";
import { getOrderStatus } from "../../lib/helpers/index";
import axios from "axios";
import moment from "moment-timezone";
import { Table, message, Spin } from "antd";

export default function BottomComponent() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const isAdmin = localStorage.getItem("adminticket");
  const isDoctor = localStorage.getItem("doctorticket");
  const isStaff = localStorage.getItem("staffticket");
  const isLabReporter = localStorage.getItem("labreporterticket");
  const isPharma = localStorage.getItem("pharmaticket");

  const labReporterConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
    },
  };

  const doctorConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    },
  };

  useEffect(() => {
    if (isAdmin || isStaff) {
      fetchAppointment();
    } else if (isDoctor) {
      fetchDoctorAppointment();
    } else if (isLabReporter) {
      fetchReports();
    } else if (isPharma) {
      fetchBills();
    }
  }, []);

  // Function to get the appropriate authorization header
  const getAuthConfig = () => {
    // List of possible tickets in order of priority (you can adjust the order)
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
      // Add other ticket types as needed
    ];

    // Find the first available ticket
    const availableTicket = ticketTypes.find(ticket => localStorage.getItem(ticket.key));

    // If no ticket is found, you can either return a default config or throw an error
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} }; // Return config without Authorization header
      // Or throw an error: throw new Error("No authentication ticket available");
    }

    // Return config with the found ticket
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`
      }
    };
  };

  // Usage example
  const config = getAuthConfig();

  const fetchAppointment = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/appointment`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDoctorAppointment = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/doctor/appointments`, doctorConfig)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchReports = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/view/labreports`, labReporterConfig)
      .then((response) => {
        setDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching lab reports:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBills = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/bills`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching lab reports:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredData = details.slice(0, 5);

  const appointmentColumns = [
    {
      title: "Appointment No.",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Department",
      key: "department",
      render: (_, record) => {
        const { department, refer } = record;
        if (refer) {
          return refer.toDepartment.department;
        } else {
          return department.department;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{getOrderStatus(status)}</span>,
    },
    {
      title: "Date",
      key: "date",
      render: (_, record) => {
        const {
          date,
          refer,
          followUp_appointmentDate,
          referred_appointmentDate,
          status,
        } = record;

        if (status === "FollowUp") {
          if (followUp_appointmentDate) {
            return followUp_appointmentDate;
          } else {
            return date;
          }
        } else if (status === "Referred") {
          if (referred_appointmentDate && refer.toDoctor !== null) {
            return referred_appointmentDate;
          } else {
            return <span className="text-red-500">Not assigned</span>;
          }
        } else if (
          status === "Ongoing" ||
          status === "Cancelled" ||
          status === "Cancel In Pending"
        ) {
          return date;
        } else {
          return date;
        }
      },
    },
  ];

  const reportColumns = [
    {
      title: "Appointment No.",
      dataIndex: ["appointmentId", "appointmentNumber"],
      key: "appointmentNumber",
      width: 195,
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
      width: 175,
    },
    {
      title: "Patient Name",
      dataIndex: ["appointmentId", "fullname"],
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: ["appointmentId", "mobile"],
      key: "phone",
    },
    {
      title: "Report Name",
      dataIndex: "reportName",
      key: "reportName",
    },
    {
      title: "Date added",
      dataIndex: "createdAt",
      key: "date",
      render: (createdAt) => createdAt.split("T")[0],
    },
  ];

  const convertToNepaliTime = (utcTime) => {
    return moment
      .utc(utcTime)
      .tz("Asia/Kathmandu")
      .format("DD-MMMM-YYYY h:mm A");
  };

  const billsColumns = [
    {
      title: "Bill No",
      dataIndex: "billNo",
      key: "billNo",
    },
    {
      title: "Patient Name",
      dataIndex: ["patient", "fullname"],
      key: "patientName",
    },
    {
      title: "Mobile",
      dataIndex: ["patient", "mobile"],
      key: "mobile",
    },
    {
      title: "Address",
      dataIndex: ["patient", "address"],
      key: "address",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Bill Date",
      dataIndex: "billDate",
      key: "billDate",
      render: (billDate) => {
        const nepaliTime = convertToNepaliTime(billDate);
        return <span>{nepaliTime}</span>;
      },
    },
  ];

  return (
    <div className="bg-white px-4 pt-3 pb-4 rounded-md border border-gray-200 flex-1">
      {(isAdmin || isDoctor || isStaff) && (
        <>
          <strong className="text-gray-700 font-medium">
            Recent Appointments
          </strong>
          <div className="rounded-md mt-2 ">
            <Table
              dataSource={filteredData}
              columns={appointmentColumns}
              bordered={false}
              rowKey="_id"
              pagination={false}
              loading={{
                indicator: <Spin size="large" />,
                spinning: loading,
              }}
            />
          </div>
        </>
      )}

      {isLabReporter && (
        <>
          <strong className="text-gray-700 font-medium">
            Recently Added Reports
          </strong>
          <div className="rounded-md mt-2 ">
            <Table
              dataSource={details}
              columns={reportColumns}
              bordered={false}
              rowKey="_id"
              pagination={false}
              loading={{
                indicator: <Spin size="large" />,
                spinning: loading,
              }}
            />
          </div>
        </>
      )}

      {isPharma && (
        <>
          <strong className="text-gray-700 font-medium">Recent Bills</strong>
          <div className="rounded-md mt-2 ">
            <Table
              dataSource={details}
              columns={billsColumns}
              bordered={false}
              rowKey="_id"
              pagination={false}
              loading={{
                indicator: <Spin size="large" />,
                spinning: loading,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
