export const VASCULITIS = [
    /// Initial Symptom
    {
        field: "vasInitialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Arthritis symmetrical",
            "Asymmetrical polyarthritis",
            "Oligoarthritis involvinig small and large joints",
            "Upper respiratory",
            "Lower respiratory",
            "Chondritis",
            "Renal",
            "Gastro intestinal",
            "Recurrent oral",
            "Genital",
            "Cutaneous ulcer",
            "Claudication of upper",
            "Lower extremities",
            "Constitutional",
            "Proximal muscle weakness",
            "Cutaneous involvement",
            "Digital gangrene",
            "Raynaud’s",
            "Stroke",
            "Caratodynia",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Total Duration of Illness
    {
        field: "vasDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Antibodies
    {
        field: "vasAntibodies",
        label: "Antibodies",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "c-ANCA/PR3 Positive (High-Titer)",
            "c-ANCA/PR3 Positive (Low-Titer)",
            "c-ANCA/PR3 Negative",
            "p-ANCA/MPO Positive (High-Titer)",
            "p-ANCA/MPO Positive (Low-Titer)",
            "p-ANCA/MPO Negative",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Done By
    {
        field: "vasDoneBy",
        label: "Done By",
        type: "select",
        options: [
            "Immunofluorescence",
            "ELISA",
            "{ Write Something Here }",
        ], extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Initially treated with Field
    {
        field: "vasInitiallyTreatedWith",
        label: "Initially treated with",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Pulse methylprednisolone {value1} mg for {duration} Days",
            "Cyclophosphamide {value1} mg for {duration} months",
            "Rituximab {value}",
            "Methotrexate {value} mg per week",
            "Mycophenolate {value} mg twice a day",
            "Azathioprine {value} mg once a day",
            "Prednisolone {value} mg per day",
            "Hydroxychloroquine {value} mg",
            "Tadalafil 10mg",
            "Nifedipine 20mg SR",
            "Nintadinib 150mg BD",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Pulse methylprednisolone {value1} mg for {duration} Days",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "select",
                        label: "Dose",

                        options: [
                            "100",
                            "250",
                            "500",
                            "750",
                            "1000",
                        ]
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Days",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                        ]
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Cyclophosphamide {value1} mg for {duration} months",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "type",
                        label: "Dose",
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Months",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Rituximab {value}",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500 mg",
                            "1 g",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Methotrexate {value} mg per week",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "7.5",
                            "10",
                            "12.5",
                            "15",
                            "17.5",
                            "20",
                            "25",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Mycophenolate {value} mg twice a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500",
                            "1000",
                            "1500",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Azathioprine {value} mg once a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "25",
                            "50",
                            "75",
                            "100",
                            "125",
                            "150",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Prednisolone {value} mg per day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: Array.from({ length: 32 }, (_, i) => 2.5 * (i + 1)),
                    }
                ]
            },
            {
                trigger: [
                    "Hydroxychloroquine {value} mg",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "200",
                            "300",
                            "400",
                        ],
                    }
                ]
            },
        ]
    },
    /// Symptoms
    {
        field: "vasSymptoms",
        label: "Symptoms",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Improved",
            "Did not improve",
            "Developed side effects",
            "Infection with initial treatment",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "vasAnaByIfaIntensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "vasAnaByIfaTiter",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "{ Write Something Here }",
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "vasAnaByIfaPattern",
        label: "ANA by-IFA : Pattern",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
            "AC 1", "AC 2", "AC 3", "AC 4", "AC 5", "AC 6", "AC 7", "AC 8", "AC 9", "AC 10",
            "AC 11", "AC 12", "AC 13", "AC 14", "AC 15", "AC 16", "AC 17", "AC 18", "AC 19", "AC 20",
            "AC 21", "AC 22", "AC 23", "AC 24", "AC 25", "AC 26", "AC 27", "AC 28", "AC 29", "AC 30",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Comorbidities Field
    {
        field: "vasComorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Hypertension",
            "Cardio Vascular Disease",
            "COPD",
            "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Conception Plan Field
    {
        field: "vasConceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "vasCompliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "vasVaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "vasSmoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "vasCurrentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Symptoms suggestive of persistant disease activity",
            "Symptoms suggestive of flare of disease",
            "Symptoms suggestive of infection",
            "Symptoms suggestive of sideeffect of drugs",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [
                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    }
]