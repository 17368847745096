export const UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE = [
    /// Total Duration of Illness
    {
        field: "uctdDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Initial Symptom
    {
        field: "uctdInitialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Arthritis",
            "Arthralgia",
            "Proximal muscle weakness",
            "Photosensitivity",
            "Oral ulcer",
            "Grittiness of eyes",
            "Raynaud's",
            "Shortness of breath",
            "Pleurisy",
            "Alopacia",
            "Weight loss",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "uctdAnaByIfaIntensity",
        label: "Pertinent Antibodies In UCTD ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "uctdAnaByIfaTiter",
        label: "Pertinent Antibodies In UCTD ANA by-IFA : Titer",
        type: "select",
        options: [
            "{ Write Something Here }",
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "uctdAnaByIfaPattern",
        label: "Pertinent Antibodies In UCTD ANA by-IFA : Pattern",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
            "AC 1", "AC 2", "AC 3", "AC 4", "AC 5", "AC 6", "AC 7", "AC 8", "AC 9", "AC 10",
            "AC 11", "AC 12", "AC 13", "AC 14", "AC 15", "AC 16", "AC 17", "AC 18", "AC 19", "AC 20",
            "AC 21", "AC 22", "AC 23", "AC 24", "AC 25", "AC 26", "AC 27", "AC 28", "AC 29", "AC 30",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Pertinent Antibodies in UCTD (Extractable Nuclear Antigens (ENAs))
    {
        field: "uctdEnas",
        label: "Pertinent Antibodies in UCTD (Extractable Nuclear Antigens (ENAs))",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Anti-Ro/SSA and Anti-La/SSB {+1/+2/+3}",
            "Anti-U1 RNP {+1/+2/+3}",
            "Anti-Sm {+1/+2/+3}",
            "Anti-Jo-1 {+1/+2/+3}",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-Ro/SSA and Anti-La/SSB {+1/+2/+3}",
                    "Anti-U1 RNP {+1/+2/+3}",
                    "Anti-Sm {+1/+2/+3}",
                    "Anti-Jo-1 {+1/+2/+3}",
                ],
                placeholders: [
                    {
                        key: "+1/+2/+3",
                        type: "select", // or "text" for free input
                        label: "Strength",
                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ],
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
        ]
    },
    /// Pertinent Antibodies in UCTD (Antiphospholipid Antibodies )
    {
        field: "uctdAa",
        label: "Pertinent Antibodies in UCTD (Antiphospholipid Antibodies)",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Anti-cardiolipin (Low-titer)",
            "Anti-cardiolipin (High-titer)",
            "Beta-2 glycoprotein I (Low-titer)",
            "Beta-2 glycoprotein I (High-titer)",
            "Lupus anticoagulant (Low-titer)",
            "Lupus anticoagulant (High-titer)",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Pertinent Antibodies in UCTD Rheumatoid Factor (RF)
    {
        field: "uctdRf",
        label: "Pertinent Antibodies in UCTD (Rheumatoid Factor (RF))",
        type: "select",
        options: [
            "Low-Titer",
            "High-Titer",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Pertinent Antibodies In UCTD Anti-Cyclic Citrullinated Peptide (Anti-CCP)
    {
        field: "uctdAntiCcp",
        label: "Pertinent Antibodies In UCTD (Anti-Cyclic Citrullinated Peptide (Anti-CCP))",
        type: "select",
        options: [
            "Low-Titer",
            "High-Titer",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Pertinent Antibodies in UCTD (Myositis-Specific and Myositis-Associated Antibodies)
    {
        field: "uctdMsAndMaa",
        label: "Pertinent Antibodies in UCTD (Myositis-Specific and Myositis-Associated Antibodies)",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Anti-Jo-1 (Anti-histidyl-tRNA synthetase) {+1/+2/+3}",
            "Anti-Mi-2 {+1/+2/+3}",
            "Anti-TIF1-y (Anti-p155/140) {+1/+2/+3}",
            "Anti-NXP-2 {+1/+2/+3}",
            "Anti-MDA5 (Anti-CADM-140) {+1/+2/+3}",
            "Anti-SRP (Signal Recognition Particle) {+1/+2/+3}",
            "Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase) {+1/+2/+3}",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-Jo-1 (Anti-histidyl-tRNA synthetase) {+1/+2/+3}",
                    "Anti-Mi-2 {+1/+2/+3}",
                    "Anti-TIF1-y (Anti-p155/140) {+1/+2/+3}",
                    "Anti-NXP-2 {+1/+2/+3}",
                    "Anti-MDA5 (Anti-CADM-140) {+1/+2/+3}",
                    "Anti-SRP (Signal Recognition Particle) {+1/+2/+3}",
                    "Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase) {+1/+2/+3}",
                ],
                placeholders: [
                    {
                        key: "+1/+2/+3",
                        type: "select", // or "text" for free input
                        label: "Strength",
                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ],
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
        ]
    },
    /// Comorbidities Field
    {
        field: "uctdComorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Hypertension",
            "Cardio Vascular Disease",
            "COPD",
            "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Conception Plan Field
    {
        field: "uctdConceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "uctdCompliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "uctdVaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "uctdSmoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "uctdCurrentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Symptoms suggestive of adverse effect of drugs",
            "Symptoms suggestive of increased disease activity",
            "Symptoms suggestive of infection of respiratory tract",
            "Symptoms suggestive of soft tissue involvement",
            "Symptoms suggestive of gastrointestinal tract involvement",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    }
]