export const MIXED_CONNECTIVE_TISSUE = [
    /// Total Duration of Illness
    {
        field: "mctDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Initial Symptom
    {
        field: "mctInitialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "History Suggestive of Arthritis",
            "History Suggestive of Arthralgia",
            "History Suggestive of Raynaud’s phenomenon {value} phase",
            "History Suggestive of Muscle weakness",
            "History Suggestive of Skin thickening",
            "History Suggestive of Shortness of breath",
            "History Suggestive of Chest pain",
            "History Suggestive of Photosensitivity",
            "History Suggestive of Constitutional symptoms",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: "Raynaud’s phenomenon {value} phase",
                placeholders: [
                    {
                        key: "value",
                        type: "multiselect", // or "text" for free input
                        label: "Raynaud's phenomenon phase",
                        options: ["Single", "Double", "Triple"]
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
        ]
    },
    /// Pertinent Antibodies Anti-U1 RNP Antibody
    {
        field: "mctPertinentAntibodiesAntiU1RnpAntibody",
        label: "Pertinent Antibodies Anti-U1 RNP Antibody",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Intensity Field
    {
        field: "mctPertinentAntibodiesAnaByIfaIntensity",
        label: "Pertinent Antibodies ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "mctPertinentAntibodiesAnaByIfaTiter",
        label: "Pertinent Antibodies ANA by-IFA : Titer",
        type: "select",
        options: [
            "{ Write Something Here }",
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "mctPertinentAntibodiesAnaByIfaPattern",
        label: "Pertinent Antibodies ANA by-IFA : Pattern",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
            "AC 1", "AC 2", "AC 3", "AC 4", "AC 5", "AC 6", "AC 7", "AC 8", "AC 9", "AC 10",
            "AC 11", "AC 12", "AC 13", "AC 14", "AC 15", "AC 16", "AC 17", "AC 18", "AC 19", "AC 20",
            "AC 21", "AC 22", "AC 23", "AC 24", "AC 25", "AC 26", "AC 27", "AC 28", "AC 29", "AC 30",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Pertinent Antibodies Rheumatoid Factor (RF)
    {
        field: "mctPertinentAntibodiesRf",
        label: "Pertinent Antibodies (Rheumatoid Factor (RF))",
        type: "select",
        options: [
            "Low-Titer",
            "High-Titer",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Pertinent Antibodies Anti-Cyclic Citrullinated Peptide (Anti-CCP)
    {
        field: "mctPertinentAntibodiesAntiCcp",
        label: "Pertinent Antibodies (Anti-Cyclic Citrullinated Peptide (Anti-CCP))",
        type: "select",
        options: [
            "Low-Titer",
            "High-Titer",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Screening for PAH
    {
        field: "mctScreeningForPah",
        label: "Screening For PAH",
        type: "select",
        options: [
            "Done",
            "Not Done",
            "Refused",
            "PAP {value} mmof Hg",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: "PAP {value} mmof Hg",
                placeholders: [
                    {
                        key: "value",
                        type: "type", // or "text" for free input
                        label: "PAP mmof Hg",
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
        ]
    },
    /// Comorbidities Field
    {
        field: "mctComorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension",
            "Cardio Vascular Disease",
            "COPD",
            "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger 
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Conception Plan Field
    {
        field: "mctConceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "mctCompliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "mctVaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "mctSmoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "mctCurrentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Symptoms not controlled on medication",
            "Developed new symptoms suggestive of shortness of breath",
            "Developed new symptoms suggestive of raynauds",
            "Developed new symptoms suggestive of contitutional",
            "Developed new symptoms suggestive of arthritis",
            "Symptoms suggestive of Infection",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    }
];