import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import LoadingScreen from "../lib/constants/loadingScreen";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";
import { message, Row, Col, Typography } from "antd";

const { Title } = Typography;

const UpdateAppointmentDateTime = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { dateTimeId } = useParams();
  const [dateTimeDetails, setDateTimeDetails] = useState([]);
  const [timeList, setTimeList] = useState([""]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [defaultDepartment, setDefaultDepartment] = useState({});
  const [defaultDoctor, setDefaultDoctor] = useState({});
  const [selectedOnlyDate, setOnlySelectedDate] = useState(null);
  const [doctorAndDateDetails, setDateAndDoctorDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentStoringValue, setDepartmentStoringValue] = useState(null);
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [doctorStoringValue, setDoctorStoringValue] = useState(null);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${apiBaseUrl}/view_appointment/single/datetime/${dateTimeId}`,
        config
      )
      .then((response) => {
        setDateTimeDetails(response.data.data);
        setSelectedDate(response.data.data.date);
        setOnlySelectedDate(response.data.data.date);
        setDefaultDoctor({
          value: response.data.data.doctorId._id,
          label: response.data.data.doctorId.fullname,
        });
        setDefaultDepartment({
          value: response.data.data.doctorId.department._id,
          label: response.data.data.doctorId.department.department,
        });
        setDoctorSelectedId(response.data.data.doctorId._id);
        console.log(response.data.data.doctorId._id);

        setTimeList(response.data.data.time);
      })
      .catch((e) => {
        console.error("Failed to get appointment date & time details:", e);
        message.error(
          "Failed to get appointment date & time details! Please try again."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`, config)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentStoringValue(selectedDepartment);
      setDepartmentSelectedId(selectedDepartment.value);
      setDoctorStoringValue("");
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`, config)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
    }
  };

  const addTime = () => {
    setTimeList([...timeList, ""]);
  };

  const removeTime = (index) => {
    const updatedList = [...timeList];
    updatedList.splice(index, 1);
    setTimeList(updatedList);
  };

  const handleInputChange = (index, time) => {
    const updatedList = [...timeList];
    updatedList[index] = time;
    setTimeList(updatedList);
  };

  const handleTimeChange = (index, selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    handleInputChange(index, formattedTime);
  };

  const handleDateChange = (date) => {
    const formattedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    setSelectedDate(formattedDate);
  };

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/view_appointment/datetime`, config)
      .then((response) => {
        setDateAndDoctorDetails(response.data);
      })
      .catch((e) => {
        console.error("Failed to get appointment date & time details:", e);
        message.error(
          "Failed to get appointment date & time details! Please try again."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isDoctorDateDisabled = (date) => {
    const formattedDate = formatDate(date);
    const hasAppointment = doctorAndDateDetails.some((item) => {
      return (
        item.doctorId._id === doctorSelectedId && item.date === formattedDate
      );
    });

    if (selectedOnlyDate && formattedDate === selectedOnlyDate) {
      return true;
    }

    return !hasAppointment;
  };

  const updateAppointmentDateTime = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      doctorId: doctorSelectedId,
      date: selectedDate,
      time: timeList,
    };

    axios
      .put(
        `${apiBaseUrl}/staff/appointment/update/dateAndtime/${dateTimeId}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.msg === "Appointment updated successfully") {
          message.success(
            "Appointment updated successfully",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/view_appointment/datetime");
            }
          );
        } else if (response.data.msg === "Datetime not found") {
          message.error("Datetime not found");
        } else if (
          response.data.msg ===
          "Appointment already exists for this date and doctor"
        ) {
          message.error("Appointment already exists for this date and doctor");
        } else {
          message.error("Please select valid doctor");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        const errorMessage = error.response
          ? error.response.data.msg
          : "An error occurred";
        message.error(errorMessage);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full bg-white max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={updateAppointmentDateTime}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Update Appointment DateTime</Title>
          </div>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={
                  departmentStoringValue != null
                    ? departmentStoringValue
                    : defaultDepartment
                }
                onChange={handleDepartmentInputChange}
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base">Doctor</label>
              <Select
                options={doctorSelectedDetails}
                value={
                  doctorStoringValue != null
                    ? doctorStoringValue
                    : defaultDoctor
                }
                onChange={handleDoctorInputChange}
                placeholder="Select doctor"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <label className="text-base -mb-4">Date</label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select a date"
            className="border-solid border-gray-300 border py-2 px-3 rounded-md text-gray-700 appearance-none w-full"
            popperClassName="mt-2 rounded-lg shadow-lg"
            calendarClassName="pb-2 bg-white rounded-lg shadow-lg border border-gray-300"
            minDate={currentDate}
            filterDate={isDoctorDateDisabled}
            readOnlyonKeyDown={(e) => e.preventDefault()} // Prevent typing in the input field
            onFocus={(e) => e.target.blur()} // Blur the input field on focus to prevent keyboard input
          />

          {timeList.map((item, index) => (
            <div className="flex items-center" key={index}>
              <div className="w-full mr-4">
                <label
                  className="text-base"
                  htmlFor={`appointment-time-${index}`}
                >
                  Time
                </label>
                <Flatpickr
                  data-enable-time
                  data-disable-date
                  value={item}
                  placeholder="Pick a time"
                  onChange={(selectedDates, dateStr, flatpickrInstance) =>
                    handleTimeChange(
                      index,
                      selectedDates,
                      dateStr,
                      flatpickrInstance
                    )
                  }
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-200"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "h:i K",
                    time_24hr: false,
                  }}
                  required
                />
              </div>
              <div className="flex items-center">
                <IconButton
                  onClick={() => removeTime(index)}
                  color="danger"
                  aria-label="minus button"
                  className="text-red-500 hover:text-red-700 transition-colors duration-200"
                >
                  <AiFillMinusCircle />
                </IconButton>
              </div>
            </div>
          ))}
          <IconButton onClick={addTime} color="primary" aria-label="add button">
            Add time field
            <HiPlusCircle />
          </IconButton>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit" //htmlType
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default UpdateAppointmentDateTime;
