import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Table, Input, Spin, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import { FaPrint } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import ReportViewModal from "./report/report_modal";
import UpdateReport from "./report/update_report"; // Import UpdateReport for editing
import DynamicModal from "./dynamicModel"; // Adjust the path if necessary
import SelfReportPrintout from "./printout/selfReportPrintout"; // Import SelfReportPrintout for printing
import AddSelfReport from "./report/add_self_report"; // Import AddSelfReport component (adjust path as needed)

const ViewLabSelfReports = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [details, setDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredDetails, setFilteredDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);

    // States for dynamic modal
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
    const [dynamicModalTitle, setDynamicModalTitle] = useState("");
    const [dynamicModalContent, setDynamicModalContent] = useState(null);

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
        },
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        axios
            .get(`${apiBaseUrl}/view/labselfreports`, config)
            .then((response) => {
                console.log("reports", response.data);
                setDetails(response.data);
                setFilteredDetails(response.data);
            })
            .catch((error) => {
                console.error("Error fetching lab reports:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleReportDelete = async (report_id) => {
        try {
            setActionLoading(true);
            if (window.confirm("Are you sure want to delete this report?")) {
                const response = await axios.delete(
                    `${apiBaseUrl}/report/delete/${report_id}`,
                    config
                );
                if (response.data.success) {
                    message.success(response.data.message);
                    fetchData();
                    setIsModalOpen(false); // Close ReportViewModal if open
                    setIsDynamicModalOpen(false); // Close dynamic modal if open
                    setDetails((prevDetails) =>
                        prevDetails.filter((item) => item._id !== report_id)
                    ); // Immediately update local state
                } else {
                    message.error(response?.error?.message || "Failed to delete the report.");
                }
            }
        } catch (e) {
            console.error("Failed to delete report:", e);
            message.error("Failed to delete! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    const filterReports = useCallback(
        debounce((searchTerm) => {
            if (searchTerm.trim() !== "") {
                const filtered = details.filter(
                    (report) =>
                        report?.patientDetails?.fullname
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        report?.patientDetails?.phone
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        report?.reportName.toLowerCase().includes(searchTerm.toLowerCase())
                    // Optionally add age and gender to filter if desired:
                    // || report?.patientDetails?.age?.toString().includes(searchTerm)
                    // || report?.patientDetails?.gender?.toLowerCase().includes(searchTerm.toLowerCase())
                );
                setFilteredDetails(filtered);
            } else {
                setFilteredDetails(details);
            }
        }, 500),
        [details]
    );

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        filterReports(searchTerm);
    };

    // Functions to handle dynamic modal
    const openDynamicModal = (title, content) => {
        setDynamicModalTitle(title);
        setDynamicModalContent(content);
        setIsDynamicModalOpen(true);
    };

    const closeDynamicModal = () => {
        setIsDynamicModalOpen(false);
        setDynamicModalTitle("");
        setDynamicModalContent(null);
        fetchData(); // Refresh data after closing modal to ensure updates (e.g., after edit)
    };

    const columns = [
        {
            title: "Patient Name",
            dataIndex: ["patientDetails", "fullname"],
            key: "fullname",
            render: (fullname) => fullname || "N/A",
        },
        {
            title: "Phone",
            dataIndex: ["patientDetails", "phone"],
            key: "phone",
            render: (phone) => phone || "N/A",
        },
        {
            title: "Address",
            dataIndex: ["patientDetails", "address"],
            key: "address",
            render: (address) => address || "N/A",
        },
        {
            title: "Age",
            dataIndex: ["patientDetails", "age"],
            key: "age",
            render: (age) => age || "N/A",
        },
        {
            title: "Gender",
            dataIndex: ["patientDetails", "gender"],
            key: "gender",
            render: (gender) => gender || "N/A",
        },
        {
            title: "Report Name",
            dataIndex: "reportName",
            key: "reportName",
        },
        {
            title: "Date added",
            dataIndex: "createdAt",
            key: "date",
            render: (createdAt) => createdAt.split("T")[0],
        },
        {
            title: "Action",
            key: "action",
            width: 200,
            render: (_, record) => (
                <div className="flex space-x-2">
                    {/* View Report */}
                    <button
                        onClick={() => {
                            setSelectedReport(record.report);
                            setIsModalOpen(true);
                        }}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
                    >
                        <GrView size={20} />
                    </button>

                    {/* Edit Report */}
                    <button
                        onClick={() =>
                            openDynamicModal("Update Report", (
                                <UpdateReport
                                    appointmentId={record._id} // Assuming _id can serve as appointmentId for self-reports
                                    reportId={record._id}
                                    onClose={closeDynamicModal}
                                    onUpdate={() => {
                                        fetchData(); // Refresh data after update
                                    }}
                                />
                            ))
                        }
                        className="inline-flex items-center px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
                    >
                        <MdEditSquare size={20} />
                    </button>

                    {/* Print Report */}
                    <button
                        onClick={() =>
                            openDynamicModal("Print Report", (
                                <SelfReportPrintout reportId={record._id} onClose={closeDynamicModal} />
                            ))
                        }
                        className="inline-flex items-center px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
                    >
                        <FaPrint size={20} />
                    </button>

                    {/* Delete Report */}
                    <button
                        onClick={() => handleReportDelete(record._id)}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-red-700 text-white hover:bg-red-800"
                    >
                        <MdDelete size={20} />
                    </button>
                </div>
            ),
        },
    ];

    return (
        <>
            {actionLoading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
                    <LoadingScreen />
                </div>
            )}
            <ReportViewModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                report={selectedReport}
            />
            {/* Dynamic Modal */}
            {isDynamicModalOpen && (
                <DynamicModal
                    isOpen={isDynamicModalOpen}
                    onClose={closeDynamicModal}
                    title={dynamicModalTitle}
                >
                    {dynamicModalContent}
                </DynamicModal>
            )}
            <div className="container max-w-8xl mx-auto">
                <div className="mb-4">
                    <h1 className="text-3xl font-bold decoration-gray-400">All Reports</h1>
                    <div className="my-3 flex justify-between items-center">
                        <Input
                            placeholder="Search..."
                            prefix={<SearchOutlined />}
                            allowClear
                            value={searchTerm}
                            onChange={handleSearch}
                            style={{ width: 300 }}
                        />
                        <button
                            onClick={() =>
                                openDynamicModal("Add Test Report", (
                                    <AddSelfReport
                                        onClose={closeDynamicModal}
                                        onAdd={() => {
                                            fetchData(); // Refresh data after adding
                                        }}
                                    />
                                ))
                            }
                            className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
                        >
                            Add Test Report
                        </button>
                    </div>
                </div>
                <div className="flex flex-col">
                    <Table
                        className="rounded-md shadow-md"
                        dataSource={filteredDetails}
                        columns={columns}
                        rowKey="_id"
                        pagination={{
                            className: "pe-3",
                            defaultPageSize: 15,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        loading={{
                            indicator: <Spin size="large" />,
                            spinning: loading,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default ViewLabSelfReports;  