export const MYOSITIS = [
    /// Total Duration of Illness
    {
        field: "msDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Initial Symptom
    {
        field: "msInitialSymptom",
        label: "Initial Symptom",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Suggestive of Proximal muscle weakness",
            "Suggestive of Whole body aches",
            "Suggestive of Joint pain and swelling",
            "Suggestive of Constitutional",
            "Suggestive of Shortness of breath",
            "Suggestive of Swelling of extremities",
            "Suggestive of Mechanic's hands",
            "Suggestive of Raynaud's phenomenon",
            "Suggestive of Gottron's papules",
            "Suggestive of Heliotrope rash",
            "Suggestive of Shawl rash",
            "Suggestive of V rash",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Initial muscle power
    {
        field: "msInitialMusclePower",
        label: "Initial muscle power",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Neckflexor {0-5}",
            "Shoulder {0-5}",
            "Elbow {0-5}",
            "Wrist {0-5}",
            "Hand {0-5}",
            "Hip {0-5}",
            "Knee {0-5}",
            "Ankle & Foot {0-5}",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "Neckflexor {0-5}",
                    "Shoulder {0-5}",
                    "Elbow {0-5}",
                    "Wrist {0-5}",
                    "Hand {0-5}",
                    "Hip {0-5}",
                    "Knee {0-5}",
                    "Ankle & Foot {0-5}",
                ],
                placeholders: [

                    {
                        key: "0-5",
                        type: "select",
                        label: "Power",
                        options: [
                            "0/5",
                            "1/5",
                            "2/5",
                            "3/5",
                            "4/5",
                            "5/5",
                        ],
                    }
                ]
            },

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },

        ]
    },
    /// Initial Investigation
    {
        field: "msInitialInvestigation",
        label: "Initial Investigation",
        type: "multiselect",
        options: [
            "CK Total {____}",
            "CRP {____}",
            "SGOT {____}",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "CK Total {____}",
                    "CRP {____}",
                    "SGOT {____}",
                ],
                placeholders: [

                    {
                        key: "____",
                        type: "type",
                        label: "Value",
                    }
                ]
            },
        ]
    },
    /// Muscle biopsy   
    {
        field: "msMuscleBiopsy",
        label: "Muscle biopsy",
        type: "type",
    },
    /// MRI
    {
        field: "msMri",
        label: "MRI",
        type: "type",
    },
    /// Myositis-Specific Antibodies (MSAs) 
    {
        field: "msMsa",
        label: "Myositis-Specific Antibodies (MSAs)",
        type: "multiselect",
        options: [
            "Anti-Jo-1 (Anti-histidyl-tRNA synthetase) {+1/+2/+3}",
            "Anti-Mi-2 {+1/+2/+3}",
            "Anti-TIF1-γ (Anti-p155/140) {+1/+2/+3}",
            "Anti-NXP-2 {+1/+2/+3}",
            "Anti-MDA5 (Anti-CADM-140) {+1/+2/+3}",
            "Anti-SRP (Signal Recognition Particle) {+1/+2/+3}",
            "Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase) {+1/+2/+3}",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-Jo-1 (Anti-histidyl-tRNA synthetase) {+1/+2/+3}",
                    "Anti-Mi-2 {+1/+2/+3}",
                    "Anti-TIF1-γ (Anti-p155/140) {+1/+2/+3}",
                    "Anti-NXP-2 {+1/+2/+3}",
                    "Anti-MDA5 (Anti-CADM-140) {+1/+2/+3}",
                    "Anti-SRP (Signal Recognition Particle) {+1/+2/+3}",
                    "Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase) {+1/+2/+3}",
                ],
                placeholders: [
                    {
                        key: "+1/+2/+3",
                        type: "select",
                        label: "Strength",
                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ],
                    }
                ]
            },
        ]
    },
    /// Myositis-Associated Antibodies (MAAs)
    {
        field: "msMaa",
        label: "Myositis-Associated Antibodies (MAAs)",
        type: "multiselect",
        options: [
            "Anti-U1 RNP {+1/+2/+3}",
            "Anti-PM/Scl {+1/+2/+3}",
            "Anti-Ku {+1/+2/+3}",
            "Anti-Ro/SSA (Anti-SSA/Ro52) {+1/+2/+3}",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-U1 RNP {+1/+2/+3}",
                    "Anti-PM/Scl {+1/+2/+3}",
                    "Anti-Ku {+1/+2/+3}",
                    "Anti-Ro/SSA (Anti-SSA/Ro52) {+1/+2/+3}",
                ],
                placeholders: [
                    {
                        key: "+1/+2/+3",
                        type: "select",
                        label: "Strength",
                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ],
                    }
                ]
            },
        ]
    },
    /// Initially Treated with
    {
        field: "msInitiallyTreatedWith",
        label: "Initially treated with",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Pulse methylprednisolone {value1} mg for {duration} Days",
            "Cyclophosphamide {value1} mg for {duration} months",
            "Rituximab {value}",
            "Methotrexate {value} mg per week",
            "Mycophenolate {value} mg twice a day",
            "Azathioprine {value} mg once a day",
            "Prednisolone {value} mg per day",
            "Hydroxychloroquine {value} mg",
            "Tadalafil 10mg",
            "Nifedipine 20mg SR",
            "Nintadinib 150mg BD",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },

            {
                // When the user selects this trigger value…
                trigger: [
                    "Pulse methylprednisolone {value1} mg for {duration} Days",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "select",
                        label: "Dose",

                        options: [
                            "100",
                            "250",
                            "500",
                            "750",
                            "1000",
                        ]
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Days",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                        ]
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Cyclophosphamide {value1} mg for {duration} months",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "type",
                        label: "Dose",
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Months",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Rituximab {value}",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500 mg",
                            "1 g",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Methotrexate {value} mg per week",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "7.5",
                            "10",
                            "12.5",
                            "15",
                            "17.5",
                            "20",
                            "25",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Mycophenolate {value} mg twice a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500",
                            "1000",
                            "1500",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Azathioprine {value} mg once a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "25",
                            "50",
                            "75",
                            "100",
                            "125",
                            "150",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Prednisolone {value} mg per day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: Array.from({ length: 32 }, (_, i) => 2.5 * (i + 1)),
                    }
                ]
            },
            {
                trigger: [
                    "Hydroxychloroquine {value} mg",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "200",
                            "300",
                            "400",
                        ],
                    }
                ]
            },
        ]
    },
    /// Response Field
    {
        field: "msResponse",
        label: "Response",
        type: "select",
        options: [
            "Improved on initial therapy",
            "Did not improve on initial therapy",
            "Did not tolerate initial therapy",
            "Developed side effect on initial therapy",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "msAnaByIfaIntensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "msAnaByIfaTiter",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "{ Write Something Here }",
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "msAnaByIfaPattern",
        label: "ANA by-IFA : Pattern",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
            "AC 1", "AC 2", "AC 3", "AC 4", "AC 5", "AC 6", "AC 7", "AC 8", "AC 9", "AC 10",
            "AC 11", "AC 12", "AC 13", "AC 14", "AC 15", "AC 16", "AC 17", "AC 18", "AC 19", "AC 20",
            "AC 21", "AC 22", "AC 23", "AC 24", "AC 25", "AC 26", "AC 27", "AC 28", "AC 29", "AC 30",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Comorbidities Field
    {
        field: "msComorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
            "{ Write Something Here }"
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Conception Plan Field
    {
        field: "msConceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "msCompliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "msVaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "msSmoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "msCurrentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Progressive weakness",
            "Worsening skin rash",
            "New skin rashes",
            "Fever",
            "Cough",
            "Developed side effects on current therapy",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    }
];