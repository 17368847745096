/// Sabai ma Template vanera use garna milxa jasma hamle trigger ni match garna pardaina 
/// field ko options mani mathc huna pardaina 
/// vaneko field ko option ko value "digital gangrene" xa ani trigger mani "digital gangrene" xa 
/// vanesi template vanera provide garera "digital gangrene {extra}" use garera placeholder ma use garna milxa
/// tala jasari

// {
//     // For example, an extra option for digital gangrene:
//     trigger: "digital gangrene",
//     placeholders: [
//         {
//             key: "extra",
//             type: "text",
//             label: "Digital gangrene extra info"
//         }
//     ],
//     // In this case, a template is provided.
//     template: "digital gangrene ({extra})"
// }


export const fieldsConfig = {
    "First_Encounter": [
        // Complaints of
        {
            field: "complaints-of",
            label: "Complaints Of",
            type: "multiselect",
            options: [
                "Symmetrical small joint pain for {durationValue} {durationUnit}",
                "Asymmetrical small joint pain for {durationValue} {durationUnit}",
                "Asymmetrical large joint pain for {durationValue} {durationUnit}",
                "Nape of neck pain for {durationValue} {durationUnit}",
                "Low Back pain with alternating buttock pain for {durationValue} {durationUnit}",
                "Low Back pain with symptom suggestive of nerve impingement for {durationValue} {durationUnit}",
                "Inflammatory in nature for {durationValue} {durationUnit}",
                "Non Inflammatory in nature for {durationValue} {durationUnit}",
                "Constitutational symptoms for {durationValue} {durationUnit}",
                "Hairfall for {durationValue} {durationUnit}",
                "Grittiness of eye for {durationValue} {durationUnit}",
                "Dryness of eyes for {durationValue} {durationUnit}",
                "Redness of eyes for {durationValue} {durationUnit}",
                "EyePain for {durationValue} {durationUnit}",
                "Blurring of vision for {durationValue} {durationUnit}",
                "Photosensitivity for {durationValue} {durationUnit}",
                "Vasculitic rash for {durationValue} {durationUnit}",
                "Oral Ulcer for {durationValue} {durationUnit}",
                "Raynaud’s phenomenon for {durationValue} {durationUnit}",
                "History suggestive f=of proximal musscle weakness for {durationValue} {durationUnit}",
                "Psoriatic skin rash for {durationValue} {durationUnit}",
                "Psoriatic non healing skin ulcer for {durationValue} {durationUnit}",
                "Digital tip ulcer for {durationValue} {durationUnit}",
                "Amputation of DIP for {durationValue} {durationUnit}",
                "History suggestive of IBD for {durationValue} {durationUnit}",
                "Urethritis for {durationValue} {durationUnit}",
                "Dry cough for {durationValue} {durationUnit}",
                "Shortness of breath for {durationValue} {durationUnit}",
                "{ Write Something Here }",
            ],
            extraOptions: [
                {
                    // When the user selects this trigger Done/Not Done/Refused…
                    trigger: [
                        "{ Write Something Here }",
                    ],
                    placeholders: [

                        {
                            key: " Write Something Here ",
                            type: "type",
                            label: "Text",
                        }
                    ]
                },
                {
                    // When the user selects this trigger value…
                    trigger: [
                        "Symmetrical small joint pain for {durationValue} {durationUnit}",
                        "Asymmetrical small joint pain for {durationValue} {durationUnit}",
                        "Asymmetrical large joint pain for {durationValue} {durationUnit}",
                        "Nape of neck pain for {durationValue} {durationUnit}",
                        "Low Back pain with alternating buttock pain for {durationValue} {durationUnit}",
                        "Low Back pain with symptom suggestive of nerve impingement for {durationValue} {durationUnit}",
                        "Inflammatory in nature for {durationValue} {durationUnit}",
                        "Non Inflammatory in nature for {durationValue} {durationUnit}",
                        "Constitutational symptoms for {durationValue} {durationUnit}",
                        "Hairfall for {durationValue} {durationUnit}",
                        "Grittiness of eye for {durationValue} {durationUnit}",
                        "Dryness of eyes for {durationValue} {durationUnit}",
                        "Redness of eyes for {durationValue} {durationUnit}",
                        "EyePain for {durationValue} {durationUnit}",
                        "Blurring of vision for {durationValue} {durationUnit}",
                        "Photosensitivity for {durationValue} {durationUnit}",
                        "Vasculitic rash for {durationValue} {durationUnit}",
                        "Oral Ulcer for {durationValue} {durationUnit}",
                        "Raynaud’s phenomenon for {durationValue} {durationUnit}",
                        "History suggestive f=of proximal musscle weakness for {durationValue} {durationUnit}",
                        "Psoriatic skin rash for {durationValue} {durationUnit}",
                        "Psoriatic non healing skin ulcer for {durationValue} {durationUnit}",
                        "Digital tip ulcer for {durationValue} {durationUnit}",
                        "Amputation of DIP for {durationValue} {durationUnit}",
                        "History suggestive of IBD for {durationValue} {durationUnit}",
                        "Urethritis for {durationValue} {durationUnit}",
                        "Dry cough for {durationValue} {durationUnit}",
                        "Shortness of breath for {durationValue} {durationUnit}",
                    ],
                    placeholders: [

                        {
                            key: "durationValue",

                            type: "select",
                            label: "value",

                            options: Array.from({ length: 40 }, (_, i) => i + 1),
                        },
                        {
                            key: "durationUnit",
                            type: "select",
                            label: "days/months/years",
                            options: [
                                "days",
                                "months",
                                "years",
                            ]
                        }
                    ]
                },
            ]
        },
        // Second Opinion
        {
            field: "second-opinion",
            label: "Second Opinion",
            type: "checkbox",
            options: ["Yes", "No"],
        },
        // Family History
        {
            field: "family-history",
            label: "Family History",
            type: "select",
            options: [
                "Positive {disease}",
                "Negative",
                "{ Write Something Here }",
            ],
            extraOptions: [
                {
                    // When the user selects this trigger Done/Not Done/Refused…
                    trigger: [
                        "{ Write Something Here }",
                    ],
                    placeholders: [

                        {
                            key: " Write Something Here ",
                            type: "type",
                            label: "Text",
                        }
                    ]
                },
                {
                    // When the user selects this trigger value…
                    trigger: [
                        "Positive {disease}",
                    ],
                    placeholders: [

                        {
                            key: "disease",
                            type: "multiselect",
                            label: "value",
                            options: [
                                "RHEUMATOID_ARTHRITIS_M05-M06", "SYSTEMIC_SCLEROSIS_M34",
                                "SYSTEMIC_LUPUS_ERYTHEMATOSUS_M32", "SPONDYLOARTHRITIS_M45",
                                "MYOSITIS_M33", "MIXED_CONNECTIVE_TISSUE", "GOUTY_ARTHRITIS_M10",
                                "VASCULITIS", "DEGENERATIVE_DISEASE_M15-M19", "PRIMARY_SJÖGREN_DISEASE",
                                "OVERLAP_SYNDROMES", "UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE",
                            ],
                        },
                    ]
                },
            ]
        },
        // Smoking History
        {
            field: "smoking-history",
            label: "Smoking History",
            type: "checkbox",
            options: ["Yes", "No"],
        },
        // Examination
        {
            field: "examination",
            label: "Examination",
            type: "multiselect",
            options: [
                "{Right/Left} - {1st/2nd/3rd/4th} {MCP/PIP} were {Tender/Swollen}",
                "With deformities",
                "Without deformities",
                "Pallor +", "Pallor -", "Icterus +", "Icterus -", "Lymphedinopathy +", "Lymphedinopathy -", "Oedema +",
                "Oedema -", "Hairloss +", "Hairloss -", "Malar rash +", "Malar rash -", "Oral ulcer +", "Oral ulcer -",
                "Dental caries +", "Dental caries -", "Heliotrope rash +", "Heliotrope rash -", "Scleritis +", "Scleritis -",
                "Epi-scleritis +", "Epi-scleritis -", "V-sign +", "V-sign -", "Sawl-sign +", "Sawl-sign -", "Muscle weakness +",
                "Muscle weakness -", "Skin tightening +", "Skin tightening -", "Holster-sign +", "Holster-sign -", "Discoit rash +",
                "Discoit rash -", "Enthesitis +", "Enthesitis -", "Condritis +", "Condritis -", "Ptosis +", "Ptosis -",
                "Digital Tip Scar +", "Digital Tip Scar -", "Vasculitic rash +", "Vasculitic rash -", "Gottron's papules +",
                "Gottron's papules -", "Gottron's-sign +", "Gottron's-sign -", "Onycholysis +", "Onycholysis -", "Nail pitting +",
                "Nail pitting -", "Dactylitis +", "Dactylitis -", "Rheumatoid nodules +", "Rheumatoid nodules -", "Tophi +", "Tophi -",
                "Knee crepitation +", "Knee crepitation -", "SI-Joint tenderness +", "SI-Joint tenderness -", "Spine tenderness +",
                "Spine tenderness -", "Petrick's test +", "Petrick's test -", "Decrease spine movement +", "Decrease spine movement -",
                "Erythemanodosun +", "Erythemanodosun -", "Palpable purpura +", "Palpable purpura -", "Echymotic rash +",
                "Echymotic rash -", "Psoriatic rash +", "Psoriatic rash -", "Features suggestive of pulmonary artery hypertension +",
                "Features suggestive of pulmonary artery hypertension -", "Features suggestive of interstetial lung disease +",
                "Features suggestive of interstetial lung disease -", "Salt pepper skin +", "Salt pepper skin -", "Muscle atrophy +",
                "Muscle atrophy -", "Dry skin +", "Dry skin -", "Dry mucosa +", "Dry mucosa -", "Deformity +", "Deformity -",
                "Ulnar deviation +", "Ulnar deviation -", "Swan neck deformity +", "Swan neck deformity -", "Boutennier deformity +",
                "Boutennier deformity -", "Pianokey deformity +", "Pianokey deformity -", "Tendinitis +", "Tendinitis -",
                "Tenosinovitis +", "Tenosinovitis -", "Hammer toe +", "Hammer toe -", "Bunion toe +", "Bunion toe -",
                "Hunched back +", "Hunched back -", "Wasting of dorsal interosseous +", "Wasting of dorsal interosseous -",
                "{ Write Something Here }",
            ],
            extraOptions: [
                // "{ Write Something Here }"
                {
                    // When the user selects this trigger Done/Not Done/Refused…
                    trigger: [
                        "{ Write Something Here }",
                    ],
                    placeholders: [

                        {
                            key: " Write Something Here ",
                            type: "type",
                            label: "Text",
                        }
                    ]
                },
                // "{Right/Left} - {1st/2nd/3rd/4th} {MCP/PIP} were {Tender/Swollen}"
                {
                    // When the user selects this trigger value…
                    trigger: [
                        "{Right/Left} - {1st/2nd/3rd/4th} {MCP/PIP} were {Tender/Swollen}",
                    ],
                    placeholders: [
                        {
                            key: "Right/Left",
                            type: "select",
                            label: "Alignment",
                            options: [
                                "Right",
                                "Left",
                            ],
                        },
                        {
                            key: "1st/2nd/3rd/4th",
                            type: "multiselect",
                            label: "Position",
                            options: [
                                "1st",
                                "2nd",
                                "3rd",
                                "4th",
                            ],
                        },
                        {
                            key: "MCP/PIP",
                            type: "multiselect",
                            label: "MCP/PIP",
                            options: [
                                "MCP",
                                "PIP",
                            ],
                        },
                        {
                            key: "Tender/Swollen",
                            type: "select",
                            label: "Tender/Swollen",
                            options: [
                                "Tender",
                                "Swollen",
                                "Tender & Swollen",
                            ],
                        },
                    ]
                },
            ]
        },
    ],
};
