import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import { Form, Input, Button, Modal, Select, Typography, Radio, message } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen"; // Adjust import path
import { fieldsConfig } from "./fields_config"; // Adjust import path

const { Title } = Typography;

const UpdateFirstEncounter = forwardRef(
    (
        { encounterId, full_name, problem, appointmentNumber, onClose, onUpdateEncounter, fetchOnMount, updateTrigger },
        ref
    ) => {
        const [forms, setForms] = useState([
            { id: 1, selectedSection: "First_Encounter", formData: {}, fieldOptions: {} },
        ]);
        const [dynamicExtra, setDynamicExtra] = useState(null);
        const [actionLoading, setActionLoading] = useState(false);
        const [loading, setLoading] = useState(false);

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const config = {
            headers: {
                Authorization:
                    "Bearer " + (localStorage.getItem("doctorticket") || localStorage.getItem("staffticket")),
            },
        };

        // Fetch data when fetchOnMount is true and updateTrigger changes
        useEffect(() => {
            if (fetchOnMount) {
                console.log("Fetching data for encounterId:", encounterId, "with trigger:", updateTrigger);
                fetchData();
                // Reset form state to initial values
                setForms([{ id: 1, selectedSection: "First_Encounter", formData: {}, fieldOptions: {} }]);
                setDynamicExtra(null);
            }
        }, [fetchOnMount, encounterId, updateTrigger]);

        const fetchData = async () => {
            if (!encounterId) {
                console.error("No encounterId provided");
                message.error("No encounter ID provided!");
                return;
            }

            setLoading(true);
            try {
                const response = await axios.get(`${apiBaseUrl}/firstEncounter/single/${encounterId}`, config);
                console.log("Fetched data:", response.data);
                if (response.data.success) {
                    const data = response.data.data;
                    const selectedSection = data.encounterData.section;
                    const formData = data.encounterData.data;
                    const configSection = fieldsConfig[selectedSection] || [];

                    const fieldOptions = {};
                    configSection.forEach((fieldConfig) => {
                        if (fieldConfig.type === "select" || fieldConfig.type === "multiselect") {
                            const defaultOptions = fieldConfig.options || [];
                            const formDataValue = formData[fieldConfig.field];
                            let customValues = [];

                            if (fieldConfig.type === "select" && formDataValue && !defaultOptions.includes(formDataValue)) {
                                customValues = [formDataValue];
                            } else if (fieldConfig.type === "multiselect" && Array.isArray(formDataValue)) {
                                customValues = formDataValue.filter((val) => !defaultOptions.includes(val));
                            }

                            fieldOptions[fieldConfig.field] = [...defaultOptions, ...customValues];
                        }
                    });

                    setForms((prevForms) =>
                        prevForms.map((f) =>
                            f.id === 1 ? { ...f, selectedSection, formData, fieldOptions } : f
                        )
                    );
                } else {
                    message.error(response?.data?.error?.error || "Failed to fetch encounter data");
                }
            } catch (error) {
                console.error("Error fetching encounter:", error);
                message.error("Failed to fetch encounter data!");
            } finally {
                setLoading(false);
            }
        };

        // Expose handleSubmitAll to parent via ref
        useImperativeHandle(ref, () => ({
            handleSubmitAll,
        }));

        const getConfigSection = (form) => fieldsConfig[form.selectedSection] || [];

        const handleChange = (formId, field, value) => {
            setForms((prevForms) =>
                prevForms.map((f) =>
                    f.id === formId ? { ...f, formData: { ...f.formData, [field]: value } } : f
                )
            );
        };

        const handleCompositeChange = (formId, compositeField, subField, value) => {
            setForms((prevForms) =>
                prevForms.map((f) => {
                    if (f.id === formId) {
                        return {
                            ...f,
                            formData: {
                                ...f.formData,
                                [compositeField]: {
                                    ...(f.formData[compositeField] || {}),
                                    [subField]: value,
                                },
                            },
                        };
                    }
                    return f;
                })
            );
        };

        const checkAndTriggerExtraOption = (formId, field, value) => {
            const form = forms.find((f) => f.id === formId);
            const configSection = getConfigSection(form);
            const fieldConfig = configSection.find((fc) => fc.field === field);
            if (fieldConfig?.extraOptions) {
                const extra = fieldConfig.extraOptions.find((opt) =>
                    Array.isArray(opt.trigger) ? opt.trigger.includes(value) : opt.trigger === value
                );
                if (extra) {
                    const initialValues = {};
                    if (Array.isArray(extra.placeholders)) {
                        extra.placeholders.forEach((p) => {
                            initialValues[p.key] = p.type === "multiselect" ? [] : "";
                        });
                    }
                    setDynamicExtra({ formId, field, extraOption: extra, values: initialValues, selectedTrigger: value });
                }
            }
        };

        const handleMultiSelectChange = (formId, field, valueArray) => {
            const form = forms.find((f) => f.id === formId);
            const configSection = getConfigSection(form);
            const fieldConfig = configSection.find((fc) => fc.field === field);
            let triggers = [];
            if (fieldConfig?.extraOptions) {
                triggers = fieldConfig.extraOptions.flatMap((opt) =>
                    Array.isArray(opt.trigger) ? opt.trigger : [opt.trigger]
                );
            }
            const cleaned = valueArray.filter((val) => !triggers.includes(val));
            setForms((prevForms) =>
                prevForms.map((f) =>
                    f.id === formId ? { ...f, formData: { ...f.formData, [field]: cleaned } } : f
                )
            );
        };

        const handleMultiSelectSelect = (formId, field, value) => {
            checkAndTriggerExtraOption(formId, field, value);
        };

        const handleDynamicExtraOk = () => {
            if (dynamicExtra) {
                const { extraOption, values, field, formId, selectedTrigger } = dynamicExtra;
                let finalString = extraOption.template || selectedTrigger;
                if (extraOption.placeholders && Array.isArray(extraOption.placeholders)) {
                    extraOption.placeholders.forEach((placeholder) => {
                        let replacement = values[placeholder.key];
                        if (Array.isArray(replacement)) {
                            replacement = replacement.join(", ");
                        }
                        finalString = finalString.replace(`{${placeholder.key}}`, replacement || placeholder.key);
                    });
                }
                setForms((prevForms) =>
                    prevForms.map((f) => {
                        if (f.id === formId) {
                            const config = getConfigSection(f);
                            const fieldConfig = config.find((fc) => fc.field === field);
                            if (fieldConfig.type === "select") {
                                return { ...f, formData: { ...f.formData, [field]: finalString } };
                            } else if (fieldConfig.type === "multiselect") {
                                const current = f.formData[field] || [];
                                const cleanedArray = Array.isArray(current)
                                    ? current.filter((val) => val !== selectedTrigger)
                                    : [];
                                if (!cleanedArray.includes(finalString)) {
                                    cleanedArray.push(finalString);
                                }
                                return { ...f, formData: { ...f.formData, [field]: cleanedArray } };
                            }
                        }
                        return f;
                    })
                );
            }
            setDynamicExtra(null);
        };

        const handleDynamicExtraCancel = () => {
            setDynamicExtra(null);
        };

        const hasFormData = (data) => {
            if (Array.isArray(data)) return data.length > 0;
            if (typeof data === "object" && data !== null) {
                return Object.values(data).some((value) => hasFormData(value));
            }
            return typeof data === "string" ? data.trim() !== "" : data !== undefined && data !== null;
        };

        const handleSubmitAll = async () => {
            setActionLoading(true);
            const selectedForm = forms[0];
            const { formData } = selectedForm;

            if (!hasFormData(formData)) {
                message.error("Please fill at least one field for the encounter.");
                setActionLoading(false);
                return;
            }

            const data = {
                encounterData: { // Reverted to include encounterData wrapper
                    section: selectedForm.selectedSection,
                    data: formData,
                },
            };

            try {
                console.log("Submitting updated data:", data); // Debug log
                await onUpdateEncounter(encounterId, data);
                console.log("Update successful");
                onClose(); // Close the modal on successful update
            } catch (error) {
                console.error("Failed to update encounter:", error);
                message.error("Failed to update encounter! Please try again.");
            } finally {
                setActionLoading(false);
            }
        };

        const handleClear = (formId, field, type) => {
            setForms((prevForms) =>
                prevForms.map((f) => {
                    if (f.id === formId) {
                        const clearedValue = type === "multiselect" ? [] : type === "composite" ? {} : "";
                        return { ...f, formData: { ...f.formData, [field]: clearedValue } };
                    }
                    return f;
                })
            );
        };

        const renderForm = (form) => {
            const configSection = getConfigSection(form);
            return (
                <div style={{ maxWidth: "900px", margin: "auto", padding: "20px" }}>
                    <Title level={2}>Update Encounter Form</Title>
                    <Form layout="vertical">
                        {configSection.map((fieldConfig) => {
                            if (fieldConfig.type === "composite") {
                                return (
                                    <div key={fieldConfig.field}>
                                        <Form.Item label={fieldConfig.label}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {fieldConfig.fields.map((subField) => (
                                                    <div key={subField.field} style={{ flex: 1, marginRight: "16px" }}>
                                                        <Form.Item label={subField.label} style={{ marginBottom: 0 }}>
                                                            <Select
                                                                placeholder={`Select ${subField.label}`}
                                                                value={
                                                                    form.formData[fieldConfig.field]?.[subField.field] || undefined
                                                                }
                                                                onChange={(value) =>
                                                                    handleCompositeChange(form.id, fieldConfig.field, subField.field, value)
                                                                }
                                                                style={{ width: "100%" }}
                                                            >
                                                                {subField.options.map((opt) => (
                                                                    <Select.Option key={opt} value={opt}>
                                                                        {opt}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                ))}
                                                <Button
                                                    type="link"
                                                    onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                                >
                                                    Clear
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </div>
                                );
                            } else if (fieldConfig.type === "multiselect") {
                                return (
                                    <div key={fieldConfig.field}>
                                        <Form.Item label={fieldConfig.label}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Select
                                                    mode="tags"
                                                    placeholder={`Select ${fieldConfig.label}`}
                                                    value={form.formData[fieldConfig.field] || []}
                                                    onChange={(value) => handleMultiSelectChange(form.id, fieldConfig.field, value)}
                                                    onSelect={(value) => handleMultiSelectSelect(form.id, fieldConfig.field, value)}
                                                    style={{ width: "100%", marginRight: "10px" }}
                                                >
                                                    {(fieldConfig.options || []).map((option) => (
                                                        <Select.Option key={option} value={option}>
                                                            {option}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                                <Button
                                                    type="link"
                                                    onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                                >
                                                    Clear
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </div>
                                );
                            } else if (fieldConfig.type === "checkbox") {
                                return (
                                    <div key={fieldConfig.field}>
                                        <Form.Item label={fieldConfig.label}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Radio.Group
                                                    options={fieldConfig.options || ["Yes", "No"]}
                                                    value={form.formData[fieldConfig.field] || ""}
                                                    onChange={(e) => handleChange(form.id, fieldConfig.field, e.target.value)}
                                                />
                                                <Button
                                                    type="link"
                                                    onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                                >
                                                    Clear
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </div>
                                );
                            } else if (fieldConfig.type === "select") {
                                const currentOptions = form.fieldOptions[fieldConfig.field] || fieldConfig.options || [];
                                return (
                                    <Form.Item label={fieldConfig.label} key={fieldConfig.field}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Select
                                                showSearch
                                                placeholder={`Select ${fieldConfig.label}`}
                                                value={form.formData[fieldConfig.field] || undefined}
                                                onChange={(value) => handleChange(form.id, fieldConfig.field, value)}
                                                onSelect={(value) => checkAndTriggerExtraOption(form.id, fieldConfig.field, value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        const searchValue = e.target.value.trim();
                                                        if (searchValue && !currentOptions.includes(searchValue)) {
                                                            setForms((prevForms) =>
                                                                prevForms.map((f) =>
                                                                    f.id === form.id
                                                                        ? {
                                                                            ...f,
                                                                            fieldOptions: {
                                                                                ...f.fieldOptions,
                                                                                [fieldConfig.field]: [...currentOptions, searchValue],
                                                                            },
                                                                            formData: { ...f.formData, [fieldConfig.field]: searchValue },
                                                                        }
                                                                        : f
                                                                )
                                                            );
                                                            checkAndTriggerExtraOption(form.id, fieldConfig.field, searchValue);
                                                        }
                                                    }
                                                }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{ width: "100%", marginRight: "10px" }}
                                            >
                                                {currentOptions.map((opt) => (
                                                    <Select.Option key={opt} value={opt}>
                                                        {opt}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            <Button
                                                type="link"
                                                onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                    </Form.Item>
                                );
                            } else if (fieldConfig.type === "type") {
                                return (
                                    <Form.Item label={fieldConfig.label} key={fieldConfig.field}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Input
                                                placeholder={`Enter ${fieldConfig.label}`}
                                                value={form.formData[fieldConfig.field] || ""}
                                                onChange={(e) => handleChange(form.id, fieldConfig.field, e.target.value)}
                                                style={{ width: "100%", marginRight: "10px" }}
                                            />
                                            <Button
                                                type="link"
                                                onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                    </Form.Item>
                                );
                            }
                            return null;
                        })}
                    </Form>
                    <pre style={{ background: "#f0f0f0", padding: "10px", marginTop: "20px" }}>
                        {JSON.stringify(form.formData, null, 2)}
                    </pre>
                </div>
            );
        };

        return (
            <>
                {actionLoading && <LoadingScreen />}
                {loading ? (
                    <LoadingScreen />
                ) : (
                    <>
                        {forms.map((form) => (
                            <div key={form.id}>{renderForm(form)}</div>
                        ))}
                        <Modal
                            title={dynamicExtra ? dynamicExtra.extraOption.label : "Extra Input"}
                            open={dynamicExtra !== null}
                            onOk={handleDynamicExtraOk}
                            onCancel={handleDynamicExtraCancel}
                            width={900}
                        >
                            <Form layout="vertical">
                                {dynamicExtra?.extraOption.placeholders?.map((placeholder) => (
                                    <Form.Item label={placeholder.label} key={placeholder.key}>
                                        {placeholder.type === "select" ? (
                                            <Select
                                                placeholder={`Select ${placeholder.label}`}
                                                value={dynamicExtra.values[placeholder.key] || undefined}
                                                onChange={(value) =>
                                                    setDynamicExtra((prev) => ({
                                                        ...prev,
                                                        values: { ...prev.values, [placeholder.key]: value },
                                                    }))
                                                }
                                            >
                                                {(placeholder.options || []).map((opt) => (
                                                    <Select.Option key={opt} value={opt}>
                                                        {opt}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ) : placeholder.type === "multiselect" ? (
                                            <Select
                                                mode="multiple"
                                                placeholder={`Select ${placeholder.label}`}
                                                value={dynamicExtra.values[placeholder.key] || []}
                                                onChange={(value) =>
                                                    setDynamicExtra((prev) => ({
                                                        ...prev,
                                                        values: { ...prev.values, [placeholder.key]: value },
                                                    }))
                                                }
                                            >
                                                {(placeholder.options || []).map((opt) => (
                                                    <Select.Option key={opt} value={opt}>
                                                        {opt}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ) : (
                                            <Input
                                                placeholder={`Enter ${placeholder.label}`}
                                                value={dynamicExtra.values[placeholder.key] || ""}
                                                onChange={(e) =>
                                                    setDynamicExtra((prev) => ({
                                                        ...prev,
                                                        values: { ...prev.values, [placeholder.key]: e.target.value },
                                                    }))
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                ))}
                            </Form>
                        </Modal>
                    </>
                )}
            </>
        );
    }
);

export default UpdateFirstEncounter;