import { useState, useEffect, createContext, useCallback } from "react";
import axios from "axios";
import { getOrderStatus } from "../lib/helpers";
import ActionAppointmentStaff from "./StaffAppointmentActionButton";
import { Table, Space, Input, Spin, message, DatePicker, Button, Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import DynamicModal from "./dynamicModel"; // Adjust the path if necessary
import AddReport from "./report/add_report";
import AddNewAppointmentStaff from "./AddNewAppointmentByStaff";

export const appointmentContext = createContext();
const { RangePicker } = DatePicker;

const ViewAppointment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  // Existing states
  const [appointmentId, setAppointmentId] = useState("");
  const [appointmentNumber, setAppointmentNumber] = useState("");
  const [department, setDepartment] = useState("");
  const [full_name, setFullName] = useState("");
  const [appointmentHistory, setAppointmentHistory] = useState("");
  const [DOB, setDOB] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [problem, setProblem] = useState("");
  const [status, setStatus] = useState("");
  const [report, setReport] = useState([]);
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDoctorAssignedAvailable, setIsDoctorAssignedAvailable] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  // States for dynamic modal
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalTitle, setDynamicModalTitle] = useState("");
  const [dynamicModalContent, setDynamicModalContent] = useState(null);

  const isLabReporter = localStorage.getItem("labreporterticket");

  // Dynamic auth config
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];
    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    return {
      headers: {
        Authorization: `Bearer ${availableTicket ? localStorage.getItem(availableTicket.key) : ""}`,
      },
    };
  };

  const config = getAuthConfig();

  useEffect(() => {
    if (isLabReporter) {
      fetchData(true);
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async (excludePending = false, startDate, endDate) => {
    setLoading(true);
    let queryParams = excludePending ? "?excludePending=true" : "";

    if (startDate && endDate) {
      queryParams += `${queryParams ? "&" : "?"}startDate=${startDate}&endDate=${endDate}`;
    }

    const url = `${apiBaseUrl}/appointment${queryParams}`;

    try {
      const response = await axios.get(url, config);
      if (response.data.success) {
        const filteredData = response.data.data.filter((appointment) => {
          const { date, refer, followUp_appointmentDate, referred_appointmentDate, status } = appointment;
          let appointmentDate = date;

          if (status === "FollowUp" && followUp_appointmentDate) {
            appointmentDate = followUp_appointmentDate;
          } else if (status === "Referred" && referred_appointmentDate && refer?.toDoctor !== null) {
            appointmentDate = referred_appointmentDate;
          }

          if (startDate && endDate) {
            return (
              new Date(appointmentDate) >= new Date(startDate) &&
              new Date(appointmentDate) <= new Date(endDate)
            );
          }
          return true;
        });

        setDetails(filteredData);
        setFilteredDetails(filteredData);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
      message.error("Failed to get appointment details! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const filterAppointments = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter(
          (appointment) =>
            appointment?.appointmentNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            appointment?.patientId?.userID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            appointment?.fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            appointment?.mobile?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        if (isLabReporter) {
          fetchData(true);
        } else {
          fetchData();
        }
      }
    }, 500),
    [searchTerm, details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterAppointments(searchTerm);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (dates) {
      const [startDate, endDate] = dates;
      fetchData(isLabReporter, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
    } else {
      fetchData(isLabReporter);
    }
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setDateRange([null, null]);
    fetchData(isLabReporter);
  };

  const toggleModal = (appointment) => {
    if (appointment && appointment._id) {
      setAppointmentId(appointment._id);
      setAppointmentNumber(appointment.appointmentNumber);
      setAppointmentHistory(appointment.appointmentHistory || "");
      setDOB(appointment.DOB || "");
      setAddress(appointment.address || "");
      setMobile(appointment.mobile || "");
      setGender(appointment.gender || "");
      setAppointmentDate(appointment.date || "");
      setAppointmentTime(appointment.time || "");
      setDepartment(appointment.department || "");
      setFullName(appointment.fullname || "");
      setProblem(appointment.problem || "");
      setReport(appointment.reports || []);
      setStatus(appointment.status || "");
      setIsDoctorAssignedAvailable(appointment.refer !== undefined && appointment.refer?.toDoctor == null);
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
      // Reset context values
      setAppointmentId("");
      setAppointmentNumber("");
      setAppointmentHistory("");
      setDOB("");
      setAddress("");
      setMobile("");
      setGender("");
      setAppointmentDate("");
      setAppointmentTime("");
      setDepartment("");
      setFullName("");
      setProblem("");
      setReport([]);
      setStatus("");
      setIsDoctorAssignedAvailable("");
    }
  };

  const openDynamicModal = (title, content) => {
    setDynamicModalTitle(title);
    setDynamicModalContent(content);
    setIsDynamicModalOpen(true);
  };

  const closeDynamicModal = () => {
    setIsDynamicModalOpen(false);
    setDynamicModalTitle("");
    setDynamicModalContent(null);
  };

  const contextValue = {
    appointmentId,
    appointmentNumber,
    appointmentHistory,
    DOB,
    address,
    mobile,
    gender,
    appointmentDate,
    appointmentTime,
    department,
    full_name,
    problem,
    report,
    status,
    isDoctorAssignedAvailable,
    openDynamicModal,
    refresh: () => fetchData(isLabReporter), // Added for table refresh
  };

  const columns = [
    {
      title: "Appointment No.",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
      width: 200,
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Doctor",
      key: "doctor",
      render: (_, record) => {
        const { refer, doctorId } = record;
        if (refer) {
          if (refer?.toDoctor) return refer.toDoctor.fullname;
          return <span className="text-red-500">Not assigned</span>;
        }
        return doctorId.fullname;
      },
    },
    {
      title: "Department",
      key: "department",
      render: (_, record) => {
        const { department, refer } = record;
        return refer ? refer.toDepartment.department : department.department;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{getOrderStatus(status)}</span>,
    },
    {
      title: "Date",
      key: "date",
      render: (_, record) => {
        const { date, refer, followUp_appointmentDate, referred_appointmentDate, status } = record;
        if (status === "FollowUp") {
          return followUp_appointmentDate || date;
        } else if (status === "Referred") {
          return referred_appointmentDate && refer.toDoctor !== null ? referred_appointmentDate : <span className="text-red-500">Not assigned</span>;
        } else if (["Ongoing", "Cancelled", "Cancel In Pending"].includes(status)) {
          return date;
        }
        return date;
      },
    },
    {
      title: "Vitals",
      key: "vitals",
      render: (_, record) => {
        const lastAppointment = record.appointmentHistory?.[record.appointmentHistory.length - 1];
        const investigations = lastAppointment?.investigations || [];
        const tests = investigations.flatMap((inv) => inv.investigation || []).filter((test) => test.trim());
        const popoverContent = (
          <div>
            <p>Weight: {record.weight} {record.weight ? "KG" : "N/A"}</p>
            <p>Pressure: {record.pressure} {record.pressure ? "mmofHg" : "N/A"}</p>
            <div className="mt-2">
              <p className="font-semibold">Investigations:</p>
              {tests.length > 0 ? (
                <ul className="mt-1 list-disc pl-4">
                  {tests.map((test, index) => (
                    <li key={index} className="text-gray-700">{test}</li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500">No investigations available</p>
              )}
            </div>
          </div>
        );
        return (
          <Popover content={popoverContent} title="Patient Vitals" trigger="hover">
            <Button className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600">
              View Vitals & Investigation
            </Button>
          </Popover>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        localStorage.getItem("labreporterticket") ? (
          <Space size="middle">
            <button
              onClick={() =>
                openDynamicModal("Add Report", (
                  <AddReport
                    appointmentId={record._id} // Changed to _id for consistency
                    full_name={record.fullname}
                    problem={record.problem}
                    status={record.status}
                    onClose={closeDynamicModal}
                  />
                ))
              }
              className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
            >
              Add Report
            </button>
          </Space>
        ) : (
          <Space size="middle">
            <button
              type="button"
              onClick={() => toggleModal(record)}
              className="px-4 py-2 rounded-md bg-sky-500 text-white hover:bg-sky-600"
            >
              Click here
            </button>
          </Space>
        ),
    },
  ];

  return (
    <>
      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">Appointments</h1>
          <div className="flex justify-between mt-3">
            <div>
              <Input
                placeholder="Search..."
                prefix={<SearchOutlined />}
                allowClear
                value={searchTerm}
                onChange={handleSearch}
                style={{ width: 250 }}
              />
              <RangePicker
                value={dateRange}
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                style={{ marginLeft: "1rem" }}
              />
              <Button
                type="default"
                onClick={handleResetFilters}
                style={{ marginLeft: "1rem" }}
              >
                Reset Filters
              </Button>
            </div>
            {localStorage.getItem("staffticket") && (
              <div>
                <button
                  onClick={() =>
                    openDynamicModal("Create Appointment", (
                      <AddNewAppointmentStaff onSuccess={() => fetchData(isLabReporter)} />
                    ))
                  }
                  className="px-4 py-1.5 rounded-md bg-sky-500 text-white hover:bg-sky-600"
                >
                  Create Appointment
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{ indicator: <Spin size="large" />, spinning: loading }}
          />
        </div>
      </div>

      {/* Existing Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">

            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
              <appointmentContext.Provider value={contextValue}>
                <ActionAppointmentStaff />
              </appointmentContext.Provider>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => toggleModal(null)}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-500 text-base font-medium text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Dynamic Modal */}
      {isDynamicModalOpen && (
        <DynamicModal
          isOpen={isDynamicModalOpen}
          onClose={closeDynamicModal}
          title={dynamicModalTitle}
        >
          {dynamicModalContent}
        </DynamicModal>
      )}
    </>
  );
};

export default ViewAppointment;