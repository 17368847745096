import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "react-datepicker/dist/react-datepicker.css";
import LoadingScreen from "../lib/constants/loadingScreen";
import { message, Row, Col, Typography, Checkbox } from "antd";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";
import { calculateAge } from "../lib/constants/calculateAge";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "./placeholder.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

// Fallback implementation of calculateAge if the imported one isn’t working
const calculateAgeFallback = (dob) => {
  if (!dob) return "";
  const dobDate = new Date(dob);
  if (isNaN(dobDate.getTime())) return ""; // Invalid date

  const today = new Date();
  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDiff = today.getMonth() - dobDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
    age--;
  }
  return age.toString();
};

const { Title } = Typography;

// Predefined list of common medical problems
const problemOptions = [
  { value: "fever", label: "Fever" },
  { value: "headache", label: "Headache" },
  { value: "cough", label: "Cough" },
  { value: "sore throat", label: "Sore Throat" },
  { value: "fatigue", label: "Fatigue" },
  { value: "abdominal pain", label: "Abdominal Pain" },
  { value: "back pain", label: "Back Pain" },
  { value: "chest pain", label: "Chest Pain" },
  { value: "nausea", label: "Nausea" },
  { value: "vomiting", label: "Vomiting" },
  { value: "diarrhea", label: "Diarrhea" },
  { value: "dizziness", label: "Dizziness" },
  { value: "rash", label: "Rash" },
  { value: "shortness of breath", label: "Shortness of Breath" },
  { value: "joint pain", label: "Joint Pain" },
  { value: "frequent urination", label: "Frequent Urination" },
  { value: "weight loss", label: "Weight Loss" },
  { value: "weight gain", label: "Weight Gain" },
  { value: "allergies", label: "Allergies" },
  { value: "anxiety", label: "Anxiety" },
];

const AddNewAppointmentStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [actionLoading, setActionLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [problems, setProblems] = useState([]);
  const [problemOptionsState, setProblemOptionsState] = useState(problemOptions);
  const [pressure, setPressure] = useState("");
  const [weight, setWeight] = useState("");
  const [address, setAddress] = useState("");
  const [DOB, setDOBinAD] = useState("");
  const [inputAge, setInputAge] = useState("");
  const [patientSelectedId, setPatientSelectedId] = useState(null);
  const [patientSelectedName, setPatientSelectedName] = useState("");
  const [patientStoringValue, setPatientStoringValue] = useState("");
  const [patientSelectedContact, setPatientSelectedContact] = useState("");
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState([]);
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentStoringValue, setDepartmentStoringValue] = useState("");
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [doctorStoringValue, setDoctorStoringValue] = useState("");
  const [time, setTime] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [printChecked, setPrintChecked] = useState(true);

  // Age calculation
  const [isDOBMatched, setIsDOBMatched] = useState(false);
  const [dateBS, setDateBS] = useState("");
  const [dateAD, setDateAD] = useState("");
  const [defaultDateBS, setDefaultDateBS] = useState(null);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    // When DOB changes (e.g., via manual edit or BS to AD conversion), recalculate age
    if (DOB) {
      const age = calculateAge(DOB) || calculateAgeFallback(DOB);
      setInputAge(age);
    }

    // Check if DOB matches the BS-converted AD date
    if (
      DOB &&
      dateAD &&
      dateAD === DOB
    ) {
      setIsDOBMatched(true);
    } else {
      setIsDOBMatched(false);
    }
  }, [DOB, dateAD]);

  // Function to calculate DOB based on age
  const handleAgeChange = (e) => {
    const inputAge = e.target.value;
    setInputAge(inputAge);

    try {
      if (inputAge) {
        const today = new Date();
        const birthYear = today.getFullYear() - inputAge;
        const birthDate = new Date(
          birthYear,
          today.getMonth(),
          today.getDate()
        );
        const calculatedDOB = birthDate.toISOString().split("T")[0];
        setDOBinAD(calculatedDOB);
      } else {
        setDOBinAD("");
      }
    } catch (error) {
      message.error("Please enter valid age.");
    }
  };

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewpatientsstaff`, config)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((patient) => ({
            value: patient._id,
            name_value: `${patient.fullname}`,
            label: `${patient.fullname} (${patient.phone})`,
            contact: patient.phone,
            DOB: patient.DOB,
            DOBinBS: patient.DOBinBS,
            address: patient.address,
          }));
          setPatientDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to get user details:", e);
        message.error("Failed to get user details! Please try again.");
      });
  }, []);

  // Handle changes for patient dropdown
  const handlePatientInputChange = (selectedPatient) => {
    if (selectedPatient) {
      setPatientStoringValue(selectedPatient);
      setPatientSelectedId(selectedPatient.value);
      setPatientSelectedContact(selectedPatient.contact);
      setPatientSelectedName(selectedPatient.name_value);
      setAddress(selectedPatient.address);

      // Set DOB in AD and calculate age
      const convertedDOB = selectedPatient.DOB?.split("T")[0];
      const convertedDOBinBS = selectedPatient.DOBinBS?.split("T")[0];

      setDefaultDateBS(convertedDOBinBS);
      setDOBinAD(convertedDOB);

      // Calculate age from DOB in AD
      if (convertedDOB) {
        const age = calculateAge(convertedDOB) || calculateAgeFallback(convertedDOB);
        setInputAge(age);
      } else {
        setInputAge("");
      }
    } else {
      // Clear fields if no patient is selected
      setPatientStoringValue("");
      setPatientSelectedId(null);
      setPatientSelectedContact("");
      setPatientSelectedName("");
      setAddress("");
      setDefaultDateBS(null);
      setDOBinAD("");
      setInputAge("");
    }
  };

  // Fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`, config)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentStoringValue(selectedDepartment);
      setDepartmentSelectedId(selectedDepartment.value);
      setDoctorStoringValue("");
    }
  };

  // Fetching doctor details based on selected department
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`, config)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
    }
  };

  // Handle problem multi-select changes
  const handleProblemChange = (selectedOptions) => {
    setProblems(selectedOptions || []);
  };

  // Handle creation of new problem options
  const handleProblemCreate = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setProblemOptionsState([...problemOptionsState, newOption]);
    setProblems([...problems, newOption]);
  };

  const createAppointment = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      patientId: patientSelectedId,
      fullname: patientSelectedName,
      mobile: patientSelectedContact,
      address,
      DOB,
      problem: problems.map((p) => p.label).join(", "),
      department: departmentSelectedId,
      doctorId: doctorSelectedId,
      date: appointmentDate,
      time,
      pressure,
      weight,
    };

    axios
      .post(`${apiBaseUrl}/staff/patient/bookDoctorAppointment`, data, config)
      .then((response) => {
        if (response.data.success) {
          const appointmentId = response.data.data._id;
          message.success("Appointment Created Successfully.", 0.6, () => {
            if (printChecked) {
              const printUrl = `/dashboard/printout/consultation/${appointmentId}`;
              window.location.replace(printUrl);
            } else {
              window.location.replace("/dashboard/viewappointment");
            }
          });
        } else {
          message.error("Something went wrong! Please try again.");
        }
      })
      .catch((err) => {
        console.error("Error booking appointment:", err);
        message.error("Error booking appointment! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    setTime(formattedTime);
  };

  const dateBStoAD = () => {
    if (dateAD) {
      setDOBinAD(dateAD);
    } else {
      setInputAge("");
    }
  };

  const handleDateInBS = ({ bsDate, adDate }) => {
    setDateBS(bsDate);
    setDateAD(adDate);
    setDefaultDateBS(bsDate);
  };

  const handleClear = () => {
    setDefaultDateBS(null);
    setDateBS(null);
    setDateAD("");
    setDOBinAD("");
    setInputAge("");
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full max-w-5xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={createAppointment}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Create Appointment</Title>
          </div>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <div>
                <label className="text-base">Patient Name</label>
                <Select
                  options={patientDetails}
                  placeholder="Select patient"
                  onChange={handlePatientInputChange}
                  value={patientStoringValue}
                  isSearchable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0.375rem",
                    }),
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={6}>
              <div>
                <label className="text-base">Contact</label>
                <input
                  value={patientSelectedContact}
                  onChange={(e) => {
                    setPatientSelectedContact(e.target.value);
                  }}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Contact"
                  required
                />
              </div>
            </Col>
            <Col xs={24} sm={6}>
              <div>
                <label className="text-base">Address</label>
                <input
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Address"
                  required
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <div>
                <label className="text-base">DOB (in BS)</label>
                <Calendar
                  key={defaultDateBS}
                  onChange={handleDateInBS}
                  theme="blue"
                  defaultDate={defaultDateBS}
                  hideDefaultValue={!defaultDateBS}
                  language="en"
                  placeholder="Select Date(BS)"
                  className="border border-gray-300 border py-1.5 px-3 rounded-md text-gray-800 custom-placeholder w-full"
                />
                {dateBS && (
                  <AiOutlineCloseCircle
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
                    onClick={handleClear}
                  />
                )}
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div>
                <label className="text-base ms-1">DOB (in AD)</label>
                <input
                  id="DOB"
                  value={DOB}
                  type="date"
                  onChange={(e) => {
                    setDOBinAD(e.target.value);
                  }}
                  max={new Date().toISOString().split("T")[0]}
                  className="border-solid border-gray-300 border h-10 py-2 px-2 w-full rounded-md text-gray-800 date-picker"
                  name="dob"
                  placeholder="Select Date"
                />
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div>
                <label className="text-base ms-1">Age (Years)</label>
                <input
                  onChange={handleAgeChange}
                  value={inputAge}
                  className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-800"
                  name="age"
                  type="number"
                  placeholder="Age"
                />
              </div>
            </Col>
          </Row>

          {!isDOBMatched && dateBS && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="-mt-3 flex justify-end">
                  <p className="text-red-500 italic me-2">
                    The selected DOB (in BS) does not match the DOB (in AD).
                  </p>
                  <Link
                    onClick={dateBStoAD}
                    className="text-blue-500 italic border-b hover:text-blue-600 font-semibold inline-flex items-center"
                  >
                    Click here to correct it.
                  </Link>
                </div>
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <label className="text-base">Problem</label>
              <CreatableSelect
                isMulti
                options={problemOptionsState}
                onChange={handleProblemChange}
                onCreateOption={handleProblemCreate}
                value={problems}
                placeholder="Select or type problems (press Enter to add)"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                    minHeight: "40px",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#e5e7eb",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "#374151",
                  }),
                  multiValueRemove: (provided) => ({
                    ...provided,
                    color: "#ef4444",
                    ":hover": {
                      backgroundColor: "#f3f4f6",
                      color: "#dc2626",
                    },
                  }),
                }}
              />
            </Col>
            <Col xs={24} sm={6}>
              <label className="text-base">Pressure In mmHg</label>
              <input
                onChange={(e) => {
                  setPressure(e.target.value);
                }}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-800"
                name="title"
                placeholder="Pressure"
                value={pressure}
              />
            </Col>
            <Col xs={24} sm={6}>
              <label className="text-base">Weight in KG</label>
              <input
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-800"
                name="title"
                placeholder="Weight"
                value={weight}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <label className="text-base">Department</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={departmentStoringValue}
                onChange={handleDepartmentInputChange}
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
                required
              />
            </Col>
            <Col xs={24} sm={8}>
              <label className="text-base ms-1">Doctor</label>
              <Select
                options={doctorSelectedDetails}
                value={doctorStoringValue}
                onChange={handleDoctorInputChange}
                placeholder="Select doctor"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
                required
              />
            </Col>
            <Col xs={24} sm={4}>
              <label className="text-base">Date</label>
              <input
                id="appointmentDate"
                type="date"
                onChange={(e) => {
                  setAppointmentDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md text-gray-800"
                name="appointmentDate"
                placeholder="Select Date"
                required
              />
            </Col>
            <Col xs={24} sm={4}>
              <div className="w-full">
                <label className="text-base" htmlFor={`appointment-time`}>
                  Time
                </label>
                <Flatpickr
                  data-enable-time
                  data-disable-date
                  placeholder="Pick a time"
                  onChange={(selectedDates, dateStr, flatpickrInstance) =>
                    handleTimeChange(selectedDates, dateStr, flatpickrInstance)
                  }
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md transition-colors duration-200 text-gray-800"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "h:i K",
                    time_24hr: false,
                    allowInput: true,
                    clearIcon: true,
                  }}
                  required
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="pt-2">
                <Checkbox
                  onChange={(e) => setPrintChecked(e.target.checked)}
                  defaultChecked
                >
                  Print Appointment
                </Checkbox>
              </div>
            </Col>
          </Row>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddNewAppointmentStaff;