export const SPONDYLOARTHRITIS = [
    /// Total Duration of Illness
    {
        field: "sdaDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Initial Symptoms Field
    {
        field: "sdaInitialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "Inflammatory low back pain",
            "Suggestive of Dactylitis",
            "Suggestive of Psoriasis",
            "Inflammatory bowel disease",
            "Reactive arthritis",
            "Uveitis",
            "{ Write Something Here }"
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Initial Investigation
    {
        field: "sdaInitialInvestigation",
        label: "Initial Investigation",
        type: "multiselect",
        options: [
            "CRP: High",
            "CRP: Low",
            "HLAB27: +",
            "HLAB27: -",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// X ray SI joint
    {
        field: "sdaXraySiJoint",
        label: "X ray SI joint",
        type: "select",
        options: [
            "Sacroillitis + ({value})",
            "Sacroillitis -",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "Sacroillitis + ({value})",
                ],
                placeholders: [

                    {
                        key: "value",
                        type: "select",
                        label: "Text",
                        options: [
                            "Right",
                            "Left",
                            "Right & Left",
                            "Right more than left",
                            "Left more than right",
                        ]
                    }
                ]
            },
        ],
    },
    /// MRI
    {
        field: "sdaMri",
        label: "MRI",
        type: "select",
        options: [
            "Sacroillitis + ({value})",
            "Sacroillitis -",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "Sacroillitis + ({value})",
                ],
                placeholders: [

                    {
                        key: "value",
                        type: "select",
                        label: "Text",
                        options: [
                            "Right",
                            "Left",
                            "Right & Left",
                            "Right more than left",
                            "Left more than right",
                        ]
                    }
                ]
            },
        ],
    },
    /// Response to NSAIDs
    {
        field: "sdaResponseToNsaids",
        label: "Response to NSAIDs",
        type: "checkbox",
        options: [
            "Yes",
            "No",
        ]
    },
    /// FamilyHistory
    {
        field: "sdaFamilyHistory",
        label: "FamilyHistory",
        type: "checkbox",
        options: [
            "Positive",
            "Negative",
        ]
    },
    /// Initially treated with
    {
        field: "sdaInitiallyTreatedWith",
        label: "Initially treated with",
        type: "multiselect",
        options: [
            "Sulfasalazine",
            "Methotrexate",
            "Tofacitinib",
            "Adalimumab",
            "NSAIDS",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Response To Initial Treatment
    {
        field: "sdaResponseToInitialTreatment",
        label: "Response To Initial Treatment",
        type: "select",
        options: [
            "Good Response",
            "Adverse Reaction",
            "Not Tolorated",
            "No Response",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Subsequent extra articular involvement
    {
        field: "sdaSubsequentExtraArticularInvolvement",
        label: "Subsequent extra articular involvement",
        type: "multiselect",
        options: [
            "Psoriasis",
            "Inflammatory bowel disease",
            "Uveitis",
            "Ankylosis",
            "Tactalytis",
            "Nail Changes",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Screening for
    {
        field: "sdaScreeningFor",
        label: "Screening for",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Diabetes {Done/Not Done/Refused}",
            "Dyslipidemia {Done/Not Done/Refused}",
            "Cardiac comorbidities {Done/Not Done/Refused}",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "Diabetes {Done/Not Done/Refused}",
                    "Dyslipidemia {Done/Not Done/Refused}",
                    "Cardiac comorbidities {Done/Not Done/Refused}",
                ],
                placeholders: [

                    {
                        key: "Done/Not Done/Refused",
                        type: "select",
                        label: "Condition",

                        options: [
                            "Done",
                            "Not Done",
                            "Refused",
                        ]
                    }
                ]
            },
        ]

    },
    /// Comorbidities
    {
        field: "sdaComorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "sdaAnaByIfaIntensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "sdaAnaByIfaTiter",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "{ Write Something Here }",
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "sdaAnaByIfaPattern",
        label: "ANA by-IFA : Pattern",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
            "AC 1", "AC 2", "AC 3", "AC 4", "AC 5", "AC 6", "AC 7", "AC 8", "AC 9", "AC 10",
            "AC 11", "AC 12", "AC 13", "AC 14", "AC 15", "AC 16", "AC 17", "AC 18", "AC 19", "AC 20",
            "AC 21", "AC 22", "AC 23", "AC 24", "AC 25", "AC 26", "AC 27", "AC 28", "AC 29", "AC 30",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Conception Plan Field
    {
        field: "sdaConceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "sdaCompliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "sdaVaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "sdaSmoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "sdaCurrentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Second Opinion",
            "Symptoms suggestive of increased Disease Activity due to non compliance to medicine and medical advices",
            "Inappropriate diagnosis",
            "Inadequate medication",
            "Other Illness",
            "Infection",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
]