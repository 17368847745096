import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Space,
  TreeSelect,
  Select,
  Row,
  Col,
  Typography,
  DatePicker,
  Checkbox, // Added for "Paid in full" checkbox
} from "antd";
import axios from "axios";
import { message } from "antd";
import { useParams } from "react-router-dom"; // Removed useNavigate
import { v4 as uuidv4 } from "uuid";
import { calculateAge } from "../../lib/constants/calculateAge";
import moment from "moment";

const { Option } = Select;
const { Title } = Typography;

const UpdateTestBill = ({ billId, onSuccess }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [testCategoryOptions, setTestCategoryOptions] = useState([]);
  const [testData, setTestData] = useState([]);
  const [billingTableData, setBillTableData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [form] = Form.useForm();
  const [fetchedDOB, setDOB] = useState("");
  const [selectedTest, setSelectedTest] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [totalGross, setTotalGross] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [isPaidInFull, setIsPaidInFull] = useState(false); // Added state for "Paid in full" checkbox

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    const calculatedAge = calculateAge(fetchedDOB);
    if (calculatedAge !== undefined && calculatedAge !== "Invalid date of birth") {
      form.setFieldsValue({ age: calculatedAge });
    } else {
      form.setFieldsValue({ age: null });
    }
  }, [fetchedDOB]);

  useEffect(() => {
    let grossSum = 0;
    let netSum = 0;
    billingTableData.forEach((item) => {
      grossSum += parseFloat(item.gross);
      netSum += parseFloat(item.net);
    });
    setTotalGross(grossSum.toFixed(2));
    setTotalNet(netSum.toFixed(2));
  }, [billingTableData]);

  // Added effect to handle "Paid in full" checkbox
  useEffect(() => {
    if (isPaidInFull) {
      form.setFieldsValue({ amountPaid: totalNet });
    }
  }, [isPaidInFull, totalNet]);

  useEffect(() => {
    fetchAppointment();
    fetchTestCategories();
    fetchBillDetails();
  }, []);

  const fetchBillDetails = () => {
    axios
      .get(`${apiBaseUrl}/test-bills/${billId}`, config)
      .then((response) => {
        if (response.data.success) {
          const billData = response.data.data;
          form.setFieldsValue({
            appointment: billData.appointment?._id,
            patient: billData.patient,
            userID: billData.appointment?.patientId?.userID,
            DOB: billData.patient.DOB?.split("T")[0],
            dueDate: moment(billData.dueDate),
            amountPaid: billData.amountPaid,
            paymentMethod: billData.paymentMethod,
            paymentStatus: billData.paymentStatus,
          });
          setDOB(billData.patient.DOB?.split("T")[0]);

          const updatedItems = billData.test.map((item) => ({
            key: item._id || uuidv4(),
            name: item.name,
            price: item.price,
            discount: item.discount + "%",
            net: item.netAmount,
            gross: item.grossAmount,
          }));
          setBillTableData(updatedItems);
        }
      })
      .catch((error) => {
        console.error("Error fetching bill details:", error);
        message.error("Failed to fetch bill details.");
      });
  };

  const fetchAppointment = () => {
    axios
      .get(`${apiBaseUrl}/appointment`, config)
      .then((response) => {
        if (response.data.success) {
          setAppointments(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
      });
  };

  const fetchTestCategories = () => {
    axios
      .get(`${apiBaseUrl}/medical_test/categories`, config)
      .then((response) => {
        if (response.data.success) {
          setTestCategoryOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching test categories:", error);
      });
  };

  const fetchPatientDetails = (appointmentId) => {
    axios
      .get(`${apiBaseUrl}/appointment/${appointmentId}`, config)
      .then((response) => {
        if (response.data.success) {
          setDOB(response.data.data.DOB?.split("T")[0]);
          form.setFieldsValue({
            patient: response.data.data,
            DOB: response.data.data.DOB?.split("T")[0],
            userID: response.data.data.patientId?.userID,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching patient details:", error);
      });
  };

  const fetchTestDetails = (categoryId) => {
    axios
      .get(`${apiBaseUrl}/lists/medical_tests?category=${categoryId}`, config)
      .then((response) => {
        if (response.data.success) {
          const formattedData = response.data.data.map((test) => ({
            title: test.name,
            price: test.price,
            value: test._id,
            children: test.subtitle.map((sub) => ({
              title: sub.name,
              value: sub._id,
              price: sub.price,
              parentName: test.name,
            })),
          }));
          setTestData(formattedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching test details:", error);
      });
  };

  const handleAppointmentChange = (appointmentId) => {
    fetchPatientDetails(appointmentId);
  };

  const handleCategoryChange = (categoryId) => {
    fetchTestDetails(categoryId);
  };

  const handleTestSelect = (value, label, extra) => {
    const { triggerNode } = extra;
    const selectedPrice = triggerNode?.props?.price;
    setSelectedTest(label);
    form.setFieldsValue({ price: selectedPrice });
  };

  const handleAddOrUpdateObject = () => {
    const values = form.getFieldsValue(["name", "price", "discount"]);
    if (selectedTest != null && values.price != null) {
      const discountPercentage = values.discount || 0;
      const discountedPrice = values.price * (1 - discountPercentage / 100);
      const netTotal = parseFloat(discountedPrice).toFixed(2);
      const grossTotal = parseFloat(values.price).toFixed(2);

      if (editingIndex !== null) {
        const updatedTableData = billingTableData.map((item, index) => {
          if (index === editingIndex) {
            return {
              ...item,
              name: values.name,
              price: parseFloat(values.price).toFixed(2),
              discount: discountPercentage + "%",
              net: netTotal,
              gross: grossTotal,
            };
          }
          return item;
        });
        setBillTableData(updatedTableData);
        setEditingIndex(null);
        setEditingItem(null);
      } else {
        const newItem = {
          key: uuidv4(),
          name: selectedTest,
          price: parseFloat(values.price).toFixed(2),
          discount: discountPercentage + "%",
          net: netTotal,
          gross: grossTotal,
        };
        setBillTableData([...billingTableData, newItem]);
      }
      form.resetFields(["name", "price", "discount"]);
      setSelectedTest(null);
    } else {
      message.warning("Please select name and price.");
    }
  };

  const handleEditItem = (index, item) => {
    setEditingIndex(index);
    setEditingItem(item);
    form.setFieldsValue({
      name: item.name,
      price: item.price,
      discount: parseFloat(item.discount),
    });
    setSelectedTest(item.name);
  };

  const handleDeleteItem = (key) => {
    const updatedTableData = billingTableData.filter((item) => item.key !== key);
    setBillTableData(updatedTableData);
  };

  const filterTreeNode = (inputValue, treeNode) => {
    const { title, children, parentName } = treeNode;
    if (!children) {
      return title.toLowerCase().includes(inputValue.toLowerCase());
    }
    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) ||
      (parentName && parentName.toLowerCase().includes(inputValue.toLowerCase()))
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Discount (%)",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Gross",
      dataIndex: "gross",
      key: "gross",
    },
    {
      title: "Net",
      dataIndex: "net",
      key: "net",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <Space size="middle">
          <a onClick={() => handleEditItem(index, record)}>Edit</a>
          <a
            onClick={() => handleDeleteItem(record.key)}
            className="text-red-500 hover:text-red-600"
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    const { appointment, patient, DOB, dueDate, amountPaid, paymentMethod, paymentStatus } = values;
    const { fullname, gender, mobile, address } = patient;

    const test = billingTableData.map((item) => ({
      name: item.name,
      price: parseFloat(item.price),
      grossAmount: parseFloat(item.gross),
      discount: parseFloat(item.discount?.split("%")[0]) || 0,
      netAmount: parseFloat(item.net),
    }));

    const data = {
      appointment,
      test,
      patient: {
        fullname,
        DOB,
        gender,
        mobile,
        address,
      },
      totalGross: parseFloat(totalGross),
      totalNet: parseFloat(totalNet),
      dueDate: dueDate ? dueDate.format("YYYY-MM-DD") : undefined,
      amountPaid: amountPaid ? parseFloat(amountPaid) : 0,
      paymentMethod: paymentMethod || null,
      paymentStatus: paymentStatus || "Pending",
    };

    if (test.length !== 0) {
      axios
        .put(`${apiBaseUrl}/test-bills/${billId}`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success("Bill updated successfully!");
            if (onSuccess) {
              onSuccess();
            }
          }
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          message.error("Failed to update bill. Please try again later.");
        });
    } else {
      message.error("Test table cannot be empty.");
    }
  };

  return (
    <div className="bg-white rounded-md px-5 pt-4 pb-1 shadow-md">
      <div className="font-medium text-sky-600 text-4xl text-center mb-2">
        Update Bill
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ paymentMethod: "Cash" }} // Default payment method to "Cash"
      >
        <Title level={3}>Patient Details</Title>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item label="Select Appointment" name="appointment">
              <Select
                placeholder="Select an appointment"
                showSearch
                onChange={handleAppointmentChange}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {appointments.map((appointment) => (
                  <Option key={appointment._id} value={appointment._id}>
                    {appointment.appointmentNumber}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Patient Name"
              name={["patient", "fullname"]}
              rules={[{ required: true, message: "Please enter patient name" }]}
            >
              <Input placeholder="Enter fullname" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="DOB" name="DOB">
              <input
                type="date"
                onChange={(e) => setDOB(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                name="dob"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Age" name="age">
              <Input placeholder="Select DOB for age." readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item label="UserID" name="userID">
              <Input placeholder="Empty for non-registered users" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Mobile"
              name={["patient", "mobile"]}
              rules={[{ required: true, message: "Please enter patient mobile number" }]}
            >
              <Input placeholder="Enter contact" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Gender"
              name={["patient", "gender"]}
              rules={[{ required: true, message: "Please enter patient gender" }]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Address"
              name={["patient", "address"]}
              rules={[{ required: true, message: "Please enter patient address" }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
        </Row>

        <Title level={3}>Test/Consult</Title>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item label="Category" name="category">
              <Select
                placeholder="Select a category"
                showSearch
                allowClear
                onChange={handleCategoryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {testCategoryOptions.map((category, index) => (
                  <Option key={index} value={category._id}>
                    {category.category}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Name" name="name">
              <TreeSelect
                treeData={testData}
                placeholder="Select a name"
                showSearch
                filterTreeNode={filterTreeNode}
                allowClear
                onChange={handleTestSelect}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Price" name="price">
              <Input type="text" placeholder="Enter price" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Discount (%)" name="discount">
              <Input type="number" placeholder="Enter discount %" />
            </Form.Item>
          </Col>
          <Col span={4} style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={handleAddOrUpdateObject}
              className="bg-gray-200 text-black rounded shadow-md"
            >
              {editingIndex !== null ? "Update" : "Add Object"}
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={billingTableData}
          pagination={false}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{totalGross}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{totalNet}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
            </Table.Summary.Row>
          )}
        />

        <Title level={3}>Payment Details</Title>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item label="Due Date" name="dueDate">
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Paid in full">
              <Checkbox
                checked={isPaidInFull}
                onChange={(e) => setIsPaidInFull(e.target.checked)}
              >
                Paid in full
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Amount Paid" name="amountPaid">
              <Input
                type="number"
                placeholder="Enter amount paid"
                min={0}
                disabled={isPaidInFull}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Payment Method" name="paymentMethod">
              <Select placeholder="Select payment method" allowClear>
                <Option value="Cash">Cash</Option>
                <Option value="Card">Card</Option>
                <Option value="Bank Transfer">Bank Transfer</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
            <button
              id="updateBtn"
              className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow py-2 px-4 font-semibold text-md rounded-md"
              type="submit"
              style={{ width: "200px" }}
            >
              Update Bill
            </button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateTestBill;