import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Modal, Select } from "antd";

const ReferredDepartmentByStaff = ({
  appointmentId,
  full_name,
  problem,
  departmentCheckIfInReferred,
  doctorCheckIfInReferred,
  onClose,
}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [actionLoading, setActionLoading] = useState(false);
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState([]);
  const [departmentToSelectedId, setDepartmentToSelectedId] = useState("");

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") || localStorage.getItem("staffticket")),
    },
  };

  // Fetch department details on component mount
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`, config)
      .then((response) => {
        if (response.data.success) {
          setDepartmentSelectedDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  // Handle form submission with confirmation
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!departmentToSelectedId) {
      message.error("Please select a department to refer to.");
      return;
    }
    Modal.confirm({
      title: "Are you sure you want to refer this appointment?",
      content: "This action cannot be undone.",
      okText: "Yes, refer",
      cancelText: "No, cancel",
      onOk: async () => {
        setActionLoading(true);
        const data = { assigningDepartment: departmentToSelectedId };
        try {
          const response = await axios.post(
            `${apiBaseUrl}/staff/appointment/referDepartment/${appointmentId}`,
            data,
            config
          );
          if (response.data.msg === "Department assigned") {
            message.success("Patient referred successfully", 0.6, () => {
              window.location.reload(); // Reload the entire window
            });
          } else {
            message.error("Failed to refer department! Please try again.");
          }
        } catch (err) {
          console.error("Failed to refer department: ", err);
          message.error("Failed to refer department! Please try again.");
        } finally {
          setActionLoading(false);
        }
      },
    });
  };

  return (
    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow">
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold">Refer Department</h2>
        <p className="text-base">
          Patient Name: <span className="text-gray-700 italic">{full_name}</span>
        </p>
        <p className="text-base">
          Problem: <span className="text-gray-700 italic">{problem}</span>
        </p>
        <div>
          <label className="text-base">Department (FROM)</label>
          <input
            type="text"
            value={departmentCheckIfInReferred}
            disabled
            className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-700"
          />
        </div>
        <div>
          <label className="text-base">Doctor (FROM)</label>
          <input
            type="text"
            value={doctorCheckIfInReferred}
            disabled
            className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-700"
          />
        </div>
        <div>
          <label className="text-base">Department (REFER TO)</label>
          <Select
            placeholder="Select department"
            value={departmentToSelectedId}
            onChange={setDepartmentToSelectedId}
            style={{ width: "100%" }}
          >
            {departmentSelectedDetails.map((dept) => (
              <Select.Option key={dept._id} value={dept._id}>
                {dept.department}
              </Select.Option>
            ))}
          </Select>
        </div>
        <button
          type="submit"
          disabled={actionLoading}
          className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg disabled:bg-gray-400"
        >
          {actionLoading ? "Referring..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ReferredDepartmentByStaff;