import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Space,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import axios from "axios";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { calculateAge } from "../../lib/constants/calculateAge";

const { Option } = Select;
const { Title } = Typography;

const AddBill = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [medicineTableData, setMedicineTableData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // New state for editing index
  const [selectedTest, setSelectedTest] = useState(null);

  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [form] = Form.useForm();
  const [fetchedDOB, setDOB] = useState("");
  const [totalGross, setTotalGross] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  let calculatedAge;

  // Function to get the appropriate authorization header
  const getAuthConfig = () => {
    // List of possible tickets in order of priority (you can adjust the order)
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
      // Add other ticket types as needed
    ];

    // Find the first available ticket
    const availableTicket = ticketTypes.find(ticket => localStorage.getItem(ticket.key));

    // If no ticket is found, you can either return a default config or throw an error
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} }; // Return config without Authorization header
      // Or throw an error: throw new Error("No authentication ticket available");
    }

    // Return config with the found ticket
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`
      }
    };
  };

  // Usage example
  const config = getAuthConfig();

  useEffect(() => {
    calculatedAge = calculateAge(fetchedDOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      form.setFieldsValue({ age: calculatedAge });
    } else {
      form.setFieldsValue({ age: null });
    }
  }, [fetchedDOB]);

  useEffect(() => {
    let grossSum = 0;
    let netSum = 0;

    medicineTableData.forEach((item) => {
      grossSum += parseFloat(item.gross);
      netSum += parseFloat(item.net);
    });

    setTotalGross(grossSum?.toFixed(2));
    setTotalNet(netSum?.toFixed(2));
  }, [medicineTableData]);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/appointment`, config)
      .then((response) => {
        if (response.data.success) {
          setAppointments(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/medicine`, config)
      .then((response) => {
        if (response.data.success) {
          setMedicineOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine:", error);
      });
  }, []);

  const fetchPatientDetails = (appointmentId) => {
    axios
      .get(`${apiBaseUrl}/appointment/${appointmentId}`, config)
      .then((response) => {
        if (response.data.success) {
          setSelectedPatient(response.data.data);
          setDOB(response.data.data.DOB?.split("T")[0]);
          form.setFieldsValue({
            patient: response.data.data,
            DOB: response.data.data.DOB?.split("T")[0],
            userID: response.data.data.patientId,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching patient details:", error);
      });
  };

  const handleAppointmentChange = (appointmentId) => {
    setSelectedAppointment(appointmentId);
    fetchPatientDetails(appointmentId);
  };

  const handleAddMedicine = () => {
    const values = form.getFieldsValue(["medicine", "quantity", "price", "discount"]);
    if (values.medicine != null && values.quantity != null) {
      const selectedMedicine = medicineOptions.find(
        (med) => med._id === values.medicine
      );
      const discountPercentage = values.discount || 0;
      const discountedPrice =
        selectedMedicine.price * (1 - discountPercentage / 100);
      const netTotal = discountedPrice * values.quantity;
      const grossTotal = selectedMedicine.price * values.quantity;
      if (editingIndex !== null) {

        const updatedTableData = medicineTableData.map((item, index) => {
          if (index === editingIndex) {
            return {
              ...item,
              medicine: selectedMedicine.name,
              quantity: values.quantity,
              price: selectedMedicine.price?.toFixed(2),
              discount: discountPercentage + "%",
              net: netTotal?.toFixed(2),
              gross: grossTotal?.toFixed(2),
            };
          }
          return item;
        });
        setMedicineTableData(updatedTableData);
        setEditingIndex(null);
      } else {

        const newItem = {
          key: uuidv4(),
          medicineId: selectedMedicine._id,
          medicine: selectedMedicine.name,
          quantity: values.quantity,
          price: selectedMedicine.price?.toFixed(2),
          discount: discountPercentage + "%",
          net: netTotal?.toFixed(2),
          gross: grossTotal?.toFixed(2),
        };
        setMedicineTableData([...medicineTableData, newItem]);
        form.setFieldsValue({
          items: [...medicineTableData, newItem],
        });
      }
      form.resetFields(["medicine", "quantity", "price", "discount"]);
    } else {
      message.warning("Please select a medicine and quantity.");
    }
  };
  const handleEditItem = (index, item) => {
    setEditingIndex(index);
    console.log(item)
    form.setFieldsValue({
      medicine: item.medicineId,
      quantity: item.quantity,
      price: item.price,
      discount: parseFloat(item.discount),
    });

    setSelectedTest(item.medicine);
  };


  const handleDeleteMedicine = (key) => {
    const updatedTableData = medicineTableData.filter(
      (item) => item.key !== key
    );
    setMedicineTableData(updatedTableData);
  };

  const columns = [
    {
      title: "Medicine",
      dataIndex: "medicine",
      key: "medicine",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price/quantity",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Discount (%)",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Gross",
      dataIndex: "gross",
      key: "gross",
    },
    {
      title: "Net",
      dataIndex: "net",
      key: "net",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <Space size="middle">
          <a onClick={() => handleEditItem(index, record)}>Edit</a>
          <a
            onClick={() => handleDeleteMedicine(record.key)}
            className="text-red-500 hover:text-red-600"
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    const { appointment, patient, DOB } = values;
    const { fullname, gender, mobile, address } = patient;

    const items = medicineTableData.map((item) => ({
      // medicine: item.medicineId,
      // quantity: parseInt(item.quantity),
      // price: parseInt(item.price),
      // netAmount: parseInt(item.net),
      // grossAmount: parseInt(item.gross),

      medicine: item.medicineId,
      quantity: parseInt(item.quantity),
      price: parseInt(item.price),
      grossAmount: parseInt(item.gross),
      discount: parseInt(item.discount?.split("%")[0]),
      netAmount: parseInt(item.net),

    }));

    const data = {
      appointment,
      regNo: "REGNO123414",
      items,

      patient: {
        fullname,
        DOB,
        gender,
        mobile,
        address,
      },
      totalGross,
      totalNet,
    };

    if (items.length !== 0) {
      axios
        .post(`${apiBaseUrl}/bills`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success(
              "Bill added successfully!",
              0.6,
              function onClose() {
                navigate(
                  `/dashboard/pharmacy/print-invoice/${response.data.data._id}`
                );
              }
            );
          }
        })
        .catch((error) => {
          console.error("Error posting data:", error);
          message.error("Failed to add bill. Please try again later.");
        });
    } else {
      message.error("Medicine table cannot be empty.");
    }
  };

  return (
    <div className="bg-white rounded-md px-5 pt-4 pb-1 shadow-md">
      <div className="font-medium text-sky-600 text-4xl text-center mb-2">
        Create Medicine Bill
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Title level={3}>Patient Details</Title>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item
              label="Select Appointment"
              name="appointment"
              rules={[{ message: "Please select an appointment" }]}
            >
              <Select
                placeholder="Select an appointment"
                showSearch
                onChange={handleAppointmentChange}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {appointments.map((appointment) => (
                  <Option key={appointment._id} value={appointment._id}>
                    {appointment.appointmentNumber}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Patient Name"
              name={["patient", "fullname"]}
              rules={[{ required: true, message: "Please enter patient name" }]}
            >
              <Input placeholder="Enter fullname" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="DOB"
              name="DOB"
              rules={[{ required: true, message: "Please select DOB" }]}
            >
              <input
                type="date"
                onChange={(e) => setDOB(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                name="dob"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Age"
              name="age"
              rules={[
                { required: false, message: "Please select DOB for age." },
              ]}
            >
              <Input placeholder="Select DOB for age." readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item
              label="UserID"
              name={["userID", "userID"]}
              rules={[{ message: "UserID" }]}
            >
              <Input placeholder="Empty for non-registered users" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Mobile"
              name={["patient", "mobile"]}
              rules={[
                {
                  required: true,
                  message: "Please enter patient mobile number",
                },
              ]}
            >
              <Input placeholder="Enter contact" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label="Gender"
              name={["patient", "gender"]}
              rules={[
                { required: true, message: "Please enter patient gender" },
              ]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Address"
              name={["patient", "address"]}
              rules={[
                { required: true, message: "Please enter patient address" },
              ]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
        </Row>

        <Title level={3}>Medicine</Title>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              label="Medicine"
              name="medicine"
              rules={[{ message: "Please select a medicine" }]}
            >
              <Select
                placeholder="Select a medicine"
                onChange={
                  (value) => {
                    const selectedMedicine = medicineOptions.find(
                      (med) => med._id === value
                    );
                    form.setFieldsValue({
                      price: selectedMedicine.price?.toFixed(2),
                    })
                  }
                }
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {medicineOptions.map((med, index) => (
                  <Option key={index} value={med._id}>
                    {med.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[{ message: "Please enter quantity" }]}
            >
              <Input type="number" placeholder="Enter medicine quantity" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Price" name="price">
              <Input type="number" placeholder="Enter Price" readOnly />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Discount (%)" name="discount">
              <Input type="number" placeholder="Enter discount percentage" />
            </Form.Item>
          </Col>
          <Col span={4} style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={handleAddMedicine}
              className="bg-gray-200 text-black rounded shadow-md"

            >
              {editingIndex !== null ? "Update" : "Add Object"}
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={medicineTableData}
          pagination={false}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={4}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{totalGross}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{totalNet}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
            </Table.Summary.Row>
          )}
        />

        <Form.Item>
          <div
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              id="addStaffBtn"
              className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow py-2 px-4 font-semibold text-md rounded-md" // Add rounded-md className for rounded corners
              type="submit"
              style={{ width: "200px" }}
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddBill;
