import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    message,
    Space,
    InputNumber,
    Select,
    Row,
    Col,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const { Option } = Select;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const BankTransactionPage = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingTransaction, setEditingTransaction] = useState(null);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);
    const [pagination, setPagination] = useState({
        current: 1, // Explicitly start at page 1
        pageSize: 10,
        total: 0,
    });
    const [netTotal, setNetTotal] = useState(0);
    const navigate = useNavigate(); // Initialize useNavigate

    // Axios config with authentication
    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    // Fetch bank transactions from the backend
    const fetchTransactions = async (page = 1, pageSize = 10) => {
        setLoading(true);
        console.log("Fetching page:", page); // Debug log for page number
        try {
            const params = {
                page,
                limit: pageSize,
                search: searchText || undefined,
                startDate: dateRange[0]
                    ? dateRange[0].startOf("day").toISOString()
                    : undefined,
                endDate: dateRange[1]
                    ? dateRange[1].endOf("day").toISOString()
                    : undefined,
            };
            const response = await axios.get(`${apiBaseUrl}/bank-transactions`, { ...config, params });
            const fetchedTransactions = response.data.data || [];
            setTransactions(fetchedTransactions);

            // Calculate net total: sum of all amounts (assuming Deposits only)
            const calculatedNetTotal = fetchedTransactions.reduce((sum, txn) => sum + txn.amount, 0);
            setNetTotal(calculatedNetTotal);

            setPagination({
                current: page,
                pageSize,
                total: response.data.total || fetchedTransactions.length,
            });
        } catch (error) {
            message.error(error.response?.data?.message || "Failed to fetch transactions");
            setTransactions([]);
            console.error("Error fetching transactions:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch transactions on mount and when filters change, resetting to page 1
    useEffect(() => {
        setPagination((prev) => ({ ...prev, current: 1 })); // Reset to page 1
        fetchTransactions(1, pagination.pageSize);
    }, [searchText, dateRange]);

    // Handle adding/updating a transaction
    const handleSaveTransaction = async (values) => {
        const transactionData = {
            ...values,
            date: values.date.toISOString(),
            type: "Deposit", // Hardcode as Deposit to match bank behavior
            sourceId: values.sourceId || null, // Backend can populate this if needed
        };

        setLoading(true);
        try {
            let transactionId;
            if (editingTransaction) {
                await axios.put(`${apiBaseUrl}/bank-transactions/${editingTransaction._id}`, transactionData, config);
                message.success("Transaction updated successfully");
                transactionId = editingTransaction._id;
            } else {
                const response = await axios.post(`${apiBaseUrl}/bank-transactions`, transactionData, config);
                transactionId = response.data._id;
                message.success("Transaction added successfully");
            }
            await fetchTransactions(pagination.current, pagination.pageSize);
            setIsModalOpen(false);
            setEditingTransaction(null);
            form.resetFields();
        } catch (error) {
            message.error(error.response?.data?.message || "Failed to save transaction");
            console.error("Error saving transaction:", error);
        } finally {
            setLoading(false);
        }
    };

    // Handle deleting a transaction
    const handleDeleteTransaction = (id) => {
        Modal.confirm({
            title: "Are you sure you want to delete this transaction?",
            onOk: async () => {
                setLoading(true);
                try {
                    await axios.delete(`${apiBaseUrl}/bank-transactions/${id}`, config);
                    message.success("Transaction deleted successfully");
                    await fetchTransactions(pagination.current, pagination.pageSize);
                } catch (error) {
                    message.error(error.response?.data?.message || "Failed to delete transaction");
                    console.error("Error deleting transaction:", error);
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    // Open modal for adding/editing a transaction
    const showModal = (transaction = null) => {
        setEditingTransaction(transaction);
        if (transaction) {
            form.setFieldsValue({
                ...transaction,
                date: moment(transaction.date),
            });
        } else {
            form.resetFields();
        }
        setIsModalOpen(true);
    };

    // Table columns with clickable Description
    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: (date) => moment(date).format("YYYY-MM-DD"),
        },
        {
            title: "Type",
            dataIndex: "type",
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            sorter: (a, b) => a.amount - b.amount,
            render: (amount) => `$${amount.toFixed(2)}`,
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (description, record) => {
                console.log("Description:", description, "SourceModel:", record.sourceModel); // Debug log
                if (record.sourceModel === "TestBill") {
                    const match = description.match(/Patient Payment\s*\(?Test(?:Bill)?\s*#\s*(\w+)\)?/i); // Updated regex from CashTransactionPage
                    const testBillNumber = match ? match[1] : null;
                    console.log("Match:", match, "TestBillNumber:", testBillNumber); // Additional debug
                    if (testBillNumber) {
                        return (
                            <a
                                onClick={() => {
                                    navigate(`/dashboard/get/generated/bills?search=${testBillNumber}`);
                                }}
                                style={{ color: "#1890ff", cursor: "pointer" }}
                            >
                                {description}
                            </a>
                        );
                    }
                }
                // Placeholder for other source models (can be expanded as needed)
                return description || "-";
            },
        },
        {
            title: "Bank Account",
            dataIndex: "bankAccount",
        },
        {
            title: "Source Model",
            dataIndex: "sourceModel",
        },
    ];

    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Bank Transactions</h1>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col>
                    <Space>
                        <Input
                            placeholder="Search by description, reference, or bank account"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            prefix={<SearchOutlined />}
                            style={{ width: 250 }}
                        />
                        <DatePicker.RangePicker
                            value={dateRange}
                            onChange={(dates) => setDateRange(dates)}
                            format="YYYY-MM-DD"
                        />
                        <Button
                            type="default"
                            onClick={() => {
                                setSearchText("");
                                setDateRange([null, null]);
                                setPagination((prev) => ({ ...prev, current: 1 })); // Reset to page 1
                                fetchTransactions(1, pagination.pageSize);
                            }}
                        >
                            Reset Filters
                        </Button>
                    </Space>
                </Col>
            </Row>
            <div style={{ marginBottom: 16, fontSize: 16, fontWeight: "bold" }}>
                Net Total: ${netTotal.toFixed(2)}
            </div>
            <Table
                columns={columns}
                dataSource={transactions}
                rowKey="_id"
                loading={loading}
                pagination={{
                    ...pagination,
                    onChange: (page, pageSize) => {
                        setPagination((prev) => ({ ...prev, current: page, pageSize }));
                        fetchTransactions(page, pageSize);
                    },
                }}
            />
            <Modal
                title={editingTransaction ? "Edit Transaction" : "Add Transaction"}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    setEditingTransaction(null);
                    form.resetFields();
                }}
                footer={null}
                width={600}
            >
                <Form form={form} layout="vertical" onFinish={handleSaveTransaction}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="date"
                                label="Date"
                                rules={[{ required: true, message: "Please select a date" }]}
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label="Amount"
                                rules={[{ required: true, message: "Please enter amount" }]}
                            >
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="bankAccount"
                                label="Bank Account"
                                rules={[{ required: true, message: "Please enter bank account" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="reference" label="Reference">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="sourceModel"
                                label="Source Model"
                                rules={[{ required: true, message: "Please select source model" }]}
                            >
                                <Select placeholder="Select source model" style={{ width: "100%" }}>
                                    <Option value="Bill">Bill</Option>
                                    <Option value="TestBill">TestBill</Option>
                                    <Option value="AccountsPayable">AccountsPayable</Option>
                                    <Option value="AccountsReceivable">AccountsReceivable</Option>
                                    <Option value="Expense">Expense</Option>
                                    <Option value="Income">Income</Option>
                                    <Option value="Payroll">Payroll</Option>
                                    <Option value="TaxManagement">TaxManagement</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="description" label="Description">
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            {editingTransaction ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default BankTransactionPage;