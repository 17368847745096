import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { appointmentContext } from "../../pages/viewCompletedAppointmentsStaff";
import { message } from "antd";
import ViewReportByAppointmentId from "../../pages/ViewReportWrtAppointment"; // Adjust path
import OnlyViewDiagnosis from "../../pages/diagnosis/onlyViewDiagnosis"; // Adjust path
import OnlyViewAdvice from "../../pages/advice/onlyViewAdvice"; // Adjust path
import OnlyViewSummary from "../../pages/summary/onlyViewSummary"; // Adjust path
import ViewPrescription from "../../pages/viewPrescription";
import AddFollowUpDateTime from "./addFollowUpDateTime";

const EndedActionAppointment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    appointmentId,
    appointmentNumber,
    full_name,
    problem,
    report,
    status,
    appointmentHistory,
    referDoctorAvailable,
    openDynamicModal,
  } = useContext(appointmentContext);
  const navigate = useNavigate();
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") || localStorage.getItem("staffticket")),
    },
  };

  const handleButtonClick = () => {
    if (status === "Ended" && referDoctorAvailable === null) {
      updateAppointmentStatus();
    } else if (status === "Ended" && (referDoctorAvailable === undefined || referDoctorAvailable !== null)) {
      openDynamicModal("Arrange Follow-Up", <AddFollowUpDateTime appointmentId={appointmentId} />);
    }
  };

  const updateAppointmentStatus = () => {
    const appointment_status = "Referred";
    if (window.confirm("Are you sure want to change this appointment status?")) {
      setActionLoading(true);
      const data = { appointmentStatus: appointment_status };
      axios
        .put(`${apiBaseUrl}/staff/updateAppointmentStatus/${appointmentId}`, data, config)
        .then((response) => {
          if (response.data.msg === "Updated Status") {
            message.success("Appointment Referred", 0.6, () => window.location.reload());
          } else {
            message.error("Failed To Update status");
          }
        })
        .catch((err) => {
          console.error("Error updating appointment status:", err);
          message.error("Failed to update appointment status! Please try again.");
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="bg-white px-4 pt-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:text-left">
            <h1 className="text-3xl leading-6 font-medium text-center text-gray-900" id="modal-title">
              Actions
            </h1>
            <div>
              <p className="text-sm text-gray-500 mx-5">
                <div className="py-2 mx-5">
                  <ul className="my-4 space-y-3 mx-8">
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Report", (
                            <ViewReportByAppointmentId
                              report={report}
                              appointmentNumber={appointmentNumber}
                              full_name={full_name}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Report</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Prescription", (
                            <ViewPrescription
                              full_name={full_name}
                              problem={problem}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Prescription</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Diagnosis", (
                            <OnlyViewDiagnosis
                              full_name={full_name}
                              problem={problem}
                              appointmentNumber={appointmentNumber}
                              appointmentHistory={appointmentHistory}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Diagnosis</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Advices", (
                            <OnlyViewAdvice
                              full_name={full_name}
                              problem={problem}
                              appointmentNumber={appointmentNumber}
                              appointmentHistory={appointmentHistory}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Advices</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Summary", (
                            <OnlyViewSummary
                              full_name={full_name}
                              problem={problem}
                              appointmentNumber={appointmentNumber}
                              appointmentHistory={appointmentHistory}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Summary</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={handleButtonClick}
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">
                          {referDoctorAvailable != null || referDoctorAvailable === undefined ? "Arrange Follow-Up" : "Arrange Refer (date)"}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EndedActionAppointment;