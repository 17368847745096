import React, { useState, useEffect } from "react";
import {
    Form,
    Input,
    Button,
    Table,
    Space,
    TreeSelect,
    Select,
    Row,
    Col,
    Typography,
    DatePicker,
    Checkbox, // Added Checkbox import
} from "antd";
import axios from "axios";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { calculateAge } from "../../lib/constants/calculateAge";

const { Option } = Select;
const { Title } = Typography;

const CreateTestBill = ({ onSuccess }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [testCategoryOptions, setTestCategoryOptions] = useState([]);
    const [testData, setTestData] = useState([]);
    const [otherTestsData, setOtherTestsData] = useState([]);
    const [billingTableData, setBillTableData] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [form] = Form.useForm();
    const [fetchedDOB, setDOB] = useState("");
    const [selectedTest, setSelectedTest] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingItem, setEditingItem] = useState(null);
    const [totalGross, setTotalGross] = useState(0);
    const [totalNet, setTotalNet] = useState(0);
    const [isPaidInFull, setIsPaidInFull] = useState(false); // Added state for "Paid in full" checkbox
    let calculatedAge;

    // Dynamic config based on available tickets
    const getAuthConfig = () => {
        const ticketTypes = [
            { key: "adminticket", name: "admin" },
            { key: "staffticket", name: "staff" },
            { key: "doctorticket", name: "doctor" },
            { key: "pharmaticket", name: "pharma" },
            { key: "labreporterticket", name: "labreporter" },
        ];

        const availableTicket = ticketTypes.find((ticket) =>
            localStorage.getItem(ticket.key)
        );

        if (!availableTicket) {
            console.warn("No authentication ticket found in localStorage");
            return { headers: {} };
        }

        return {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
            },
        };
    };

    const config = getAuthConfig();

    // Calculate age based on DOB
    useEffect(() => {
        calculatedAge = calculateAge(fetchedDOB);
        if (
            calculatedAge !== undefined &&
            calculatedAge !== "Invalid date of birth"
        ) {
            form.setFieldsValue({ age: calculatedAge });
        } else {
            form.setFieldsValue({ age: null });
        }
    }, [fetchedDOB]);

    // Calculate total gross and net amounts
    useEffect(() => {
        let grossSum = 0;
        let netSum = 0;
        billingTableData.forEach((item) => {
            grossSum += parseFloat(item.gross);
            netSum += parseFloat(item.net);
        });
        setTotalGross(grossSum?.toFixed(2));
        setTotalNet(netSum?.toFixed(2));
    }, [billingTableData]);

    // Added effect to handle "Paid in full" checkbox
    useEffect(() => {
        if (isPaidInFull) {
            form.setFieldsValue({ amountPaid: totalNet });
        }
    }, [isPaidInFull, totalNet]);

    // Fetch appointments
    useEffect(() => {
        axios
            .get(`${apiBaseUrl}/appointment`, config)
            .then((response) => {
                if (response.data.success) {
                    setAppointments(response.data.data);
                }
            })
            .catch((error) => {
                console.error("Error fetching appointments:", error);
            });
    }, []);

    // Fetch test categories and set "OTHER TESTS" as default
    useEffect(() => {
        axios
            .get(`${apiBaseUrl}/medical_test/categories`, config)
            .then((response) => {
                if (response.data.success) {
                    setTestCategoryOptions(response.data.data);

                    const otherTestsCategory = response.data.data.find(
                        (category) => category.category.toUpperCase() === "OTHER TESTS"
                    );
                    if (otherTestsCategory) {
                        fetchOtherTests(otherTestsCategory._id);
                        form.setFieldsValue({ category: otherTestsCategory._id });
                        fetchTestDetails(otherTestsCategory._id);
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching test categories:", error);
            });
    }, []);

    // Fetch tests under "OTHER TESTS" category
    const fetchOtherTests = (categoryId) => {
        axios
            .get(`${apiBaseUrl}/lists/medical_tests?category=${categoryId}`, config)
            .then((response) => {
                if (response.data.success) {
                    const formattedData = response.data.data.map((test) => ({
                        title: test.name,
                        price: test.price,
                        value: test._id,
                        children: test.subtitle.map((sub) => ({
                            title: sub.name,
                            value: sub._id,
                            price: sub.price,
                            parentName: test.name,
                        })),
                    }));
                    setOtherTestsData(formattedData);
                }
            })
            .catch((error) => {
                console.error("Error fetching OTHER TESTS:", error);
            });
    };

    // Fetch tests for a selected category
    const fetchTestDetails = (categoryId) => {
        axios
            .get(`${apiBaseUrl}/lists/medical_tests?category=${categoryId}`, config)
            .then((response) => {
                if (response.data.success) {
                    const formattedData = response.data.data.map((test) => ({
                        title: test.name,
                        price: test.price,
                        value: test._id,
                        children: test.subtitle.map((sub) => ({
                            title: sub.name,
                            value: sub._id,
                            price: sub.price,
                            parentName: test.name,
                        })),
                    }));
                    setTestData(formattedData);
                }
            })
            .catch((error) => {
                console.error("Error fetching test details:", error);
            });
    };

    // Get the latest relevant appointment history
    const getRelevantAppointmentHistory = (history) => {
        if (!history || history.length === 0) return null;

        for (let i = history.length - 1; i >= 0; i--) {
            const entry = history[i];
            if (
                entry.type === "Ongoing" ||
                entry.type === "FollowUp" ||
                (entry.type === "Referred" && entry.doctor)
            ) {
                console.log("Relevant History Entry:", entry);
                return entry;
            }
        }

        console.log("No relevant history entry found.");
        return null;
    };

    // Modified fetchPatientDetails to explicitly set patient fields including gender
    const fetchPatientDetails = (appointmentId) => {
        axios
            .get(`${apiBaseUrl}/appointment/${appointmentId}`, config)
            .then((response) => {
                if (response.data.success) {
                    const appointment = response.data.data;
                    setDOB(appointment.DOB?.split("T")[0]);
                    form.setFieldsValue({
                        patient: {
                            fullname: appointment.fullname,
                            gender: appointment.gender,
                            mobile: appointment.mobile,
                            address: appointment.address,
                        },
                        DOB: appointment.DOB?.split("T")[0],
                        userID: appointment.patientId,
                    });

                    const relevantHistory = getRelevantAppointmentHistory(appointment.appointmentHistory);
                    if (relevantHistory) {
                        if (relevantHistory.investigations && relevantHistory.investigations.length > 0) {
                            autoAddInvestigations(relevantHistory.investigations);
                        }
                        if (relevantHistory.doctor && relevantHistory.doctor.fullname) {
                            autoAddDoctorsFee(relevantHistory.doctor.fullname);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching patient details:", error);
            });
    };

    // Auto-add doctor's fee to billing table
    const autoAddDoctorsFee = (doctorName) => {
        const formattedDoctorName = `Dr. ${doctorName}`;
        let matchedTest = null;
        let matchedPrice = 0;

        otherTestsData.forEach((test) => {
            if (test.title.toLowerCase() === "doctor's fee" && test.children) {
                const childMatch = test.children.find(
                    (child) =>
                        child.title.toLowerCase() === formattedDoctorName.toLowerCase()
                );
                if (childMatch) {
                    matchedTest = formattedDoctorName;
                    matchedPrice = childMatch.price;
                }
            }
        });

        if (matchedTest && matchedPrice) {
            const grossTotal = parseFloat(matchedPrice).toFixed(2);
            const netTotal = grossTotal;
            const newItem = {
                key: uuidv4(),
                name: [matchedTest],
                price: grossTotal,
                discount: "0%",
                net: netTotal,
                gross: grossTotal,
            };

            setBillTableData((prevData) => {
                const existingNames = new Set(prevData.map(item => item.name[0]));
                if (!existingNames.has(matchedTest)) {
                    message.info(`Added Doctor's Fee for ${matchedTest}`);
                    return [...prevData, newItem];
                }
                return prevData;
            });
        } else {
            console.log(`No matching doctor's fee found for ${formattedDoctorName}`);
        }
    };

    // Auto-add investigations to billing table
    const autoAddInvestigations = (investigations) => {
        const newBillingItems = [];

        investigations.forEach((investigation) => {
            investigation.investigation.forEach((invName) => {
                let matchedTest = null;
                let matchedPrice = 0;

                otherTestsData.forEach((test) => {
                    if (test.title.toLowerCase() === invName.toLowerCase()) {
                        matchedTest = test.title;
                        matchedPrice = test.price;
                    }
                    if (test.children) {
                        const childMatch = test.children.find(
                            (child) => child.title.toLowerCase() === invName.toLowerCase()
                        );
                        if (childMatch) {
                            matchedTest = childMatch.title;
                            matchedPrice = childMatch.price;
                        }
                    }
                });

                if (matchedTest && matchedPrice) {
                    const grossTotal = parseFloat(matchedPrice).toFixed(2);
                    const netTotal = grossTotal;
                    const newItem = {
                        key: uuidv4(),
                        name: [matchedTest],
                        price: grossTotal,
                        discount: "0%",
                        net: netTotal,
                        gross: grossTotal,
                    };
                    newBillingItems.push(newItem);
                }
            });
        });

        setBillTableData((prevData) => {
            const existingNames = new Set(prevData.map(item => item.name[0]));
            const filteredNewItems = newBillingItems.filter(
                item => !existingNames.has(item.name[0])
            );
            return [...prevData, ...filteredNewItems];
        });

        if (newBillingItems.length > 0) {
            message.info(`Added ${newBillingItems.length} investigations to the bill.`);
        }
    };

    const handleAppointmentChange = (appointmentId) => {
        setBillTableData([]);
        fetchPatientDetails(appointmentId);
    };

    const handleCategoryChange = (categoryId) => {
        fetchTestDetails(categoryId);
    };

    const handleTestSelect = (value, label, extra) => {
        const { triggerNode } = extra;
        const selectedPrice = triggerNode?.props?.price;
        setSelectedTest(label);
        form.setFieldsValue({ price: selectedPrice });
    };

    const handleAddOrUpdateObject = () => {
        const values = form.getFieldsValue(["name", "price", "discount"]);
        if (selectedTest != null && values.price != null) {
            const discountPercentage = values.discount || 0;
            const discountedPrice = values.price * (1 - discountPercentage / 100);
            const netTotal = parseFloat(discountedPrice)?.toFixed(2);
            const grossTotal = parseFloat(values.price)?.toFixed(2);

            if (editingIndex !== null) {
                const updatedTableData = billingTableData.map((item, index) => {
                    if (index === editingIndex) {
                        return {
                            ...item,
                            name: values.name,
                            price: parseFloat(values.price)?.toFixed(2),
                            discount: discountPercentage + "%",
                            net: netTotal,
                            gross: grossTotal,
                        };
                    }
                    return item;
                });
                setBillTableData(updatedTableData);
                setEditingIndex(null);
                setEditingItem(null);
            } else {
                const newItem = {
                    key: uuidv4(),
                    name: selectedTest,
                    price: parseFloat(values.price)?.toFixed(2),
                    discount: discountPercentage + "%",
                    net: netTotal,
                    gross: grossTotal,
                };
                setBillTableData([...billingTableData, newItem]);
            }
            form.resetFields(["name", "price", "discount"]);
            setSelectedTest(null);
        } else {
            message.warning("Please select name and price.");
        }
    };

    const handleEditItem = (index, item) => {
        setEditingIndex(index);
        setEditingItem(item);
        form.setFieldsValue({
            name: item.name,
            price: item.price,
            discount: parseFloat(item.discount),
        });
        setSelectedTest(item.name);
    };

    const handleDeleteItem = (key) => {
        const updatedTableData = billingTableData.filter(
            (item) => item.key !== key
        );
        setBillTableData(updatedTableData);
    };

    const filterTreeNode = (inputValue, treeNode) => {
        const { title, children, parentName } = treeNode;
        if (!children) {
            return title.toLowerCase().includes(inputValue.toLowerCase());
        }
        return (
            title.toLowerCase().includes(inputValue.toLowerCase()) ||
            (parentName &&
                parentName.toLowerCase().includes(inputValue.toLowerCase()))
        );
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name) => name[0],
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Discount (%)",
            dataIndex: "discount",
            key: "discount",
        },
        {
            title: "Gross",
            dataIndex: "gross",
            key: "gross",
        },
        {
            title: "Net",
            dataIndex: "net",
            key: "net",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record, index) => (
                <Space size="middle">
                    <a onClick={() => handleEditItem(index, record)}>Edit</a>
                    <a
                        onClick={() => handleDeleteItem(record.key)}
                        className="text-red-500 hover:text-red-600"
                    >
                        Delete
                    </a>
                </Space>
            ),
        },
    ];

    const onFinish = (values) => {
        const { appointment, patient, DOB, dueDate, amountPaid, paymentMethod } = values;
        const { fullname, gender, mobile, address } = patient;

        const test = billingTableData.map((item) => ({
            name: item.name[0],
            price: parseFloat(item.price),
            grossAmount: parseFloat(item.gross),
            discount: parseFloat(item.discount?.split("%")[0]) || 0,
            netAmount: parseFloat(item.net),
        }));

        const data = {
            appointment,
            test,
            patient: {
                fullname,
                DOB,
                gender,
                mobile,
                address,
            },
            billDate: new Date().toISOString(),
            totalGross,
            totalNet,
            dueDate: dueDate.format("YYYY-MM-DD"),
            amountPaid: parseFloat(amountPaid) || 0,
            paymentMethod: paymentMethod || null,
        };

        if (test.length !== 0) {
            axios
                .post(`${apiBaseUrl}/test-bills`, data, config)
                .then((response) => {
                    if (response.data.success) {
                        message.success("Bill added successfully!");
                        if (onSuccess) {
                            onSuccess();
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error posting data:", error);
                    message.error("Failed to add bill. Please try again later.");
                });
        } else {
            message.error("Test table cannot be empty.");
        }
    };

    return (
        <div className="bg-white rounded-md px-5 pt-4 pb-1 shadow-md">
            <div className="font-medium text-sky-600 text-4xl text-center mb-2">
                Create Bill
            </div>
            <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{ paymentMethod: "Cash" }}>
                <Title level={3}>Patient Details</Title>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        <Form.Item
                            label="Select Appointment"
                            name="appointment"
                            rules={[{ message: "Please select an appointment" }]}
                        >
                            <Select
                                placeholder="Select an appointment"
                                showSearch
                                onChange={handleAppointmentChange}
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {appointments.map((appointment) => (
                                    <Option key={appointment._id} value={appointment._id}>
                                        {appointment.appointmentNumber}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Patient Name"
                            name={["patient", "fullname"]}
                            rules={[{ required: true, message: "Please enter patient name" }]}
                        >
                            <Input placeholder="Enter fullname" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="DOB"
                            name="DOB"
                            rules={[{ required: true, message: "Please select DOB" }]}
                        >
                            <input
                                type="date"
                                onChange={(e) => setDOB(e.target.value)}
                                max={new Date().toISOString().split("T")[0]}
                                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                                name="dob"
                                placeholder="Select Date"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Age"
                            name="age"
                            rules={[{ required: false, message: "Please select DOB for age." }]}
                        >
                            <Input placeholder="Select DOB for age." readOnly />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        <Form.Item
                            label="UserID"
                            name={["userID", "userID"]}
                            rules={[{ message: "UserID" }]}
                        >
                            <Input placeholder="Empty for non-registered users" allowClear />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Mobile"
                            name={["patient", "mobile"]}
                            rules={[{ required: true, message: "Please enter patient mobile number" }]}
                        >
                            <Input placeholder="Enter contact" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Gender"
                            name={["patient", "gender"]}
                            rules={[{ required: true, message: "Please enter patient gender" }]}
                        >
                            <Select placeholder="Select gender">
                                <Option value="Male">Male</Option>
                                <Option value="Female">Female</Option>
                                <Option value="Others">Others</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Address"
                            name={["patient", "address"]}
                            rules={[{ required: true, message: "Please enter patient address" }]}
                        >
                            <Input placeholder="Enter address" />
                        </Form.Item>
                    </Col>
                </Row>

                <Title level={3}>Test/Consult</Title>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[{ message: "Please select a category" }]}
                        >
                            <Select
                                placeholder="Select a category"
                                showSearch
                                allowClear
                                onChange={handleCategoryChange}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {testCategoryOptions.map((category, index) => (
                                    <Option key={index} value={category._id}>
                                        {category.category}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ message: "Please select name" }]}
                        >
                            <TreeSelect
                                treeData={testData}
                                placeholder="Select a name"
                                showSearch
                                filterTreeNode={filterTreeNode}
                                allowClear
                                onChange={handleTestSelect}
                                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Price"
                            name="price"
                            rules={[{ message: "Please enter price" }]}
                        >
                            <Input type="text" placeholder="Enter price" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Discount (%)" name="discount">
                            <Input type="number" placeholder="Enter discount %" min={0} max={100} />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ display: "flex", alignItems: "center" }}>
                        <Button
                            onClick={handleAddOrUpdateObject}
                            className="bg-gray-200 text-black rounded shadow-md"
                        >
                            {editingIndex !== null ? "Update" : "Add Object"}
                        </Button>
                    </Col>
                </Row>

                <Table
                    columns={columns}
                    dataSource={billingTableData}
                    pagination={false}
                    summary={() => (
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={3}>
                                <strong>Total</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <strong>{totalGross}</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <strong>{totalNet}</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                        </Table.Summary.Row>
                    )}
                />

                <Title level={3}>Payment Details</Title>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Form.Item
                            label="Due Date"
                            name="dueDate"
                            rules={[{ required: true, message: "Please select due date" }]}
                        >
                            <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Paid in full">
                            <Checkbox
                                checked={isPaidInFull}
                                onChange={(e) => setIsPaidInFull(e.target.checked)}
                            >
                                Paid in full
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Amount Paid"
                            name="amountPaid"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value && value < 0) {
                                            return Promise.reject("Amount paid cannot be negative");
                                        }
                                        if (value && value > parseFloat(totalNet)) {
                                            return Promise.reject("Amount paid cannot exceed total net amount");
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder="Enter amount paid"
                                min={0}
                                max={totalNet}
                                disabled={isPaidInFull} // Disable input when "Paid in full" is checked
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Payment Method"
                            name="paymentMethod"
                            rules={[
                                {
                                    required: form.getFieldValue("amountPaid") > 0,
                                    message: "Please select a payment method if amount is paid",
                                },
                            ]}
                        >
                            <Select placeholder="Select payment method" allowClear>
                                <Option value="Cash">Cash</Option>
                                <Option value="Card">Card</Option>
                                <Option value="Bank">Bank Transfer</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                        <button
                            id="addStaffBtn"
                            className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow py-2 px-4 font-semibold text-md rounded-md"
                            type="submit"
                            style={{ width: "200px" }}
                        >
                            Submit
                        </button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CreateTestBill;