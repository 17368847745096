import React, { useState } from "react";
import axios from "axios";
import { Form, Input, message, Row, Col } from "antd";
import LoadingScreen from "../lib/constants/loadingScreen";

const AddMedicine = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [actionLoading, setActionLoading] = useState(false);
  const [expDate, setExpDate] = useState("");
  const [manfDate, setManufactureDate] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
    },
  };

  const addMedicine = async (values) => {
    setActionLoading(true);

    const data = {
      name: values.name,
      type: values.type,
      affiliateName: values.affiliateName,
      expDate,
      manfDate,
      quantity: values.quantity,
      batch: values.batch,
      dosage: values.dosage,
      price: values.price,
      costPrice: values.costPrice, // Added costPrice to payload
    };

    try {
      const response = await axios.post(
        `${apiBaseUrl}/medicine/create`,
        data,
        config
      );

      if (response.data.success) {
        message.success(response.data.msg, 0.6, function onClose() {
          window.location.replace("/dashboard/medicine");
        });
      } else {
        message.error(response?.data?.error?.msg || "Failed to add medicine.");
      }
    } catch (error) {
      console.error("Failed to add medicine:", error);
      message.error("Failed to add medicine! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={addMedicine}
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-2"
        >
          <div className="font-medium text-3xl mb-4">Add Medicine</div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Medicine Name"
                name="name"
                rules={[
                  { required: true, message: "Please input medicine name!" },
                ]}
              >
                <Input placeholder="Medicine name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Medicine Type"
                name="type"
                rules={[
                  { required: true, message: "Please input medicine type!" },
                ]}
              >
                <Input placeholder="Medicine type (e.g., Tablets/Capsules...)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Affiliate Name"
                name="affiliateName"
                rules={[
                  { required: true, message: "Please input affiliate name!" },
                ]}
              >
                <Input placeholder="Affiliate Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Batch"
                name="batch"
                rules={[
                  { required: true, message: "Please input batch number!" },
                ]}
              >
                <Input placeholder="Batch number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label>Manufacture Date</label>
              <input
                id="manufactureDate"
                type="date"
                onChange={(e) => {
                  setManufactureDate(e.target.value);
                }}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3"
                name="title"
                placeholder="Select Manufacture Date"
                required
              />
            </Col>
            <Col span={12}>
              <label>Expiry Date</label>
              <input
                id="expDate"
                type="date"
                onChange={(e) => {
                  setExpDate(e.target.value);
                }}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3"
                name="title"
                placeholder="Select Expiry Date"
                required
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please input medicine quantity!",
                  },
                ]}
              >
                <Input type="number" placeholder="Medicine quantity" min={1} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Price/Quantity"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please input price per quantity!",
                  },
                ]}
              >
                <Input type="number" placeholder="Selling price per quantity" min={0} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Cost Price/Quantity"
                name="costPrice"
                rules={[
                  {
                    required: true,
                    message: "Please input cost price per quantity!",
                  },
                ]}
              >
                <Input type="number" placeholder="Cost price per quantity" min={0} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Dosage/Milligram (MG)"
                name="dosage"
                rules={[{ required: true, message: "Please input dosage!" }]}
              >
                <Input placeholder="Enter with MG" />
              </Form.Item>
            </Col>
          </Row>

          <button
            id="addMedicine"
            className="w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
};

export default AddMedicine;