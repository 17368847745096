export const DEGENERATIVE_DISEASE = [
    /// DEGENERATIVE DISEASE
    {
        field: "ddDegenerativeDisease",
        label: "Degenerative Disease",
        type: "multiselect",
        options: [
            "{Primary/Secondary} {value} {of} {left/right knee} {and} {First MCP/MTP}",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger value…
                trigger: [
                    "{Primary/Secondary} {value} {of} {left/right knee} {and} {First MCP/MTP}",
                ],
                placeholders: [

                    {
                        key: "Primary/Secondary",
                        type: "select",
                        label: "P/S",

                        options: [
                            "Primary",
                            "Secondary",
                        ]
                    },

                    {
                        key: "value",
                        type: "select",
                        label: "diseases",

                        options: [
                            "Osteoarthritis",
                            "Nodal osteoarthritis of hands",
                            "Lumbar spondylosis",
                            "Cervical Spondylosis",
                        ]
                    },
                    {
                        key: "of",
                        type: "checkbox",
                        label: "Of",

                        options: [
                            "of",
                        ]
                    },

                    {
                        key: "left/right knee",
                        type: "select",
                        label: "Knee",

                        options: [
                            "Left Knee",
                            "Right Knee",
                            "Left & Right Knee",
                        ]
                    },

                    {
                        key: "and",
                        type: "checkbox",
                        label: "And",

                        options: [
                            "and",
                        ]
                    },
                    {
                        key: "First MCP/MTP",
                        type: "multiselect",
                        label: "First MCP/MTP",

                        options: [
                            "First MCP",
                            "First MTP",
                        ]
                    }
                ]
            },

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Total Duration of Illness
    {
        field: "ddTotalDurationOfIllness",
        label: "Total Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "ddAnaByIfaIntensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "ddAnaByIfaTiter",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Pattern Field a
    {
        field: "ddAnaByIfaPattern",
        label: "ANA by-IFA : Pattern",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
            "AC 1", "AC 2", "AC 3", "AC 4", "AC 5", "AC 6", "AC 7", "AC 8", "AC 9", "AC 10",
            "AC 11", "AC 12", "AC 13", "AC 14", "AC 15", "AC 16", "AC 17", "AC 18", "AC 19", "AC 20",
            "AC 21", "AC 22", "AC 23", "AC 24", "AC 25", "AC 26", "AC 27", "AC 28", "AC 29", "AC 30",

        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Comorbidities Field
    {
        field: "ddComorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Conception Plan Field
    {
        field: "ddConceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "ddCompliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "ddVaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "ddSmoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to physiotherapy and life style modification
    {
        field: "ddCompliantToPhysioTherapyAndLifestyleModification",
        label: "Compliant to physiotherapy and lifestyle modification",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Weight Field
    {
        field: "ddWeight",
        label: "Weight",
        type: "select",
        options: [
            "Reduced",
            "Increased",
            "Static to previous visit",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
]