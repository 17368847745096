export const OVERLAP_SYNDROMES = [
    /// Total Duration of Illness
    {
        field: "osDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Overlapped Diseases
    {
        field: "osOverlappedDiseases",
        label: "Overlapped Diseases",
        type: "composite",
        fields: [
            {
                field: "overlapDisease1",
                label: "OverlapDisease Number 1",
                type: "select",
                options: [
                    "RHEUMATOID_ARTHRITIS",
                    "SYSTEMIC_LUPUS_ERYTHEMATOSUS",
                    "SPONDYLOARTHRITIS",
                    "SYSTEMIC_SCLEROSIS",
                    "MYOSITIS",
                    "MIXED_CONNECTIVE_TISSUE",
                    "GOUTY_ARTHRITIS",
                    "VASCULITIS",
                    "DEGENERATIVE_DISEASE",
                    "PRIMARY_SJÖGREN_DISEASE",
                    "UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE",
                ],
            },
            {
                field: "overlapDisease2",
                label: "OverlapDisease Number 2",
                type: "select",
                options: [
                    "RHEUMATOID_ARTHRITIS",
                    "SYSTEMIC_LUPUS_ERYTHEMATOSUS",
                    "SPONDYLOARTHRITIS",
                    "SYSTEMIC_SCLEROSIS",
                    "MYOSITIS",
                    "MIXED_CONNECTIVE_TISSUE",
                    "GOUTY_ARTHRITIS",
                    "VASCULITIS",
                    "DEGENERATIVE_DISEASE",
                    "PRIMARY_SJÖGREN_DISEASE",
                    "UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE",
                ]
            },
            {
                field: "predominantFeatureOf",
                label: "Pre-Dominant Feature Of",
                type: "select",
                options: [
                    "RHEUMATOID_ARTHRITIS",
                    "SYSTEMIC_LUPUS_ERYTHEMATOSUS",
                    "SPONDYLOARTHRITIS",
                    "SYSTEMIC_SCLEROSIS",
                    "MYOSITIS",
                    "MIXED_CONNECTIVE_TISSUE",
                    "GOUTY_ARTHRITIS",
                    "VASCULITIS",
                    "DEGENERATIVE_DISEASE",
                    "PRIMARY_SJÖGREN_DISEASE",
                    "UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE",
                ]
            },
        ]
    },
    /// Initial Symptom
    {
        field: "osInitialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "Arthritis",
            "Proximal muscle weakness",
            "Photosensitivity",
            "Constitutional",
            "Raynaud's",
            "Renal involvement",
            "Cardiac involvement",
            "Lung involvement",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// Pertinent Antibodies
    {
        field: "osPertinentAntibodies",
        label: "Pertinent Antibodies",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Anti-U1 RNP Antibody",
            "Anti-Jo-1",
            "Anti-SRP, Anti-Mi-2",
            "Anti-MDA5",
            "Anti-Scl-70 (Topoisomerase I)",
            "Anti-Centromere",
            "Anti-RNA Polymerase III",
            "Anti-dsDNA",
            "Anti-Sm",
            "Anti-Ro/SSA and Anti-La/SSB",
            "Rheumatoid Factor (RF)",
            "Anti-Cyclic Citrullinated Peptide (Anti-CCP)",
            "Anti-cardiolipin",
            "Beta-2 glycoprotein I",
            "Lupus anticoagulant",
            "Anti-Ku",
            "Anti-PM/Scl",
            "Anti-RNP and Anti-Sm",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "osAnaByIfaIntensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "osAnaByIfaTiter",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "{ Write Something Here }",
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "osAnaByIfaPattern",
        label: "ANA by-IFA : Pattern",
        type: "multiselect",
        options: [
            "{ Write Something Here }",
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
            "AC 1", "AC 2", "AC 3", "AC 4", "AC 5", "AC 6", "AC 7", "AC 8", "AC 9", "AC 10",
            "AC 11", "AC 12", "AC 13", "AC 14", "AC 15", "AC 16", "AC 17", "AC 18", "AC 19", "AC 20",
            "AC 21", "AC 22", "AC 23", "AC 24", "AC 25", "AC 26", "AC 27", "AC 28", "AC 29", "AC 30",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],

    },
    /// Comorbidities Field
    {
        field: "osComorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Conception Plan Field
    {
        field: "osConceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "osCompliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "osVaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "osSmoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "osCurrentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Symptoms suggestive of increased disease activity",
            "Symptoms suggestive of side effects of drugs",
            "Symptoms suggestive of infection",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ]
    }
]