import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Table, Input, Select, Spin, Space, message, Modal } from "antd";
import debounce from "lodash/debounce";
import { SearchOutlined } from "@ant-design/icons";
import UpdateRefer from "./UpdateRefer";

const { Option } = Select;

const CheckRefers = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [assignedFilter, setAssignedFilter] = useState("unassigned");
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);

  // Dynamic auth config based on localStorage ticket
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];

    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} };
    }

    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
      },
    };
  };

  const config = getAuthConfig();

  /** Fetch referral data from the API */
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiBaseUrl}/appointments/referred`, config);
      if (response.data.success) {
        setDetails(response.data.data);
      } else {
        message.error("No referral data found.");
      }
    } catch (e) {
      console.error("Failed to get appointment details:", e);
      message.error("Failed to get appointment details! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  /** Initial data fetch on component mount */
  useEffect(() => {
    fetchData();
  }, []);

  /** Debounced filter function */
  const filterDetails = useCallback(
    debounce(() => {
      let filteredData = details.filter((detail) => {
        if (assignedFilter === "all") {
          return true;
        } else if (assignedFilter === "assigned") {
          return !!detail.refer?.toDoctor; // Ensure refer exists
        } else {
          return !detail.refer?.toDoctor; // Ensure refer exists
        }
      });

      filteredData = filteredData.filter((detail) =>
        [
          detail.patientId?.userID,
          detail.fullname,
          detail.appointmentNumber,
          detail.mobile,
        ].some((field) =>
          field?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

      setFilteredDetails(filteredData);
    }, 300),
    [searchTerm, assignedFilter, details]
  );

  /** Filter data whenever search term, filter, or details change */
  useEffect(() => {
    filterDetails();
  }, [filterDetails]);

  const handleAssignedFilterChange = (value) => {
    setAssignedFilter(value);
  };

  const handleSearchTermChange = (value) => {
    setSearchTerm(value);
  };

  /** Handle successful assignment */
  const handleAssignSuccess = () => {
    setIsAssignModalOpen(false);
    setSelectedAppointmentId(null);
    fetchData(); // Refresh the table data
  };

  const columns = [
    {
      title: "Appointment No.",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Referred by (Dr)",
      dataIndex: ["refer", "by", "fullname"],
      key: "byDoctor",
    },
    {
      title: "From Department",
      dataIndex: ["refer", "by", "department", "department"],
      key: "fromDepartment",
    },
    {
      title: "Referred To (Dr)",
      key: "toDoctor",
      render: (_, record) => {
        const { refer } = record;
        return refer?.toDoctor ? (
          refer.toDoctor.fullname
        ) : (
          <span className="text-red-500">Unassigned</span>
        );
      },
    },
    {
      title: "To Department",
      dataIndex: ["refer", "toDepartment", "department"],
      key: "toDepartment",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        !record.refer?.toDoctor ? (
          <Space size="middle">
            <button
              onClick={() => {
                setSelectedAppointmentId(record._id);
                setIsAssignModalOpen(true);
              }}
              className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
            >
              Assign
            </button>
          </Space>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <>
      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">Refers</h1>
        </div>
        <div className="flex justify-start items-center mb-4">
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            style={{ width: 300 }}
            value={searchTerm}
            allowClear
            onChange={(e) => handleSearchTermChange(e.target.value)}
          />
          <Select
            value={assignedFilter}
            onChange={handleAssignedFilterChange}
            style={{ width: 200, marginLeft: 8 }}
          >
            <Option value="unassigned">Unassigned</Option>
            <Option value="assigned">Assigned</Option>
            <Option value="all">All</Option>
          </Select>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
      <Modal
        title="Assign Doctor"
        open={isAssignModalOpen}
        onCancel={() => {
          setIsAssignModalOpen(false);
          setSelectedAppointmentId(null);
        }}
        footer={null}
        width={800}
      >
        {selectedAppointmentId && (
          <UpdateRefer
            appointmentId={selectedAppointmentId}
            onAssignSuccess={handleAssignSuccess}
          />
        )}
      </Modal>
    </>
  );
};

export default CheckRefers;