import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Form, Input, Button, Modal, Select, Typography, Radio, message } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen"; // Adjust import path as needed
import { fieldsConfig } from "./fields_config"; // Adjust import path as needed
import UpdateFirstEncounter from "./update_first_encounter"; // Import UpdateFirstEncounter

const { Title } = Typography;

const FirstEncounter = ({
    appointmentId,
    full_name,
    problem,
    appointmentNumber,
    appointmentHistory,
    onClose,
    onUpdateEncounter: onParentUpdateEncounter,
}) => {
    const [forms, setForms] = useState([
        { id: 1, selectedSection: "First_Encounter", formData: {}, fieldOptions: {} },
    ]);
    const [dynamicExtra, setDynamicExtra] = useState(null);
    const [actionLoading, setActionLoading] = useState(false);
    const [encounters, setEncounters] = useState([]);
    const [hasEditableEncounter, setHasEditableEncounter] = useState(false);
    const [fullNameData, setFullNameData] = useState(full_name || "");
    const [problemData, setProblemData] = useState(problem || "");
    const [appointmentNumberData, setAppointmentNumberData] = useState(appointmentNumber || "");
    const [loading, setLoading] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [currentEncounterId, setCurrentEncounterId] = useState(null);
    const [updateTrigger, setUpdateTrigger] = useState(0); // New state to trigger fetch
    const [updateForm] = Form.useForm();

    const updateFirstEncounterRef = useRef(null); // Ref to access UpdateFirstEncounter methods

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // Determine user role from localStorage
    const userRole = localStorage.getItem("doctorticket")
        ? "doctor"
        : localStorage.getItem("staffticket")
            ? "staff"
            : null;

    // Set up the auth header with the appropriate token
    const config = userRole
        ? {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(userRole + "ticket")}`,
            },
        }
        : null;

    useEffect(() => {
        setFullNameData(full_name || "");
        setProblemData(problem || "");
        setAppointmentNumberData(appointmentNumber || "");
        console.log("ROLE", userRole);
        if (!userRole) {
            message.error("User role not detected. Please log in.");
            return;
        }
        fetchEncounters();
    }, [userRole, full_name, problem, appointmentNumber]);

    if (!userRole) {
        return null;
    }

    // Fetch existing encounters for the appointment
    const fetchEncounters = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiBaseUrl}/firstEncounter/${appointmentId}`, config);
            if (response.data.success) {
                setEncounters(response.data.data);
                checkEditableEncounter(response.data.data);
            }
        } catch (error) {
            console.error("Failed to fetch encounters:", error);
            message.error("Failed to fetch encounters!");
        } finally {
            setLoading(false);
        }
    };

    // Check if there’s an editable encounter in the latest appointment history
    const checkEditableEncounter = (encounterData) => {
        if (!encounterData || encounterData.length === 0) {
            setHasEditableEncounter(false);
            return;
        }

        const editable = encounterData.some((item) => {
            if (!item.appointmentId?.appointmentHistory) return false;
            const lastHistory = item.appointmentId.appointmentHistory[item.appointmentId.appointmentHistory.length - 1];
            return lastHistory?.encounter?.some((encounterItem) => encounterItem._id === item._id);
        });
        setHasEditableEncounter(editable);
    };

    // Date and time formatting functions
    function timeAgo(dateString) {
        const now = new Date();
        const created = new Date(dateString);
        const msPerDay = 1000 * 60 * 60 * 24;
        const diffMs = now - created;
        const diffDays = Math.floor(diffMs / msPerDay);

        if (diffDays === 0) return "today";
        if (diffDays < 30) return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
        const diffMonths = Math.floor(diffDays / 30);
        return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`;
    }

    function formatDate(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}/${month}/${day}`;
    }

    // Get field label for display
    const getFieldLabel = (section, fieldName) => {
        const sectionConfig = fieldsConfig[section];
        if (!sectionConfig) return fieldName;
        const fieldConfig = sectionConfig.find((field) => field.field === fieldName);
        return fieldConfig ? fieldConfig.label : fieldName;
    };

    // Get configuration for the selected form section
    const getConfigSection = (form) => fieldsConfig[form.selectedSection] || [];

    // Handle simple field changes
    const handleChange = (formId, field, value) => {
        setForms((prevForms) =>
            prevForms.map((f) =>
                f.id === formId ? { ...f, formData: { ...f.formData, [field]: value } } : f
            )
        );
    };

    // Handle composite field changes
    const handleCompositeChange = (formId, compositeField, subField, value) => {
        setForms((prevForms) =>
            prevForms.map((f) => {
                if (f.id === formId) {
                    return {
                        ...f,
                        formData: {
                            ...f.formData,
                            [compositeField]: {
                                ...(f.formData[compositeField] || {}),
                                [subField]: value,
                            },
                        },
                    };
                }
                return f;
            })
        );
    };

    // Check and trigger extra options for fields with dynamic inputs
    const checkAndTriggerExtraOption = (formId, field, value) => {
        const form = forms.find((f) => f.id === formId);
        const configSection = getConfigSection(form);
        const fieldConfig = configSection.find((fc) => fc.field === field);
        if (fieldConfig?.extraOptions) {
            const extra = fieldConfig.extraOptions.find((opt) =>
                Array.isArray(opt.trigger) ? opt.trigger.includes(value) : opt.trigger === value
            );
            if (extra) {
                const initialValues = {};
                if (Array.isArray(extra.placeholders)) {
                    extra.placeholders.forEach((p) => {
                        initialValues[p.key] = p.type === "multiselect" ? [] : "";
                    });
                }
                setDynamicExtra({ formId, field, extraOption: extra, values: initialValues, selectedTrigger: value });
            }
        }
    };

    // Handle multiselect field changes
    const handleMultiSelectChange = (formId, field, valueArray) => {
        const form = forms.find((f) => f.id === formId);
        const configSection = getConfigSection(form);
        const fieldConfig = configSection.find((fc) => fc.field === field);
        let triggers = [];
        if (fieldConfig?.extraOptions) {
            triggers = fieldConfig.extraOptions.flatMap((opt) =>
                Array.isArray(opt.trigger) ? opt.trigger : [opt.trigger]
            );
        }
        const cleaned = valueArray.filter((val) => !triggers.includes(val));
        setForms((prevForms) =>
            prevForms.map((f) =>
                f.id === formId ? { ...f, formData: { ...f.formData, [field]: cleaned } } : f
            )
        );
    };

    const handleMultiSelectSelect = (formId, field, value) => {
        checkAndTriggerExtraOption(formId, field, value);
    };

    // Handle dynamic extra option confirmation
    const handleDynamicExtraOk = () => {
        if (dynamicExtra) {
            const { extraOption, values, field, formId, selectedTrigger } = dynamicExtra;
            let finalString = extraOption.template || selectedTrigger;
            if (extraOption.placeholders && Array.isArray(extraOption.placeholders)) {
                extraOption.placeholders.forEach((placeholder) => {
                    let replacement = values[placeholder.key];
                    if (Array.isArray(replacement)) {
                        replacement = replacement.join(", ");
                    }
                    finalString = finalString.replace(`{${placeholder.key}}`, replacement || placeholder.key);
                });
            }
            setForms((prevForms) =>
                prevForms.map((f) => {
                    if (f.id === formId) {
                        const config = getConfigSection(f);
                        const fieldConfig = config.find((fc) => fc.field === field);
                        if (fieldConfig.type === "select") {
                            return { ...f, formData: { ...f.formData, [field]: finalString } };
                        } else if (fieldConfig.type === "multiselect") {
                            const current = f.formData[field] || [];
                            const cleanedArray = Array.isArray(current)
                                ? current.filter((val) => val !== selectedTrigger)
                                : [];
                            if (!cleanedArray.includes(finalString)) {
                                cleanedArray.push(finalString);
                            }
                            return { ...f, formData: { ...f.formData, [field]: cleanedArray } };
                        }
                    }
                    return f;
                })
            );
        }
        setDynamicExtra(null);
    };

    const handleDynamicExtraCancel = () => {
        setDynamicExtra(null);
    };

    // Check if form has meaningful data
    const hasFormData = (data) => {
        if (Array.isArray(data)) return data.length > 0;
        if (typeof data === "object" && data !== null) {
            return Object.values(data).some((value) => hasFormData(value));
        }
        return typeof data === "string" ? data.trim() !== "" : data !== undefined && data !== null;
    };

    // Submit all form data
    const handleSubmitAll = async () => {
        setActionLoading(true);
        const selectedForm = forms[0];
        const { formData } = selectedForm;

        if (!hasFormData(formData)) {
            message.error("Please fill at least one field for the encounter.");
            setActionLoading(false);
            return;
        }

        const data = {
            encounterData: {
                section: selectedForm.selectedSection,
                data: formData,
            },
        };

        try {
            const response = await axios.post(
                `${apiBaseUrl}/${userRole}/firstEncounter/${appointmentId}`,
                data,
                config
            );
            if (response.data.success) {
                message.success("Encounter added successfully", 0.6, () => {
                    fetchEncounters();
                });
                setForms([{ id: 1, selectedSection: "First_Encounter", formData: {}, fieldOptions: {} }]);
            } else {
                message.error(response?.data?.error?.error || "Failed to add encounter");
            }
        } catch (error) {
            console.error("Failed to add encounter:", error);
            message.error("Failed to add encounter! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    // Update an existing encounter
    const updateEncounter = async (encounterId, updatedData) => {
        setActionLoading(true);
        try {
            const response = await axios.put(
                `${apiBaseUrl}/${userRole}/firstEncounter/${encounterId}`,
                updatedData,
                config
            );
            if (response.data.success) {
                message.success("Encounter updated successfully", 0.6, () => {
                    fetchEncounters();
                    setIsUpdateModalOpen(false);
                });
            } else {
                message.error(response?.data?.error?.error || "Failed to update encounter");
            }
        } catch (error) {
            console.error("Failed to update encounter:", error);
            message.error("Failed to update encounter! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    // Delete an existing encounter with Modal.confirm
    const handleDelete = (encounterId) => {
        Modal.confirm({
            title: "Are you sure you want to delete this encounter?",
            content: "This action cannot be undone.",
            okText: "Yes, delete",
            okType: "danger",
            cancelText: "No, cancel",
            onOk: async () => {
                setActionLoading(true);
                try {
                    const response = await axios.delete(
                        `${apiBaseUrl}/${userRole}/firstEncounter/${encounterId}`,
                        config
                    );
                    if (response.data.success) {
                        message.success("Encounter deleted successfully", 0.6, () => {
                            fetchEncounters();
                        });
                    } else {
                        message.error(response?.data?.error?.error || "Failed to delete encounter");
                    }
                } catch (error) {
                    console.error("Failed to delete encounter:", error);
                    message.error("Failed to delete encounter! Please try again.");
                } finally {
                    setActionLoading(false);
                }
            },
            onCancel: () => {
                console.log("Deletion canceled");
            },
        });
    };

    // Clear a field’s value
    const handleClear = (formId, field, type) => {
        setForms((prevForms) =>
            prevForms.map((f) => {
                if (f.id === formId) {
                    const clearedValue = type === "multiselect" ? [] : type === "composite" ? {} : "";
                    return { ...f, formData: { ...f.formData, [field]: clearedValue } };
                }
                return f;
            })
        );
    };

    // Show update modal and trigger fetch
    const showUpdateModal = (encounterId) => {
        setCurrentEncounterId(encounterId);
        setUpdateTrigger((prev) => prev + 1); // Increment trigger on each click
        setIsUpdateModalOpen(true);
    };

    const handleUpdateOk = () => {
        if (updateFirstEncounterRef.current) {
            updateFirstEncounterRef.current.handleSubmitAll();
        }
    };

    const handleUpdateCancel = () => {
        if (updateForm.isFieldsTouched()) {
            Modal.confirm({
                title: "Are you sure you want to discard changes?",
                content: "Your changes will not be saved.",
                okText: "Yes, discard",
                cancelText: "No, keep editing",
                onOk: () => {
                    updateForm.resetFields();
                    setIsUpdateModalOpen(false);
                },
            });
        } else {
            setIsUpdateModalOpen(false);
        }
    };

    // Render the form based on configuration
    const renderForm = (form) => {
        const configSection = getConfigSection(form);
        return (
            <div style={{ maxWidth: "900px", margin: "auto", padding: "20px" }}>
                <Title level={2}>First Encounter Form</Title>
                <Form layout="vertical">
                    {configSection.map((fieldConfig) => {
                        if (fieldConfig.type === "composite") {
                            return (
                                <div key={fieldConfig.field}>
                                    <Form.Item label={fieldConfig.label}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {fieldConfig.fields.map((subField) => (
                                                <div key={subField.field} style={{ flex: 1, marginRight: "16px" }}>
                                                    <Form.Item label={subField.label} style={{ marginBottom: 0 }}>
                                                        <Select
                                                            placeholder={`Select ${subField.label}`}
                                                            value={form.formData[fieldConfig.field]?.[subField.field] || undefined}
                                                            onChange={(value) =>
                                                                handleCompositeChange(form.id, fieldConfig.field, subField.field, value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        >
                                                            {subField.options.map((opt) => (
                                                                <Select.Option key={opt} value={opt}>
                                                                    {opt}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            ))}
                                            <Button
                                                type="link"
                                                onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </div>
                            );
                        } else if (fieldConfig.type === "multiselect") {
                            return (
                                <div key={fieldConfig.field}>
                                    <Form.Item label={fieldConfig.label}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Select
                                                mode="tags"
                                                placeholder={`Select ${fieldConfig.label}`}
                                                value={form.formData[fieldConfig.field] || []}
                                                onChange={(value) => handleMultiSelectChange(form.id, fieldConfig.field, value)}
                                                onSelect={(value) => handleMultiSelectSelect(form.id, fieldConfig.field, value)}
                                                style={{ width: "100%", marginRight: "10px" }}
                                            >
                                                {(fieldConfig.options || []).map((option) => (
                                                    <Select.Option key={option} value={option}>
                                                        {option}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            <Button
                                                type="link"
                                                onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </div>
                            );
                        } else if (fieldConfig.type === "checkbox") {
                            return (
                                <div key={fieldConfig.field}>
                                    <Form.Item label={fieldConfig.label}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Radio.Group
                                                options={fieldConfig.options || ["Yes", "No"]}
                                                value={form.formData[fieldConfig.field] || ""}
                                                onChange={(e) => handleChange(form.id, fieldConfig.field, e.target.value)}
                                            />
                                            <Button
                                                type="link"
                                                onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </div>
                            );
                        } else if (fieldConfig.type === "select") {
                            const currentOptions = form.fieldOptions[fieldConfig.field] || fieldConfig.options || [];
                            return (
                                <Form.Item label={fieldConfig.label} key={fieldConfig.field}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Select
                                            showSearch
                                            placeholder={`Select ${fieldConfig.label}`}
                                            value={form.formData[fieldConfig.field] || undefined}
                                            onChange={(value) => handleChange(form.id, fieldConfig.field, value)}
                                            onSelect={(value) => checkAndTriggerExtraOption(form.id, fieldConfig.field, value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    const searchValue = e.target.value.trim();
                                                    if (searchValue && !currentOptions.includes(searchValue)) {
                                                        setForms((prevForms) =>
                                                            prevForms.map((f) =>
                                                                f.id === form.id
                                                                    ? {
                                                                        ...f,
                                                                        fieldOptions: {
                                                                            ...f.fieldOptions,
                                                                            [fieldConfig.field]: [...currentOptions, searchValue],
                                                                        },
                                                                        formData: { ...f.formData, [fieldConfig.field]: searchValue },
                                                                    }
                                                                    : f
                                                            )
                                                        );
                                                        checkAndTriggerExtraOption(form.id, fieldConfig.field, searchValue);
                                                    }
                                                }
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ width: "100%", marginRight: "10px" }}
                                        >
                                            {currentOptions.map((opt) => (
                                                <Select.Option key={opt} value={opt}>
                                                    {opt}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        <Button
                                            type="link"
                                            onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                </Form.Item>
                            );
                        } else if (fieldConfig.type === "type") {
                            return (
                                <Form.Item label={fieldConfig.label} key={fieldConfig.field}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Input
                                            placeholder={`Enter ${fieldConfig.label}`}
                                            value={form.formData[fieldConfig.field] || ""}
                                            onChange={(e) => handleChange(form.id, fieldConfig.field, e.target.value)}
                                            style={{ width: "100%", marginRight: "10px" }}
                                        />
                                        <Button
                                            type="link"
                                            onClick={() => handleClear(form.id, fieldConfig.field, fieldConfig.type)}
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                </Form.Item>
                            );
                        }
                        return null;
                    })}
                </Form>
                <pre style={{ background: "#f0f0f0", padding: "10px", marginTop: "20px" }}>
                    {JSON.stringify(form.formData, null, 2)}
                </pre>
            </div>
        );
    };

    const handleGoBack = () => {
        onClose();
    };

    return (
        <>
            {actionLoading && <LoadingScreen />}

            <div className="mb-3">
                <h1 className="text-3xl font-bold decoration-gray-400">First Encounter</h1>
            </div>

            <div className="flex items-start">
                <div className="max-w-5xl p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
                    {loading && <LoadingScreen />}

                    {!loading && (
                        <>
                            <div className="text-center">
                                {/* <div className="flex justify-end">
                                    <button
                                        className="px-4 py-1 mb-2 rounded-md bg-blue-600 text-sky-100 hover:bg-blue-700"
                                        onClick={handleGoBack}
                                    >
                                        Go Back
                                    </button>
                                </div> */}
                                <h5 className="text-xl font-bold tracking-tight text-gray-900">
                                    Appointment Number: <span className="font-semibold italic">{` ${appointmentNumberData}`}</span>
                                </h5>
                                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                                    Patient Name: <span className="font-semibold italic">{` ${fullNameData}`}</span>
                                </h5>
                                <p className="mb-1 font-semibold text-gray-700">
                                    Problem: <span className="font-normal">{` ${problemData}`}</span>
                                </p>
                            </div>
                            <hr className="w-48 h-1 mx-auto bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700" />

                            {!hasEditableEncounter && (
                                <div>
                                    {forms.map((form) => (
                                        <div key={form.id}>{renderForm(form)}</div>
                                    ))}
                                    <div style={{ padding: "20px", textAlign: "center" }}>
                                        <Button type="primary" onClick={handleSubmitAll}>
                                            Submit First Encounter
                                        </Button>
                                    </div>
                                </div>
                            )}

                            <Modal
                                title={dynamicExtra ? dynamicExtra.extraOption.label : "Extra Input"}
                                open={dynamicExtra !== null}
                                onOk={handleDynamicExtraOk}
                                onCancel={handleDynamicExtraCancel}
                                width={900}
                            >
                                <Form layout="vertical">
                                    {dynamicExtra?.extraOption.placeholders?.map((placeholder) => (
                                        <Form.Item label={placeholder.label} key={placeholder.key}>
                                            {placeholder.type === "select" ? (
                                                <Select
                                                    placeholder={`Select ${placeholder.label}`}
                                                    value={dynamicExtra.values[placeholder.key] || undefined}
                                                    onChange={(value) =>
                                                        setDynamicExtra((prev) => ({
                                                            ...prev,
                                                            values: { ...prev.values, [placeholder.key]: value },
                                                        }))
                                                    }
                                                >
                                                    {(placeholder.options || []).map((opt) => (
                                                        <Select.Option key={opt} value={opt}>
                                                            {opt}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            ) : placeholder.type === "multiselect" ? (
                                                <Select
                                                    mode="multiple"
                                                    placeholder={`Select ${placeholder.label}`}
                                                    value={dynamicExtra.values[placeholder.key] || []}
                                                    onChange={(value) =>
                                                        setDynamicExtra((prev) => ({
                                                            ...prev,
                                                            values: { ...prev.values, [placeholder.key]: value },
                                                        }))
                                                    }
                                                >
                                                    {(placeholder.options || []).map((opt) => (
                                                        <Select.Option key={opt} value={opt}>
                                                            {opt}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Input
                                                    placeholder={`Enter ${placeholder.label}`}
                                                    value={dynamicExtra.values[placeholder.key] || ""}
                                                    onChange={(e) =>
                                                        setDynamicExtra((prev) => ({
                                                            ...prev,
                                                            values: { ...prev.values, [placeholder.key]: e.target.value },
                                                        }))
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                    ))}
                                </Form>
                            </Modal>

                            {encounters.length > 0 ? (
                                <div style={{ marginTop: "20px" }}>
                                    <Title level={3}>Existing Encounters</Title>
                                    {encounters.map((item) => (
                                        <div
                                            key={item._id}
                                            className="relative mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex"
                                        >
                                            <div className="flex-grow">
                                                <p className="mb-1 font-semibold text-gray-700">
                                                    Encounter added on: <span className="font-normal">{formatDate(item.createdAt)}</span>
                                                    <span className="font-normal"> ({timeAgo(item.createdAt)})</span>
                                                </p>
                                                <div style={{ whiteSpace: "pre-wrap", marginBottom: "1rem" }}>
                                                    <h2 style={{ marginBottom: "0.5rem" }}>Encounter Report:</h2>
                                                    {item.encounterData.section && (
                                                        <h3 style={{ margin: "0.5rem 0", fontWeight: "bold" }}>
                                                            {item.encounterData.section.replace(/_/g, " ")}
                                                        </h3>
                                                    )}
                                                    {item.encounterData.data &&
                                                        Object.entries(item.encounterData.data).map(([key, value], index) => (
                                                            <div key={index} style={{ marginBottom: "0.5rem" }}>
                                                                <div>
                                                                    <strong>{getFieldLabel(item.encounterData.section, key)}:</strong>
                                                                </div>
                                                                <div style={{ marginLeft: "1rem" }}>
                                                                    {Array.isArray(value) ? (
                                                                        value.map((val, idx) => (
                                                                            <div key={idx}>{val}</div>
                                                                        ))
                                                                    ) : typeof value === "object" && value !== null ? (
                                                                        Object.entries(value).map(([subKey, subValue], idx) => (
                                                                            <div key={idx}>
                                                                                <strong>{getFieldLabel(item.encounterData.section, subKey)}:</strong>{" "}
                                                                                {subValue}
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div>{value}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                <p className="mb-1 font-semibold text-gray-700">
                                                    Added by: <span className="font-normal">Dr. {item.doctorId?.fullname || "Unknown"}</span>
                                                </p>
                                            </div>

                                            <div className="relative">
                                                <div className="absolute top-0 bottom-0 border-r-2 border-dotted border-gray-400"></div>
                                            </div>

                                            {item.appointmentId.appointmentHistory[item.appointmentId.appointmentHistory.length - 1]?.encounter?.some(
                                                (enc) => enc._id === item._id
                                            ) && (
                                                    <div className="flex flex-col items-start pl-6">
                                                        <button
                                                            onClick={() => showUpdateModal(item._id)}
                                                            className="px-5 py-1 rounded-md bg-blue-600 text-sky-100 hover:bg-blue-800"
                                                        >
                                                            Update
                                                        </button>
                                                        <button
                                                            onClick={() => handleDelete(item._id)}
                                                            className="px-5 py-1 mt-2 rounded-md bg-red-700 text-sky-100 hover:bg-red-800"
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="mt-3 text-center">
                                    <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                                        No Encounters added!!
                                    </h5>
                                </p>
                            )}
                        </>
                    )}
                </div>
            </div>

            <Modal
                title="Update Encounter"
                open={isUpdateModalOpen}
                onOk={handleUpdateOk}
                onCancel={handleUpdateCancel}
                width={900}
                footer={[
                    <Button key="back" onClick={handleUpdateCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleUpdateOk}>
                        Update
                    </Button>,
                ]}
            >
                <UpdateFirstEncounter
                    ref={updateFirstEncounterRef}
                    encounterId={currentEncounterId}
                    full_name={fullNameData}
                    problem={problemData}
                    appointmentNumber={appointmentNumberData}
                    onClose={() => setIsUpdateModalOpen(false)}
                    onUpdateEncounter={(encounterId, data) => updateEncounter(encounterId, data)}
                    fetchOnMount={isUpdateModalOpen} // Still passed, but now paired with updateTrigger
                    updateTrigger={updateTrigger} // Pass the trigger as a prop
                />
            </Modal>
        </>
    );
};

export default FirstEncounter;