import React, { useState, useEffect } from "react";
import { Table, Input, Button, Space, message, Modal, InputNumber, Select, Switch } from "antd"; // Added Switch import
import axios from "axios";
import moment from "moment";

const { confirm } = Modal;

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const PatientFinancialsPage = () => {
    const [patientName, setPatientName] = useState("");
    const [financialData, setFinancialData] = useState([]);
    const [filteredFinancialData, setFilteredFinancialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [amountModalVisible, setAmountModalVisible] = useState(false);
    const [selectedBillNo, setSelectedBillNo] = useState(null);
    const [customAmount, setCustomAmount] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState("Cash"); // Default to Cash, user can change
    const [searchMode, setSearchMode] = useState(false);

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    // Fetch financial data from /patient-financials endpoint
    const fetchFinancialData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiBaseUrl}/patient-financials`, config);
            if (response.data.success) {
                const data = response.data.data || [];
                setFinancialData(data);
                const filtered = searchMode && patientName.trim()
                    ? data.filter((item) =>
                        item.patient.fullname.toLowerCase().includes(patientName.toLowerCase())
                    )
                    : data;
                setFilteredFinancialData(filtered);
            }
        } catch (error) {
            console.error("Error fetching patient financials:", error);
            message.error("Failed to fetch patient financials");
            setFinancialData([]);
            setFilteredFinancialData([]);
        } finally {
            setLoading(false);
        }
    };

    // Update payment status (Paid or Paid Amount)
    const updatePayment = async (billNo, amountPaid) => {
        try {
            const payload = { billNo, paymentMethod };
            if (amountPaid !== undefined) payload.amountPaid = amountPaid;

            const response = await axios.post(
                `${apiBaseUrl}/patient-financials/update-payment`,
                payload,
                config
            );
            if (response.data.success) {
                message.success("Payment updated successfully");
                fetchFinancialData(); // Refresh data after update
            }
        } catch (error) {
            console.error("Error updating payment:", error);
            message.error("Failed to update payment");
        }
    };

    // Handle "Paid" button click with confirmation
    const handlePaidClick = (billNo) => {
        confirm({
            title: "Are you sure?",
            content: (
                <div>
                    <p>This will mark the bill as fully paid. Confirm to proceed.</p>
                    <Select
                        value={paymentMethod}
                        onChange={(value) => setPaymentMethod(value)}
                        style={{ width: "100%", marginTop: 8 }}
                    >
                        <Select.Option value="Cash">Cash</Select.Option>
                        <Select.Option value="Bank">Bank</Select.Option>
                    </Select>
                </div>
            ),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                updatePayment(billNo); // Proceed with full payment
            },
            onCancel() {
                // Do nothing, just close the modal
            },
        });
    };

    // Handle "Paid Amount" button click
    const handlePaidAmountClick = (billNo) => {
        setSelectedBillNo(billNo);
        setAmountModalVisible(true);
    };

    // Handle modal submission for custom amount
    const handleAmountSubmit = () => {
        if (customAmount !== null && customAmount >= 0) {
            updatePayment(selectedBillNo, customAmount);
            setAmountModalVisible(false);
            setCustomAmount(null);
            setSelectedBillNo(null);
        } else {
            message.warning("Please enter a valid amount");
        }
    };

    // Fetch data on initial load
    useEffect(() => {
        fetchFinancialData();
    }, []);

    // Trigger fetch or filter on search when in search mode
    const handleSearch = () => {
        if (searchMode) {
            fetchFinancialData();
        }
    };

    // Toggle between All Patients and Search Patient mode
    const handleModeToggle = (checked) => {
        setSearchMode(checked);
        setPatientName("");
        fetchFinancialData();
    };

    // Define table columns based on dueBills structure
    const columns = [
        {
            title: "Bill No",
            dataIndex: "billNo",
            key: "billNo",
        },
        {
            title: "Bill Date",
            dataIndex: "billDate",
            key: "billDate",
            render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "N/A"),
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Appointment Date",
            dataIndex: "appointmentDate",
            key: "appointmentDate",
            render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "N/A"),
        },
        {
            title: "Appointment Number",
            dataIndex: "appointmentNumber",
            key: "appointmentNumber",
            render: (number) => number || "N/A",
        },
        {
            title: "Total Net",
            dataIndex: "totalNet",
            key: "totalNet",
            render: (amount) => `$${typeof amount === 'number' ? amount.toFixed(2) : "0.00"}`,
        },
        {
            title: "Amount Paid",
            dataIndex: "amountPaid",
            key: "amountPaid",
            render: (amount) => `$${typeof amount === 'number' ? amount.toFixed(2) : "0.00"}`,
        },
        {
            title: "Due Amount",
            dataIndex: "dueAmount",
            key: "dueAmount",
            render: (amount) => (
                <span style={{ color: "red" }}>
                    ${typeof amount === 'number' ? amount.toFixed(2) : "0.00"}
                </span>
            ),
        },
        {
            title: "Payment Status",
            dataIndex: "paymentStatus",
            key: "paymentStatus",
            render: (status) => (
                <span
                    style={{
                        color:
                            status === "Pending"
                                ? "#fa8c16"
                                : status === "Partially Paid"
                                    ? "#1890ff"
                                    : "#52c41a",
                    }}
                >
                    {status || "Pending"}
                </span>
            ),
        },
        {
            title: "Due Date",
            dataIndex: "dueDate",
            key: "dueDate",
            render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "N/A"),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => handlePaidClick(record.billNo)}
                        disabled={record.paymentStatus === "Paid"}
                        style={{ backgroundColor: "#52c41a", borderColor: "#52c41a" }}
                    >
                        Paid
                    </Button>
                    <Button
                        type="default"
                        size="small"
                        onClick={() => handlePaidAmountClick(record.billNo)}
                        disabled={record.paymentStatus === "Paid"}
                    >
                        Paid Amount
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: 16 }}>
                Patient Financials
            </h1>
            <Space style={{ marginBottom: 16 }}>
                <Switch
                    checked={searchMode}
                    onChange={handleModeToggle}
                    checkedChildren="Search Patient"
                    unCheckedChildren="All Patients"
                    style={{ marginRight: 16 }}
                />
                <Input
                    placeholder="Filter by patient name"
                    value={patientName}
                    onChange={(e) => setPatientName(e.target.value)}
                    onPressEnter={handleSearch}
                    style={{ width: 300 }}
                    disabled={!searchMode}
                />
                <Button type="primary" onClick={handleSearch} disabled={!searchMode}>
                    Filter
                </Button>
                <Button
                    type="default"
                    onClick={() => {
                        setPatientName("");
                        setFilteredFinancialData(financialData);
                    }}
                >
                    Clear Filter
                </Button>
            </Space>

            {loading ? (
                <div>Loading...</div>
            ) : filteredFinancialData.length > 0 ? (
                filteredFinancialData.map((patientData, index) => (
                    <div key={index} style={{ marginBottom: 24 }}>
                        <div style={{ marginBottom: 16 }}>
                            <strong style={{ fontSize: "18px" }}>
                                Financial Summary for "{patientData.patient.fullname}":
                            </strong>
                            <div>
                                Total Dues: <span style={{ color: "red" }}>${patientData.totalDues.toFixed(2)}</span>
                            </div>
                            <div>
                                Total Cleared: <span style={{ color: "green" }}>${patientData.totalCleared.toFixed(2)}</span>
                            </div>
                            <div>Phone: {patientData.patient.phone}</div>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={patientData.dueBills}
                            rowKey="billNo"
                            pagination={{ pageSize: 10 }}
                            locale={{ emptyText: "No outstanding bills for this patient" }}
                            style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}
                        />
                    </div>
                ))
            ) : (
                <p>No financial data available.</p>
            )}

            {/* Modal for entering custom payment amount with payment method selection */}
            <Modal
                title="Enter Payment Amount"
                visible={amountModalVisible}
                onOk={handleAmountSubmit}
                onCancel={() => {
                    setAmountModalVisible(false);
                    setCustomAmount(null);
                    setSelectedBillNo(null);
                }}
                okText="Submit"
                cancelText="Cancel"
            >
                <InputNumber
                    value={customAmount}
                    onChange={(value) => setCustomAmount(value)}
                    min={0}
                    placeholder="Enter amount"
                    style={{ width: "100%", marginBottom: 16 }}
                />
                <Select
                    value={paymentMethod}
                    onChange={(value) => setPaymentMethod(value)}
                    style={{ width: "100%" }}
                >
                    <Select.Option value="Cash">Cash</Select.Option>
                    <Select.Option value="Bank">Bank</Select.Option>
                </Select>
            </Modal>
        </div>
    );
};

export default PatientFinancialsPage;