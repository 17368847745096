import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { getOrderStatus } from "../lib/helpers";
import { Table, Space, Input, Spin, message, DatePicker, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import DynamicModal from "./dynamicModel"; // Adjust path if necessary
import UpdateAppointmentRequestByStaff from "./updateAppointmentRequestByStaff";

const { RangePicker } = DatePicker;

const ViewAppointmentRequestFromPatient = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalTitle, setDynamicModalTitle] = useState("");
  const [dynamicModalContent, setDynamicModalContent] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  // Dynamic auth config
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];
    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} };
    }
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
      },
    };
  };

  const config = getAuthConfig();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiBaseUrl}/appointment`, config);
      if (response.data.success) {
        const pendingAppointments = response.data.data.filter(
          (appointment) => appointment.status === "Pending"
        );
        setDetails(pendingAppointments);
        setFilteredDetails(pendingAppointments);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
      message.error("Failed to get appointment details! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const filterAppointments = useCallback(
    debounce((searchTerm, dateRange) => {
      let filtered = details;
      if (searchTerm.trim() !== "") {
        filtered = details.filter(
          (appointment) =>
            appointment?.appointmentNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.patientId?.userID
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.fullname
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.mobile.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      if (dateRange && dateRange[0] && dateRange[1]) {
        const [startDate, endDate] = dateRange.map((date) =>
          date.format("YYYY-MM-DD")
        );
        filtered = filtered.filter((appointment) => {
          const appointmentDate = appointment.date;
          return appointmentDate >= startDate && appointmentDate <= endDate;
        });
      }
      setFilteredDetails(filtered);
    }, 500),
    [details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterAppointments(searchTerm, dateRange);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates || [null, null]);
    filterAppointments(searchTerm, dates);
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setDateRange([null, null]);
    setFilteredDetails(details);
  };

  const openDynamicModal = (title, content) => {
    setDynamicModalTitle(title);
    setDynamicModalContent(content);
    setIsDynamicModalOpen(true);
  };

  const closeDynamicModal = () => {
    setIsDynamicModalOpen(false);
    setDynamicModalTitle("");
    setDynamicModalContent(null);
    fetchData(); // Refresh table after modal closes
  };

  const columns = [
    {
      title: "Appointment No.",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Doctor",
      key: "doctor",
      render: (_, record) => {
        const { refer, doctorId } = record;
        if (refer) {
          if (refer?.toDoctor) {
            return refer.toDoctor.fullname;
          } else {
            return <span className="text-red-500">Not assigned</span>;
          }
        } else {
          return doctorId.fullname;
        }
      },
    },
    {
      title: "Department",
      key: "department",
      render: (_, record) => {
        const { department, refer } = record;
        if (refer) {
          return refer.toDepartment.department;
        } else {
          return department.department;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>{status === "Pending" && getOrderStatus(status)}</span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            onClick={() =>
              openDynamicModal("Update Appointment Request", (
                <UpdateAppointmentRequestByStaff
                  appointmentId={record._id}
                  onSuccess={closeDynamicModal}
                />
              ))
            }
            className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
          >
            Update Request
          </button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            Appointment Requests
          </h1>
          <div className="flex justify-between mt-3">
            <div>
              <Input
                placeholder="Search..."
                prefix={<SearchOutlined />}
                allowClear
                value={searchTerm}
                onChange={handleSearch}
                style={{ width: 250 }}
              />
              <RangePicker
                style={{ marginLeft: "1rem" }}
                format="YYYY-MM-DD"
                value={dateRange}
                onChange={handleDateChange}
              />
              <Button
                type="default"
                onClick={handleResetFilters}
                style={{ marginLeft: "1rem" }}
              >
                Reset Filters
              </Button>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>

      {/* Dynamic Modal */}
      {isDynamicModalOpen && (
        <DynamicModal
          isOpen={isDynamicModalOpen}
          onClose={closeDynamicModal}
          title={dynamicModalTitle}
        >
          {dynamicModalContent}
        </DynamicModal>
      )}
    </>
  );
};

export default ViewAppointmentRequestFromPatient;