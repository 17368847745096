import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineLocalHospital } from "react-icons/md";
import { HiOutlineLogout } from "react-icons/hi";
import { Menu, Input } from "antd";
import "./sidebar.css";
import {
  DASHBOARD_SIDEBAR_LINKS,
  DASHBOARD_SIDEBAR_BOTTOM_LINKS,
  ADMIN_DASHBOARD_USER_SIDEBAR_LINKS,
  ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS,
} from "../../lib/constants";
import { SearchOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

// Modal component for logout confirmation
const Modal = ({ title, message, onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-gray-900 opacity-55 cursor-pointer"
        onClick={onClose}
      ></div>
      <div className="bg-white w-96 p-5 rounded-lg shadow-lg z-50">
        <div className="text-lg font-semibold mb-4">{title}</div>
        <div className="mb-4">{message}</div>
        <div className="flex justify-end">
          <button
            className="bg-gray-200 px-4 py-2 rounded-md mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openKeys, setOpenKeys] = useState([]);

  const handleLogout = () => {
    setIsModalOpen(true);
  };

  // Update selected menu item based on current path
  useEffect(() => {
    const updateSelectedKeys = () => {
      const allLinks = [
        ...DASHBOARD_SIDEBAR_LINKS,
        ...DASHBOARD_SIDEBAR_BOTTOM_LINKS,
        ...(localStorage.getItem("adminticket") ? ADMIN_DASHBOARD_USER_SIDEBAR_LINKS : []),
        ...(localStorage.getItem("adminticket") ? ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS : []),
      ];

      const flattenedLinks = allLinks.reduce((acc, link) => {
        if (link.children) {
          return [...acc, ...link.children];
        }
        return [...acc, link];
      }, []);

      const activeLink = flattenedLinks.find((link) => link.path === location.pathname);
      if (activeLink) {
        setSelectedKeys([activeLink.key]);
        const parentLink = allLinks.find((link) =>
          link.children && link.children.some((child) => child.key === activeLink.key)
        );
        if (parentLink) {
          setOpenKeys([parentLink.key]);
        }
      } else {
        setSelectedKeys([]);
      }
    };
    updateSelectedKeys();
  }, [location.pathname]);

  // Render individual sidebar links or submenus
  const renderSidebarLink = (link) => {
    if (link.isHeading) {
      return (
        <Menu.Item key={link.key} className="sidebar-heading">
          {link.label}
        </Menu.Item>
      );
    }
    if (link.children) {
      return (
        <SubMenu
          key={link.key}
          icon={<span className="sidebar-icon">{link.icon}</span>}
          title={link.label}
          className="sidebar-submenu"
        >
          {link.children.map((child) => (
            <Menu.Item
              key={child.key}
              className={classNames(
                Array.isArray(selectedKeys) && selectedKeys.includes(child.key)
                  ? "bg-blue-100 text-blue-800"
                  : "text-neutral-800",
                "sidebar-link"
              )}
            >
              <Link to={child.path}>{child.label}</Link>
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }
    return (
      <Menu.Item
        key={link.key}
        icon={<span className="sidebar-icon">{link.icon}</span>}
        className={classNames(
          Array.isArray(selectedKeys) && selectedKeys.includes(link.key)
            ? "bg-blue-100 text-blue-800"
            : "text-neutral-800",
          "sidebar-link"
        )}
      >
        <Link to={link.path}>{link.label}</Link>
      </Menu.Item>
    );
  };

  const isAdmin = localStorage.getItem("adminticket");

  // Filter menu items based on search input
  const filteredMenuItems = DASHBOARD_SIDEBAR_LINKS.map((link) => {
    if (!searchText) return link;
    if (link.isHeading) {
      return link.label.toLowerCase().includes(searchText.toLowerCase()) ? link : null;
    }
    if (link.children) {
      const filteredChildren = link.children.filter((child) =>
        child.label.toLowerCase().includes(searchText.toLowerCase())
      );
      if (filteredChildren.length > 0 || link.label.toLowerCase().includes(searchText.toLowerCase())) {
        return { ...link, children: filteredChildren };
      }
      return null;
    }
    return link.label.toLowerCase().includes(searchText.toLowerCase()) ? link : null;
  }).filter(Boolean);

  const filteredAdminUserMenu = isAdmin
    ? ADMIN_DASHBOARD_USER_SIDEBAR_LINKS.map((link) => {
      if (!searchText) return link;
      if (link.isHeading) {
        return link.label.toLowerCase().includes(searchText.toLowerCase()) ? link : null;
      }
      return link.label.toLowerCase().includes(searchText.toLowerCase()) ? link : null;
    }).filter(Boolean)
    : [];

  const filteredAdminTestBillMenu = isAdmin
    ? ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS.map((link) => {
      if (!searchText) return link;
      if (link.isHeading) {
        return link.label.toLowerCase().includes(searchText.toLowerCase()) ? link : null;
      }
      return link.label.toLowerCase().includes(searchText.toLowerCase()) ? link : null;
    }).filter(Boolean)
    : [];

  const filteredBottomMenu = DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => {
    if (!searchText) return link;
    if (link.isHeading) {
      return link.label.toLowerCase().includes(searchText.toLowerCase()) ? link : null;
    }
    return link.label.toLowerCase().includes(searchText.toLowerCase()) ? link : null;
  }).filter(Boolean);

  return (
    <div className="bg-white w-52 flex flex-col relative sidebar-container">
      <Link to={"/dashboard"}>
        <div className="flex items-center gap-2 mx-2 mt-2 mb-3">
          <MdOutlineLocalHospital fontSize={24} color="black" />
          <span className="text-gray-700 text-base font-medium">
            Your Clinic
          </span>
        </div>
      </Link>

      <div style={{ padding: "8px 16px" }}>
        <Input
          placeholder="Search menu..."
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: "100%", borderRadius: "4px" }}
        />
      </div>

      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={(keys) => setOpenKeys(keys)}
        onSelect={(keys) => setSelectedKeys(keys)}
        className="sidebar-menu flex-1"
      >
        {filteredMenuItems.map(renderSidebarLink)}
        {filteredAdminUserMenu.length > 0 && filteredAdminUserMenu.map(renderSidebarLink)}
        {filteredAdminTestBillMenu.length > 0 && filteredAdminTestBillMenu.map(renderSidebarLink)}
        {filteredBottomMenu.map(renderSidebarLink)}
      </Menu>

      <div className="sidebar-footer">
        <div
          className={classNames(
            "sidebar-logout flex items-center gap-2 px-4 py-2 cursor-pointer",
            {
              "bg-blue-100 text-blue-800": Array.isArray(selectedKeys) && selectedKeys.includes("logout"),
            }
          )}
          onClick={handleLogout}
        >
          <span className="sidebar-icon">
            <HiOutlineLogout />
          </span>
          <span>Logout</span>
        </div>
      </div>

      {isModalOpen && (
        <Modal
          title="Logout"
          message="Are you sure you want to log out?"
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => {
            localStorage.clear();
            navigate("/", { replace: true });
          }}
        />
      )}
    </div>
  );
}