import { Modal } from "antd";
import React from 'react';

const DynamicModal = ({ isOpen, onClose, title, children }) => {
    return (
        <Modal
            title={title}
            open={isOpen}
            onCancel={onClose}
            footer={null}
            width="80%"
            style={{ top: 20 }}
            bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
        >
            <div>
                {children ? React.cloneElement(children, { onClose }) : <p>No content available</p>}
            </div>
        </Modal>
    );
};

export default DynamicModal;