import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { message, Row, Col, Typography } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";

const { Title } = Typography;

const AddFollowUpDateTime = ({
  appointmentId,
  full_name, // Added as a prop instead of fetching
  status: initialStatus, // Optional prop for initial status
  defaultDepartment: initialDepartment, // Optional prop for pre-filled department
  defaultDoctor: initialDoctor, // Optional prop for pre-filled doctor
}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [patientSelectedName, setPatientSelectedName] = useState(full_name || "");
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [defaultDepartment, setDefaultDepartment] = useState(initialDepartment || {});
  const [defaultDoctor, setDefaultDoctor] = useState(initialDoctor || {});
  const [status, setStatus] = useState(initialStatus || "");
  const [time, setTime] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");

  // Dynamic auth config based on localStorage ticket
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];

    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} };
    }

    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
      },
    };
  };

  const config = getAuthConfig();

  // Fetch appointment details by ID if props aren’t fully provided
  useEffect(() => {
    const fetchAppointmentDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiBaseUrl}/staff/getBookedDoctorAppointment/${appointmentId}`,
          config
        );
        if (response.data.success) {
          const data = response.data.data;
          setPatientSelectedName(full_name || data.fullname);
          setStatus(initialStatus || data.status);
          if (!initialDepartment || !initialDoctor) {
            if (data.refer?.toDoctor) {
              setDefaultDepartment({
                value: data.refer.toDepartment._id,
                label: data.refer.toDepartment.department,
              });
              setDefaultDoctor({
                value: data.refer.toDoctor._id,
                label: data.refer.toDoctor.fullname,
              });
              setDoctorSelectedId(data.refer.toDoctor._id);
            } else {
              setDefaultDepartment({
                value: data.department._id,
                label: data.department.department,
              });
              setDefaultDoctor({
                value: data.doctorId._id,
                label: data.doctorId.fullname,
              });
              setDoctorSelectedId(data.doctorId._id);
            }
          } else {
            setDoctorSelectedId(initialDoctor.value);
          }
        }
      } catch (error) {
        console.error("Failed to get appointment detail", error);
        message.error("Failed to get selected appointment detail! Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchAppointmentDetails();
  }, [appointmentId, full_name, initialStatus, initialDepartment, initialDoctor]);

  // Submit follow-up date and time
  const addFollowUpDateTime = async (e) => {
    e.preventDefault();
    setActionLoading(true);

    const data = {
      status,
      assigningDoctorId: doctorSelectedId,
      followUp_appointmentDate: appointmentDate,
      followUp_appointmentTime: time,
    };

    try {
      const response = await axios.post(
        `${apiBaseUrl}/add/datetime/staff/appointment/followup/${appointmentId}`,
        data,
        config
      );
      if (response.data.success) {
        message.success("DateTime Assigned Successfully", 0.6, () => {
          window.location.replace("/dashboard/view/inprogress/appointment");
        });
      } else {
        message.error("Something went wrong! Please try again.");
      }
    } catch (err) {
      console.error("Error booking appointment:", err);
      message.error("Error booking appointment! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    setTime(formattedTime);
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={addFollowUpDateTime}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Add Followup Date-Time</Title>
          </div>
          <p className="text-base -mb-2 -mt-1">
            Patient Name:{" "}
            <span className="text-gray-700 italic">{patientSelectedName}</span>
          </p>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                placeholder="Select department"
                value={defaultDepartment}
                isSearchable
                isDisabled
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Doctor</label>
              <Select
                value={defaultDoctor}
                placeholder="Select doctor"
                isSearchable
                isDisabled
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Date</label>
              <input
                id="appointmentDate"
                type="date"
                onChange={(e) => setAppointmentDate(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                name="appointmentDate"
                placeholder="Select Date"
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Time</label>
              <Flatpickr
                data-enable-time
                data-disable-date
                placeholder="Pick a time"
                onChange={handleTimeChange}
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md transition-colors duration-200"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "h:i K",
                  time_24hr: false,
                  allowInput: true,
                  clearIcon: true,
                }}
                required
              />
            </Col>
          </Row>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddFollowUpDateTime;