import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { message, Select, Row, Col } from "antd";
import { testTypesData } from "./report_data";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "../placeholder.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

const calculateAgeNumber = (dob) => {
    if (!dob) return 0;

    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
};

const { Option } = Select;

const AddSelfReport = ({ onClose, onAdd }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { appointmentId } = useParams();
    const location = useLocation();

    // New patient fields
    const [fullName, setFullName] = useState("");
    const [address, setAddress] = useState("");
    const [age, setAge] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("");

    // Original states
    const [patientDetails, setPatientDetails] = useState([]);
    const [patientStoringValue, setPatientStoringValue] = useState("");
    const [inputAge, setInputAge] = useState("");
    const [patientSelectedId, setPatientSelectedId] = useState(null);
    const [patientSelectedContact, setPatientSelectedContact] = useState("");
    const [patientSelectedName, setPatientSelectedName] = useState("");
    const [defaultDateBS, setDefaultDateBS] = useState(null);
    const [DOB, setDOBinAD] = useState("");
    const [fullName_state, setFullName_state] = useState("");
    const [problem_state, setProblem] = useState("");
    const [followUp, setFollowUp] = useState("");
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(true);
    const [doctorDepartment, setDoctorDepartment] = useState("");
    const [actionLoading, setActionLoading] = useState(false);
    const [singleAppointmentData, setSingleAppointmentData] = useState(null);
    const [reportName, setReportName] = useState("");
    const [isDOBMatched, setIsDOBMatched] = useState(false);
    const [dateBS, setDateBS] = useState("");
    const [dateAD, setDateAD] = useState("");
    const [idCounter, setIdCounter] = useState(0);
    const [reportList, setReportList] = useState([
        {
            test: "",
            result: "",
            unit: "",
            refRange: "",
        },
    ]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [unitOptions] = useState([
        "mg/dl",
        "mg/l",
        "Between 4-5%",
        "Mm/hr",
        "gm/dl",
        "/cumm",
        "millions/cumm",
        "/Hpf",
        "g/dl",
        "mmol/L",
        "µmol/L",
        "mEq/L",
        "ng/mL",
        "pg/mL",
        "U/L",
        "IU/L",
        "%",
        "K/µL",
        "cells/mcL",
        "mm/hr",
        "fL",
        "pg",
        "g/L",
        "ratio",
    ]);
    const [testTypes] = useState(testTypesData);
    const [details, setDetails] = useState([]);
    const [removedTestNames, setRemovedTestNames] = useState([]);

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
        },
    };

    useEffect(() => {
        axios
            .get(`${apiBaseUrl}/viewpatientsLabReporter`, config)
            .then((response) => {
                if (response.data.success) {
                    const options = response.data.data.map((patient) => ({
                        value: patient._id,
                        name_value: `${patient.fullname}`,
                        label: `${patient.fullname} (${patient.phone})`,
                        contact: patient.phone,
                        DOB: patient.DOB,
                        DOBinBS: patient.DOBinBS,
                        address: patient.address,
                        children: `${patient.fullname} ${patient.phone} ${patient.address}`,
                    }));
                    setPatientDetails(options);
                }
            })
            .catch((e) => {
                console.error("Failed to get user details:", e);
                message.error("Failed to get user details! Please try again.");
            });
    }, []);

    const getResultColor = (result, refRange) => {
        if (!result || !refRange) return "bg-white";
        const isWithinRange = true;
        return isWithinRange ? "bg-blue-50" : "bg-red-50";
    };

    const isTitleTest = (test) => {
        return (
            test.test !== "" &&
            test.result === "" &&
            test.unit === "" &&
            test.refRange === ""
        );
    };

    const handleInputChange = (index, key, value) => {
        const updatedList = [...reportList];
        updatedList[index][key] = value;
        setReportList(updatedList);
    };

    const handleTestTypeChange = (value) => {
        if (!value) return;
        if (!selectedTypes.includes(value)) {
            setSelectedTypes([...selectedTypes, value]);
            const newReportName =
                selectedTypes.length > 0
                    ? reportName + "," + testTypes[value].reportName
                    : testTypes[value].reportName;
            setReportName(newReportName);
            const isDefaultEmpty =
                reportList.length === 1 &&
                Object.values(reportList[0]).every((v) => v === "");
            const newFields = testTypes[value].fields;
            setReportList(isDefaultEmpty ? newFields : [...reportList, ...newFields]);
        }
    };

    const removeTestType = (typeToRemove) => {
        const updatedTypes = selectedTypes.filter((type) => type !== typeToRemove);
        setSelectedTypes(updatedTypes);
        const newReportName = updatedTypes
            .map((type) => testTypes[type].reportName)
            .join(",");
        setReportName(newReportName);
        const removedTypeFieldCount = testTypes[typeToRemove].fields.length;
        const currentIndex = selectedTypes.indexOf(typeToRemove);
        const startIndex = selectedTypes
            .slice(0, currentIndex)
            .reduce((acc, type) => acc + testTypes[type].fields.length, 0);
        const updatedReportList = [
            ...reportList.slice(0, startIndex),
            ...reportList.slice(startIndex + removedTypeFieldCount),
        ];
        setReportList(
            updatedReportList.length
                ? updatedReportList
                : [
                    {
                        test: "",
                        result: "",
                        unit: "",
                        refRange: "",
                    },
                ]
        );
    };

    const addTest = () => {
        setReportList([
            ...reportList,
            {
                test: "",
                result: "",
                unit: "",
                refRange: "",
            },
        ]);
    };

    const removeGroup = (startIndex) => {
        let endIndex = reportList.length;
        for (let i = startIndex + 1; i < reportList.length; i++) {
            if (isTitleTest(reportList[i])) {
                endIndex = i;
                break;
            }
        }
        const removedTests = reportList.slice(startIndex, endIndex);
        const titleTest = removedTests.find(isTitleTest);
        if (titleTest) {
            const testType = selectedTypes.find((type) =>
                testTypes[type].fields.some((field) => field.test === titleTest.test)
            );
            setRemovedTestNames((prev) => [
                ...prev,
                { testName: titleTest.test, testType },
            ]);
        }
        const updatedList = [
            ...reportList.slice(0, startIndex),
            ...reportList.slice(endIndex),
        ];
        setReportList(updatedList);
    };

    const removeMedicine = (index) => {
        const removedTest = reportList[index];
        if (isTitleTest(removedTest)) {
            const testType = selectedTypes.find((type) =>
                testTypes[type].fields.some((field) => field.test === removedTest.test)
            );
            setRemovedTestNames((prev) => [
                ...prev,
                { testName: removedTest.test, testType },
            ]);
            let endIndex = reportList.length;
            for (let i = index + 1; i < reportList.length; i++) {
                if (isTitleTest(reportList[i])) {
                    endIndex = i;
                    break;
                }
            }
            const newList = [...reportList.slice(0, index), ...reportList.slice(endIndex)];
            setReportList(newList);
        } else {
            const newList = [...reportList];
            newList.splice(index, 1);
            setReportList(newList);
        }
    };

    const reAddTestFields = (testName, testType) => {
        const testTypeData = testTypes[testType];
        if (!testTypeData) return;

        const fieldsToAdd = [];
        let foundTitle = false;
        for (let i = 0; i < testTypeData.fields.length; i++) {
            const field = testTypeData.fields[i];
            if (field.test === testName && isTitleTest(field)) {
                foundTitle = true;
                fieldsToAdd.push(field);
                continue;
            }
            if (foundTitle) {
                if (isTitleTest(field)) {
                    break;
                }
                fieldsToAdd.push(field);
            }
        }

        if (fieldsToAdd.length === 0) return;

        const isDefaultEmpty =
            reportList.length === 1 && Object.values(reportList[0]).every((v) => v === "");
        setReportList(isDefaultEmpty ? fieldsToAdd : [...reportList, ...fieldsToAdd]);

        setRemovedTestNames((prev) =>
            prev.filter((item) => !(item.testName === testName && item.testType === testType))
        );
    };

    const renderReportList = () => {
        const handleKeyDown = (e, index) => {
            if (e.key === "ArrowDown" && index < reportList.length - 1) {
                e.preventDefault();
                const nextResultInput = document.querySelector(`#result-input-${index + 1}`);
                if (nextResultInput) {
                    nextResultInput.focus();
                }
            } else if (e.key === "ArrowUp" && index > 0) {
                e.preventDefault();
                const prevResultInput = document.querySelector(`#result-input-${index - 1}`);
                if (prevResultInput) {
                    prevResultInput.focus();
                }
            }
        };

        return (
            <div className="mt-4 space-y-4">
                <table className="w-full">
                    <thead>
                        <tr className="border-b">
                            <th className="text-left p-2">Test Name</th>
                            <th className="text-left p-2">Result</th>
                            <th className="text-left p-2 w-1/4">Unit</th>
                            <th className="text-left p-2">Ref-Range</th>
                            <th className="text-left p-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportList.map((medicine, index) => (
                            <tr
                                className={`border-b ${isTitleTest(medicine) ? "bg-gray-50" : ""}`}
                                key={index}
                            >
                                <td className="p-2">
                                    <input
                                        className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:border-transparent transition-all duration-200"
                                        type="text"
                                        required
                                        placeholder="eg.. LFT Test"
                                        value={medicine.test}
                                        onChange={(e) => handleInputChange(index, "test", e.target.value)}
                                    />
                                </td>
                                <td className="p-2">
                                    <input
                                        id={`result-input-${index}`}
                                        className={`border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ${getResultColor(
                                            medicine.result,
                                            medicine.refRange
                                        )}`}
                                        type="text"
                                        placeholder="eg.. 2.76"
                                        value={medicine.result}
                                        onChange={(e) => handleInputChange(index, "result", e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                </td>
                                <td className="p-2">
                                    <Select
                                        showSearch
                                        className="w-full"
                                        placeholder="Select unit"
                                        value={medicine.unit}
                                        onChange={(value) => handleInputChange(index, "unit", value)}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        allowClear
                                        popupClassName="rounded-md shadow-lg"
                                    >
                                        {unitOptions.map((unit) => (
                                            <Option key={unit} value={unit}>
                                                {unit}
                                            </Option>
                                        ))}
                                    </Select>
                                </td>
                                <td className="p-2">
                                    <textarea
                                        className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                        placeholder="Range"
                                        value={medicine.refRange}
                                        onChange={(e) => handleInputChange(index, "refRange", e.target.value)}
                                        style={{
                                            maxHeight: "75px",
                                            minHeight: "38px",
                                            resize: "none",
                                            padding: "9px",
                                            boxSizing: "border-box",
                                            fontSize: "15px",
                                        }}
                                    />
                                </td>
                                <td className="p-2">
                                    <IconButton
                                        onClick={() => removeMedicine(index)}
                                        color="danger"
                                        aria-label="minus button"
                                        className="hover:bg-red-100 transition-colors duration-200"
                                    >
                                        <AiFillMinusCircle className="text-red-500 h-5 w-5" />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const handleDateInBS = ({ bsDate, adDate }) => {
        setDateBS(bsDate);
        setDateAD(adDate);
        setDefaultDateBS(bsDate);
    };

    const handleClear = () => {
        setDefaultDateBS(null);
        setDateBS(null);
    };

    const handleActionLoading = (value) => {
        setActionLoading(value);
    };

    const handlePatientInputChange = (value, option) => {
        if (option) {
            setPatientStoringValue(option);
            setPatientSelectedId(option.value);
            setPatientSelectedContact(option.contact);
            setPatientSelectedName(option.name_value);
            setAddress(option.address);
            const convertedDOB = option.DOB?.split("T")[0];
            const age = calculateAgeNumber(convertedDOB);
            setInputAge(age);
            setDOBinAD(convertedDOB);
        }
    };

    const handleAgeChange = (e) => {
        const inputAge = e.target.value;
        setInputAge(inputAge);
        try {
            if (inputAge) {
                const today = new Date();
                const birthYear = today.getFullYear() - inputAge;
                const birthDate = new Date(birthYear, today.getMonth(), today.getDate());
                const calculatedDOB = birthDate.toISOString().split("T")[0];
                setDOBinAD(calculatedDOB);
            } else {
                setDOBinAD("");
            }
        } catch (error) {
            message.error("Please enter valid age.");
        }
    };

    const addReport = async (e) => {
        e.preventDefault();
        handleActionLoading(true);

        if (!fullName || !age || !gender) {
            handleActionLoading(false);
            message.error("Full Name, Age, and Gender are required");
            return;
        }

        const isReportNameEmpty = reportName === "";
        const isReportListEmpty =
            reportList.length === 0 ||
            JSON.stringify(reportList[0]) ===
            JSON.stringify({
                test: "",
                result: "",
                unit: "",
                refRange: "",
            });

        const isReportListValid = reportList.every((item, index) => {
            if (item.test && !item.result) {
                const subsequentTestHasResult = reportList
                    .slice(index + 1)
                    .some((nextItem) => nextItem.test && nextItem.result);
                return subsequentTestHasResult;
            }
            return true;
        });

        const isMedicinePartiallyFilled = (medicine) => {
            const { test, result, unit, refRange } = medicine;
            const allFieldsEmpty = !test && !result && !unit && !refRange;
            const allFieldsFilled = test;
            return !(allFieldsEmpty || allFieldsFilled);
        };

        const hasPartiallyFilledMedicine = reportList.some(isMedicinePartiallyFilled);

        const data = {
            report: reportList,
            reportName: reportName,
            self: true,
            fullname: fullName,
            address: address,
            age: parseInt(age),
            phone: phone,
            gender: gender,
        };

        if (isReportNameEmpty && isReportListEmpty) {
            handleActionLoading(false);
            message.error("Both medicine and followup cannot be empty");
        } else if (!isReportListValid) {
            handleActionLoading(false);
            message.error(
                "If a test name is entered, at least one test below it must have a result filled in."
            );
        } else if (hasPartiallyFilledMedicine) {
            handleActionLoading(false);
            message.error("Medicine fields cannot be partially filled");
        } else {
            try {
                let response;
                if (localStorage.getItem("labreporterticket")) {
                    response = await axios.post(`${apiBaseUrl}/add-self-report`, data, config);
                }
                if (response.data.success === true) {
                    message.success("Report Added", 0.6, () => {
                        onAdd();
                        onClose();
                    });
                } else {
                    message.error("Failed To add report");
                }
            } catch (error) {
                console.error("Failed to add report:", error);
                message.error("Failed To add report! Please try again.");
            } finally {
                handleActionLoading(false);
            }
        }
    };

    const titleTestIndices = reportList.reduce((acc, test, index) => {
        if (isTitleTest(test)) {
            acc.push({ index: index, type: reportList[index].test });
        }
        return acc;
    }, []);

    const handleReportChange = (value) => {
        setReportName(value);
    };

    return (
        <>
            {actionLoading && <LoadingScreen />}
            <div className="mb-3">
                <h1 className="text-3xl font-bold decoration-gray-400">Add Report</h1>
            </div>
            <div className="flex items-start">
                <form
                    className="forbox w-full max-w-7xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
                    onSubmit={addReport}
                >
                    <div className="block p-6 items-center bg-white border border-gray-200 rounded-lg shadow">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <label className="text-base">Full Name</label>
                                <input
                                    className="border border-solid border-gray-300 py-2 px-2 w-full rounded-md"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    placeholder="Full Name"
                                    required
                                />
                            </Col>
                            <Col span={12}>
                                <label className="text-base">Phone</label>
                                <input
                                    className="border border-solid border-gray-300 py-2 px-2 w-full rounded-md"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="Phone"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <label className="text-base">Address</label>
                                <input
                                    className="border border-solid border-gray-300 py-2 px-2 w-full rounded-md"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder="Address"
                                />
                            </Col>
                            <Col span={6}>
                                <label className="text-base">Age</label>
                                <input
                                    className="border border-solid border-gray-300 py-2 px-2 w-full rounded-md"
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    type="number"
                                    placeholder="Age"
                                    required
                                />
                            </Col>
                            <Col span={6}>
                                <label className="text-base">Gender</label>
                                <Select
                                    className="w-full h-10"
                                    value={gender}
                                    onChange={(value) => setGender(value)}
                                    placeholder="Select Gender"
                                    required
                                >
                                    <Option value="">Select Gender</Option>
                                    <Option value="Male">Male</Option>
                                    <Option value="Female">Female</Option>
                                    <Option value="Other">Other</Option>
                                </Select>
                            </Col>
                        </Row>

                        <div className="w-full md:w-1/4 mb-6 md:mb-0">
                            <label
                                className="block uppercase tracking-wide text-xs font-bold mb-2"
                                htmlFor="test-type"
                            >
                                Test Type
                            </label>
                            <Select
                                showSearch
                                className="w-full"
                                placeholder="Select test type"
                                onChange={handleTestTypeChange}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={null}
                                allowClear
                            >
                                {Object.keys(testTypes).map((type) => (
                                    <Option key={type} value={type}>
                                        {type}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {selectedTypes.map((type) => (
                                <div
                                    key={type}
                                    className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                                >
                                    <span className="mr-1">{type}</span>
                                    <button
                                        type="button"
                                        onClick={() => removeTestType(type)}
                                        className="ml-1 text-gray-500 hover:text-gray-700"
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>
                        <p className="text-xl mb-1 font-bold tracking-tight text-gray-800">
                            Add Report
                        </p>
                        <div className="font-normal text-gray-700">
                            <div className="w-full md:w-1/4 mb-6 md:mb-0">
                                <label
                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                    htmlFor="grid-zip"
                                >
                                    Report Name
                                </label>
                                <input
                                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-300"
                                    type="text"
                                    required
                                    placeholder="eg.. Report Name"
                                    value={reportName}
                                    onChange={(e) => handleReportChange(e.target.value)}
                                />
                            </div>
                            {removedTestNames.length > 0 && (
                                <div className="mt-2">
                                    <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                                        Removed Tests
                                    </label>
                                    <div className="flex flex-wrap gap-2">
                                        {removedTestNames.map((item, idx) => (
                                            <div
                                                key={item.testName + idx}
                                                className="inline-flex items-center px-2 py-1 bg-red-100 rounded-md cursor-pointer"
                                                onClick={() => reAddTestFields(item.testName, item.testType)}
                                            >
                                                <span className="mr-1">{item.testName}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-wrap gap-2 mt-2">
                                {titleTestIndices.map((type) => (
                                    <div
                                        key={type.type}
                                        className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                                    >
                                        <span className="mr-1">{type.type}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeGroup(type.index)}
                                            className="ml-1 text-gray-500 hover:text-gray-700"
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>
                            {renderReportList()}

                            <IconButton onClick={addTest} color="primary" aria-label="add button">
                                Add Test field <HiPlusCircle />
                            </IconButton>
                            <button
                                id="addStaffBtn"
                                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddSelfReport;