import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Table, Input, Spin, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import { FaPrint } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import ReportViewModal from "./report/report_modal"; // Keep this for direct usage
import UpdateReport from "./report/update_report"; // Import UpdateReport for editing
import DynamicModal from "./dynamicModel"; // Adjust the path if necessary
import ReportPrintout from "./printout/reportPrintout";

const ViewLabReports = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  // States for ReportViewModal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  // States for dynamic modal
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalTitle, setDynamicModalTitle] = useState("");
  const [dynamicModalContent, setDynamicModalContent] = useState(null);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/view/labreports`, config)
      .then((response) => {
        console.log("reports", response.data);
        setDetails(response.data);
        setFilteredDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching lab reports:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReportDelete = async (report_id) => {
    try {
      setActionLoading(true);
      if (window.confirm("Are you sure want to delete this report?")) {
        const response = await axios.delete(
          `${apiBaseUrl}/report/delete/${report_id}`,
          config
        );
        if (response.data.success) {
          message.success(response.data.message);
          fetchData();
          setIsDynamicModalOpen(false); // Close dynamic modal if open
          setIsModalOpen(false); // Close ReportViewModal if open
          setDetails((prevDetails) =>
            prevDetails.filter((item) => item._id !== report_id)
          ); // Immediately update local state
        } else {
          message.error(
            response?.error?.message || "Failed to delete the report."
          );
        }
      }
    } catch (e) {
      console.error("Failed to delete report:", e);
      message.error("Failed to delete! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const filterReports = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter(
          (report) =>
            report?.appointmentId?.appointmentNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report?.patientId?.userID
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report?.appointmentId?.fullname
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report?.appointmentId?.mobile
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report?.reportName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        fetchData();
      }
    }, 500),
    [searchTerm, details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterReports(searchTerm);
  };

  const shouldShowButtons = (record) => {
    if (!record._id) return false;

    const history = record?.appointmentId.appointmentHistory || [];
    if (history.length === 0) return false;

    let targetEntry = history[history.length - 1];

    if (
      targetEntry.type === "Ended" ||
      (targetEntry.type === "Referred" && !targetEntry.doctor)
    ) {
      for (let i = history.length - 2; i >= 0; i--) {
        const entry = history[i];
        if (
          entry.type === "Ongoing" ||
          entry.type === "FollowUp" ||
          (entry.type === "Referred" && entry.doctor)
        ) {
          targetEntry = entry;
          break;
        }
      }
    }

    if (!targetEntry.reports) return false;

    return targetEntry.reports.some((report) => report._id === record._id);
  };

  // Functions to handle dynamic modal
  const openDynamicModal = (title, content) => {
    setDynamicModalTitle(title);
    setDynamicModalContent(content);
    setIsDynamicModalOpen(true);
  };

  const closeDynamicModal = () => {
    setIsDynamicModalOpen(false);
    setDynamicModalTitle("");
    setDynamicModalContent(null);
    fetchData(); // Refresh data after closing modal to ensure updates (e.g., after edit)
  };

  const columns = [
    {
      title: "Appointment No.",
      dataIndex: ["appointmentId", "appointmentNumber"],
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: ["appointmentId", "fullname"],
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: ["appointmentId", "mobile"],
      key: "phone",
    },
    {
      title: "Report Name",
      dataIndex: "reportName",
      key: "reportName",
    },
    {
      title: "Test Headers",
      key: "testHeaders",
      render: (_, record) => {
        const isEmpty = (value) =>
          value === null || value === undefined || value.trim() === "";

        const subHeadings = record.report
          ?.filter(
            (test) =>
              isEmpty(test.result) &&
              isEmpty(test.unit) &&
              isEmpty(test.refRange)
          )
          ?.map((test) => test.test.trim())
          ?.filter(
            (value, index, self) =>
              self.indexOf(value) === index && value !== ""
          );

        return subHeadings?.length > 0 ? subHeadings.join(", ") : "-";
      },
    },
    {
      title: "Date added",
      dataIndex: "createdAt",
      key: "date",
      render: (createdAt) => createdAt.split("T")[0],
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <div className="flex space-x-2">
          {/* View Report */}
          <button
            onClick={() => {
              setSelectedReport(record.report);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
          >
            <GrView size={20} />
          </button>

          {/* Edit Report */}
          {shouldShowButtons(record) && (
            <button
              onClick={() =>
                openDynamicModal("Update Report", (
                  <UpdateReport
                    appointmentId={record.appointmentId._id}
                    reportId={record._id}
                    onClose={closeDynamicModal}
                    onUpdate={() => {
                      fetchData(); // Refresh data after update
                    }}
                  />
                ))
              }
              className="inline-flex items-center px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
            >
              <MdEditSquare size={20} />
            </button>
          )}

          {/* Print Report */}
          <button
            onClick={() =>
              openDynamicModal("Print Report", (
                <ReportPrintout reportId={record._id} onClose={closeDynamicModal} />
              ))
            }
            className="inline-flex items-center px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
          >
            <FaPrint size={20} />
          </button>

          {/* Delete Report */}
          {shouldShowButtons(record) && (
            <button
              onClick={() => handleReportDelete(record._id)}
              className="inline-flex items-center px-4 py-2 rounded-md bg-red-700 text-white hover:bg-red-800"
            >
              <MdDelete size={20} />
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      {/* ReportViewModal */}
      <ReportViewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        report={selectedReport}
      />

      {/* Dynamic Modal */}
      {isDynamicModalOpen && (
        <DynamicModal
          isOpen={isDynamicModalOpen}
          onClose={closeDynamicModal}
          title={dynamicModalTitle}
        >
          {dynamicModalContent}
        </DynamicModal>
      )}

      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            All Reports
          </h1>
          <div className="my-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ViewLabReports;