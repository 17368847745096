import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import { Form, Input, Row, Col, message, Button, Modal, Select, Tag, Typography, Space, Checkbox, Radio } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { diseaseFieldConfig } from "./config";
import EnhancedFormField from "./enhancedFormField";

const { Title } = Typography;
const { TextArea } = Input;

const UpdateSummary = forwardRef(({
    summaryId,
    full_name,
    problem,
    appointmentNumber,
    onClose,
    onUpdateSummary,
    updateTrigger, // Add updateTrigger prop
}, ref) => {
    const [forms, setForms] = useState([{ id: 1, selectedDisease: "", formData: {}, fieldOptions: {} }]);
    const [dynamicExtra, setDynamicExtra] = useState(null);
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);

    const getConfigDisease = (form) =>
        form.selectedDisease ? diseaseFieldConfig[form.selectedDisease] : [];

    const handleDiseaseChange = (formId, value) => {
        setForms((prevForms) =>
            prevForms.map((f) => {
                if (f.id === formId) {
                    const configDisease = diseaseFieldConfig[value] || [];
                    const fieldOptions = {};
                    configDisease.forEach((fieldConfig) => {
                        if (fieldConfig.type === "select" || fieldConfig.type === "multiselect") {
                            fieldOptions[fieldConfig.field] = [...(fieldConfig.options || [])];
                        }
                    });
                    return { ...f, selectedDisease: value, formData: {}, fieldOptions };
                }
                return f;
            })
        );
    };

    const handleChange = (formId, field, value) => {
        setForms((prevForms) =>
            prevForms.map((f) =>
                f.id === formId
                    ? { ...f, formData: { ...f.formData, [field]: value } }
                    : f
            )
        );
    };

    const handleCompositeChange = (formId, compositeField, subField, value) => {
        setForms((prevForms) =>
            prevForms.map((f) => {
                if (f.id === formId) {
                    return {
                        ...f,
                        formData: {
                            ...f.formData,
                            [compositeField]: {
                                ...(f.formData[compositeField] || {}),
                                [subField]: value,
                            },
                        },
                    };
                }
                return f;
            })
        );
    };

    const checkAndTriggerExtraOption = (formId, field, value) => {
        const form = forms.find((f) => f.id === formId);
        const configDisease = getConfigDisease(form);
        const fieldConfig = configDisease.find((fc) => fc.field === field);
        if (fieldConfig && fieldConfig.extraOptions) {
            const extra = fieldConfig.extraOptions.find((opt) => {
                return Array.isArray(opt.trigger)
                    ? opt.trigger.includes(value)
                    : opt.trigger === value;
            });
            if (extra) {
                const initialValues = {};
                if (Array.isArray(extra.placeholders)) {
                    extra.placeholders.forEach((p) => {
                        initialValues[p.key] = p.type === "multiselect" ? [] : "";
                    });
                }
                setDynamicExtra({
                    formId,
                    field,
                    extraOption: extra,
                    values: initialValues,
                    selectedTrigger: value,
                });
            }
        }
    };

    const handleMultiSelectChange = (formId, field, valueArray) => {
        const form = forms.find((f) => f.id === formId);
        const configDisease = getConfigDisease(form);
        const fieldConfig = configDisease.find((fc) => fc.field === field);
        let triggers = [];
        if (fieldConfig && fieldConfig.extraOptions) {
            triggers = fieldConfig.extraOptions.flatMap((opt) =>
                Array.isArray(opt.trigger) ? opt.trigger : [opt.trigger]
            );
        }
        const cleaned = valueArray.filter((val) => !triggers.includes(val));
        setForms((prevForms) =>
            prevForms.map((f) =>
                f.id === formId
                    ? { ...f, formData: { ...f.formData, [field]: cleaned } }
                    : f
            )
        );
    };

    const handleMultiSelectSelect = (formId, field, value) => {
        checkAndTriggerExtraOption(formId, field, value);
    };

    const handleDynamicExtraOk = () => {
        if (dynamicExtra) {
            const { extraOption, values, field, formId, selectedTrigger } = dynamicExtra;
            let finalString = extraOption.template || selectedTrigger;
            if (extraOption.placeholders && Array.isArray(extraOption.placeholders)) {
                extraOption.placeholders.forEach((placeholder) => {
                    let replacement = values[placeholder.key];
                    if (Array.isArray(replacement)) {
                        replacement = replacement.join(", ");
                    }
                    finalString = finalString.replace(`{${placeholder.key}}`, replacement);
                });
            }

            setForms((prevForms) =>
                prevForms.map((f) => {
                    if (f.id === formId) {
                        const config = getConfigDisease(f);
                        const fieldConfig = config.find((fc) => fc.field === field);
                        if (fieldConfig && fieldConfig.type === "select") {
                            return {
                                ...f,
                                formData: { ...f.formData, [field]: finalString },
                            };
                        } else {
                            const current = f.formData[field];
                            const currentArray = Array.isArray(current)
                                ? current
                                : current
                                    ? [current]
                                    : [];
                            const cleanedArray = currentArray.filter(
                                (val) => val !== selectedTrigger
                            );
                            if (!cleanedArray.includes(finalString)) {
                                cleanedArray.push(finalString);
                            }
                            return {
                                ...f,
                                formData: { ...f.formData, [field]: cleanedArray },
                            };
                        }
                    }
                    return f;
                })
            );
        }
        setDynamicExtra(null);
    };

    const handleDynamicExtraCancel = () => {
        setDynamicExtra(null);
    };

    const handleSubmitAll = () => {
        setActionLoading(true);
        const data = {
            diseaseSummary: {
                diseaseType: forms[0].selectedDisease,
                summaryData: forms[0].formData,
            },
        };
        try {
            onUpdateSummary(summaryId, data);
        } catch (error) {
            console.error("Failed to update summary:", error);
            message.error("Failed to update summary! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    useImperativeHandle(ref, () => ({
        handleSubmitAll,
    }));

    const handleClear = (formId, field, type) => {
        setForms((prevForms) =>
            prevForms.map((f) => {
                if (f.id === formId) {
                    let clearedValue;
                    if (type === "composite") {
                        clearedValue = {};
                    } else if (type === "multiselect") {
                        clearedValue = [];
                    } else {
                        clearedValue = "";
                    }
                    return {
                        ...f,
                        formData: { ...f.formData, [field]: clearedValue },
                    };
                }
                return f;
            })
        );
    };

    const renderForm = (form) => {
        const configDisease = getConfigDisease(form);
        return (
            <div style={{ maxWidth: "900px", margin: "auto", padding: "20px" }}>
                <Title level={2}>Summary Form</Title>
                <Form
                    layout="vertical"
                    onFinish={() => console.log("Submitting form", form.id, form.formData)}
                >
                    <Form.Item label="Select Disease">
                        <Select
                            placeholder="Select Disease"
                            value={form.selectedDisease || undefined}
                            onChange={(value) => handleDiseaseChange(form.id, value)}
                            required
                        >
                            <Select.Option value="">--Select Disease--</Select.Option>
                            {Object.keys(diseaseFieldConfig).map((disease) => (
                                <Select.Option key={disease} value={disease}>
                                    {disease.replace(/_/g, " ")}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {form.selectedDisease && (
                        <>
                            <Title level={4}>
                                {form.selectedDisease.replace(/_/g, " ")}
                            </Title>
                            {configDisease.map((fieldConfig) => (
                                <EnhancedFormField
                                    key={fieldConfig.field}
                                    fieldConfig={fieldConfig}
                                    form={form}
                                    formId={form.id}
                                    formData={form.formData}
                                    fieldOptions={form.fieldOptions}
                                    handleChange={handleChange}
                                    handleMultiSelectChange={handleMultiSelectChange}
                                    handleCompositeChange={handleCompositeChange}
                                    handleClear={handleClear}
                                />
                            ))}
                        </>
                    )}
                </Form>
                <pre
                    style={{
                        background: "#f0f0f0",
                        padding: "10px",
                        marginTop: "20px",
                    }}
                >
                    {JSON.stringify(form.formData, null, 2)}
                </pre>
            </div>
        );
    };

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [fullNameData, setFullNameData] = useState(full_name || "");
    const [problemData, setProblemData] = useState(problem || "");
    const [appointmentNumberData, setAppointmentNumberData] = useState(appointmentNumber || "");
    const [details, setDetails] = useState([]);

    const config = {
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("doctorticket") ||
                    localStorage.getItem("staffticket")),
        },
    };

    useEffect(() => {
        setFullNameData(full_name || "");
        setProblemData(problem || "");
        setAppointmentNumberData(appointmentNumber || "");
        fetchData();
    }, [summaryId, full_name, problem, appointmentNumber, updateTrigger]); // Add updateTrigger to dependencies

    const fetchData = () => {
        setLoading(true);
        axios
            .get(`${apiBaseUrl}/summary/single/${summaryId}`, config)
            .then((response) => {
                if (response.data.success) {
                    setDetails(response.data.data);
                    const data = response.data.data;
                    const selectedDisease = data.diseaseSummary.diseaseType;
                    const formData = data.diseaseSummary.summaryData;
                    const configDisease = diseaseFieldConfig[selectedDisease] || [];

                    const fieldOptions = {};
                    configDisease.forEach((fieldConfig) => {
                        if (fieldConfig.type === "select" || fieldConfig.type === "multiselect") {
                            const defaultOptions = fieldConfig.options || [];
                            const formDataValue = formData[fieldConfig.field];
                            let customValues = [];

                            if (fieldConfig.type === "select" && formDataValue) {
                                if (!defaultOptions.includes(formDataValue)) {
                                    customValues = [formDataValue];
                                }
                            } else if (fieldConfig.type === "multiselect" && Array.isArray(formDataValue)) {
                                customValues = formDataValue.filter((val) => !defaultOptions.includes(val));
                            }

                            fieldOptions[fieldConfig.field] = [...defaultOptions, ...customValues];
                        }
                    });

                    setForms((prevForms) =>
                        prevForms.map((f) =>
                            f.id === 1
                                ? { ...f, selectedDisease, formData, fieldOptions }
                                : f
                        )
                    );
                    console.log("Summary Data", response.data.data);
                }
            })
            .catch((error) => {
                console.error("Error fetching summary:", error);
                message.error("Failed to fetch summary data.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleGoBack = () => {
        onClose();
    };

    return (
        <>
            {actionLoading && <LoadingScreen />}

            <div className="mb-3">
                <h1 className="text-3xl font-bold decoration-gray-400">Summary</h1>
            </div>

            <div className="flex items-start">
                <div className="max-w-5xl p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
                    {loading && <LoadingScreen />}

                    {!loading && (
                        <>
                            <div className="text-center">
                                {/* <div className="flex justify-end">
                                    <button
                                        className="px-4 py-1 mb-2 rounded-md bg-blue-600 text-sky-100 hover:bg-blue-700"
                                        onClick={handleGoBack}
                                    >
                                        Go Back
                                    </button>
                                </div> */}
                                <h5 className="text-xl font-bold tracking-tight text-gray-900">
                                    Appointment Number:{" "}
                                    <span className="font-semibold italic">{` ${appointmentNumberData}`}</span>
                                </h5>
                                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                                    Patient Name:{" "}
                                    <span className="font-semibold italic">{` ${fullNameData}`}</span>
                                </h5>

                                <p className="mb-1 font-semibold text-gray-700">
                                    Problem: <span className="font-normal">{` ${problemData}`}</span>
                                </p>
                            </div>
                            <hr className="w-48 h-1 mx-auto bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700"></hr>

                            <div>
                                {forms.map((form) => (
                                    <div key={form.id}>{renderForm(form)}</div>
                                ))}

                                <Modal
                                    title={dynamicExtra ? dynamicExtra.extraOption.label : "Extra Input"}
                                    open={dynamicExtra !== null}
                                    onOk={handleDynamicExtraOk}
                                    onCancel={handleDynamicExtraCancel}
                                >
                                    <Form layout="vertical">
                                        {dynamicExtra && dynamicExtra.extraOption.placeholders ? (
                                            dynamicExtra.extraOption.placeholders.map((placeholder) => (
                                                <Form.Item label={placeholder.label} key={placeholder.key}>
                                                    {placeholder.type === "select" ? (
                                                        <Select
                                                            placeholder={`Select ${placeholder.label}`}
                                                            value={dynamicExtra.values[placeholder.key] || undefined}
                                                            onChange={(value) =>
                                                                setDynamicExtra((prev) => ({
                                                                    ...prev,
                                                                    values: {
                                                                        ...prev.values,
                                                                        [placeholder.key]: value,
                                                                    },
                                                                }))
                                                            }
                                                        >
                                                            {placeholder.options.map((opt) => (
                                                                <Select.Option key={opt} value={opt}>
                                                                    {opt}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    ) : placeholder.type === "multiselect" ? (
                                                        <Select
                                                            mode="multiple"
                                                            placeholder={`Select ${placeholder.label}`}
                                                            value={dynamicExtra.values[placeholder.key] || []}
                                                            onChange={(value) =>
                                                                setDynamicExtra((prev) => ({
                                                                    ...prev,
                                                                    values: {
                                                                        ...prev.values,
                                                                        [placeholder.key]: value,
                                                                    },
                                                                }))
                                                            }
                                                        >
                                                            {placeholder.options.map((opt) => (
                                                                <Select.Option key={opt} value={opt}>
                                                                    {opt}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    ) : placeholder.type === "checkbox" ? (
                                                        <Checkbox.Group
                                                            options={placeholder.options}
                                                            value={dynamicExtra.values[placeholder.key] || []}
                                                            onChange={(checkedValues) =>
                                                                setDynamicExtra((prev) => ({
                                                                    ...prev,
                                                                    values: {
                                                                        ...prev.values,
                                                                        others: checkedValues,
                                                                    },
                                                                }))
                                                            }
                                                        />
                                                    ) : (
                                                        <Input
                                                            placeholder={`Enter ${placeholder.label}`}
                                                            value={dynamicExtra.values[placeholder.key] || ""}
                                                            onChange={(e) =>
                                                                setDynamicExtra((prev) => ({
                                                                    ...prev,
                                                                    values: {
                                                                        ...prev.values,
                                                                        [placeholder.key]: e.target.value,
                                                                    },
                                                                }))
                                                            }
                                                        />
                                                    )}
                                                </Form.Item>
                                            ))
                                        ) : (
                                            <Form.Item label="Input">
                                                <Input
                                                    placeholder="Enter value"
                                                    value={dynamicExtra ? dynamicExtra.value : ""}
                                                    onChange={(e) =>
                                                        setDynamicExtra((prev) => ({ ...prev, value: e.target.value }))
                                                    }
                                                />
                                            </Form.Item>
                                        )}
                                    </Form>
                                </Modal>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
});

export default UpdateSummary;