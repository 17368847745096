import React, { useState, useEffect } from "react";
import { Button, Divider, message, Table, Tag } from "antd";
import axios from "axios";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import "./historyPrintout.css";
import moment from "moment-timezone";
import { calculateAge } from "../../lib/constants/calculateAge";
import { diseaseFieldConfig } from "../summary/config";
import logo from "../../tulasi_logo.png";

const HistoryPrintout = ({ appointmentId, full_name, problem, status, sendTo, onPrint }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [appointmentHistory, setAppointmentHistory] = useState([]);
    const [DOB, setDOB] = useState("");
    const [age, setAge] = useState("");
    const [sendToWhat] = useState(sendTo); // No need to update this dynamically
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({});

    // Dynamic auth config
    const getAuthConfig = () => {
        const ticketTypes = [
            { key: "staffticket", name: "staff" },
            { key: "doctorticket", name: "doctor" },
            { key: "adminticket", name: "admin" },
            { key: "pharmaticket", name: "pharma" },
            { key: "labreporterticket", name: "labreporter" },
        ];
        const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
        if (!availableTicket) {
            console.warn("No authentication ticket found in localStorage");
            return { headers: {} };
        }
        return {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
            },
        };
    };

    const config = getAuthConfig();

    const fetchData = async () => {
        setLoading(true);
        const url = `${apiBaseUrl}/appointment/${appointmentId}`;
        try {
            const response = await axios.get(url, config);
            if (response.data.success) {
                const data = response.data.data;
                setDetails(data);
                setDOB(data.DOB || "");
                setAppointmentHistory(data.appointmentHistory || []);
                console.log(data);
            }
        } catch (error) {
            console.error("Error fetching appointments:", error);
            message.error("Failed to get appointment details! Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const getRelevantAppointmentHistory = (history) => {
        if (!history || history.length === 0) return null;
        let latestEntry = history[history.length - 1];
        if (
            latestEntry.type === "Ongoing" ||
            latestEntry.type === "FollowUp" ||
            (latestEntry.type === "Referred" && latestEntry.doctor)
        ) {
            console.log("Latest ENTRY", latestEntry);
            return latestEntry;
        }
        for (let i = history.length - 2; i >= 0; i--) {
            const entry = history[i];
            if (
                entry.type === "Ongoing" ||
                entry.type === "FollowUp" ||
                (entry.type === "Referred" && entry.doctor)
            ) {
                console.log("ENTRY", entry);
                return entry;
            }
        }
        return null;
    };

    const latestAppointment = getRelevantAppointmentHistory(appointmentHistory);

    const getFieldLabel = (diseaseType, fieldName) => {
        const diseaseConfig = diseaseFieldConfig[diseaseType];
        if (!diseaseConfig) return fieldName;
        const fieldConfig = diseaseConfig.find((field) => field.field === fieldName);
        return fieldConfig ? fieldConfig.label : fieldName;
    };

    const prescriptionColumns = [
        {
            title: "Medicine",
            dataIndex: "medicineName",
            key: "medicineName",
            render: (text, record) => {
                if (record.isFollowUp) {
                    return <span className="text-gray-500 italic">Followed By:</span>;
                }
                const warning = record.selectedMedicine?.warning?.[0] || "";
                return (
                    <div>
                        <div className="font-medium">{text ? text.split("[")[0].trim() : ""}</div>
                        {record.comment && (
                            <div className="text-xs text-gray-500 mt-1">{record.comment}</div>
                        )}
                        {warning && (
                            <div className="text-sm text-red-600 mt-1 italic">Warning: {warning}</div>
                        )}
                    </div>
                );
            },
        },
        { title: "Tablet", dataIndex: "tablet", key: "tablet" },
        { title: "Dose", dataIndex: "dose", key: "dose" },
        {
            title: "Frequency",
            dataIndex: "frequency",
            key: "frequency",
            render: (_, record) => (
                <div className="flex items-center gap-1">
                    <span>•</span>
                    {`${record.frequencyNumber}x/${record.frequencyPeriod}`.replace(/\<Day\>/g, "Day")}
                    {record.frequencyPeriod === "week" && record.frequencyDays && (
                        <span className="text-xs text-gray-500 ml-2">
                            ({record.frequencyDays.join(", ")})
                        </span>
                    )}
                </div>
            ),
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
            render: (_, record) =>
                `${record.durationNumber} ${record.durationPeriod}${record.durationNumber > 1 ? "s" : ""}`,
        },
        {
            title: "Meal",
            dataIndex: "meal",
            key: "meal",
            render: (text) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : ""),
        },
    ];

    const prescriptionData =
        latestAppointment?.prescriptions?.flatMap((prescription, pIndex) =>
            prescription.medicine.flatMap((med, mIndex) =>
                med.details.map((detail, dIndex) => ({
                    key: `${pIndex}-${mIndex}-${dIndex}`,
                    medicineName: dIndex === 0 ? med.medicineName : null,
                    comment: dIndex === 0 ? med.comment : null,
                    tablet: detail.tablet,
                    dose: detail.dose,
                    isFollowUp: dIndex > 0,
                    frequencyNumber: detail.frequency.number,
                    frequencyPeriod: detail.frequency.period.toLowerCase(),
                    frequencyDays: detail.frequency.selectedDays?.map((day) =>
                        day.charAt(0).toUpperCase() + day.slice(1)
                    ),
                    durationNumber: detail.duration.number,
                    durationPeriod: detail.duration.period.toLowerCase(),
                    meal: detail.Meal,
                    selectedMedicine: dIndex === 0 ? med.selectedMedicine : null,
                }))
            )
        ) || [];

    useEffect(() => {
        const calculatedAge = calculateAge(DOB);
        if (calculatedAge !== undefined && calculatedAge !== "Invalid date of birth") {
            setAge(calculatedAge);
        } else {
            setAge("");
        }
    }, [DOB]);

    useEffect(() => {
        fetchData();
    }, [appointmentId]);

    const handlePrint = () => {
        const printableContent = document.getElementById("printableContent").innerHTML;
        const printWindow = window.open("", "_blank");
        printWindow.document.write(`
      <html>
        <head>
          <title>History Printout</title>
          <style>
            @media print {
              body { font-family: Arial, sans-serif; margin: 20px; }
              .print-content { width: 100%; }
              table { width: 100%; border-collapse: collapse; }
              th, td { border: 1px solid #ddd; padding: 8px; }
              th { background-color: #f2f2f2; }
            }
          </style>
        </head>
        <body>${printableContent}</body>
      </html>
    `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        if (onPrint) onPrint();
    };

    const convertToNepaliTime = (utcTime) => {
        return utcTime
            ? moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD")
            : "";
    };

    const diagnosisColumns = [
        {
            title: "Diagnosis",
            key: "diagnosis",
            render: (_, record) => (
                <div style={{ whiteSpace: "pre-line" }}>
                    {record.diagnosis ? record.diagnosis.replace(/\\n/g, "\n") : ""}
                </div>
            ),
        },
    ];

    const diagnosisData =
        latestAppointment?.diagnosis?.map((diag, index) => ({
            key: index,
            ...diag,
        })) || [];

    const generateNaturalSentence = (label, value) => {
        label = label.toLowerCase();
        value = value ? value.toString() : "not recorded";
        if (label.includes("duration")) {
            return `The ${label.replace("duration", "duration of illness")} was ${value}.`;
        } else if (label.includes("symptoms")) {
            return `The patient experienced ${value}.`;
        } else if (label.includes("antibodies") || label.includes("antibody")) {
            return `Antibody testing revealed ${value}.`;
        } else if (label.includes("initial treatment")) {
            return `Initial treatment included ${value}.`;
        } else if (label.includes("comorbid conditions")) {
            return `Comorbid conditions included ${value}.`;
        } else if (label.includes("conception plan")) {
            return `The patient's conception plan was ${value}.`;
        } else if (label.includes("compliance")) {
            const compliance = value === "Yes" ? "compliant" : "non-compliant";
            return `Patient compliance with medication and advices was ${compliance}.`;
        } else if (label.includes("vaccination status")) {
            return `Vaccination status: ${value}.`;
        } else if (label.includes("smoker")) {
            return value === "Yes" ? "The patient is a smoker." : "The patient is not a smoker.";
        } else if (label.includes("ana by-ifa")) {
            return `The ANA by-IFA ${label.replace("ana by-ifa", "")} was ${value}.`;
        } else if (label.includes("current issues")) {
            return `Current issues reported: ${value}.`;
        } else {
            return `Additional information: ${label} – ${value}.`;
        }
    };

    const summaryColumns = [
        {
            title: "Patient Summary",
            key: "summary",
            render: (_, record) => {
                const diseaseType = record.diseaseSummary.diseaseType;
                const summaryData = record.diseaseSummary.summaryData;
                let summaryText = "";
                if (diseaseType) {
                    const diseaseTypeLabel = diseaseType.replace(/_/g, " ");
                    summaryText += `Summary Report for ${diseaseTypeLabel}: `;
                }
                if (summaryData) {
                    const sentences = Object.entries(summaryData).map(([key, value]) => {
                        const label = getFieldLabel(diseaseType, key).replace(/_/g, " ");
                        let valueStr = "";
                        if (Array.isArray(value)) {
                            valueStr = value.join(", ");
                        } else if (typeof value === "object" && value !== null) {
                            if (value.durationValue && value.durationUnit) {
                                valueStr = `${value.durationValue} ${value.durationUnit}`;
                            } else {
                                valueStr = Object.values(value).join(" ");
                            }
                        } else if (typeof value === "boolean") {
                            valueStr = value ? "Yes" : "No";
                        } else {
                            valueStr = value;
                        }
                        return generateNaturalSentence(label, valueStr);
                    });
                    if (sentences.length > 0) {
                        const processedSentences = sentences
                            .map((s) => s.replace(/\.$/, "").replace(/^$/, ""))
                            .filter(Boolean);
                        summaryText += processedSentences.join(". ") + ".";
                    }
                }
                return (
                    <div style={{ whiteSpace: "pre-wrap", marginBottom: "1rem" }}>
                        <p>{summaryText}</p>
                    </div>
                );
            },
        },
    ];

    const summaryData =
        latestAppointment?.summary?.map((sum, index) => ({
            key: index,
            ...sum,
        })) || [];

    const adviceColumns = [
        {
            title: "Advice",
            key: "advice",
            render: (_, record) => (
                <div style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}>
                    {record.advice.map((str, idx) => (
                        <Tag key={idx} color="blue">
                            {str}
                        </Tag>
                    ))}
                </div>
            ),
        },
    ];

    const adviceData =
        latestAppointment?.advice?.map((adv, index) => ({
            key: index,
            ...adv,
        })) || [];

    const investigationColumns = [
        {
            title: "Investigation",
            dataIndex: "investigation",
            key: "investigation",
            render: (investigation) => (
                <div style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}>
                    {investigation.map((str, idx) => (
                        <Tag key={idx} color="blue">
                            {str}
                        </Tag>
                    ))}
                </div>
            ),
        },
    ];

    const investigationData =
        latestAppointment?.investigations?.map((inv, index) => ({
            key: index,
            ...inv,
        })) || [];

    const timeAfter = (dateString) => {
        const now = new Date();
        const futureDate = new Date(dateString);
        const msPerDay = 1000 * 60 * 60 * 24;
        const diffMs = futureDate - now;
        const diffDays = Math.floor(diffMs / msPerDay);
        if (diffDays === 0) return "today";
        if (diffDays < 7) return `${diffDays} day${diffDays !== 1 ? "s" : ""} after`;
        const diffWeeks = Math.floor(diffDays / 7);
        if (diffWeeks < 4) return `${diffWeeks} week${diffWeeks !== 1 ? "s" : ""} after`;
        const diffMonths = Math.floor(diffDays / 30);
        return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} after`;
    };

    const followUpDate = latestAppointment?.prescriptions?.[0]?.follow_up;

    return (
        <div>
            <div id="printableContent" className="print-content">
                <div className="container p-4 place-content-center">
                    <div className="mb-3">
                        <div className="text-center">
                            <img
                                src={logo}
                                alt="Tulsi Clinic Logo"
                                style={{ width: "120px", height: "auto", marginBottom: "1rem" }}
                            />
                            <span className="text-2xl font-bold">Tulsi MultiSpeciality Clinic</span>
                            <p>
                                <EnvironmentOutlined /> Avm chowk 05, Lalitpur 44600
                            </p>
                            <p>
                                <PhoneOutlined /> 01-5914294
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-between mb-8 border-b pb-4">
                        <div className="flex flex-col text-start">
                            <p>
                                <strong>Name:</strong> {details?.fullname || ""}
                            </p>
                            <p>
                                <strong>Age / Sex:</strong> {age} / {details?.patientId?.gender || ""}
                            </p>
                            <p>
                                <strong>Address:</strong> {details?.address || ""}
                            </p>
                        </div>
                        <div className="flex flex-col text-end">
                            <p>
                                <strong>Appointment No:</strong> {details?.appointmentNumber || ""}
                            </p>
                            <p>
                                <strong>Date:</strong> {convertToNepaliTime(details?.date)}
                            </p>
                            <p>
                                <strong>Doctor:</strong> {details?.doctorId?.fullname || ""}
                            </p>
                        </div>
                    </div>

                    <div className="mb-3">
                        <h3 className="text-xl font-bold">Vitals</h3>
                        <div className="flex justify-between">
                            <div className="flex flex-col text-start">
                                <p>
                                    <strong>Weight:</strong> {latestAppointment?.weight || ""} kg
                                </p>
                            </div>
                            <div className="flex flex-col text-end">
                                <p>
                                    <strong>Blood Pressure:</strong> {latestAppointment?.pressure || ""} mmHg
                                </p>
                            </div>
                        </div>
                    </div>

                    {summaryData.length > 0 && (
                        <div className="mb-6">
                            <Table
                                columns={summaryColumns}
                                dataSource={summaryData}
                                pagination={false}
                                bordered
                                size="small"
                            />
                        </div>
                    )}

                    {diagnosisData.length > 0 && (
                        <div className="mb-6">
                            <Table
                                columns={diagnosisColumns}
                                dataSource={diagnosisData}
                                pagination={false}
                                bordered
                                size="small"
                            />
                        </div>
                    )}

                    {prescriptionData.length > 0 && (
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-2">Prescriptions</h3>
                            <Table
                                columns={prescriptionColumns}
                                dataSource={prescriptionData}
                                pagination={false}
                                bordered
                                size="small"
                            />
                        </div>
                    )}

                    {followUpDate && (
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-2">Follow-Up Appointment</h3>
                            <p>
                                Your next follow-up appointment is scheduled on:{" "}
                                <strong>{convertToNepaliTime(followUpDate)}</strong>
                            </p>
                        </div>
                    )}

                    {adviceData.length > 0 && (
                        <div className="mb-6">
                            <Table
                                columns={adviceColumns}
                                dataSource={adviceData}
                                pagination={false}
                                bordered
                                size="small"
                            />
                        </div>
                    )}

                    {investigationData.length > 0 && (
                        <div className="mb-6">
                            <Table
                                columns={investigationColumns}
                                dataSource={investigationData}
                                pagination={false}
                                bordered
                                size="small"
                            />
                        </div>
                    )}

                    <div className="text-center mt-8">
                        <p className="text-xs text-gray-500">
                            This report is computer-generated and does not require a signature.
                        </p>
                    </div>
                </div>
            </div>

            <Button
                type="primary"
                onClick={handlePrint}
                className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow font-semibold text-md rounded-md"
                disabled={loading}
            >
                {loading ? "Loading..." : "Print Invoice"}
            </Button>
        </div>
    );
};

export default HistoryPrintout;