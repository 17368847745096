import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";

const OnlyViewSummary = ({
    appointmentId,
    full_name,
    problem,
    appointmentNumber,
    appointmentHistory, // Included for consistency, though not used yet
}) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [fullNameData, setFullNameData] = useState(full_name || "");
    const [problemData, setProblemData] = useState(problem || "");
    const [appointmentNumberData, setAppointmentNumberData] = useState(appointmentNumber || "");
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [details, setDetails] = useState([]);

    // Dynamic auth config based on localStorage ticket
    const getAuthConfig = () => {
        const ticketTypes = [
            { key: "adminticket", name: "admin" },
            { key: "staffticket", name: "staff" },
            { key: "doctorticket", name: "doctor" },
            { key: "pharmaticket", name: "pharma" },
            { key: "labreporterticket", name: "labreporter" },
        ];

        const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
        if (!availableTicket) {
            console.warn("No authentication ticket found in localStorage");
            return { headers: {} };
        }

        return {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
            },
        };
    };

    const config = getAuthConfig();

    // Fetch summary data when component mounts or props change
    useEffect(() => {
        setFullNameData(full_name || "");
        setProblemData(problem || "");
        setAppointmentNumberData(appointmentNumber || "");
        fetchData();
    }, [appointmentId, full_name, problem, appointmentNumber]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiBaseUrl}/summary/${appointmentId}`, config);
            if (response.data.success) {
                setDetails(response.data.data);
                console.log("Summary Data", response.data.data);
            }
        } catch (error) {
            console.error("Error fetching summary:", error);
            message.error("Failed to fetch summary data.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {actionLoading && <LoadingScreen />}

            <div className="mb-3">
                <h1 className="text-3xl font-bold decoration-gray-400">Summary</h1>
            </div>

            <div className="flex items-start">
                <div className="max-w-3xl p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
                    {loading && <LoadingScreen />}

                    {!loading && (
                        <>
                            <div className="text-center">
                                <h5 className="text-xl font-bold tracking-tight text-gray-900">
                                    Appointment Number:{" "}
                                    <span className="font-semibold italic">{` ${appointmentNumberData}`}</span>
                                </h5>
                                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                                    Patient Name:{" "}
                                    <span className="font-semibold italic">{` ${fullNameData}`}</span>
                                </h5>
                                <p className="mb-1 font-semibold text-gray-700">
                                    Problem: <span className="font-normal">{` ${problemData}`}</span>
                                </p>
                            </div>
                            <hr className="w-48 h-1 mx-auto bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700" />

                            {details.length > 0 ? (
                                details.map((item) => (
                                    <div
                                        key={item._id}
                                        className="relative mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex"
                                    >
                                        {/* Left Content */}
                                        <div className="flex-grow">
                                            <p
                                                className="mb-1 font-semibold text-gray-700"
                                                style={{ whiteSpace: "pre-wrap" }}
                                            >
                                                Summary Report:{" "}
                                                <span className="font-normal">{item.summary}</span>
                                            </p>
                                            <p className="mb-1 font-semibold text-gray-700">
                                                Added by:{" "}
                                                <span className="font-normal">
                                                    Dr. {item.doctorId.fullname}
                                                </span>
                                            </p>
                                        </div>

                                        {/* Dotted Vertical Line */}
                                        <div className="relative">
                                            <div className="absolute top-0 bottom-0 border-r-2 border-dotted border-gray-400"></div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="mt-3 text-center">
                                    <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                                        No Summary added!!
                                    </h5>
                                </p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default OnlyViewSummary;