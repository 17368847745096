import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    message,
    Space,
    InputNumber,
    Select,
    Row,
    Col,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ExpensesPage = () => {
    // State declarations
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingExpense, setEditingExpense] = useState(null);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [totalExpenses, setTotalExpenses] = useState(0);

    // Axios configuration with authentication
    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    // Fetch expenses from the backend
    const fetchExpenses = async (page = 1, pageSize = 10) => {
        setLoading(true);
        try {
            const params = {
                page,
                limit: pageSize,
                search: searchText || undefined,
                startDate: dateRange[0] ? dateRange[0].startOf("day").toISOString() : undefined,
                endDate: dateRange[1] ? dateRange[1].endOf("day").toISOString() : undefined,
            };
            const response = await axios.get(`${apiBaseUrl}/expenses`, { ...config, params });
            console.log("Expenses", response.data.data);
            const fetchedExpenses = response.data.data || [];
            setExpenses(fetchedExpenses); // Ensure expenses is always an array
            setTotalExpenses(
                fetchedExpenses.reduce((sum, expense) => sum + (expense.amount || 0), 0)
            );
            setPagination({
                current: page,
                pageSize,
                total: response.data.total || fetchedExpenses.length,
            });
        } catch (error) {
            message.error(error.response?.data?.message || "Failed to fetch expenses");
            setExpenses([]); // Reset to empty array on error
            console.error("Error fetching expenses:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch expenses on mount and when dependencies change
    useEffect(() => {
        fetchExpenses(pagination.current, pagination.pageSize);
    }, [searchText, dateRange]);

    // Handle adding or updating an expense
    const handleSaveExpense = async (values) => {
        const expenseData = {
            ...values,
            date: values.date.toISOString(),
        };

        setLoading(true);
        try {
            if (editingExpense) {
                await axios.put(`${apiBaseUrl}/expenses/${editingExpense._id}`, expenseData, config);
                message.success("Expense updated successfully");
            } else {
                await axios.post(`${apiBaseUrl}/expenses`, expenseData, config);
                message.success("Expense added successfully");
            }
            await fetchExpenses(pagination.current, pagination.pageSize); // Refresh data
            setIsModalOpen(false);
            setEditingExpense(null);
            form.resetFields();
        } catch (error) {
            message.error(error.response?.data?.message || "Failed to save expense");
            console.error("Error saving expense:", error);
        } finally {
            setLoading(false);
        }
    };

    // Handle deleting an expense
    const handleDeleteExpense = (id) => {
        Modal.confirm({
            title: "Are you sure you want to delete this expense?",
            onOk: async () => {
                setLoading(true);
                try {
                    await axios.delete(`${apiBaseUrl}/expenses/${id}`, config);
                    message.success("Expense deleted successfully");
                    await fetchExpenses(pagination.current, pagination.pageSize); // Refresh data
                } catch (error) {
                    message.error(error.response?.data?.message || "Failed to delete expense");
                    console.error("Error deleting expense:", error);
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    // Open modal for adding or editing
    const showModal = (expense = null) => {
        setEditingExpense(expense);
        if (expense) {
            form.setFieldsValue({
                ...expense,
                date: moment(expense.date),
            });
        } else {
            form.resetFields();
        }
        setIsModalOpen(true);
    };

    // Expense categories
    const expenseCategories = [
        "Rent",
        "Utilities",
        "Salaries",
        "Medical Supplies",
        "Equipment Maintenance",
        "Office Supplies",
        "Insurance",
        "Marketing",
        "Professional Fees",
        "Miscellaneous",
    ];

    // Paid to options
    const paidToOptions = [
        "Landlord",
        "Utility Companies",
        "Staff",
        "Supplier",
        "Service Provider",
        "Insurance Company",
        "Marketing Agency",
        "Others",
    ];

    // Table columns
    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: (date) => moment(date).format("YYYY-MM-DD"),
        },
        {
            title: "Category",
            dataIndex: "category",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            sorter: (a, b) => a.amount - b.amount,
            render: (amount) => `$${amount.toFixed(2)}`,
        },
        {
            title: "Description",
            dataIndex: "description",
        },
        {
            title: "Paid To",
            dataIndex: "paidTo",
        },
        {
            title: "Payment Method",
            dataIndex: "paymentMethod",
        },
        {
            title: "Tax Amount",
            dataIndex: "taxAmount",
            render: (tax) => (tax ? `$${tax.toFixed(2)}` : "-"),
        },
        {
            title: "Actions",
            render: (_, record) => (
                <Space>
                    <Button onClick={() => showModal(record)}>Edit</Button>
                    <Button danger onClick={() => handleDeleteExpense(record._id)}>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    // JSX rendering
    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Expenses</h1>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col>
                    <Space>
                        <Input
                            placeholder="Search by category, description, or paid to"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            prefix={<SearchOutlined />}
                            style={{ width: 250 }}
                        />
                        <DatePicker.RangePicker
                            value={dateRange}
                            onChange={(dates) => setDateRange(dates)}
                            format="YYYY-MM-DD"
                        />
                        <Button
                            type="default"
                            onClick={() => {
                                setSearchText("");
                                setDateRange([null, null]);
                            }}
                        >
                            Reset Filters
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
                        Add Expense
                    </Button>
                </Col>
            </Row>
            <div style={{ marginBottom: 16, fontSize: 16, fontWeight: "bold" }}>
                Total Expenses: ${totalExpenses.toFixed(2)}
            </div>
            <Table
                columns={columns}
                dataSource={expenses}
                rowKey="_id"
                loading={loading}
                pagination={{
                    ...pagination,
                    onChange: (page, pageSize) => {
                        setPagination((prev) => ({ ...prev, current: page, pageSize }));
                        fetchExpenses(page, pageSize);
                    },
                }}
            />
            <Modal
                title={editingExpense ? "Edit Expense" : "Add Expense"}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    setEditingExpense(null);
                    form.resetFields();
                }}
                footer={null}
                width={600}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSaveExpense}
                    initialValues={{ taxAmount: 0, taxRate: 0 }}
                    onValuesChange={(changedValues, allValues) => {
                        if (changedValues.amount || changedValues.taxRate) {
                            const amount = allValues.amount || 0;
                            const taxRate = allValues.taxRate || 0;
                            form.setFieldsValue({ taxAmount: amount * (taxRate / 100) });
                        }
                        if (changedValues.taxAmount) {
                            const amount = allValues.amount || 0;
                            const taxAmount = changedValues.taxAmount || 0;
                            const taxRate = amount > 0 ? (taxAmount / amount) * 100 : 0;
                            form.setFieldsValue({ taxRate });
                        }
                    }}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="date"
                                label="Date"
                                rules={[{ required: true, message: "Please select a date" }]}
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="category"
                                label="Category"
                                rules={[{ required: true, message: "Please select a category" }]}
                            >
                                <Select placeholder="Select a category" style={{ width: "100%" }}>
                                    {expenseCategories.map((category) => (
                                        <Option key={category} value={category}>
                                            {category}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label="Amount"
                                rules={[{ required: true, message: "Please enter an amount" }]}
                            >
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="description" label="Description">
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="paidTo" label="Paid To">
                                <Select placeholder="Select recipient" allowClear style={{ width: "100%" }}>
                                    {paidToOptions.map((recipient) => (
                                        <Option key={recipient} value={recipient}>
                                            {recipient}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="paymentMethod" label="Payment Method">
                                <Select placeholder="Select method" style={{ width: "100%" }}>
                                    <Option value="Cash">Cash</Option>
                                    <Option value="Bank Transfer">Bank Transfer</Option>
                                    <Option value="Credit Card">Credit Card</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="taxRate"
                                label="Tax Rate (%)"
                                rules={[
                                    {
                                        type: "number",
                                        min: 0,
                                        max: 100,
                                        message: "Tax rate must be between 0 and 100",
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={100} step={0.1} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="taxAmount" label="Tax Amount">
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            {editingExpense ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ExpensesPage;