import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { message, Select } from "antd";
import { testTypesData } from "./report_data";

const { Option } = Select;

const UpdateReport = ({ appointmentId, reportId, onClose, onUpdate }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();

    // States
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);
    const [reportName, setReportName] = useState("");
    const [originalReportName, setOriginalReportName] = useState(""); // Store original data
    const [reportList, setReportList] = useState([
        {
            test: "",
            result: "",
            unit: "",
            refRange: "",
        },
    ]);
    const [originalReportList, setOriginalReportList] = useState([]); // Store original data
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [fieldTypeMap, setFieldTypeMap] = useState({});
    const [removedTestNames, setRemovedTestNames] = useState([]); // For re-adding removed tests
    const [unitOptions] = useState([
        "mg/dl",
        "mg/l",
        "Between 4-5%",
        "Mm/hr",
        "gm/dl",
        "/cumm",
        "millions/cumm",
        "/Hpf",
        "g/dl",
        "mmol/L",
        "µmol/L",
        "mEq/L",
        "ng/mL",
        "pg/mL",
        "U/L",
        "IU/L",
        "%",
        "K/µL",
        "cells/mcL",
        "mm/hr",
        "fL",
        "pg",
        "g/L",
        "ratio",
    ]);

    // Test types data
    const [testTypes] = useState(testTypesData);

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
        },
    };

    const isTitleTest = (test) => {
        return test.test !== "" && test.result === "" && test.unit === "" && test.refRange === "";
    };

    const getResultColor = (result, refRange) => {
        if (!result || !refRange) return "bg-white";
        const isWithinRange = true; // Add your range checking logic here
        return isWithinRange ? "bg-blue-50" : "bg-red-50";
    };

    const renderReportList = () => {
        const handleKeyDown = (e, index) => {
            if (e.key === "ArrowDown" && index < reportList.length - 1) {
                e.preventDefault();
                const nextResultInput = document.querySelector(`#result-input-${index + 1}`);
                if (nextResultInput) {
                    nextResultInput.focus();
                }
            } else if (e.key === "ArrowUp" && index > 0) {
                e.preventDefault();
                const prevResultInput = document.querySelector(`#result-input-${index - 1}`);
                if (prevResultInput) {
                    prevResultInput.focus();
                }
            }
        };
        return (


            <div className="mt-4 space-y-4">
                <table className="w-full">
                    <thead>
                        <tr className="border-b">
                            <th className="text-left p-2">Test Name</th>
                            <th className="text-left p-2">Result</th>
                            <th className="text-left p-2 w-1/4">Unit</th>
                            <th className="text-left p-2">Ref-Range</th>
                            <th className="text-left p-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportList.map((medicine, index) => (
                            <tr className={`border-b ${isTitleTest(medicine) ? "bg-gray-50" : ""}`}>
                                <td className="p-2">
                                    <input
                                        className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:border-transparent transition-all duration-200"
                                        type="text"
                                        required
                                        placeholder="eg.. LFT Test"
                                        value={medicine.test}
                                        onChange={(e) => handleInputChange(index, "test", e.target.value)}
                                    />
                                </td>
                                <td className="p-2">
                                    <input
                                        id={`result-input-${index}`}
                                        className={`border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ${getResultColor(
                                            medicine.result,
                                            medicine.refRange
                                        )}`}
                                        type="text"
                                        placeholder="eg.. 2.76"
                                        value={medicine.result}
                                        onChange={(e) => handleInputChange(index, "result", e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                </td>
                                <td className="p-2">
                                    <Select
                                        showSearch
                                        className="w-full"
                                        placeholder="Select unit"
                                        value={medicine.unit}
                                        onChange={(value) => handleInputChange(index, "unit", value)}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        allowClear
                                        popupClassName="rounded-md shadow-lg"
                                    >
                                        {unitOptions.map((unit) => (
                                            <Option key={unit} value={unit}>
                                                {unit}
                                            </Option>
                                        ))}
                                    </Select>
                                </td>
                                <td className="p-2">
                                    <textarea
                                        className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                        placeholder="Range"
                                        value={medicine.refRange}
                                        onChange={(e) => handleInputChange(index, "refRange", e.target.value)}
                                        style={{
                                            maxHeight: "75px",
                                            minHeight: "38px",
                                            resize: "none",
                                            padding: "9px",
                                            boxSizing: "border-box",
                                            fontSize: "15px",
                                        }}
                                    />
                                </td>
                                <td className="p-2">
                                    <IconButton
                                        onClick={() => removeTest(index)}
                                        color="danger"
                                        aria-label="minus button"
                                        className="hover:bg-red-100 transition-colors duration-200"
                                    >
                                        <AiFillMinusCircle className="text-red-500 h-5 w-5" />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    const handleActionLoading = (value) => {
        setActionLoading(value);
    };

    useEffect(() => {
        fetchReportData();
    }, [reportId]); // Run fetchReportData whenever reportId changes

    const fetchReportData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${apiBaseUrl}/view/single/report/${reportId}`,
                config
            );

            if (response.data.success) {
                const reportData = response.data.data;
                setReportName(reportData.reportName);
                setOriginalReportName(reportData.reportName); // Store original data
                setReportList(reportData.report);
                setOriginalReportList([...reportData.report]); // Store original data

                // Extract test types from reportName
                const reportTypes = reportData.reportName
                    .split(",")
                    .map((type) => type.trim())
                    .filter((type) => type && Object.keys(testTypes).includes(type));
                setSelectedTypes(reportTypes);

                // Initialize fieldTypeMap based on reportList
                const initialFieldMap = {};
                reportData.report.forEach((field) => {
                    Object.entries(testTypes).forEach(([type, typeData]) => {
                        if (typeData.fields.some((f) => f.test === field.test)) {
                            initialFieldMap[field.test] = type;
                        }
                    });
                });
                setFieldTypeMap(initialFieldMap);

                // Identify missing test names for "Removed Tests" section
                const currentTests = new Set(reportData.report.map((item) => item.test));
                const missingTests = [];

                reportTypes.forEach((type) => {
                    const expectedFields = testTypes[type].fields.filter(isTitleTest); // Only title tests
                    expectedFields.forEach((field) => {
                        if (!currentTests.has(field.test)) {
                            missingTests.push({
                                testName: field.test,
                                testType: type,
                            });
                        }
                    });
                });

                setRemovedTestNames(missingTests);
            } else {
                message.error("Failed to fetch report data");
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            message.error("Failed to fetch report data! Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleTestTypeChange = (value) => {
        if (!value) return;

        if (!selectedTypes.includes(value)) {
            setSelectedTypes([...selectedTypes, value]);

            // Update report name
            const newReportName =
                selectedTypes.length > 0
                    ? `${reportName},${value}`
                    : value;
            setReportName(newReportName);

            // Add new fields
            const newFields = testTypes[value].fields;
            const newFieldMap = {};
            newFields.forEach((field) => {
                newFieldMap[field.test] = value;
            });
            setFieldTypeMap({ ...fieldTypeMap, ...newFieldMap });

            const isDefaultEmpty =
                reportList.length === 1 &&
                Object.values(reportList[0]).every((v) => v === "");

            setReportList(isDefaultEmpty ? newFields : [...reportList, ...newFields]);
        }
    };

    const removeTestType = (typeToRemove) => {
        // Get all fields belonging to this type
        const fieldsToRemove = testTypes[typeToRemove].fields;

        // Remove fields associated with this type
        const updatedReportList = reportList.filter((item) =>
            !fieldsToRemove.some((field) => field.test === item.test)
        );

        // Update field type mapping
        const newFieldTypeMap = { ...fieldTypeMap };
        fieldsToRemove.forEach((field) => {
            delete newFieldTypeMap[field.test];
        });
        setFieldTypeMap(newFieldTypeMap);

        // Update selected types
        const updatedTypes = selectedTypes.filter((type) => type !== typeToRemove);
        setSelectedTypes(updatedTypes);

        // Update report name
        const newReportName = updatedTypes.join(",");
        setReportName(newReportName);

        // Update report list
        setReportList(
            updatedReportList.length
                ? updatedReportList
                : [{ test: "", result: "", unit: "", refRange: "" }]
        );

        // Recompute removed tests after removing a test type
        const currentTests = new Set(updatedReportList.map((item) => item.test));
        const missingTests = [];

        updatedTypes.forEach((type) => {
            const expectedFields = testTypes[type].fields.filter(isTitleTest);
            expectedFields.forEach((field) => {
                if (!currentTests.has(field.test)) {
                    missingTests.push({
                        testName: field.test,
                        testType: type,
                    });
                }
            });
        });

        setRemovedTestNames(missingTests);
    };

    const handleInputChange = (index, key, value) => {
        const updatedList = [...reportList];
        updatedList[index][key] = value;
        setReportList(updatedList);
    };

    const addTest = () => {
        setReportList([
            ...reportList,
            {
                test: "",
                result: "",
                unit: "",
                refRange: "",
            },
        ]);
    };

    const removeGroup = (startIndex) => {
        let endIndex = reportList.length;
        for (let i = startIndex + 1; i < reportList.length; i++) {
            if (isTitleTest(reportList[i])) {
                endIndex = i;
                break;
            }
        }
        const updatedList = [...reportList.slice(0, startIndex), ...reportList.slice(endIndex)];

        // Track removed test names and their types
        const removedTests = reportList.slice(startIndex, endIndex).filter(isTitleTest);
        const newRemovedTestNames = removedTests.map((test) => ({
            testName: test.test,
            testType: fieldTypeMap[test.test] || selectedTypes[0], // Fallback to first selected type
        }));

        setRemovedTestNames([...removedTestNames, ...newRemovedTestNames]);
        setReportList(updatedList);
    };

    const removeTest = (index) => {
        const removedTest = reportList[index];

        // Remove from fieldTypeMap if it exists
        if (fieldTypeMap[removedTest.test]) {
            const newFieldTypeMap = { ...fieldTypeMap };
            delete newFieldTypeMap[removedTest.test];
            setFieldTypeMap(newFieldTypeMap);
        }

        // Track removed test name and type
        if (isTitleTest(removedTest)) {
            setRemovedTestNames([
                ...removedTestNames,
                { testName: removedTest.test, testType: fieldTypeMap[removedTest.test] || selectedTypes[0] },
            ]);
        }

        const updatedList = [...reportList];
        updatedList.splice(index, 1);
        setReportList(updatedList);

        // Update selected types if needed
        const remainingTests = new Set(
            updatedList.map((item) =>
                Object.keys(fieldTypeMap).find((key) => key === item.test)
            )
        );
        const newSelectedTypes = selectedTypes.filter((type) =>
            testTypes[type].fields.some((field) => remainingTests.has(field.test))
        );
        setSelectedTypes(newSelectedTypes);
    };

    const reAddTestFields = (testName, testType) => {
        const testTypeData = testTypes[testType];
        if (!testTypeData) return;

        // Find all fields related to the testName (title) and its sub-tests
        const fieldsToAdd = [];
        let foundTitle = false;
        for (let i = 0; i < testTypeData.fields.length; i++) {
            const field = testTypeData.fields[i];
            if (field.test === testName && isTitleTest(field)) {
                foundTitle = true;
                fieldsToAdd.push(field);
                continue;
            }
            if (foundTitle) {
                if (isTitleTest(field)) {
                    break; // Stop at the next title
                }
                fieldsToAdd.push(field);
            }
        }

        if (fieldsToAdd.length === 0) return;

        const isDefaultEmpty =
            reportList.length === 1 && Object.values(reportList[0]).every((v) => v === "");
        setReportList(isDefaultEmpty ? fieldsToAdd : [...reportList, ...fieldsToAdd]);

        // Update fieldTypeMap
        const newFieldMap = {};
        fieldsToAdd.forEach((field) => {
            newFieldMap[field.test] = testType;
        });
        setFieldTypeMap({ ...fieldTypeMap, ...newFieldMap });

        // Remove from removedTestNames since it's re-added
        setRemovedTestNames((prev) =>
            prev.filter((item) => !(item.testName === testName && item.testType === testType))
        );
    };

    const updateReport = async (e) => {
        e.preventDefault();
        handleActionLoading(true);

        const isReportNameEmpty = reportName === "";
        const isReportListEmpty =
            reportList.length === 0 ||
            JSON.stringify(reportList[0]) ===
            JSON.stringify({ test: "", result: "", unit: "", refRange: "" });

        // Check if each test is valid based on the specified rule
        const isReportListValid = reportList.every((item, index) => {
            if (item.test && !item.result) {
                // Check if any subsequent test has a result
                const subsequentTestHasResult = reportList
                    .slice(index + 1)
                    .some((nextItem) => nextItem.test && nextItem.result);
                return subsequentTestHasResult;
            }
            return true;
        });

        const isPartiallyFilled = (item) => {
            const { test, result, unit, refRange } = item;
            const allFieldsEmpty = !test && !result && !unit && !refRange;
            const allFieldsFilled = test;
            return !(allFieldsEmpty || allFieldsFilled);
        };

        const hasPartiallyFilledTests = reportList.some(isPartiallyFilled);

        if (isReportNameEmpty || isReportListEmpty) {
            handleActionLoading(false);
            message.error("Report name and at least one test are required");
            return;
        }

        if (!isReportListValid) {
            handleActionLoading(false);
            message.error("If a test name is entered, at least one test below it must have a result filled in.");
            return;
        }

        if (hasPartiallyFilledTests) {
            handleActionLoading(false);
            message.error("All test fields must be either completely filled or completely empty");
            return;
        }

        const data = {
            report: reportList,
            reportName: reportName,
        };

        try {
            const response = await axios.put(
                `${apiBaseUrl}/update-report/${reportId}`,
                data,
                config
            );

            if (response.data.success) {
                message.success("Report Updated Successfully", 0.6, () => {
                    if (onClose) onClose(); // Close the modal if provided
                    if (onUpdate) onUpdate(data); // Call onUpdate callback with updated data
                });
            } else {
                message.error("Failed to update report");
            }
        } catch (error) {
            console.error("Error updating report:", error);
            message.error("Failed to update report! Please try again.");
        } finally {
            handleActionLoading(false);
        }
    };

    const handleCancel = () => {
        // Reset to original data on cancel
        setReportName(originalReportName);
        setReportList([...originalReportList]);
        setSelectedTypes([]);
        setFieldTypeMap({});
        setRemovedTestNames([]);

        // Extract test types from original reportName
        const reportTypes = originalReportName
            .split(",")
            .map((type) => type.trim())
            .filter((type) => type && Object.keys(testTypes).includes(type));
        setSelectedTypes(reportTypes);

        // Reinitialize fieldTypeMap
        const initialFieldMap = {};
        originalReportList.forEach((field) => {
            Object.entries(testTypes).forEach(([type, typeData]) => {
                if (typeData.fields.some((f) => f.test === field.test)) {
                    initialFieldMap[field.test] = type;
                }
            });
        });
        setFieldTypeMap(initialFieldMap);

        // Recompute removed tests
        const currentTests = new Set(originalReportList.map((item) => item.test));
        const missingTests = [];
        reportTypes.forEach((type) => {
            const expectedFields = testTypes[type].fields.filter(isTitleTest);
            expectedFields.forEach((field) => {
                if (!currentTests.has(field.test)) {
                    missingTests.push({
                        testName: field.test,
                        testType: type,
                    });
                }
            });
        });
        setRemovedTestNames(missingTests);

        if (onClose) onClose(); // Close the modal
    };

    const titleTestIndices = reportList.reduce((acc, test, index) => {
        if (isTitleTest(test)) {
            acc.push({ index: index, type: reportList[index].test });
        }
        return acc;
    }, []);

    return (
        <>
            {actionLoading && <LoadingScreen />}

            <div className="mb-3">
                <h1 className="text-3xl font-bold decoration-gray-400">Update Report</h1>
            </div>

            <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow">
                {loading ? (
                    <LoadingScreen />
                ) : (
                    <div className="font-normal text-gray-700">
                        <form className="pb-10 pt-5 rounded-lg flex flex-col gap-4" onSubmit={updateReport}>
                            <div className="w-full md:w-1/4 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                                    Test Type
                                </label>
                                <Select
                                    showSearch
                                    className="w-full"
                                    placeholder="Select test type"
                                    onChange={handleTestTypeChange}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    allowClear
                                >
                                    {Object.keys(testTypes).map((type) => (
                                        <Option key={type} value={type}>
                                            {type}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="flex flex-wrap gap-2 mt-2">
                                {selectedTypes.map((type) => (
                                    <div
                                        key={type}
                                        className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                                    >
                                        <span className="mr-1">{type}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeTestType(type)}
                                            className="ml-1 text-gray-500 hover:text-gray-700"
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>

                            <div className="w-full md:w-1/4 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                                    Report Name
                                </label>
                                <input
                                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-400"
                                    type="text"
                                    required
                                    placeholder="Enter report name"
                                    value={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                />
                            </div>

                            {/* Removed Tests Section */}
                            {removedTestNames.length > 0 && (
                                <div className="mt-2">
                                    <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                                        Removed Tests
                                    </label>
                                    <div className="flex flex-wrap gap-2">
                                        {removedTestNames.map((item, idx) => (
                                            <div
                                                key={item.testName + idx}
                                                className="inline-flex items-center px-2 py-1 bg-red-100 rounded-md cursor-pointer"
                                                onClick={() => reAddTestFields(item.testName, item.testType)}
                                            >
                                                <span className="mr-1">{item.testName}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-wrap gap-2 mt-2">
                                {titleTestIndices.map((type) => (
                                    <div
                                        key={type.type}
                                        className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                                    >
                                        <span className="mr-1">{type.type}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeGroup(type.index)}
                                            className="ml-1 text-gray-500 hover:text-gray-700"
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {renderReportList()}

                            <IconButton
                                onClick={addTest}
                                color="primary"
                                aria-label="add test"
                            >
                                <HiPlusCircle />
                                Add Test Field
                            </IconButton>

                            {/* Submit and Cancel Buttons - Keep only one set */}
                            <div className="flex gap-4 mt-4">
                                <button
                                    className="w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                                    type="submit"
                                >
                                    Update Report
                                </button>
                                <button
                                    type="button"
                                    onClick={handleCancel}
                                    className="w-full bg-gray-500 text-white hover:bg-gray-600 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
};

export default UpdateReport;