import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { Table, Input, Spin, message, DatePicker, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdEditSquare } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { FaPrint } from "react-icons/fa6";
import moment from "moment-timezone";
import LoadingScreen from "../../lib/constants/loadingScreen";
import DynamicModal from "../dynamicModel";
import CreateTestBill from "./createLabBill";
import ViewDetailedBill from "./viewdetailedBill";
import UpdateTestBill from "./updateLabBill";
import StaffBillPrintInvoice from "./printInvoice";

const { RangePicker } = DatePicker;

const ViewLabBillsPage = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isCreateBillModalOpen, setIsCreateBillModalOpen] = useState(false);
  // New modal states
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState(null); // To store the selected bill ID
  const location = useLocation();

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle search term from URL query parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchFromUrl = searchParams.get("search");
    if (searchFromUrl) {
      setSearchTerm(searchFromUrl);
    }
  }, [location.search]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/test-bills`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error("Failed to fetch bills! Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredStaff = useCallback(
    debounce((searchTerm, dateRange) => {
      let filtered = details;
      if (searchTerm.trim() !== "") {
        filtered = filtered.filter(
          (bill) =>
            bill?.billNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            bill?.patient?.mobile?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            bill?.patient?.fullname?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      if (dateRange && dateRange[0] && dateRange[1]) {
        const [startDate, endDate] = dateRange.map((date) =>
          date.format("YYYY-MM-DD")
        );
        filtered = filtered.filter((date) => {
          const billingDate = moment
            .utc(date.billDate)
            .tz("Asia/Kathmandu")
            .format("YYYY-MM-DD");
          return billingDate >= startDate && billingDate <= endDate;
        });
      }
      setFilteredDetails(filtered);
    }, 500),
    [details]
  );

  useEffect(() => {
    const filterBills = (term) => {
      if (!term.trim()) {
        setFilteredDetails(details);
        return;
      }
      const searchRegex = new RegExp(term, "i");
      const filtered = details.filter((bill) =>
        searchRegex.test(bill?.billNo?.trim())
      );
      setFilteredDetails(filtered);
    };

    filterBills(searchTerm);
  }, [searchTerm, details]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filteredStaff(searchTerm, dateRange);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates || [null, null]);
    filteredStaff(searchTerm, dates);
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setDateRange([null, null]);
    setFilteredDetails(details);
  };

  const convertToNepaliTime = (utcTime) => {
    return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
  };

  // Functions to open modals
  const openViewModal = (billId) => {
    setSelectedBillId(billId);
    setIsViewModalOpen(true);
  };

  const openUpdateModal = (billId) => {
    setSelectedBillId(billId);
    setIsUpdateModalOpen(true);
  };

  const openPrintModal = (billId) => {
    setSelectedBillId(billId);
    setIsPrintModalOpen(true);
  };

  const columns = [
    {
      title: "BillNo",
      dataIndex: "billNo",
      key: "billNo",
    },
    {
      title: "Patient Name",
      dataIndex: ["patient", "fullname"],
      key: "fullname",
    },
    {
      title: "DOB",
      dataIndex: ["patient", "DOB"],
      key: "DOB",
      render: (DOB) => (DOB ? DOB.split("T")[0] : "-"),
    },
    {
      title: "Contact",
      dataIndex: ["patient", "mobile"],
      key: "mobile",
    },
    {
      title: "Address",
      dataIndex: ["patient", "address"],
      key: "address",
    },
    {
      title: "Total Amount",
      dataIndex: "totalNet",
      key: "totalNet",
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (amountPaid) => amountPaid ?? 0,
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (paymentMethod) => paymentMethod || "-",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (paymentStatus) => paymentStatus || "Pending",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (dueDate) =>
        dueDate ? moment(dueDate).format("YYYY-MM-DD") : "-",
    },
    {
      title: "Bill Date",
      dataIndex: "billDate",
      key: "billDate",
      render: (billDate) => {
        const nepaliTime = convertToNepaliTime(billDate);
        return <span>{nepaliTime}</span>;
      },
    },
    {
      title: "Accounts Receivable",
      dataIndex: "accountsReceivable",
      key: "accountsReceivable",
      render: (accountsReceivable) =>
        accountsReceivable ? (
          <Link to={`/dashboard/accounting/accounts-receivable/${accountsReceivable._id}`}>
            <button className="px-2 py-1 rounded-md bg-blue-600 text-white hover:bg-blue-700">
              View Receivable
            </button>
          </Link>
        ) : "-",
    },
    {
      title: "External Expense",
      dataIndex: "expense",
      key: "expense",
      render: (expense) =>
        expense ? (
          <Link to={`/dashboard/accounting/expenses/${expense._id}`}>
            <button className="px-2 py-1 rounded-md bg-blue-600 text-white hover:bg-blue-700">
              View Expense
            </button>
          </Link>
        ) : "-",
    },
    {
      title: "Transaction",
      key: "transaction",
      render: (_, record) => (
        <Space>
          {record.cashTransaction ? (
            <Link to={`/dashboard/accounting/cash-transactions/${record.cashTransaction._id}`}>
              <button className="px-2 py-1 rounded-md bg-green-600 text-white hover:bg-green-700">
                View Cash
              </button>
            </Link>
          ) : record.bankTransaction ? (
            <Link to={`/dashboard/accounting/bank-transactions/${record.bankTransaction._id}`}>
              <button className="px-2 py-1 rounded-md bg-green-600 text-white hover:bg-green-700">
                View Bank
              </button>
            </Link>
          ) : "-"}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <>
          <button
            onClick={() => openViewModal(record._id)}
            className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
          >
            <GrView />
          </button>
          <button
            onClick={() => openUpdateModal(record._id)}
            className="px-4 py-2 ms-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
          >
            <MdEditSquare />
          </button>
          <button
            onClick={() => openPrintModal(record._id)}
            className="px-4 py-2 ms-2 bg-gray-50 border hover:bg-gray-100 hover:text-black text-black font-bold rounded-md shadow-md"
          >
            <FaPrint />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && <LoadingScreen />}
      <div className="container max-w-8xl mx-auto" id="viewStaff">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            Billing Archives
          </h1>
          <div className="flex justify-between mt-3">
            <div>
              <Input
                placeholder="Search..."
                prefix={<SearchOutlined />}
                allowClear
                value={searchTerm}
                onChange={handleSearch}
                style={{ width: 250 }}
              />
              <RangePicker
                style={{ marginLeft: "1rem" }}
                format="YYYY-MM-DD"
                value={dateRange}
                onChange={handleDateChange}
              />
              <Button
                type="default"
                onClick={handleResetFilters}
                style={{ marginLeft: "1rem" }}
              >
                Reset Filters
              </Button>
            </div>
            <div>
              <button
                onClick={() => setIsCreateBillModalOpen(true)}
                className="px-4 py-1.5 rounded-md bg-sky-600 text-white hover:bg-sky-700"
              >
                Create Bill
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>

        {/* Create Bill Modal */}
        {isCreateBillModalOpen && (
          <DynamicModal
            isOpen={isCreateBillModalOpen}
            onClose={() => setIsCreateBillModalOpen(false)}
            title="Create Test Bill"
          >
            <CreateTestBill
              onSuccess={() => {
                setIsCreateBillModalOpen(false);
                fetchData();
              }}
            />
          </DynamicModal>
        )}

        {/* View Detailed Bill Modal */}
        {isViewModalOpen && (
          <DynamicModal
            isOpen={isViewModalOpen}
            onClose={() => setIsViewModalOpen(false)}
            title="View Detailed Bill"
          >
            <ViewDetailedBill billId={selectedBillId} />
          </DynamicModal>
        )}

        {/* Update Test Bill Modal */}
        {isUpdateModalOpen && (
          <DynamicModal
            isOpen={isUpdateModalOpen}
            onClose={() => setIsUpdateModalOpen(false)}
            title="Update Test Bill"
          >
            <UpdateTestBill
              billId={selectedBillId}
              onSuccess={() => {
                setIsUpdateModalOpen(false);
                fetchData();
              }}
            />
          </DynamicModal>
        )}

        {/* Print Invoice Modal */}
        {isPrintModalOpen && (
          <DynamicModal
            isOpen={isPrintModalOpen}
            onClose={() => setIsPrintModalOpen(false)}
            title="Print Invoice"
          >
            <StaffBillPrintInvoice billId={selectedBillId} />
          </DynamicModal>
        )}
      </div>
    </>
  );
};

export default ViewLabBillsPage;