import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Table,
  Input,
  Spin,
  message,
  Form,
  Typography,
  Popconfirm,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import LoadingScreen from "../../../lib/constants/loadingScreen";

const ViewMedicalTestLists = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [form] = Form.useForm();

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/medical_test_list`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medical test lists:", error);
        message.error(
          "Failed to fetch medical test lists. Please try again later."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteMedicalTest = async (testId) => {
    setActionLoading(true);
    try {
      if (window.confirm("Are you sure you want to delete this medical test?")) {
        const response = await axios.delete(
          `${apiBaseUrl}/medical_test_list/${testId}`,
          config
        );
        if (response.data.success) {
          message.success("Medical test deleted", 0.6, function onClose() {
            fetchData();
          });
        } else {
          message.error("Failed to delete medical test.");
        }
      }
    } catch (error) {
      console.error("Error deleting medical test:", error);
      message.error("Failed to delete medical test! Please try again later.");
    } finally {
      setActionLoading(false);
    }
  };

  const deleteMedicalTestSubtitle = async (testId, subtitleId) => {
    setActionLoading(true);
    try {
      if (window.confirm("Are you sure you want to delete this subtitle?")) {
        const response = await axios.delete(
          `${apiBaseUrl}/medical_test_list/${testId}/subtitle/${subtitleId}`,
          config
        );
        if (response.data.success) {
          message.success("Subtitle deleted", 0.6, function onClose() {
            fetchData();
          });
        } else {
          message.error("Failed to delete subtitle.");
        }
      }
    } catch (error) {
      console.error("Error deleting subtitle:", error);
      message.error("Failed to delete subtitle! Please try again later.");
    } finally {
      setActionLoading(false);
    }
  };

  const filteredMedicines = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        const filtered = details.filter(
          (test) =>
            test?.name.toLowerCase().includes(lowercasedSearchTerm) ||
            test.subtitle?.some((sub) =>
              sub.name.toLowerCase().includes(lowercasedSearchTerm)
            )
        );
        setFilteredDetails(filtered);

        const expandedKeys = filtered
          .filter((test) => test.subtitle && test.subtitle.length > 0)
          .map((test) => test._id);
        setExpandedRowKeys(expandedKeys);
      } else {
        setFilteredDetails(details);
        setExpandedRowKeys([]);
      }
    }, 500),
    [details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filteredMedicines(searchTerm);
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      price: "",
      pf: "", // Include pf for editing
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key, testId) => {
    try {
      const row = await form.validateFields();
      const newData = [...details];
      const testIndex = newData.findIndex((item) => item._id === testId);
      if (testIndex > -1) {
        const testItem = newData[testIndex];
        const subtitleIndex = testItem.subtitle.findIndex(
          (sub) => key === sub._id
        );
        if (subtitleIndex > -1) {
          const subtitleItem = testItem.subtitle[subtitleIndex];
          const updatedSubtitle = { ...subtitleItem, ...row };
          const response = await axios.put(
            `${apiBaseUrl}/medical_test_list/${testId}/subtitle/${key}`,
            updatedSubtitle,
            config
          );
          if (response.data.success) {
            testItem.subtitle.splice(subtitleIndex, 1, response.data.updatedTest.subtitle.find(sub => sub._id === key));
            newData.splice(testIndex, 1, testItem);
            setDetails(newData);
            setFilteredDetails(newData);
            setEditingKey("");
            message.success("Subtitle updated successfully");
          } else {
            message.error("Failed to update subtitle.");
          }
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      message.error("Validation failed. Please check the fields.");
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              { required: dataIndex !== "pf", message: `Please Input ${title}!` }, // pf is optional
            ]}
          >
            <Input placeholder={`Please enter ${title}`} />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const expandedRowRender = (record) => {
    if (!record.subtitle || record.subtitle.length === 0) {
      return null;
    }
    const expandedColumns = [
      {
        title: "SN",
        dataIndex: "sn",
        key: "sn",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        editable: true,
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        editable: true,
      },
      {
        title: "PF",
        dataIndex: "pf",
        key: "pf",
        editable: true,
        render: () => "-", // Hide pf value in display (since it's excluded from API response)
      },
      {
        title: "Action",
        key: "action",
        width: 300,
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record._id, record.testId)}
                style={{ marginRight: 8 }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <Link>Cancel</Link>
              </Popconfirm>
            </span>
          ) : (
            <div className="flex">
              <Typography.Link
                disabled={editingKey !== ""}
                className="me-2"
                onClick={() => edit(record)}
              >
                <span className="text-sky-600 hover:text-sky-700">
                  <MdEditSquare size={24} />
                </span>
              </Typography.Link>
              <Typography.Link
                onClick={() =>
                  deleteMedicalTestSubtitle(record.testId, record._id)
                }
              >
                <span className="text-red-500 hover:text-red-600">
                  <MdDelete size={24} />
                </span>
              </Typography.Link>
            </div>
          );
        },
      },
    ];

    const mergedColumns = expandedColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "price" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    const expandedData = record.subtitle.map((sub, index) => ({
      ...sub,
      key: sub._id,
      sn: `${record.sn}.${index + 1}`,
      testId: record._id,
    }));

    return (
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={expandedData}
          pagination={{
            defaultPageSize: 3,
            showLessItems: true,
            style: { marginBottom: "0" },
          }}
          rowClassName="editable-row"
        />
      </Form>
    );
  };

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      key: "sn",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Test Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => (price ? price : "-"),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => category?.category || "",
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/medical_test/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            onClick={() => {
              deleteMedicalTest(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800">
              <MdDelete />
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">Test Lists</h1>
          <div className="flex justify-between mt-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
            <div>
              <Link to="/dashboard/add/medical_test">
                <button className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                  Add Test
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            expandable={{
              expandedRowRender,
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: setExpandedRowKeys,
              rowExpandable: (record) =>
                record.subtitle && record.subtitle.length > 0,
            }}
            pagination={{
              className: "pe-3",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ViewMedicalTestLists;