import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    message,
    Space,
    InputNumber,
    Select,
    Row,
    Col,
    AutoComplete,
} from "antd";
import { SearchOutlined, PlusOutlined, CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// Predefined item list
const predefinedItems = [
    "Paracetamol 500mg",
    "Ibuprofen 200mg",
    "Stethoscope",
    "Blood Pressure Monitor",
    "Surgical Gloves",
];

const AccountsPayablePage = () => {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [paymentMethodModal, setPaymentMethodModal] = useState({
        visible: false,
        record: null,
        selectedMethod: "Cash",
    });
    const [selectedType, setSelectedType] = useState("vendor"); // Default type for modal
    const [filterType, setFilterType] = useState("all"); // Default filter type
    const [searchParams, setSearchParams] = useSearchParams();

    // Axios configuration with authentication
    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    // Fetch records from the backend with optional filters and search from URL
    const fetchRecords = async (page = 1, pageSize = 10) => {
        setLoading(true);
        try {
            const urlSearch = searchParams.get("search");
            const urlType = searchParams.get("type") || "all";
            const effectiveSearch = urlSearch || searchText || undefined;
            const effectiveType = urlType !== "all" ? urlType : undefined;

            console.log("Fetching records with search:", effectiveSearch, "type:", effectiveType); // Debug log

            const params = {
                page,
                limit: pageSize,
                search: effectiveSearch,
                startDueDate: dateRange[0] ? dateRange[0].startOf("day").toISOString() : undefined,
                endDueDate: dateRange[1] ? dateRange[1].endOf("day").toISOString() : undefined,
                type: effectiveType,
            };
            const response = await axios.get(`${apiBaseUrl}/accounts-payable`, { ...config, params });
            const fetchedRecords = response.data.data || [];
            setRecords(fetchedRecords);
            setPagination({
                current: page,
                pageSize,
                total: response.data.total || fetchedRecords.length,
            });

            if (urlSearch && !searchText) {
                setSearchText(urlSearch);
            }
            if (urlType && filterType !== urlType) {
                setFilterType(urlType);
            }
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to fetch records");
            setRecords([]);
            console.error("Error fetching records:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecords(pagination.current, pagination.pageSize);
    }, [searchText, dateRange, searchParams]);

    // Handle saving a record (add/update)
    const handleSaveRecord = async (values) => {
        const recordData = {
            type: selectedType,
            vendor: selectedType === "vendor" ? values.vendor : undefined,
            category: selectedType === "expense" ? values.category : undefined,
            amount: selectedType !== "inventoryCosts" ? values.amount : undefined, // Only include amount for non-inventory types
            dueDate: (selectedType !== "inventoryCosts" && values.dueDate) ? values.dueDate.toISOString() : undefined, // Include dueDate if provided, regardless of paymentStatus
            paymentStatus: values.paymentStatus || "Pending",
            description: values.description || "",
            paymentMethod: values.paymentMethod || "",
            paidTo: selectedType === "expense" ? values.paidTo : undefined,
            itemName: selectedType === "inventoryCosts" ? values.itemName : undefined,
            itemType: selectedType === "inventoryCosts" ? values.itemType : undefined,
            cost: selectedType === "inventoryCosts" ? values.cost : undefined,
            quantity: selectedType === "inventoryCosts" ? values.quantity : undefined,
            purchaseDate: selectedType === "inventoryCosts" && values.purchaseDate ? values.purchaseDate.toISOString() : undefined,
            depreciation: selectedType === "inventoryCosts" ? values.depreciation : undefined,
            employee: selectedType === "payroll" ? values.employee : undefined,
            period: selectedType === "payroll" ? values.period : undefined,
        };

        if (selectedType === "inventoryCosts" && values.quantity > 0) {
            recordData.costPerQuantity = values.cost / values.quantity; // Virtual field (handled server-side)
        }

        setLoading(true);
        try {
            if (editingRecord) {
                await axios.put(`${apiBaseUrl}/accounts-payable/${editingRecord._id}`, recordData, config);
                message.success("Record updated successfully");
            } else {
                await axios.post(`${apiBaseUrl}/accounts-payable`, recordData, config);
                message.success("Record created successfully");
            }
            await fetchRecords(pagination.current, pagination.pageSize);
            setIsModalOpen(false);
            setEditingRecord(null);
            form.resetFields();
            setSelectedType("vendor"); // Reset to default type
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to save record");
            console.error("Error saving record:", error);
        } finally {
            setLoading(false);
        }
    };

    // Handle marking a record as Paid
    const handleMarkAsPaid = (record) => {
        setPaymentMethodModal({
            visible: true,
            record,
            selectedMethod: record.paymentMethod || "Cash",
        });
    };

    const handleConfirmPaid = async () => {
        const { record, selectedMethod } = paymentMethodModal;
        setLoading(true);
        try {
            const currentDate = new Date();
            const updatedRecord = {
                ...record,
                type: record.type,
                paymentStatus: "Paid", // Always set to "Paid" when marking as paid
                paymentMethod: selectedMethod,
                paidAt: currentDate,
                dueDate: record.dueDate,
                vendor: record.vendor,
                category: record.category,
                amount: record.amount,
                paidTo: record.paidTo,
                itemName: record.itemName,
                itemType: record.itemType,
                cost: record.cost,
                quantity: record.quantity,
                purchaseDate: record.purchaseDate,
                depreciation: record.depreciation,
                employee: record.employee,
                period: record.period,
                description: record.description,
            };
            await axios.put(`${apiBaseUrl}/accounts-payable/${record._id}`, updatedRecord, config);
            message.success(`Record marked as ${updatedRecord.paymentStatus} successfully`);
            await fetchRecords(pagination.current, pagination.pageSize);
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to mark as Paid");
            console.error("Error marking as Paid:", error);
        } finally {
            setLoading(false);
            setPaymentMethodModal({ visible: false, record: null, selectedMethod: "Cash" });
        }
    };

    const handleCancelPaid = () => {
        setPaymentMethodModal({ visible: false, record: null, selectedMethod: "Cash" });
    };

    // Handle deleting a record
    const handleDeleteRecord = (id) => {
        Modal.confirm({
            title: "Are you sure you want to delete this record?",
            onOk: async () => {
                setLoading(true);
                try {
                    await axios.delete(`${apiBaseUrl}/accounts-payable/${id}`, config);
                    message.success("Record deleted successfully");
                    await fetchRecords(pagination.current, pagination.pageSize);
                } catch (error) {
                    message.error(error.response?.data?.error || "Failed to delete record");
                    console.error("Error deleting record:", error);
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    // Open modal for adding or editing a record
    const showModal = (record = null) => {
        setEditingRecord(record);
        if (record) {
            form.setFieldsValue({
                ...record,
                dueDate: record.dueDate ? moment(record.dueDate) : null,
                purchaseDate: record.purchaseDate ? moment(record.purchaseDate) : null,
            });
            setSelectedType(record.type);
        } else {
            form.resetFields();
            setSelectedType("vendor"); // Default to vendor
        }
        setIsModalOpen(true);
    };

    // Define table columns dynamically based on filterType
    const getColumns = () => {
        const baseColumns = [
            {
                title: filterType === "vendor" ? "Vendor" : filterType === "expense" ? "Category/Paid To" : filterType === "inventoryCosts" ? "Item" : filterType === "payroll" ? "Employee/Period" : "Vendor/Item/Employee",
                dataIndex: "vendor",
                sorter: (a, b) => (a.vendor || a.itemName || a.employee || "").localeCompare(b.vendor || b.itemName || b.employee || ""),
                render: (text, record) => {
                    if (record.type === "vendor") return record.vendor || "-";
                    if (record.type === "expense") return `${record.category || "-"} to ${record.paidTo || "-"}`;
                    if (record.type === "inventoryCosts") return record.itemName || "-";
                    if (record.type === "payroll") return `${record.employee || "-"} (${record.period || "-"})`;
                    return "-";
                },
            },
            {
                title: filterType === "inventoryCosts" ? "Cost" : "Amount",
                dataIndex: "amount",
                sorter: (a, b) => (a.amount || a.cost || 0) - (b.amount || b.cost || 0),
                render: (text, record) => `$${record.type === "inventoryCosts" ? (record.cost || 0).toFixed(2) : (record.amount || 0).toFixed(2)}`,
            },
            {
                title: filterType === "inventoryCosts" ? "Purchase Date" : "Due Date",
                dataIndex: "dueDate",
                sorter: (a, b) => new Date(a.dueDate || a.purchaseDate || 0) - new Date(b.dueDate || b.purchaseDate || 0),
                render: (text, record) => {
                    if (record.type === "inventoryCosts") return record.purchaseDate ? moment(record.purchaseDate).format("YYYY-MM-DD") : "-";
                    return record.dueDate ? moment(record.dueDate).format("YYYY-MM-DD") : "-";
                },
            },
            {
                title: "Payment Status",
                dataIndex: "paymentStatus",
                sorter: (a, b) => a.paymentStatus.localeCompare(b.paymentStatus),
                render: (text, record) => {
                    if (record.paymentStatus === "Paid" && record.dueDate && record.paidAt) {
                        const dueDate = moment(record.dueDate);
                        const paidAt = moment(record.paidAt);
                        if (paidAt.isBefore(dueDate, 'day')) {
                            return `${record.paymentStatus} (Paid at: ${paidAt.format("YYYY-MM-DD")})`;
                        }
                    }
                    return record.paymentStatus || "-";
                },
            },
            {
                title: "Description",
                dataIndex: "description",
            },
            {
                title: "Payment Method",
                dataIndex: "paymentMethod",
            },
            {
                title: "Type",
                dataIndex: "type",
                render: (text) => text || "-",
            },
        ];

        if (filterType === "inventoryCosts" || filterType === "all") {
            baseColumns.push(
                {
                    title: "Quantity",
                    dataIndex: "quantity",
                    render: (text, record) => (record.type === "inventoryCosts" ? record.quantity || "-" : "-"),
                },
                {
                    title: "Cost Per Quantity",
                    dataIndex: "costPerQuantity",
                    render: (text, record) => (record.type === "inventoryCosts" ? `$${(record.costPerQuantity || 0).toFixed(2)}` : "-"),
                }
            );
        }

        baseColumns.push({
            title: "Actions",
            render: (_, record) => (
                <Space>
                    {record.paymentStatus !== "Paid" && (<Button onClick={() => showModal(record)}>Edit</Button>)}
                    {record.paymentStatus !== "Paid" && (
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => handleMarkAsPaid(record)}
                        >
                            Mark as Paid
                        </Button>
                    )}
                    {record.paymentStatus !== "Paid" && (
                        <Button danger onClick={() => handleDeleteRecord(record._id)}>
                            Delete
                        </Button>
                    )}
                </Space>
            ),
        });

        return baseColumns;
    };

    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Accounts Payable / Inventory Management</h1>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col>
                    <Space>
                        <Input
                            placeholder="Search by vendor, itemName, description, or payment method"
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                setSearchParams((prev) => {
                                    const params = {};
                                    if (e.target.value) params.search = e.target.value;
                                    if (filterType !== "all") params.type = filterType;
                                    return params;
                                });
                            }}
                            prefix={<SearchOutlined />}
                            style={{ width: 250 }}
                            allowClear
                        />
                        <DatePicker.RangePicker
                            value={dateRange}
                            onChange={(dates) => setDateRange(dates)}
                            format="YYYY-MM-DD"
                        />
                        <Select
                            value={filterType}
                            onChange={(value) => {
                                setFilterType(value);
                                setSearchParams((prev) => {
                                    const params = {};
                                    if (searchText) params.search = searchText;
                                    if (value !== "all") params.type = value;
                                    return params;
                                });
                            }}
                            style={{ width: 150 }}
                            placeholder="Filter by Type"
                        >
                            <Option value="all">All</Option>
                            <Option value="vendor">Vendor</Option>
                            <Option value="expense">Expense</Option>
                            <Option value="inventoryCosts">Inventory Costs</Option>
                            <Option value="payroll">Payroll</Option>
                        </Select>
                        <Button
                            type="default"
                            onClick={() => {
                                setSearchText("");
                                setDateRange([null, null]);
                                setFilterType("all");
                                setSearchParams({});
                                fetchRecords(pagination.current, pagination.pageSize);
                            }}
                        >
                            Reset Filters
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
                        Add Record
                    </Button>
                </Col>
            </Row>
            <Table
                columns={getColumns()}
                dataSource={records}
                rowKey="_id"
                loading={loading}
                pagination={{
                    ...pagination,
                    onChange: (page, pageSize) => {
                        setPagination((prev) => ({ ...prev, current: page, pageSize }));
                        fetchRecords(page, pageSize);
                    },
                }}
            />
            <Modal
                title={editingRecord ? "Edit Record" : "Add Record"}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    setEditingRecord(null);
                    form.resetFields();
                    setSelectedType("vendor");
                }}
                footer={null}
                width={600}
                style={{ top: 20 }}
                bodyStyle={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden" }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSaveRecord}
                    style={{ display: "flex", flexDirection: "column", gap: 8 }}
                >
                    <Form.Item
                        name="type"
                        label="Record Type"
                        rules={[{ required: true, message: "Please select record type" }]}
                        initialValue="vendor"
                    >
                        <Select value={selectedType} onChange={setSelectedType} style={{ width: "100%" }}>
                            <Option value="vendor">Vendor</Option>
                            <Option value="expense">Expense</Option>
                            <Option value="inventoryCosts">Inventory Costs</Option>
                            <Option value="payroll">Payroll</Option>
                        </Select>
                    </Form.Item>
                    {selectedType === "vendor" && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="vendor"
                                    label="Vendor"
                                    rules={[{ required: true, message: "Please enter vendor name" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    rules={[{ required: true, message: "Please enter amount" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {selectedType === "expense" && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="category"
                                    label="Category"
                                    rules={[{ required: true, message: "Please select category" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <Select>
                                        <Option value="Equipment Maintenance">Equipment Maintenance</Option>
                                        <Option value="Medical Supplies">Medical Supplies</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="paidTo"
                                    label="Paid To"
                                    rules={[{ required: true, message: "Please enter paid to" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    rules={[{ required: true, message: "Please enter amount" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {selectedType === "inventoryCosts" && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="itemName"
                                    label="Item Name"
                                    rules={[{ required: true, message: "Please enter or select item name" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <AutoComplete
                                        options={predefinedItems.map((item) => ({ value: item }))}
                                        style={{ width: "100%" }}
                                        placeholder="Type to search items"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => form.setFieldsValue({ itemName: value })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="itemType"
                                    label="Item Type"
                                    rules={[{ required: true, message: "Please select item type" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <Select>
                                        <Option value="Equipment">Equipment</Option>
                                        <Option value="Medicine">Medicine</Option>
                                        <Option value="Supply">Supply</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="cost"
                                    label="Cost"
                                    rules={[{ required: true, message: "Please enter cost" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="quantity"
                                    label="Quantity"
                                    rules={[{ required: true, message: "Please enter quantity" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <InputNumber min={1} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="purchaseDate"
                                    label="Purchase Date"
                                    rules={[{ required: true, message: "Please select purchase date" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="depreciation" label="Depreciation" style={{ marginBottom: 8 }}>
                                    <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Cost Per Quantity" name="costPerQuantity" style={{ marginBottom: 8 }}>
                                    <Input readOnly value={form.getFieldValue("cost") / (form.getFieldValue("quantity") || 1) || 0} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {selectedType === "payroll" && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="employee"
                                    label="Employee"
                                    rules={[{ required: true, message: "Please enter employee name" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="period"
                                    label="Pay Period"
                                    rules={[{ required: true, message: "Please enter pay period" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <Input placeholder="e.g., Jan 2025" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    rules={[{ required: true, message: "Please enter amount" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {(selectedType === "vendor" || selectedType === "expense" || selectedType === "payroll") && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="dueDate"
                                    label="Due Date"
                                    rules={[{ required: true, message: "Please select due date" }]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name="paymentStatus"
                                label="Payment Status"
                                style={{ marginBottom: 8 }}
                                initialValue="Pending"
                            >
                                <Select
                                    onChange={(value) => {
                                        // When paymentStatus changes, re-validate paymentMethod
                                        form.validateFields(["paymentMethod"], { force: true });
                                    }}
                                >
                                    <Option value="Paid">Paid</Option>
                                    <Option value="Pending">Pending</Option>
                                    <Option value="Partially Paid">Partially Paid</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="description"
                                label="Description"
                                style={{ marginBottom: 8 }}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="paymentMethod"
                                label="Payment Method"
                                style={{ marginBottom: 8 }}
                                dependencies={["paymentStatus"]}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const paymentStatus = getFieldValue("paymentStatus");
                                            if ((paymentStatus === "Paid" || paymentStatus === "Partially Paid") && !value) {
                                                return Promise.reject(new Error("Payment method is required when status is Paid or Partially Paid"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block style={{ marginTop: 8 }}>
                            {editingRecord ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Select Payment Method"
                open={paymentMethodModal.visible}
                onOk={handleConfirmPaid}
                onCancel={handleCancelPaid}
                okText="OK"
                cancelText="Cancel"
            >
                <Select
                    value={paymentMethodModal.selectedMethod}
                    style={{ width: "100%" }}
                    onChange={(value) =>
                        setPaymentMethodModal((prev) => ({ ...prev, selectedMethod: value }))
                    }
                >
                    <Option value="Cash">Cash</Option>
                    <Option value="Bank">Bank</Option>
                </Select>
            </Modal>
        </div>
    );
};

export default AccountsPayablePage;