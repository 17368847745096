import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// Function to get the appropriate authorization header
const getAuthConfig = () => {
  // List of possible tickets in order of priority (you can adjust the order)
  const ticketTypes = [
    { key: "adminticket", name: "admin" },
    { key: "staffticket", name: "staff" },
    { key: "doctorticket", name: "doctor" },
    { key: "pharmaticket", name: "pharma" },
    { key: "labreporterticket", name: "labreporter" },
    // Add other ticket types as needed
  ];

  // Find the first available ticket
  const availableTicket = ticketTypes.find(ticket => localStorage.getItem(ticket.key));

  // If no ticket is found, you can either return a default config or throw an error
  if (!availableTicket) {
    console.warn("No authentication ticket found in localStorage");
    return { headers: {} }; // Return config without Authorization header
    // Or throw an error: throw new Error("No authentication ticket available");
  }

  // Return config with the found ticket
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`
    }
  };
};

// Usage example
const config = getAuthConfig();
const apiService = {

  fetchBills: async () => {
    const response = await axios.get(`${apiBaseUrl}/bills`, config);
    return response.data;
  },

  createBill: async (billData) => {
    const response = await axios.post(`${apiBaseUrl}/bills`, config, billData);
    return response.data;
  },

  updateBill: async (billData) => {
    const response = await axios.put(
      `${apiBaseUrl}/bills/${billData._id}`,
      config,
      billData
    );
    return response.data;
  },

  fetchBillById: async (billId) => {
    const response = await axios.get(`${apiBaseUrl}/bills/${billId}`, config);
    return response.data;
  },
};

export default apiService;
