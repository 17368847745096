import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import LoadingScreen from "../../../lib/constants/loadingScreen";
import { appointmentContext } from "../../../pages/EndedAppointment";
import { jwtDecode } from "jwt-decode";
import { message } from "antd";

// Import components for viewing
import ViewSummary from "../../../pages/summary/onlyViewSummary"; // View-only version
import ViewAdvice from "../../../pages/advice/onlyViewAdvice"; // View-only version
import ViewDiagnosis from "../../../pages/diagnosis/onlyViewDiagnosis"; // View-only version
import ViewReportByAppointmentId from "../../../pages/ViewReportWrtAppointment";
import HistoryPrintout from "../../../pages/printout/historyPrintout";
import ViewPrescription from "../../../pages/viewPrescription";

const ActionEndedAppointment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    appointmentId,
    appointmentNumber,
    full_name,
    problem,
    report,
    doctorIdForCheck,
    appointmentHistory,
    openDynamicModal,
    refresh, // Added from context
  } = useContext(appointmentContext);

  const [actionLoading, setActionLoading] = useState(false);
  const [loggedInDoctorId, setLoggedInDoctorId] = useState(null);

  // Dynamic auth config
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];
    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    return {
      headers: {
        Authorization: `Bearer ${availableTicket ? localStorage.getItem(availableTicket.key) : ""}`,
      },
    };
  };

  const config = getAuthConfig();

  useEffect(() => {
    const token = localStorage.getItem("doctorticket") || localStorage.getItem("staffticket");
    if (token) {
      const decodedToken = jwtDecode(token);
      setLoggedInDoctorId(decodedToken.dID);
    }
  }, []);

  const updateAppointmentStatus = () => {
    if (loggedInDoctorId === doctorIdForCheck) {
      if (window.confirm("Are you sure want to change this appointment status to follow up?")) {
        setActionLoading(true);
        const data = {
          appointmentStatus: "FollowUp",
        };
        axios
          .put(`${apiBaseUrl}/doctor/updateAppointmentStatus/${appointmentId}`, data, config)
          .then((response) => {
            if (response.data.msg === "Updated Status") {
              message.success("Appointment status changed to followup.", 0.6, () => {
                refresh(); // Refresh the table
              });
            } else {
              message.error("Failed To Update! Please try again.");
            }
          })
          .catch((err) => {
            console.error("Error", err);
            message.error("Failed to update! Please try again.");
          })
          .finally(() => {
            setActionLoading(false);
          });
      }
    } else {
      message.error(
        "Sorry! You are not authorized to change the status of this appointment! Please contact ADMIN or STAFF."
      );
    }
  };

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}
      <div className="bg-white px-4 pt-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center">
            <h1
              className="text-3xl leading-6 font-medium text-center text-gray-900"
              id="modal-title"
            >
              Actions
            </h1>
            <div className="">
              <p className="text-sm text-gray-500 mx-5">
                <div className="py-2 mx-5">
                  <ul className="my-4 space-y-3 mx-8">
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("History Printout", (
                            <HistoryPrintout
                              full_name={full_name}
                              problem={problem}
                              status="Ended"
                              sendTo="DoctorEnded"
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">History Printout</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Report", (
                            <ViewReportByAppointmentId
                              report={report}
                              appointmentNumber={appointmentNumber}
                              full_name={full_name}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Report</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Prescription", (
                            <ViewPrescription
                              full_name={full_name}
                              problem={problem}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-2.5 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Prescription</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Diagnosis", (
                            <ViewDiagnosis
                              full_name={full_name}
                              problem={problem}
                              appointmentNumber={appointmentNumber}
                              appointmentHistory={appointmentHistory}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Diagnosis</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Advices", (
                            <ViewAdvice
                              full_name={full_name}
                              problem={problem}
                              appointmentNumber={appointmentNumber}
                              appointmentHistory={appointmentHistory}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Advices</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openDynamicModal("View Summary", (
                            <ViewSummary
                              full_name={full_name}
                              problem={problem}
                              appointmentNumber={appointmentNumber}
                              appointmentHistory={appointmentHistory}
                              appointmentId={appointmentId}
                            />
                          ))
                        }
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">View Summary</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={updateAppointmentStatus}
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">Arrange Follow-Up</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionEndedAppointment;