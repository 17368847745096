import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import maleIcon from "../components/img/male-user-icon.png";
import femaleIcon from "../components/img/female-user-icon.png";
import otherIcon from "../components/img/others.png";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Table, Input, Spin, Image, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import { calculateAge } from "../lib/constants/calculateAge";
import DynamicModal from "./dynamicModel"; // Adjust path if necessary
import AddPatient from "./AddPatient";
import UpdatePatient from "./UpdatePatient";

const User = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalTitle, setDynamicModalTitle] = useState("");
  const [dynamicModalContent, setDynamicModalContent] = useState(null);

  // Dynamic auth config
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];
    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} };
    }
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
      },
    };
  };

  const config = getAuthConfig();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiBaseUrl}/viewpatientsstaff`, config);
      if (response.data.success) {
        setDetails(response.data.data);
        setFilteredDetails(response.data.data);
      }
    } catch (e) {
      console.error("Failed to get user details:", e);
      message.error("Failed to get user details! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const deletePatient = async (patient_id) => {
    if (window.confirm("Are you sure want to delete this user?")) {
      setActionLoading(true);
      try {
        const result = await axios.delete(`${apiBaseUrl}/patient/delete/${patient_id}`, config);
        if (result.data.success) {
          message.success("Success deletion", 0.6, () => fetchUsers());
        } else {
          message.error("Failed to delete");
        }
      } catch (e) {
        console.error("Failed to delete user:", e);
        message.error("Failed to delete user! Please try again.");
      } finally {
        setActionLoading(false);
      }
    }
  };

  const filterUserDetails = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter(
          (user) =>
            user?.userID.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user?.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user?.phone.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        fetchUsers();
      }
    }, 500),
    [details] // Removed searchTerm from dependencies as it’s handled in handleSearch
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterUserDetails(searchTerm);
  };

  const openDynamicModal = (title, content) => {
    setDynamicModalTitle(title);
    setDynamicModalContent(content);
    setIsDynamicModalOpen(true);
  };

  const closeDynamicModal = () => {
    setIsDynamicModalOpen(false);
    setDynamicModalTitle("");
    setDynamicModalContent(null);
    fetchUsers(); // Refresh table after modal closes
  };

  const columns = [
    {
      title: "Image",
      key: "picture",
      render: (_, record) => {
        const { picture, gender } = record;
        if (picture) {
          return (
            <Image
              src={picture}
              width={80}
              height={80}
              className="object-contain rounded-md"
            />
          );
        } else {
          if (gender === "Male") {
            return (
              <Image
                src={maleIcon}
                width={80}
                height={80}
                className="object-contain rounded-md"
              />
            );
          } else if (gender === "Female") {
            return (
              <Image
                src={femaleIcon}
                width={80}
                height={80}
                className="object-contain rounded-md"
              />
            );
          } else {
            return (
              <Image
                src={otherIcon}
                width={80}
                height={80}
                className="object-contain rounded-md"
              />
            );
          }
        }
      },
    },
    {
      title: "UserID",
      dataIndex: "userID",
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => (email ? email : "-"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
      render: (DOB) => (DOB ? DOB.split("T")[0] : "-"),
    },
    {
      title: "Age",
      key: "age",
      width: 100,
      render: (_, data) => {
        const DOB = data.DOB;
        if (DOB) {
          const age = calculateAge(DOB);
          if (age.includes("year") || age.includes("years")) {
            const ageValue = age?.split(",")[0];
            return ageValue;
          } else {
            return age;
          }
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <button
            onClick={() =>
              openDynamicModal("Edit Patient", (
                <UpdatePatient patientId={record._id} onSuccess={closeDynamicModal} />
              ))
            }
            className="px-4 py-2 ms-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
          >
            <MdEditSquare />
          </button>
          <button
            onClick={() => deletePatient(record._id)}
            className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800"
          >
            <MdDelete />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">Patients</h1>
          <div className="flex justify-between mt-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
            <button
              onClick={() =>
                openDynamicModal("Add Patient", (
                  <AddPatient onSuccess={closeDynamicModal} />
                ))
              }
              className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
            >
              Add Patient
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>

      {/* Dynamic Modal */}
      {isDynamicModalOpen && (
        <DynamicModal
          isOpen={isDynamicModalOpen}
          onClose={closeDynamicModal}
          title={dynamicModalTitle}
        >
          {dynamicModalContent}
        </DynamicModal>
      )}
    </>
  );
};

export default User;