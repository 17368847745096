import React, { useState, useEffect } from "react";
import axios from "axios";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { message, Select, Row, Col, Typography, Modal, Button } from "antd";
import { testTypesData } from "./report_data";
import { Link } from "react-router-dom";
import UpdateReport from "./update_report";

const { Option } = Select;

const AddReport = ({ appointmentId, full_name, problem, onSuccess, status }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [fullName_state, setFullName] = useState("");
  const [problem_state, setProblem] = useState("");
  const [followUp, setFollowUp] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(true);
  const [doctorDepartment, setDoctorDepartment] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [singleAppointmentData, setSingleAppointmentData] = useState(null);
  const [reportName, setReportName] = useState("");
  const [reportList, setReportList] = useState([
    {
      test: "",
      result: "",
      unit: "",
      refRange: "",
    },
  ]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [removedTestNames, setRemovedTestNames] = useState([]); // Track removed test names and their test types
  const [unitOptions] = useState([
    "mg/dl",
    "mg/l",
    "Between 4-5%",
    "Mm/hr",
    "gm/dl",
    "/cumm",
    "millions/cumm",
    "/Hpf",
    "g/dl",
    "mmol/L",
    "µmol/L",
    "mEq/L",
    "ng/mL",
    "pg/mL",
    "U/L",
    "IU/L",
    "%",
    "K/µL",
    "cells/mcL",
    "mm/hr",
    "fL",
    "pg",
    "g/L",
    "ratio",
  ]);
  const [testTypes] = useState(testTypesData);
  const [details, setDetails] = useState([]);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false); // State for Update Modal
  const [currentReportId, setCurrentReportId] = useState(null); // To store the report ID for update

  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];
    const availableTicket = ticketTypes.find((ticket) =>
      localStorage.getItem(ticket.key)
    );
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} };
    }
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
      },
    };
  };

  const config = getAuthConfig();

  const getResultColor = (result, refRange) => {
    if (!result || !refRange) return "bg-white";
    const isWithinRange = true; // Add your range checking logic here
    return isWithinRange ? "bg-blue-50" : "bg-red-50";
  };

  const isTitleTest = (test) => {
    return (
      test.test !== "" &&
      test.result === "" &&
      test.unit === "" &&
      test.refRange === ""
    );
  };

  const renderReportList = () => {
    const handleKeyDown = (e, index) => {
      if (e.key === "ArrowDown" && index < reportList.length - 1) {
        e.preventDefault();
        const nextResultInput = document.querySelector(
          `#result-input-${index + 1}`
        );
        if (nextResultInput) nextResultInput.focus();
      } else if (e.key === "ArrowUp" && index > 0) {
        e.preventDefault();
        const prevResultInput = document.querySelector(
          `#result-input-${index - 1}`
        );
        if (prevResultInput) prevResultInput.focus();
      }
    };

    return (
      <div className="mt-4 space-y-4">
        <table className="w-full">
          <thead>
            <tr className="border-b">
              <th className="text-left p-2">Test Name</th>
              <th className="text-left p-2">Result</th>
              <th className="text-left p-2 w-1/4">Unit</th>
              <th className="text-left p-2">Ref-Range</th>
              <th className="text-left p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {reportList.map((medicine, index) => (
              <tr
                className={`border-b ${isTitleTest(medicine) ? "bg-gray-50" : ""}`}
                key={index}
              >
                <td className="p-2">
                  <input
                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:border-transparent transition-all duration-200"
                    type="text"
                    required
                    placeholder="eg.. LFT Test"
                    value={medicine.test}
                    onChange={(e) =>
                      handleInputChange(index, "test", e.target.value)
                    }
                  />
                </td>
                <td className="p-2">
                  <input
                    id={`result-input-${index}`}
                    className={`border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ${getResultColor(
                      medicine.result,
                      medicine.refRange
                    )}`}
                    type="text"
                    placeholder="eg.. 2.76"
                    value={medicine.result}
                    onChange={(e) =>
                      handleInputChange(index, "result", e.target.value)
                    }
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                </td>
                <td className="p-2">
                  <Select
                    showSearch
                    className="w-full"
                    placeholder="Select unit"
                    value={medicine.unit}
                    onChange={(value) => handleInputChange(index, "unit", value)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    popupClassName="rounded-md shadow-lg"
                  >
                    {unitOptions.map((unit) => (
                      <Option key={unit} value={unit}>
                        {unit}
                      </Option>
                    ))}
                  </Select>
                </td>
                <td className="p-2">
                  <textarea
                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                    placeholder="Range"
                    value={medicine.refRange}
                    onChange={(e) =>
                      handleInputChange(index, "refRange", e.target.value)
                    }
                    style={{
                      maxHeight: "75px",
                      minHeight: "38px",
                      resize: "none",
                      padding: "9px",
                      boxSizing: "border-box",
                      fontSize: "15px",
                    }}
                  />
                </td>
                <td className="p-2">
                  <IconButton
                    onClick={() => removeMedicine(index)}
                    color="danger"
                    aria-label="minus button"
                    className="hover:bg-red-100 transition-colors duration-200"
                  >
                    <AiFillMinusCircle className="text-red-500 h-5 w-5" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const shouldShowButtons = (item) => {
    if (!item._id) return false;

    const history = singleAppointmentData?.appointmentHistory || [];
    if (history.length === 0) return false;

    let targetEntry = history[history.length - 1];
    if (
      targetEntry.type === "Ended" ||
      (targetEntry.type === "Referred" && !targetEntry.doctor)
    ) {
      for (let i = history.length - 2; i >= 0; i--) {
        const entry = history[i];
        if (
          entry.type === "Ongoing" ||
          entry.type === "FollowUp" ||
          (entry.type === "Referred" && entry.doctor)
        ) {
          targetEntry = entry;
          break;
        }
      }
    }

    if (!targetEntry.reports) return false;
    return targetEntry.reports.some((report) => report._id === item._id);
  };

  const handleTestTypeChange = (value) => {
    if (!value || selectedTypes.includes(value)) return;

    setSelectedTypes([...selectedTypes, value]);
    const newReportName =
      selectedTypes.length > 0
        ? reportName + "," + testTypes[value].reportName
        : testTypes[value].reportName;
    setReportName(newReportName);

    const isDefaultEmpty =
      reportList.length === 1 && Object.values(reportList[0]).every((v) => v === "");
    const newFields = testTypes[value].fields;
    setReportList(isDefaultEmpty ? newFields : [...reportList, ...newFields]);
  };

  const removeTestType = (typeToRemove) => {
    const updatedTypes = selectedTypes.filter((type) => type !== typeToRemove);
    setSelectedTypes(updatedTypes);

    const newReportName = updatedTypes
      .map((type) => testTypes[type].reportName)
      .join(",");
    setReportName(newReportName);

    const removedTypeFieldCount = testTypes[typeToRemove].fields.length;
    const currentIndex = selectedTypes.indexOf(typeToRemove);
    const startIndex = selectedTypes
      .slice(0, currentIndex)
      .reduce((acc, type) => acc + testTypes[type].fields.length, 0);

    const updatedReportList = [
      ...reportList.slice(0, startIndex),
      ...reportList.slice(startIndex + removedTypeFieldCount),
    ];
    setReportList(
      updatedReportList.length
        ? updatedReportList
        : [{ test: "", result: "", unit: "", refRange: "" }]
    );

    // Clear removed test names associated with this test type
    setRemovedTestNames((prev) =>
      prev.filter((item) => item.testType !== typeToRemove)
    );
  };

  const reAddTestFields = (testName, testType) => {
    const testTypeData = testTypes[testType];
    if (!testTypeData) return;

    // Find all fields related to the testName (title) and its sub-tests
    const fieldsToAdd = [];
    let foundTitle = false;
    for (let i = 0; i < testTypeData.fields.length; i++) {
      const field = testTypeData.fields[i];
      if (field.test === testName && isTitleTest(field)) {
        foundTitle = true;
        fieldsToAdd.push(field);
        continue;
      }
      if (foundTitle) {
        if (isTitleTest(field)) {
          break; // Stop at the next title
        }
        fieldsToAdd.push(field);
      }
    }

    if (fieldsToAdd.length === 0) return;

    const isDefaultEmpty =
      reportList.length === 1 && Object.values(reportList[0]).every((v) => v === "");
    setReportList(isDefaultEmpty ? fieldsToAdd : [...reportList, ...fieldsToAdd]);

    // Remove from removedTestNames since it's re-added
    setRemovedTestNames((prev) =>
      prev.filter(
        (item) => !(item.testName === testName && item.testType === testType)
      )
    );
  };

  const removeGroup = (startIndex, testName, testType) => {
    let endIndex = reportList.length;
    for (let i = startIndex + 1; i < reportList.length; i++) {
      if (isTitleTest(reportList[i])) {
        endIndex = i;
        break;
      }
    }
    const updatedList = [...reportList.slice(0, startIndex), ...reportList.slice(endIndex)];
    setReportList(updatedList);

    // Add to removedTestNames to allow re-adding later
    setRemovedTestNames((prev) => [...prev, { testName, testType }]);
  };

  const handleActionLoading = (value) => {
    setActionLoading(value);
  };

  useEffect(() => {
    setFullName(full_name || "");
    setProblem(problem || "");
    fetchData();
    fetchSingleAppointment();
    fetchMedicine();
  }, [appointmentId, full_name, problem]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/appointment-reports/${appointmentId}`, config)
      .then((response) => {
        setDetails(response.data.data);
        console.log("Data", response.data.data);
      })
      .catch((e) => {
        console.error("Failed to fetch report details:", e);
        message.error("Failed to get report details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSingleAppointment = () => {
    setButtonLoading(true);
    axios
      .get(`${apiBaseUrl}/staff/getBookedDoctorAppointment/${appointmentId}`, config)
      .then((response) => {
        setSingleAppointmentData(response.data.data);
        console.log("Single appointment data", response.data.data);
      })
      .catch((e) => {
        console.error("Failed to fetch appointment details:", e);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const fetchMedicine = () => {
    axios
      .get(`${apiBaseUrl}/get/doctor/department/medicine`)
      .then((response) => {
        if (response.data.success) {
          setMedicineOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine:", error);
      });
  };

  const addReport = async (e) => {
    e.preventDefault();
    handleActionLoading(true);

    const isReportNameEmpty = reportName === "";
    const isReportListEmpty =
      reportList.length === 0 ||
      JSON.stringify(reportList[0]) ===
      JSON.stringify({ test: "", result: "", unit: "", refRange: "" });

    const isReportListValid = reportList.every((item, index) => {
      if (item.test && !item.result) {
        const subsequentTestHasResult = reportList
          .slice(index + 1)
          .some((nextItem) => nextItem.test && nextItem.result);
        return subsequentTestHasResult;
      }
      return true;
    });

    const isMedicinePartiallyFilled = (medicine) => {
      const { test, result, unit, refRange } = medicine;
      const allFieldsEmpty = !test && !result && !unit && !refRange;
      const allFieldsFilled = test;
      return !(allFieldsEmpty || allFieldsFilled);
    };
    const hasPartiallyFilledMedicine = reportList.some(isMedicinePartiallyFilled);

    const data = {
      report: reportList,
      reportName: reportName,
    };

    if (isReportNameEmpty && isReportListEmpty) {
      handleActionLoading(false);
      message.error("Both medicine and followup cannot be empty");
    } else if (!isReportListValid) {
      handleActionLoading(false);
      message.error(
        "If a test name is entered, at least one test below it must have a result filled in."
      );
    } else if (hasPartiallyFilledMedicine) {
      handleActionLoading(false);
      message.error("Medicine fields cannot be partially filled");
    } else {
      try {
        let response;
        if (localStorage.getItem("labreporterticket")) {
          response = await axios.post(
            `${apiBaseUrl}/add-report/${appointmentId}`,
            data,
            config
          );
        }
        if (response.data.success) {
          message.success("Report Added", 0.6, () => {
            fetchData();
            fetchSingleAppointment();
            if (onSuccess) onSuccess();
          });
        } else {
          message.error("Failed To add report");
        }
      } catch (error) {
        console.error("Failed to add report:", error);
        message.error("Failed To add report! Please try again.");
      } finally {
        handleActionLoading(false);
      }
    }
  };

  const addTest = () => {
    setReportList([
      ...reportList,
      { test: "", result: "", unit: "", refRange: "" },
    ]);
  };

  const removeMedicine = (index) => {
    if (isTitleTest(reportList[index])) {
      let endIndex = reportList.length;
      for (let i = index + 1; i < reportList.length; i++) {
        if (isTitleTest(reportList[i])) {
          endIndex = i;
          break;
        }
      }
      const newList = [...reportList.slice(0, index), ...reportList.slice(endIndex)];
      setReportList(newList);
    } else {
      const newList = [...reportList];
      newList.splice(index, 1);
      setReportList(newList);
    }
  };

  const titleTestIndices = reportList.reduce((acc, test, index) => {
    if (isTitleTest(test)) {
      acc.push({ index: index, type: reportList[index].test });
    }
    return acc;
  }, []);

  const handleInputChange = (index, key, value) => {
    const updatedList = [...reportList];
    updatedList[index][key] = value;
    setReportList(updatedList);
  };

  const handleReportChange = (value) => {
    setReportName(value);
  };

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  const deleteReport = async (report_id) => {
    if (window.confirm("Are you sure want to delete this report?")) {
      handleActionLoading(true);
      try {
        let response;
        if (localStorage.getItem("labreporterticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/report/delete/${report_id}`,
            config
          );
        }
        if (response.data.success) {
          message.success("Report Deleted", 0.6, () => {
            fetchData();
            fetchSingleAppointment();
            if (onSuccess) onSuccess();
            setIsUpdateModalOpen(false); // Ensure modal is closed
            setDetails((prevDetails) =>
              prevDetails.filter((item) => item._id !== report_id)
            ); // Immediately update local state
          });
        } else {
          message.error("Failed to delete report.");
        }
      } catch (e) {
        console.error("Failed to delete report:", e);
        message.error("Failed to delete report! Please try again.");
      } finally {
        handleActionLoading(false);
      }
    }
  };

  const hasUpdateDeleteButtons = details.some(
    (item) => singleAppointmentData && shouldShowButtons(item)
  );

  // Handler for opening the Update Modal
  const handleUpdateOpen = (reportId) => {
    setCurrentReportId(reportId);
    setIsUpdateModalOpen(true);
  };

  // Handler for canceling the Update Modal
  const handleUpdateCancel = () => {
    setIsUpdateModalOpen(false);
    setCurrentReportId(null);
  };

  // Handler for confirming the Update Modal


  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="mb-3">
        <h1 className="text-3xl font-bold decoration-gray-400">Report</h1>
        <Link
          to="/dashboard/view/labreports"
          className="text-blue-500 hover:underline mt-2 inline-block"
        >
          Navigate to Print Part
        </Link>
      </div>

      <div className="flex flex-col items-start">
        {/* Add Report Section */}
        {!hasUpdateDeleteButtons && (
          <div className="w-full block p-6 bg-white border border-gray-200 rounded-lg shadow mb-6">
            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-xs font-bold mb-2"
                htmlFor="test-type"
              >
                Test Type
              </label>
              <Select
                showSearch
                className="w-full"
                placeholder="Select test type"
                onChange={handleTestTypeChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={null}
                allowClear
              >
                {Object.keys(testTypes).map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {selectedTypes.map((type) => (
                <div
                  key={type}
                  className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                >
                  <span className="mr-1">{type}</span>
                  <button
                    type="button"
                    onClick={() => removeTestType(type)}
                    className="ml-1 text-gray-500 hover:text-gray-700"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <p className="text-xl mb-1 mt-4 font-bold tracking-tight text-gray-800">
              Add Report
            </p>
            <div className="font-normal text-gray-700">
              <form
                className="pb-10 pt-5 mt-5 rounded-lg flex flex-col gap-4"
                onSubmit={addReport}
              >
                <div className="w-full md:w-1/4 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                    htmlFor="grid-zip"
                  >
                    Report Name
                  </label>
                  <input
                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-300"
                    type="text"
                    required
                    placeholder="eg.. Report Name"
                    value={reportName}
                    onChange={(e) => handleReportChange(e.target.value)}
                  />
                </div>

                {/* Removed Tests Section */}
                {removedTestNames.length > 0 && (
                  <div className="mt-2">
                    <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                      Removed Tests
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {removedTestNames.map((item, idx) => (
                        <div
                          key={item.testName + idx}
                          className="inline-flex items-center px-2 py-1 bg-red-100 rounded-md cursor-pointer"
                          onClick={() => reAddTestFields(item.testName, item.testType)}
                        >
                          <span className="mr-1">{item.testName}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Active Test Names */}
                <div className="flex flex-wrap gap-2 mt-2">
                  {titleTestIndices.map((item) => {
                    const testType = selectedTypes[0]; // Fallback to first selected type
                    return (
                      <div
                        key={item.type}
                        className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                      >
                        <span className="mr-1">{item.type}</span>
                        <button
                          type="button"
                          onClick={() =>
                            removeGroup(item.index, item.type, testType)
                          }
                          className="ml-1 text-gray-500 hover:text-gray-700"
                        >
                          ×
                        </button>
                      </div>
                    );
                  })}
                </div>
                {renderReportList()}
                <IconButton
                  onClick={addTest}
                  color="primary"
                  aria-label="add button"
                >
                  Add Test field
                  <HiPlusCircle />
                </IconButton>
                <button
                  id="addStaffBtn"
                  className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}

        {/* Show Reports Section */}
        <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow">
          {loading && <LoadingScreen />}
          {!loading && (
            <>
              <div className="max-w-3xl">
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name: <span className="font-semibold">{fullName_state}</span>
                </h5>
                <p className="mb-3 font-semibold text-gray-700">
                  Problem: <span className="font-normal">{problem_state}</span>
                </p>
                {details.length > 0 ? (
                  details.map((item) => (
                    <div
                      key={item._id}
                      className="mb-4 p-3 bg-white border border-gray-100 rounded-lg shadow"
                    >
                      <p className="mb-2 text-sm font-semibold text-gray-700">
                        Report added on:{" "}
                        <span className="font-normal">
                          {formatDate(item.createdAt)}
                        </span>
                      </p>
                      <p className="mb-2 text-sm font-semibold text-gray-700">
                        Report Name:{" "}
                        <span className="font-normal">{item.reportName}</span>
                      </p>
                      <div className="overflow-x-auto">
                        <table className="min-w-full text-sm">
                          <thead>
                            <tr className="bg-gray-50">
                              <th className="px-3 py-2 text-left font-medium text-gray-700">
                                Test
                              </th>
                              <th className="px-3 py-2 text-left font-medium text-gray-700">
                                Result
                              </th>
                              <th className="px-3 py-2 text-left font-medium text-gray-700">
                                Unit
                              </th>
                              <th className="px-3 py-2 text-left font-medium text-gray-700">
                                RefRange
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.report.map((testItem, index) => (
                              <tr key={index} className="border-t border-gray-100">
                                <td className="px-3 py-2 font-medium">
                                  {testItem.test}
                                </td>
                                <td className="px-3 py-2">{testItem.result}</td>
                                <td className="px-3 py-2">{testItem.unit}</td>
                                <td className="px-3 py-2">{testItem.refRange}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex justify-end mt-3 space-x-2">
                        {buttonLoading ? (
                          <LoadingScreen />
                        ) : (
                          singleAppointmentData &&
                          shouldShowButtons(item) && (
                            <>
                              <button
                                onClick={() => handleUpdateOpen(item._id)}
                                className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
                              >
                                Update Report
                              </button>
                              <button
                                onClick={() => deleteReport(item._id)}
                                className="px-3 py-1 text-sm rounded-md bg-red-700 text-sky-100 hover:bg-red-800"
                              >
                                Delete
                              </button>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="mt-3">
                    <h5 className="text-xl text-red-500 font-semibold">
                      Report not found!!
                    </h5>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {/* Update Report Modal */}
        <Modal
          title="Update Report"
          open={isUpdateModalOpen}
          onCancel={handleUpdateCancel}
          footer={null} // Remove footer since UpdateReport has its own buttons
          width={900}
        >
          <UpdateReport
            appointmentId={appointmentId}
            reportId={currentReportId}
            reportData={details.find((item) => item._id === currentReportId)}
            onClose={handleUpdateCancel}
            onUpdate={(updatedData) => {
              // Simply refresh the data in AddReport after the update
              fetchData();
              fetchSingleAppointment();
              if (onSuccess) onSuccess();
              setIsUpdateModalOpen(false);
            }}
          />
        </Modal>
      </div>
    </>
  );
};

export default AddReport;