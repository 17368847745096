import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import axios from "axios";
import { PhoneOutlined, EnvironmentOutlined, FileTextOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import tulasiLogo from "../../tulasi_logo.png"; // Header logo
import { Handler, registerHandlers } from "pagedjs";

const SelfReportPrintout = ({ reportId, onClose }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState(null);

    // Tulasi brand colors
    const primaryColor = "#d81b60"; // Magenta/pink from logo
    const secondaryColor = "#1b5e20"; // Dark green from logo

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
        },
    };

    useEffect(() => {
        fetchData();

        // Initialize PagedJS with proper footer handling
        const handler = new Handler();
        registerHandlers(handler);

        // Add PagedJS-specific CSS to properly position the footer
        const style = document.createElement('style');
        style.textContent = `
            @page {
                size: auto;
                margin: 10mm 10mm 25mm 10mm; /* Increase bottom margin to make room for footer */
                @bottom-center {
                    content: element(footer);
                }
            }
            
            /* The footer element that will be placed at bottom-center of each page */
            #page-footer {
                position: running(footer);
                text-align: center;
                width: 100%;
                border-top: 1px solid #e5e7eb;
                padding-top: 8px;
            }
            
            .page-border {
                position: fixed;
                pointer-events: none;
                z-index: 1;
            }
            
            .report-page {
                position: relative;
            }
            
            /* Add more space for top sections on secondary pages */
            .secondary-page {
                padding-top: 24px;
            }
            
            /* Ensure the last page doesn't duplicate the footer */
            .pagedjs_page:last-of-type .pagedjs_foot_area {
                display: none;
            }
        `;
        document.head.appendChild(style);
    }, [reportId]);

    const fetchData = async () => {
        setLoading(true);
        const url = `${apiBaseUrl}/view/single/report/${reportId}`;
        try {
            const response = await axios.get(url, config);
            if (response.data.success) {
                setDetails(response.data.data);
            } else {
                message.error("Failed to get report details! Please try again.");
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            message.error("Failed to get report details! Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handlePrint = () => {
        const printableContent = document.getElementById("printableContent").innerHTML;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = printableContent;
        document.body.innerHTML = tempDiv.innerHTML;
        window.print();
        // Reload the page after printing (or canceling)
        window.location.reload();
    };

    const convertToNepaliTime = (utcTime) => {
        return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
    };

    // Improved watermark SVG with better visibility
    const TulasiWatermark = () => (
        <div className="fixed inset-0 pointer-events-none flex items-center justify-center" style={{ zIndex: 0 }}>
            <svg width="350" height="350" viewBox="0 0 200 200" opacity="0.15">
                <g>
                    <path d="M100,0 C155,0 200,45 200,100 C200,155 155,200 100,200 C45,200 0,155 0,100 C0,45 45,0 100,0 Z"
                        fill={primaryColor} fillOpacity="0.4" />
                    <path d="M100,40 C100,40 140,70 140,100 C140,130 100,160 100,160 C100,160 60,130 60,100 C60,70 100,40 100,40 Z"
                        fill={secondaryColor} fillOpacity="0.9" />
                    <path d="M70,70 C70,70 100,85 130,70 C145,100 130,130 100,130 C70,130 55,100 70,70 Z"
                        fill={secondaryColor} fillOpacity="0.9" />
                </g>
            </svg>
        </div>
    );

    // Improved Footer component with social media - positioned by PagedJS
    const PageFooter = () => (
        <div id="page-footer">
            <div className="text-center text-xs text-gray-500 mt-1">
                For appointments: 01-5914294 | tulsi.msc123@gmail.com
            </div>
        </div>
    );

    // Page border that will appear on every page
    const PageBorder = () => (
        <div className="page-border inset-0">
            <div className="absolute top-0 left-0 w-16 h-16 border-l-4 border-t-4 border-pink-600 rounded-tl-lg"></div>
            <div className="absolute top-0 right-0 w-16 h-16 border-r-4 border-t-4 border-pink-600 rounded-tr-lg"></div>
            <div className="absolute bottom-0 left-0 w-16 h-16 border-l-4 border-b-4 border-pink-600 rounded-bl-lg"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 border-r-4 border-b-4 border-pink-600 rounded-br-lg"></div>
        </div>
    );

    const renderReportTable = () => {
        if (!details?.report || details.report.length === 0) return null;

        const groupedReports = {};
        let currentCategory = null;

        details.report.forEach((item) => {
            if (!item.result && !item.unit && !item.refRange) {
                currentCategory = item.test;
                groupedReports[currentCategory] = [];
            } else if (currentCategory) {
                groupedReports[currentCategory].push(item);
            }
        });

        return (
            <div className="mt-4">
                {Object.keys(groupedReports).map((category, index) => {
                    const rows = groupedReports[category];
                    const estimatedHeight = 50 + rows.length * 32;
                    const isSecondaryPage = index > 0 && estimatedHeight > 650;

                    return (
                        <div
                            key={index}
                            className={`report-section-wrapper report-page ${isSecondaryPage ? 'secondary-page' : ''}`}
                            style={{
                                breakInside: "avoid",
                                pageBreakInside: "avoid",
                                marginBottom: "16px",
                                pageBreakBefore: isSecondaryPage ? "always" : "auto",
                            }}
                        >
                            {/* Each page gets border elements */}
                            <PageBorder />

                            <div className="report-section">
                                <h2 className="text-lg font-bold mb-2 text-pink-600 border-b-2 border-pink-600 pb-1 relative">
                                    {category}
                                    <span className="absolute right-0 bottom-1 w-16 h-1 bg-pink-600"></span>
                                </h2>
                                <table className="w-full border-collapse table-auto border border-gray-300">
                                    <thead>
                                        <tr className="bg-pink-50">
                                            <th className="text-left px-3 py-2 border border-gray-300 text-sm font-bold text-gray-800">Test Name</th>
                                            <th className="text-left px-3 py-2 border border-gray-300 text-sm font-bold text-gray-800">Result</th>
                                            <th className="text-left px-3 py-2 border border-gray-300 text-sm font-bold text-gray-800">Unit</th>
                                            <th className="text-left px-3 py-2 border border-gray-300 text-sm font-bold text-gray-800">Reference Range</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((item, idx) => {
                                            // Check if result is outside reference range
                                            let isAbnormal = false;
                                            if (item.refRange && item.result) {
                                                const refRange = item.refRange.split('-');
                                                if (refRange.length === 2) {
                                                    const lowerLimit = parseFloat(refRange[0]);
                                                    const upperLimit = parseFloat(refRange[1]);
                                                    const resultValue = parseFloat(item.result);
                                                    if (!isNaN(lowerLimit) && !isNaN(upperLimit) && !isNaN(resultValue)) {
                                                        isAbnormal = resultValue < lowerLimit || resultValue > upperLimit;
                                                    }
                                                }
                                            }

                                            return (
                                                <tr key={idx} className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                                                    <td className="px-3 py-1 border border-gray-300 text-sm">{item.test}</td>
                                                    <td className={`px-3 py-1 border border-gray-300 text-sm ${isAbnormal ? "font-bold text-pink-700" : ""}`}>
                                                        {item.result || "-"}
                                                    </td>
                                                    <td className="px-3 py-1 border border-gray-300 text-sm">{item.unit || "-"}</td>
                                                    <td className="px-3 py-1 border border-gray-300 text-sm">{item.refRange || "-"}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {index === Object.keys(groupedReports).length - 1 && (
                                <div className="report-footer-section mt-8">
                                    <div className="flex justify-center my-3">
                                        <div className="text-center px-4 py-1 border-t-2 border-pink-600 w-64 relative">
                                            {/* Decorative corner elements for signature */}
                                            <div className="absolute top-0 left-0 w-3 h-3 border-l-2 border-t-2 border-pink-600"></div>
                                            <div className="absolute top-0 right-0 w-3 h-3 border-r-2 border-t-2 border-pink-600"></div>
                                            <p className="text-sm font-medium text-gray-800">
                                                PAWAN SHRESTHA<br />
                                                LAB TECHNOLOGIST<br />
                                                NHPC NO: A-1675 MLT
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-center mb-1">
                                        <p className="text-sm font-medium text-gray-700 border-t border-b py-1 mx-auto max-w-xs">
                                            ------------End of Report-------------
                                        </p>
                                    </div>
                                    <div className="text-right mr-5">
                                        {details?.doctorId && (
                                            <div className="mt-2 mb-0 text-sm text-gray-700 border-t-2 border-pink-600 inline-block pt-2 px-4 relative">
                                                {/* Decorative corner elements for doctor signature */}
                                                <div className="absolute top-0 left-0 w-2 h-2 border-l-2 border-t-2 border-pink-600"></div>
                                                <div className="absolute top-0 right-0 w-2 h-2 border-r-2 border-t-2 border-pink-600"></div>
                                                <p className="font-bold">Dr. {details.doctorId.fullname}</p>
                                                <p>{details.appointmentId?.department?.department}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            <div className="flex justify-end mb-4">
                <Button
                    type="primary"
                    onClick={handlePrint}
                    className="bg-pink-600 hover:bg-pink-700 text-white border shadow font-semibold text-md rounded-md px-4 py-2"
                    icon={<FileTextOutlined />}
                >
                    Print Report
                </Button>
                <Button
                    type="default"
                    onClick={onClose}
                    className="mr-2 border shadow font-semibold text-md rounded-md px-4 py-2"
                >
                    Close
                </Button>
            </div>

            <div id="printableContent" className="relative bg-white shadow-lg border border-gray-200 rounded-lg p-4">
                {/* Improved watermark implementation */}
                <TulasiWatermark />

                {/* Content with proper spacing */}
                <div className="w-full bg-white p-6 report-page">
                    {/* Fixed border for each page */}
                    <PageBorder />

                    {/* Header Section - Repeated on each page */}
                    <div className="page-header" style={{ marginBottom: "24px" }}>
                        {/* Clinic Logo and Address */}
                        <div className="text-center mb-4">
                            <img
                                src={tulasiLogo}
                                alt="Tulsi Clinic Logo"
                                className="w-[280px] h-auto mx-auto mb-2"
                            />
                            <div className="my-2">
                                <p className="my-1 text-sm text-gray-600">
                                    <EnvironmentOutlined className="mr-1" /> Avm chowk 05, Lalitpur 44600
                                </p>
                                <p className="my-1 text-sm text-gray-600">
                                    <PhoneOutlined className="mr-1" /> 01-5914294
                                </p>
                            </div>
                        </div>

                        {/* Patient Information - styled with gradient border */}
                        <div className="mb-4 text-sm text-gray-900 rounded-lg p-3 bg-gray-50 relative overflow-hidden">
                            {/* Gradient decoration on patient info box */}
                            <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-pink-600 to-purple-500"></div>
                            <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-pink-600 to-purple-500"></div>

                            <div className="flex justify-between">
                                <div className="flex flex-col space-y-1">
                                    <p><span className="font-bold">Name:</span> {details?.patientDetails?.fullname || "N/A"}</p>
                                    <p>
                                        <span className="font-bold">Age/Sex:</span> {details?.patientDetails?.age || "N/A"} /{" "}
                                        {details?.patientDetails?.gender || "N/A"}
                                    </p>
                                    <p><span className="font-bold">Address:</span> {details?.patientDetails?.address || "N/A"}</p>
                                </div>
                                <div className="flex flex-col space-y-1 text-right">
                                    <p><span className="font-bold">Phone:</span> {details?.patientDetails?.phone || "N/A"}</p>
                                    <p><span className="font-bold">Report Date:</span> {convertToNepaliTime(details?.createdAt)}</p>
                                </div>
                            </div>
                        </div>

                        {details?.reportName && (
                            <div className="mb-6 text-center">
                                <h1 className="text-xl font-bold text-pink-600 py-2 border-y-2 border-pink-600 inline-block px-8 relative">
                                    {/* Decorative elements for report title */}
                                    <span className="absolute top-0 left-0 w-2 h-2 bg-pink-600"></span>
                                    <span className="absolute top-0 right-0 w-2 h-2 bg-pink-600"></span>
                                    <span className="absolute bottom-0 left-0 w-2 h-2 bg-pink-600"></span>
                                    <span className="absolute bottom-0 right-0 w-2 h-2 bg-pink-600"></span>
                                    {details.reportName} Report
                                </h1>
                            </div>
                        )}
                    </div>

                    {/* Report Tables */}
                    {renderReportTable()}

                    {/* Footer with social media placed at the bottom of each page via CSS running element */}
                    <PageFooter />
                </div>
            </div>
        </div>
    );
};

export default SelfReportPrintout;