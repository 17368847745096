import { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useParams } from "react-router-dom";
import LoadingScreen from "../lib/constants/loadingScreen";
import { message, Row, Col, Typography } from "antd";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";

const { Title } = Typography;

const UpdateRefer = ({
  appointmentId: propAppointmentId, // Optional prop if passed from parent
  initialDepartment, // Optional prop for pre-filled department
  onAssignSuccess, // Optional callback for success (instead of redirect)
}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointment_id: urlAppointmentId } = useParams(); // Fallback to URL param
  const appointmentId = propAppointmentId || urlAppointmentId; // Use prop if provided, else URL

  const [department, setDepartment] = useState(initialDepartment?.value || null);
  const [departmentName, setDepartmentName] = useState(initialDepartment?.label || "");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [doctorStoringValue, setDoctorStoringValue] = useState("");
  const [time, setTime] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");

  // Dynamic auth config based on localStorage ticket
  const getAuthConfig = () => {
    const ticketTypes = [
      { key: "adminticket", name: "admin" },
      { key: "staffticket", name: "staff" },
      { key: "doctorticket", name: "doctor" },
      { key: "pharmaticket", name: "pharma" },
      { key: "labreporterticket", name: "labreporter" },
    ];

    const availableTicket = ticketTypes.find((ticket) => localStorage.getItem(ticket.key));
    if (!availableTicket) {
      console.warn("No authentication ticket found in localStorage");
      return { headers: {} };
    }

    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(availableTicket.key)}`,
      },
    };
  };

  const config = getAuthConfig();

  // Fetch referral details if not provided via props
  useEffect(() => {
    if (!initialDepartment) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${apiBaseUrl}/appointments/referred/${appointmentId}`,
            config
          );
          setDepartment(response.data.refer.toDepartment._id);
          setDepartmentName(response.data.refer.toDepartment.department);
        } catch (e) {
          console.error("Failed to get refer details", e);
          message.error("Failed to get refer details! Please try again");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [appointmentId, initialDepartment]);

  // Fetch doctor details based on selected department
  useEffect(() => {
    if (department) {
      setLoading(true);
      const fetchDoctors = async () => {
        try {
          const response = await axios.get(
            `${apiBaseUrl}/doctor/category/${department}`,
            config
          );
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        } catch (e) {
          console.error("Failed to fetch doctors:", e);
          message.error("Failed to fetch doctors! Please try again.");
        } finally {
          setLoading(false);
        }
      };

      fetchDoctors();
    }
  }, [department]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
    }
  };

  // Submit the referral assignment
  const updateRefer = async (e) => {
    e.preventDefault();
    setActionLoading(true);

    const data = {
      assigningDoctorId: doctorSelectedId,
      referred_appointmentDate: appointmentDate,
      referred_appointmentTime: time,
    };

    try {
      const response = await axios.post(
        `${apiBaseUrl}/staff/appointment/assignDoctor/${appointmentId}`,
        data,
        config
      );
      if (
        response.data.msg === "Doctor assigned to the appointment successfully."
      ) {
        message.success("Doctor assigned successfully", 0.6, () => {
          if (onAssignSuccess) {
            onAssignSuccess(); // Call callback if provided
          } else {
            window.location.replace("/dashboard/refer"); // Fallback redirect
          }
        });
      } else {
        message.error("Failed to assign doctor! Please try again.");
      }
    } catch (error) {
      console.error("Failed to assign doctor:", error);
      message.error("Failed to assign doctor! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    setTime(formattedTime);
  };

  return (
    <>
      {(actionLoading || loading) && <LoadingScreen />}
      <div>
        <form
          className="forbox w-full max-w-2xl h-fit bg-white max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={updateRefer}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Assign Doctor</Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Referred FROM (Department)</label>
              <input
                value={departmentName}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-700"
                name="title"
                disabled
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Refer to (Doctor)</label>
              {doctorSelectedDetails.length > 0 ? (
                <Select
                  options={doctorSelectedDetails}
                  value={doctorStoringValue}
                  onChange={handleDoctorInputChange}
                  placeholder="Select a doctor"
                  isSearchable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0.375rem",
                    }),
                  }}
                />
              ) : (
                <div className="text-center m-4 text-red-500">
                  <div role="status">
                    <p>No doctors available!</p>
                    <p>Please try again, or verify the presence of data.</p>
                  </div>
                </div>
              )}
            </Col>
          </Row>

          {doctorSelectedDetails.length > 0 && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label className="text-base text-lg">Date</label>
                  <input
                    id="appointmentDate"
                    type="date"
                    onChange={(e) => setAppointmentDate(e.target.value)}
                    min={new Date().toISOString().split("T")[0]}
                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                    name="appointmentDate"
                    placeholder="Select Date"
                    required
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label className="text-base text-lg">Time</label>
                  <Flatpickr
                    data-enable-time
                    data-disable-date
                    placeholder="Pick a time"
                    onChange={handleTimeChange}
                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md transition-colors duration-200"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "h:i K",
                      time_24hr: false,
                      allowInput: true,
                      clearIcon: true,
                    }}
                    required
                  />
                </Col>
              </Row>
            </>
          )}

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default UpdateRefer;