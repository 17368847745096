import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    message,
    Space,
    InputNumber,
    Select,
    Row,
    Col,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const AccountsReceivablePage = () => {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
    };

    const fetchRecords = async (page = 1, pageSize = 10) => {
        setLoading(true);
        try {
            const params = {
                page,
                limit: pageSize,
                search: searchText || undefined,
                startDueDate: dateRange[0] ? dateRange[0].startOf("day").toISOString() : undefined,
                endDueDate: dateRange[1] ? dateRange[1].endOf("day").toISOString() : undefined,
            };
            const response = await axios.get(`${apiBaseUrl}/accounts-receivable`, { ...config, params });
            setRecords(response.data.data || []);
            setPagination({
                current: page,
                pageSize,
                total: response.data.total || 0,
            });
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to fetch records");
            setRecords([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecords(pagination.current, pagination.pageSize);
    }, [searchText, dateRange]);

    const handleSaveRecord = async (values) => {
        const recordData = {
            ...values,
            dueDate: values.dueDate.toISOString(),
        };
        setLoading(true);
        try {
            if (editingRecord) {
                await axios.put(`${apiBaseUrl}/accounts-receivable/${editingRecord._id}`, recordData, config);
                message.success("Record updated successfully");
            } else {
                await axios.post(`${apiBaseUrl}/accounts-receivable`, recordData, config);
                message.success("Record created successfully");
            }
            fetchRecords(pagination.current, pagination.pageSize);
            setIsModalOpen(false);
            setEditingRecord(null);
            form.resetFields();
        } catch (error) {
            message.error(error.response?.data?.error || "Failed to save record");
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteRecord = (id) => {
        Modal.confirm({
            title: "Are you sure you want to delete this record?",
            onOk: async () => {
                setLoading(true);
                try {
                    await axios.delete(`${apiBaseUrl}/accounts-receivable/${id}`, config);
                    message.success("Record deleted successfully");
                    fetchRecords(pagination.current, pagination.pageSize);
                } catch (error) {
                    message.error(error.response?.data?.error || "Failed to delete record");
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    const showModal = (record = null) => {
        setEditingRecord(record);
        if (record) {
            form.setFieldsValue({
                ...record,
                dueDate: moment(record.dueDate),
            });
        } else {
            form.resetFields();
        }
        setIsModalOpen(true);
    };

    const columns = [
        { title: "Entity", dataIndex: "entity", sorter: (a, b) => a.entity.localeCompare(b.entity) },
        { title: "Amount", dataIndex: "amount", sorter: (a, b) => a.amount - b.amount, render: (amount) => `$${amount.toFixed(2)}` },
        { title: "Due Date", dataIndex: "dueDate", sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate), render: (date) => moment(date).format("YYYY-MM-DD") },
        { title: "Payment Status", dataIndex: "paymentStatus", sorter: (a, b) => a.paymentStatus.localeCompare(b.paymentStatus) },
        { title: "Amount Paid", dataIndex: "amountPaid", render: (amount) => `$${amount.toFixed(2)}` },
        { title: "Description", dataIndex: "description" },
        { title: "Reference", dataIndex: "reference" },

    ];

    return (
        <div style={{ padding: 24 }}>
            <h1 style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Accounts Receivable</h1>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col>
                    <Space>
                        <Input
                            placeholder="Search by entity, description, or reference"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            prefix={<SearchOutlined />}
                            style={{ width: 250 }}
                        />
                        <DatePicker.RangePicker
                            value={dateRange}
                            onChange={(dates) => setDateRange(dates)}
                            format="YYYY-MM-DD"
                        />
                        <Button type="default" onClick={() => { setSearchText(""); setDateRange([null, null]); }}>
                            Reset Filters
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={records}
                rowKey="_id"
                loading={loading}
                pagination={{
                    ...pagination,
                    onChange: (page, pageSize) => {
                        setPagination((prev) => ({ ...prev, current: page, pageSize }));
                        fetchRecords(page, pageSize);
                    },
                }}
            />
            <Modal
                title={editingRecord ? "Edit Record" : "Add Record"}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    setEditingRecord(null);
                    form.resetFields();
                }}
                footer={null}
                width={600}
            >
                <Form form={form} layout="vertical" onFinish={handleSaveRecord}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="entity" label="Entity" rules={[{ required: true, message: "Please enter entity" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="amount" label="Amount" rules={[{ required: true, message: "Please enter amount" }]}>
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="dueDate" label="Due Date" rules={[{ required: true, message: "Please select due date" }]}>
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="paymentStatus" label="Payment Status" initialValue="Pending">
                                <Select>
                                    <Option value="Paid">Paid</Option>
                                    <Option value="Pending">Pending</Option>
                                    <Option value="Partially Paid">Partially Paid</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="amountPaid" label="Amount Paid" initialValue={0}>
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="paymentMethod" label="Payment Method">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="reference" label="Reference">
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            {editingRecord ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AccountsReceivablePage;