import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Menu, Popover, Transition } from "@headlessui/react";
import { HiOutlineBell, HiOutlineChatAlt } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import logo from "../img/tulasi_logo_login.png";
import adminLogo from "../img/admin-image.png";
import { Badge, count, message } from "antd";

// Modal component
const Modal = ({ title, message, onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-gray-900 opacity-55 cursor-pointer"
        onClick={onClose}
      ></div>
      <div className="bg-white w-96 p-5 rounded-lg shadow-lg z-50">
        <div className="text-lg font-semibold mb-4">{title}</div>
        <div className="mb-4">{message}</div>
        <div className="flex justify-end">
          <button
            className="bg-gray-200 px-4 py-2 rounded-md mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default function Header() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isAdmin = localStorage.getItem("adminticket");
  const isDoctor = localStorage.getItem("doctorticket");
  const isStaff = localStorage.getItem("staffticket");
  const isLabReporter = localStorage.getItem("labreporterticket");
  const isPharma = localStorage.getItem("pharmaticket");

  const doctorConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    },
  };
  const staffConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };
  const labReporterConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
    },
  };
  const pharmaConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
    },
  };

  useEffect(() => {
    if (isDoctor) {
      fetchDoctor();
    }
    if (isStaff) {
      fetchStaff();
    }
    if (isLabReporter) {
      fetchLabReporter();
    }
    if (isPharma) {
      fetchPharma();
    }
  }, []);

  // doctor details
  const fetchDoctor = () => {
    axios
      .get(`${apiBaseUrl}/doctor/profile`, doctorConfig)
      .then((response) => {
        if (response.data.success) {
          setImage(response.data.data.picture);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching profile details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details.", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching profile details.";
        message.error(errorMessage);
      });
  };

  // staff details
  const fetchStaff = () => {
    axios
      .get(`${apiBaseUrl}/staff/profile`, staffConfig)
      .then((response) => {
        if (response.data.success) {
          setImage(response.data.data.picture);
        } else {
          const errorMessage =
            response.data.error || "An error occurred while fetching profile.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details.", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching profile details.";
        message.error(errorMessage);
      });
  };

  // labreporter details
  const fetchLabReporter = () => {
    axios
      .get(`${apiBaseUrl}/get/labreporter/profile`, labReporterConfig)
      .then((response) => {
        if (response.data.success) {
          setImage(response.data.data.picture);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching profile details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details.", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching profile details.";
        message.error(errorMessage);
      });
  };

  // pharma details
  const fetchPharma = () => {
    axios
      .get(`${apiBaseUrl}/pharma/profile`, pharmaConfig)
      .then((response) => {
        if (response.data.success) {
          setImage(response.data.data.picture);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching profile details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details.", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching profile details.";
        message.error(errorMessage);
      });
  };

  const handleLogout = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="bg-white border-l h-16 px-4 flex items-center border-b border-gray-200 justify-between">
      <div className="relative">
        {/* <img src={logo} alt="logo" className="h-9" /> */}
      </div>
      <div className="flex items-center gap-2 mr-2">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open && "bg-gray-100",
                  "group inline-flex items-center rounded-sm p-0.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100"
                )}
              >
                <Badge count={0} showZero>
                  <HiOutlineChatAlt fontSize={26} />
                </Badge>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 z-10 transform w-80">
                  <div className="bg-white rounded-md shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                    <strong className="text-gray-700 font-medium">
                      Messages
                    </strong>
                    <div className="mt-2 py-1 text-sm">
                      This is messages panel.
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open && "bg-gray-100",
                  "group inline-flex items-center rounded-sm p-1.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100"
                )}
              >
                <Badge count={20} showZero>
                  <HiOutlineBell fontSize={26} />
                </Badge>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 z-10 transform w-80">
                  <div className="bg-white rounded-md shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                    <strong className="text-gray-700 font-medium">
                      Notifications
                    </strong>
                    <div className="mt-2 py-1 text-sm">
                      This is notification panel.
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        <Menu as="div" className="relative">
          <div>
            <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
              <span className="sr-only">Open user menu</span>
              <div
                className="h-10 w-10 rounded-full bg-white bg-contain bg-no-repeat bg-center"
                style={{
                  backgroundImage: `url(${isAdmin ? adminLogo : image})`,
                }}
              >
                <span className="sr-only">Profile</span>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              {!isAdmin && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => navigate("/dashboard/profile")}
                      className={classNames(
                        active && "bg-gray-100",
                        "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                      )}
                    >
                      Profile
                    </div>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => navigate("/dashboard/changepassword")}
                    className={classNames(
                      active && "bg-gray-100",
                      "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                    )}
                  >
                    Change Password
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <>
                    <div
                      onClick={handleLogout}
                      className={classNames(
                        active && "bg-gray-100",
                        "active:bg-gray-200 rounded-sm text-red-500 px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                      )}
                    >
                      Log out
                    </div>

                    {isModalOpen && (
                      <Modal
                        title="Logout"
                        message="Are you sure you want to log out?"
                        onClose={() => setIsModalOpen(false)}
                        onConfirm={() => {
                          localStorage.clear();
                          navigate("/", { replace: true });
                        }}
                      />
                    )}
                  </>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
