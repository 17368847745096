// rheumatoid_arthritis_config.js
export const RHEUMATOID_ARTHRITIS = [
    /// Total Duration of Illness
    {
        field: "raDurationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    // Initial Symptom
    {
        field: "raInitialSymptom",
        label: "Initial Symptom",
        type: "select",
        options: [
            "Suggestive of symmetrical polyarthritis predominantly involving upper extremities.",
            "Suggestive of atypical presentation of RA",
            "Suggestive of upper and lower extremities",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Joint Examination
    {
        field: "raJointExam",
        label: "Joint Examination",
        type: "multiselect",
        options: [
            "{Right} - {RightValues} {&} {Left} {LeftValues}",
            "With deformities [{values}]",
            "Without deformities",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "With deformities [{values}]",
                ],
                placeholders: [

                    {
                        key: "values",
                        type: "multiselect",
                        label: "options",
                        options: [
                            "Boutonnière Deformity",
                            "Swan Neck Deformity",
                            "Ulnar Deviation (Ulnar Drift)",
                            "Z-Thumb (Hitchhiker’s Thumb)",
                            "Piano Key Sign",
                            "Hallux Valgus",
                            "Hammer Toes",
                            "Claw Toes",
                            "Flat Foot (Pes Planus)",
                            "Forefoot Subluxation",
                            "Rheumatoid Nodules",
                            "Atlantoaxial Subluxation",
                        ],
                    }
                ]
            },

            {
                // When the user selects this trigger value…
                trigger: [
                    "{Right} - {RightValues} {&} {Left} {LeftValues}",
                ],
                placeholders: [
                    {
                        key: "Right",
                        type: "checkbox",
                        label: "Alignment",
                        options: [
                            "Right-",
                        ],
                    },
                    {
                        key: "RightValues",
                        type: "multiselect",
                        label: "Position",
                        options: [
                            "1st MCP were Swollen",
                            "1st MCP were Tender",
                            "1st MCP were Tender & Swollen",
                            "1st PIP were Tender",
                            "1st PIP were Swollen",
                            "1st PIP were Tender & Swollen",
                            "2nd MCP were Tender",
                            "2nd MCP were Swollen",
                            "2nd MCP were Tender & Swollen",
                            "2nd PIP were Tender",
                            "2nd PIP were Swollen",
                            "2nd PIP were Tender & Swollen",
                            "3rd MCP were Tender",
                            "3rd MCP were Swollen",
                            "3rd MCP were Tender & Swollen",
                            "3rd PIP were Tender",
                            "3rd PIP were Swollen",
                            "3rd PIP were Tender & Swollen",
                            "4th MCP were Tender",
                            "4th MCP were Swollen",
                            "4th MCP were Tender & Swollen",
                            "4th PIP were Tender",
                            "4th PIP were Swollen",
                            "4th PIP were Tender & Swollen",
                        ],
                    },
                    {
                        key: "Left",
                        type: "checkbox",
                        label: "Alignment",
                        options: [
                            "Left-",
                        ],
                    },
                    {
                        key: "LeftValues",
                        type: "multiselect",
                        label: "Position",
                        options: [
                            "1st MCP were Swollen",
                            "1st MCP were Tender",
                            "1st MCP were Tender & Swollen",
                            "1st PIP were Tender",
                            "1st PIP were Swollen",
                            "1st PIP were Tender & Swollen",
                            "2nd MCP were Tender",
                            "2nd MCP were Swollen",
                            "2nd MCP were Tender & Swollen",
                            "2nd PIP were Tender",
                            "2nd PIP were Swollen",
                            "2nd PIP were Tender & Swollen",
                            "3rd MCP were Tender",
                            "3rd MCP were Swollen",
                            "3rd MCP were Tender & Swollen",
                            "3rd PIP were Tender",
                            "3rd PIP were Swollen",
                            "3rd PIP were Tender & Swollen",
                            "4th MCP were Tender",
                            "4th MCP were Swollen",
                            "4th MCP were Tender & Swollen",
                            "4th PIP were Tender",
                            "4th PIP were Swollen",
                            "4th PIP were Tender & Swollen",
                        ],
                    },

                ]
            },
        ]
    },
    /// Lab Investigation
    {
        field: "raLabInvestigations",
        label: "Lab Investigations",
        type: "multiselect",
        options: [
            "Rheumatoid factor: Positive",
            "AntiCCP: Positive",
            "ESR: Raised",
            "CRP: Raised",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Current Issues
    {
        field: "currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Second Opinion",
            "Increased Disease Activity",
            "Non compliance to medication",
            "Inadequate medication",
            "Other Illness/Infection",
            "{ Write Something Here }",
        ], extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },

    /// Comorbidities Field
    {
        field: "ra-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
            "{ Write Something Here }",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Conception Plan Field
    {
        field: "ra-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "ra-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "ra-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
            "{ Write Something Here }",
        ],
        extraOptions: [
            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "{ Write Something Here }",
                ],
                placeholders: [

                    {
                        key: " Write Something Here ",
                        type: "type",
                        label: "Text",
                    }
                ]
            },
        ],
    },
    /// Smoker Field
    {
        field: "ra-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
]