import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Input, Spin, Select, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import LoadingScreen from "../lib/constants/loadingScreen";
import { MdDelete, MdEditSquare } from "react-icons/md";

const { Option } = Select;

const Medicine = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiBaseUrl}/medicine`, config);
      if (response.data.success) {
        setDetails(response.data.data);
        setFilteredDetails(response.data.data);
      }
    } catch (error) {
      console.error("Failed to get medicine details:", error);
      message.error("Failed to get medicine details! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const deleteMedicine = async (medicine_id) => {
    try {
      setActionLoading(true);
      if (window.confirm("Are you sure you want to delete this medicine?")) {
        const response = await axios.delete(
          `${apiBaseUrl}/delete/medicine/${medicine_id}`,
          config
        );
        if (response.data.success) {
          message.success(response.data.msg);
          fetchData();
        } else {
          message.error(response.error.msg);
        }
      }
    } catch (error) {
      console.error("Failed to delete medicine:", error);
      message.error("Failed to delete medicine! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const filterDetails = useCallback(
    debounce((searchTerm, filterOption) => {
      let filteredData = details.filter((medicine) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered =
          medicine.name.toLowerCase().includes(lowerCaseSearchTerm) ||
          medicine.batch.toLowerCase().includes(lowerCaseSearchTerm) ||
          medicine.expDate.toLowerCase().includes(lowerCaseSearchTerm);

        if (!filterOption || filterOption === "reset") return filtered;

        const expDate = new Date(medicine.expDate.split("T")[0]);
        const currentDate = new Date();

        if (filterOption === "expired") {
          return filtered && expDate < currentDate;
        } else if (filterOption === "expiring_soon") {
          return (
            filtered &&
            expDate > currentDate &&
            expDate < new Date(currentDate.setMonth(currentDate.getMonth() + 4))
          );
        } else if (filterOption === "more_than_4_months") {
          return (
            expDate > new Date(currentDate.setMonth(currentDate.getMonth() + 4))
          );
        }
      });

      setFilteredDetails(filteredData);
    }, 500),
    [details]
  );

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterDetails(searchTerm, filterOption);
  };

  const handleFilterChange = (value) => {
    setFilterOption(value);
    filterDetails(searchTerm, value);
  };

  const columns = [
    {
      title: "Medicine",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Affiliate Name",
      dataIndex: "affiliateName",
      key: "affiliateName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price/Quantity",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Cost Price/Quantity",
      dataIndex: "costPrice",
      key: "costPrice",
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
    },
    {
      title: "Dosage (MG)",
      dataIndex: "dosage",
      key: "dosage",
    },
    {
      title: "Expiry Date",
      dataIndex: "expDate",
      key: "expDate",
      render: (expDate) => (
        <div
          className={`rounded text-center text-white ${new Date(expDate.split("T")[0]) < new Date()
              ? "bg-red-600"
              : new Date(expDate.split("T")[0]) >
                new Date(new Date().setMonth(new Date().getMonth() + 4))
                ? "bg-emerald-700"
                : "bg-yellow-500"
            }`}
        >
          <p>{expDate.split("T")[0]}</p>
        </div>
      ),
    },
    {
      title: "Manufacture Date",
      dataIndex: "manfDate",
      key: "manfDate",
      render: (manfDate) => manfDate.split("T")[0],
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/medicine/update/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            onClick={() => {
              deleteMedicine(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800">
              <MdDelete />
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">Medicines</h1>
          <div className="flex justify-between">
            <div className="flex items-center mt-3">
              <Input
                placeholder="Search by Name, Batch, or Expiry Date"
                prefix={<SearchOutlined />}
                value={searchTerm}
                allowClear
                onChange={handleSearchTermChange}
                style={{ width: 300 }}
              />
              <Select
                value={filterOption}
                onChange={handleFilterChange}
                style={{ width: 200, marginLeft: 8 }}
              >
                <Option value="">Filter by (expiry date)...</Option>
                <Option value="expired">Expired</Option>
                <Option value="expiring_soon">Expiring Soon</Option>
                <Option value="more_than_4_months">More Than 4 Months</Option>
                <Option value="reset">Reset</Option>
              </Select>
            </div>
            <div className="mt-3">
              <Link to="/dashboard/medicine/create">
                <button className="px-3 py-1 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                  Add Medicine
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Medicine;