import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Form, Input, Row, Col, message, Button, Modal, Select, Tag, Typography, Space, Checkbox, Radio, Tabs } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { diseaseFieldConfig } from "./config";
import UpdateSummary from "./updateSummary";
import EnhancedFormField from "./enhancedFormField";

const { TabPane } = Tabs;
const { Title } = Typography;
const { TextArea } = Input;

const ViewSummary = ({
  appointmentId,
  full_name,
  problem,
  appointmentNumber,
  appointmentHistory,
  onClose,
  onUpdateSummary: onParentUpdateSummary,
}) => {
  const [selectedDisease, setSelectedDisease] = useState("");
  const [formData, setFormData] = useState({});

  const [forms, setForms] = useState([{ id: 1, selectedDisease: "", formData: {}, fieldOptions: {} }]);
  const [activeTabKey, setActiveTabKey] = useState("1");

  const updateSummaryRef = useRef(null);

  const [dynamicExtra, setDynamicExtra] = useState(null);

  const getConfigDisease = (form) =>
    form.selectedDisease ? diseaseFieldConfig[form.selectedDisease] : [];

  const handleDiseaseChange = (formId, value) => {
    setForms((prevForms) =>
      prevForms.map((f) => {
        if (f.id === formId) {
          const configDisease = diseaseFieldConfig[value] || [];
          const fieldOptions = {};
          configDisease.forEach((fieldConfig) => {
            if (fieldConfig.type === "select" || fieldConfig.type === "multiselect") {
              fieldOptions[fieldConfig.field] = [...fieldConfig.options];
            }
          });
          return { ...f, selectedDisease: value, formData: {}, fieldOptions };
        }
        return f;
      })
    );
  };

  const handleChange = (formId, field, value) => {
    setForms((prevForms) =>
      prevForms.map((f) =>
        f.id === formId
          ? { ...f, formData: { ...f.formData, [field]: value } }
          : f
      )
    );
  };

  const handleCompositeChange = (formId, compositeField, subField, value) => {
    setForms((prevForms) =>
      prevForms.map((f) => {
        if (f.id === formId) {
          return {
            ...f,
            formData: {
              ...f.formData,
              [compositeField]: {
                ...(f.formData[compositeField] || {}),
                [subField]: value,
              },
            },
          };
        }
        return f;
      })
    );
  };

  const checkAndTriggerExtraOption = (formId, field, value) => {
    const form = forms.find((f) => f.id === formId);
    const configDisease = getConfigDisease(form);
    const fieldConfig = configDisease.find((fc) => fc.field === field);
    if (fieldConfig && fieldConfig.extraOptions) {
      const extra = fieldConfig.extraOptions.find((opt) => {
        return Array.isArray(opt.trigger)
          ? opt.trigger.includes(value)
          : opt.trigger === value;
      });
      if (extra) {
        const initialValues = {};
        if (Array.isArray(extra.placeholders)) {
          extra.placeholders.forEach((p) => {
            initialValues[p.key] = p.type === "multiselect" ? [] : "";
          });
        }
        setDynamicExtra({
          formId,
          field,
          extraOption: extra,
          values: initialValues,
          selectedTrigger: value,
        });
      }
    }
  };

  const handleMultiSelectChange = (formId, field, valueArray) => {
    const form = forms.find((f) => f.id === formId);
    const configDisease = getConfigDisease(form);
    const fieldConfig = configDisease.find((fc) => fc.field === field);
    let triggers = [];
    if (fieldConfig && fieldConfig.extraOptions) {
      triggers = fieldConfig.extraOptions.flatMap((opt) =>
        Array.isArray(opt.trigger) ? opt.trigger : [opt.trigger]
      );
    }
    const cleaned = valueArray.filter((val) => !triggers.includes(val));
    setForms((prevForms) =>
      prevForms.map((f) =>
        f.id === formId
          ? { ...f, formData: { ...f.formData, [field]: cleaned } }
          : f
      )
    );
  };

  const handleMultiSelectSelect = (formId, field, value) => {
    checkAndTriggerExtraOption(formId, field, value);
  };

  const handleDynamicExtraOk = () => {
    if (dynamicExtra) {
      const { extraOption, values, field, formId, selectedTrigger } = dynamicExtra;
      let finalString = extraOption.template || selectedTrigger;
      if (extraOption.placeholders && Array.isArray(extraOption.placeholders)) {
        extraOption.placeholders.forEach((placeholder) => {
          let replacement = values[placeholder.key];
          if (Array.isArray(replacement)) {
            replacement = replacement.join(", ");
          }
          finalString = finalString.replace(`{${placeholder.key}}`, replacement);
        });
      }

      setForms((prevForms) =>
        prevForms.map((f) => {
          if (f.id === formId) {
            const config = getConfigDisease(f);
            const fieldConfig = config.find((fc) => fc.field === field);
            if (fieldConfig && fieldConfig.type === "select") {
              return {
                ...f,
                formData: { ...f.formData, [field]: finalString },
              };
            } else {
              const current = f.formData[field];
              const currentArray = Array.isArray(current)
                ? current
                : current
                  ? [current]
                  : [];
              const cleanedArray = currentArray.filter(
                (val) => val !== selectedTrigger
              );
              if (!cleanedArray.includes(finalString)) {
                cleanedArray.push(finalString);
              }
              return {
                ...f,
                formData: { ...f.formData, [field]: cleanedArray },
              };
            }
          }
          return f;
        })
      );
    }
    setDynamicExtra(null);
  };

  const handleDynamicExtraCancel = () => {
    setDynamicExtra(null);
  };

  function hasFormData(data) {
    if (Array.isArray(data)) {
      return data.length > 0;
    } else if (typeof data === "object" && data !== null) {
      return Object.values(data).some((value) => hasFormData(value));
    } else if (typeof data === "string") {
      return data.trim() !== "";
    } else {
      return data !== undefined && data !== null;
    }
  }

  const handleSubmitAll = async () => {
    setActionLoading(true);

    const selectedForm = forms[0];
    const { selectedDisease } = selectedForm;

    if (!selectedDisease) {
      message.error("Please select a disease to proceed.");
      setActionLoading(false);
      return;
    }

    const formData = selectedForm.formData;

    if (!hasFormData(formData)) {
      message.error("Please fill at least one field for the disease summary.");
      setActionLoading(false);
      return;
    }

    try {
      const data = {
        diseaseSummary: {
          diseaseType: forms[0].selectedDisease,
          summaryData: forms[0].formData,
        },
      };
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.post(
          `${apiBaseUrl}/doctor/summary/${appointmentId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.post(
          `${apiBaseUrl}/staff/summary/${appointmentId}`,
          data,
          config
        );
      }

      if (response.data.success) {
        message.success(response.data.message, 0.6, function onClose() {
          fetchData();
        });
      } else {
        message.error(
          response?.data?.error?.error || "Failed to add summary."
        );
      }
    } catch (error) {
      console.error("Failed to add summary:", error);
      message.error("Failed to add summary! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const handleClear = (formId, field, type) => {
    setForms((prevForms) =>
      prevForms.map((f) => {
        if (f.id === formId) {
          let clearedValue;
          if (type === "composite") {
            clearedValue = {};
          } else if (type === "multiselect") {
            clearedValue = [];
          } else {
            clearedValue = "";
          }
          return {
            ...f,
            formData: { ...f.formData, [field]: clearedValue },
          };
        }
        return f;
      })
    );
  };

  const renderForm = (form) => {
    const configDisease = getConfigDisease(form);
    return (
      <div style={{ maxWidth: "900px", margin: "auto", padding: "20px" }}>
        <Title level={2}>Summary Form</Title>
        <Form
          layout="vertical"
          onFinish={() => console.log("Submitting form", form.id, form.formData)}
        >
          <Form.Item label="Select Disease">
            <Select
              placeholder="Select Disease"
              value={form.selectedDisease || undefined}
              onChange={(value) => handleDiseaseChange(form.id, value)}
              required
            >
              <Select.Option value="">--Select Disease--</Select.Option>
              {Object.keys(diseaseFieldConfig).map((disease) => (
                <Select.Option key={disease} value={disease}>
                  {disease.replace(/_/g, " ")}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {form.selectedDisease && (
            <>
              <Title level={4}>
                {form.selectedDisease.replace(/_/g, " ")}
              </Title>
              {configDisease.map((fieldConfig) => (
                <EnhancedFormField
                  key={fieldConfig.field}
                  fieldConfig={fieldConfig}
                  form={form}
                  formId={form.id}
                  formData={form.formData}
                  fieldOptions={form.fieldOptions}
                  handleChange={handleChange}
                  handleMultiSelectChange={handleMultiSelectChange}
                  handleCompositeChange={handleCompositeChange}
                  handleClear={handleClear}
                />
              ))}
            </>
          )}
        </Form>
        <pre
          style={{
            background: "#f0f0f0",
            padding: "10px",
            marginTop: "20px",
          }}
        >
          {JSON.stringify(form.formData, null, 2)}
        </pre>
      </div>
    );
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fullNameData, setFullNameData] = useState(full_name || "");
  const [problemData, setProblemData] = useState(problem || "");
  const [appointmentNumberData, setAppointmentNumberData] = useState(appointmentNumber || "");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentSummaryId, setCurrentSummaryId] = useState(null);
  const [hasEditableSummary, setHasEditableSummary] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(0); // Added updateTrigger state

  const [selectedDiseases, setSelectedDiseases] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  const getFieldLabel = (diseaseType, fieldName) => {
    const diseaseConfig = diseaseFieldConfig[diseaseType];
    if (!diseaseConfig) return fieldName;

    const fieldConfig = diseaseConfig.find((field) => field.field === fieldName);
    return fieldConfig ? fieldConfig.label : fieldName;
  };

  const handleDiseaseSelectUpdate = (category) => {
    // Implementation as needed
  };

  const handleDiseaseRemoveUpdate = (categoryToRemove) => {
    const newDiseases = selectedDiseases.filter(category => category !== categoryToRemove);
    setSelectedDiseases(newDiseases);

    const currentText = updateForm.getFieldValue('summary') || '';
    const textParts = currentText.split('\n\n');
    const newText = textParts
      .filter(part => !part.startsWith(`${categoryToRemove}:`))
      .join('\n\n');
    updateForm.setFieldsValue({ summary: newText });
  };

  useEffect(() => {
    setFullNameData(full_name || "");
    setProblemData(problem || "");
    setAppointmentNumberData(appointmentNumber || "");
    fetchData();
  }, [appointmentId, full_name, problem, appointmentNumber]);

  useEffect(() => {
    const checkEditableSummary = () => {
      if (!details || details.length === 0) return false;

      return details.some(item => {
        if (!item.appointmentId?.appointmentHistory) return false;

        const lastHistory = item.appointmentId.appointmentHistory[
          item.appointmentId.appointmentHistory.length - 1
        ];

        return lastHistory?.summary?.some(
          prescriptionItem => prescriptionItem._id === item._id
        );
      });
    };

    setHasEditableSummary(checkEditableSummary());
  }, [details]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/summary/${appointmentId}`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          console.log("Summary Data", response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching summary:", error);
        message.error("Failed to fetch summary data.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showUpdateModal = (summary, summaryId) => {
    setCurrentSummaryId(summaryId);
    setUpdateTrigger((prev) => prev + 1); // Increment updateTrigger on each click
    setIsUpdateModalOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const handleUpdateOk = () => {
    if (updateSummaryRef.current) {
      updateSummaryRef.current.handleSubmitAll();
    }
    setIsUpdateModalOpen(false);
  };

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          form.resetFields();
          setSelectedDiseases([]);
          setIsModalOpen(false);
        },
      });
    } else {
      setIsModalOpen(false);
    }
  };

  const handleUpdateCancel = () => {
    if (updateForm.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          updateForm.resetFields();
          setSelectedDiseases([]);
          setIsUpdateModalOpen(false);
        },
      });
    } else {
      setIsUpdateModalOpen(false);
    }
  };

  const updateSummary = (summaryId, data) => {
    setActionLoading(true);
    axios
      .put(
        `${apiBaseUrl}/${localStorage.getItem("doctorticket") ? "doctor" : "staff"
        }/summary/${summaryId}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.success) {
          message.success(response.data.message, 0.6, () => {
            fetchData();
            setIsUpdateModalOpen(false);
          });
        } else {
          message.error(
            response?.data?.error?.error || "Failed to update summary."
          );
        }
      })
      .catch((error) => {
        console.error("Failed to update summary:", error);
        message.error("Failed to update summary! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const deleteSummary = (summaryId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this summary?",
      content: "This action cannot be undone.",
      okText: "Yes, delete",
      okType: "danger",
      cancelText: "No, cancel",
      onOk: () => {
        setActionLoading(true);
        axios
          .delete(
            `${apiBaseUrl}/${localStorage.getItem("doctorticket") ? "doctor" : "staff"
            }/summary/${summaryId}`,
            config
          )
          .then((response) => {
            if (response.data.success) {
              message.success(response.data.message, 0.6, () => {
                fetchData();
              });
            } else {
              message.error(
                response?.data?.error?.error || "Failed to delete summary."
              );
            }
          })
          .catch((error) => {
            console.error("Failed to delete summary:", error);
            message.error("Failed to delete summary! Please try again.");
          })
          .finally(() => {
            setActionLoading(false);
          });
      },
      onCancel: () => {
        console.log("Deletion canceled");
      },
    });
  };

  const handleGoBack = () => {
    onClose();
  };

  function timeAgo(dateString) {
    const now = new Date();
    const created = new Date(dateString);
    const msPerDay = 1000 * 60 * 60 * 24;
    const diffMs = now - created;
    const diffDays = Math.floor(diffMs / msPerDay);

    if (diffDays === 0) return "today";
    if (diffDays < 30) return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
    const diffMonths = Math.floor(diffDays / 30);
    return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`;
  }

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="mb-3">
        <h1 className="text-3xl font-bold decoration-gray-400">Summary</h1>
      </div>

      <div className="flex items-start">
        <div className="max-w-5xl p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
          {loading && <LoadingScreen />}

          {!loading && (
            <>
              <div className="text-center">
                <div className="flex justify-end">
                  {/* <button
                    className="px-4 py-1 mb-2 rounded-md bg-blue-600 text-sky-100 hover:bg-blue-700"
                    onClick={handleGoBack}
                  >
                    Go Back
                  </button> */}
                </div>
                <h5 className="text-xl font-bold tracking-tight text-gray-900">
                  Appointment Number:{" "}
                  <span className="font-semibold italic">{` ${appointmentNumberData}`}</span>
                </h5>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name:{" "}
                  <span className="font-semibold italic">{` ${fullNameData}`}</span>
                </h5>

                <p className="mb-1 font-semibold text-gray-700">
                  Problem: <span className="font-normal">{` ${problemData}`}</span>
                </p>
              </div>
              <hr className="w-48 h-1 mx-auto bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700"></hr>

              {!hasEditableSummary && (
                <div>
                  {forms.map((form) => (
                    <div key={form.id}>{renderForm(form)}</div>
                  ))}

                  <div style={{ padding: "20px", textAlign: "center" }}>
                    <Button type="primary" onClick={handleSubmitAll}>
                      Add All Disease Summary
                    </Button>
                  </div>

                  <Modal
                    title={dynamicExtra ? dynamicExtra.extraOption.label : "Extra Input"}
                    open={dynamicExtra !== null}
                    onOk={handleDynamicExtraOk}
                    onCancel={handleDynamicExtraCancel}
                    width={900}
                  >
                    <Form layout="vertical">
                      {dynamicExtra && dynamicExtra.extraOption.placeholders ? (
                        dynamicExtra.extraOption.placeholders.map((placeholder) => (
                          <Form.Item label={placeholder.label} key={placeholder.key}>
                            {placeholder.type === "select" ? (
                              <Select
                                placeholder={`Select ${placeholder.label}`}
                                value={dynamicExtra.values[placeholder.key] || undefined}
                                onChange={(value) =>
                                  setDynamicExtra((prev) => ({
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      [placeholder.key]: value,
                                    },
                                  }))
                                }
                              >
                                {placeholder.options.map((opt) => (
                                  <Select.Option key={opt} value={opt}>
                                    {opt}
                                  </Select.Option>
                                ))}
                              </Select>
                            ) : placeholder.type === "multiselect" ? (
                              <Select
                                mode="multiple"
                                placeholder={`Select ${placeholder.label}`}
                                value={dynamicExtra.values[placeholder.key] || []}
                                onChange={(value) =>
                                  setDynamicExtra((prev) => ({
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      [placeholder.key]: value,
                                    },
                                  }))
                                }
                              >
                                {placeholder.options.map((opt) => (
                                  <Select.Option key={opt} value={opt}>
                                    {opt}
                                  </Select.Option>
                                ))}
                              </Select>
                            ) : placeholder.type === "checkbox" ? (
                              <Checkbox.Group
                                options={placeholder.options}
                                value={dynamicExtra.values[placeholder.key] || []}
                                onChange={(checkedValues) =>
                                  setDynamicExtra((prev) => ({
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      [placeholder.key]: checkedValues,
                                    },
                                  }))
                                }
                              />
                            ) : (
                              <Input
                                placeholder={`Enter ${placeholder.label}`}
                                value={dynamicExtra.values[placeholder.key] || ""}
                                onChange={(e) =>
                                  setDynamicExtra((prev) => ({
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      [placeholder.key]: e.target.value,
                                    },
                                  }))
                                }
                              />
                            )}
                          </Form.Item>
                        ))
                      ) : (
                        <Form.Item label="Input">
                          <Input
                            placeholder="Enter value"
                            value={dynamicExtra ? dynamicExtra.value : ""}
                            onChange={(e) =>
                              setDynamicExtra((prev) => ({ ...prev, value: e.target.value }))
                            }
                          />
                        </Form.Item>
                      )}
                    </Form>
                  </Modal>
                </div>
              )}

              {details.length > 0 ? (
                details.map((item) => (
                  <div
                    key={item._id}
                    className="relative mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex"
                  >
                    <div className="flex-grow">
                      <p className="mb-1 font-semibold text-gray-700">
                        Summary added on:{" "}
                        <span className="font-normal">{formatDate(item.createdAt)}</span>
                        <span className="font-normal"> ({timeAgo(item.createdAt)})</span>
                      </p>
                      <div style={{ whiteSpace: "pre-wrap", marginBottom: "1rem" }}>
                        <h2 style={{ marginBottom: "0.5rem" }}>Summary Report:</h2>
                        {item.diseaseSummary.diseaseType && (
                          <h3 style={{ margin: "0.5rem 0", fontWeight: "bold" }}>
                            {item.diseaseSummary.diseaseType.replace(/_/g, " ")}
                          </h3>
                        )}
                        {item.diseaseSummary.summaryData &&
                          Object.entries(item.diseaseSummary.summaryData).map(([key, value], index) => (
                            <div key={index} style={{ marginBottom: "0.5rem" }}>
                              <div>
                                <strong>{getFieldLabel(item.diseaseSummary.diseaseType, key)}:</strong>
                              </div>
                              <div style={{ marginLeft: "1rem" }}>
                                {Array.isArray(value) ? (
                                  value.map((val, idx) => (
                                    <div key={idx}>{val}</div>
                                  ))
                                ) : typeof value === "object" && value !== null ? (
                                  Object.entries(value).map(([subKey, subValue], idx) => (
                                    <div key={idx}>
                                      <strong>
                                        {getFieldLabel(item.diseaseSummary.diseaseType, subKey)}:
                                      </strong>{" "}
                                      {subValue}
                                    </div>
                                  ))
                                ) : (
                                  <div>{value}</div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                      <p className="mb-1 font-semibold text-gray-700">
                        Added by:{" "}
                        <span className="font-normal">
                          Dr. {item.doctorId.fullname}
                        </span>
                      </p>
                    </div>

                    <div className="relative">
                      <div className="absolute top-0 bottom-0 border-r-2 border-dotted border-gray-400"></div>
                    </div>

                    {item.appointmentId.appointmentHistory[item.appointmentId.appointmentHistory.length - 1]?.summary.some(
                      summaryItem => summaryItem._id === item._id
                    ) && (
                        <div className="flex flex-col items-start pl-6">
                          <button
                            onClick={() => showUpdateModal(item.diseaseSummary.summaryData, item._id)}
                            className="px-5 py-1 rounded-md bg-blue-600 text-sky-100 hover:bg-blue-800"
                          >
                            Update
                          </button>
                          <button
                            onClick={() => deleteSummary(item._id)}
                            className="px-5 py-1 rounded-md bg-red-700 text-sky-100 hover:bg-red-800"
                          >
                            Delete
                          </button>
                        </div>
                      )}
                  </div>
                ))
              ) : (
                <p className="mt-3 text-center">
                  <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                    No Summary added!!
                  </h5>
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        title="Add Summary"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Summary">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Summary"
        open={isUpdateModalOpen}
        onOk={handleUpdateOk}
        onCancel={handleUpdateCancel}
        width={900}
        footer={[
          <Button key="back" onClick={handleUpdateCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpdateOk}>
            Update
          </Button>,
        ]}
      >
        <UpdateSummary
          ref={updateSummaryRef}
          summaryId={currentSummaryId}
          full_name={fullNameData}
          problem={problemData}
          appointmentNumber={appointmentNumberData}
          onClose={() => setIsUpdateModalOpen(false)}
          onUpdateSummary={(summaryId, data) => updateSummary(summaryId, data)}
          updateTrigger={updateTrigger} // Pass updateTrigger as prop
        />
      </Modal>

      <Modal
        title={dynamicExtra ? dynamicExtra.extraOption.label : "Extra Input"}
        open={dynamicExtra !== null}
        onOk={handleDynamicExtraOk}
        onCancel={handleDynamicExtraCancel}
        width={900}
      >
        <Form layout="vertical">
          {dynamicExtra && dynamicExtra.extraOption.placeholders ? (
            dynamicExtra.extraOption.placeholders.map((placeholder) => (
              <Form.Item label={placeholder.label} key={placeholder.key}>
                {placeholder.type === "select" ? (
                  <Select
                    placeholder={`Select ${placeholder.label}`}
                    value={dynamicExtra.values[placeholder.key] || undefined}
                    onChange={(value) =>
                      setDynamicExtra((prev) => ({
                        ...prev,
                        values: {
                          ...prev.values,
                          [placeholder.key]: value,
                        },
                      }))
                    }
                  >
                    {placeholder.options.map((opt) => (
                      <Select.Option key={opt} value={opt}>
                        {opt}
                      </Select.Option>
                    ))}
                  </Select>
                ) : placeholder.type === "multiselect" ? (
                  <Select
                    mode="multiple"
                    placeholder={`Select ${placeholder.label}`}
                    value={dynamicExtra.values[placeholder.key] || []}
                    onChange={(value) =>
                      setDynamicExtra((prev) => ({
                        ...prev,
                        values: {
                          ...prev.values,
                          [placeholder.key]: value,
                        },
                      }))
                    }
                  >
                    {placeholder.options.map((opt) => (
                      <Select.Option key={opt} value={opt}>
                        {opt}
                      </Select.Option>
                    ))}
                  </Select>
                ) : placeholder.type === "checkbox" ? (
                  <Checkbox.Group
                    options={placeholder.options}
                    value={dynamicExtra.values[placeholder.key] || []}
                    onChange={(checkedValues) =>
                      setDynamicExtra((prev) => ({
                        ...prev,
                        values: {
                          ...prev.values,
                          [placeholder.key]: checkedValues,
                        },
                      }))
                    }
                  />
                ) : (
                  <Input
                    placeholder={`Enter ${placeholder.label}`}
                    value={dynamicExtra.values[placeholder.key] || ""}
                    onChange={(e) =>
                      setDynamicExtra((prev) => ({
                        ...prev,
                        values: {
                          ...prev.values,
                          [placeholder.key]: e.target.value,
                        },
                      }))
                    }
                  />
                )}
              </Form.Item>
            ))
          ) : (
            <Form.Item label="Input">
              <Input
                placeholder="Enter value"
                value={dynamicExtra ? dynamicExtra.value : ""}
                onChange={(e) =>
                  setDynamicExtra((prev) => ({ ...prev, value: e.target.value }))
                }
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ViewSummary;