import { useState, useEffect } from "react";
import axios from "axios";
import { Table, Card, Spin, message } from "antd";
import moment from "moment-timezone";
import LoadingScreen from "../../lib/constants/loadingScreen";

const ViewDetailedBill = ({ billId }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState({});
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    if (billId) {
      fetchData();
    }
  }, [billId]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/test-bills/${billId}`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data.test);
          setFetchedData(response.data.data);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error("Failed to fetch bill details! Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const convertToNepaliTime = (utcTime) => {
    return moment
      .utc(utcTime)
      .tz("Asia/Kathmandu")
      .format("DD-MMMM-YYYY h:mm A");
  };

  const columns = [
    {
      title: "SN",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Test Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Gross Amount",
      dataIndex: "grossAmount",
      key: "grossAmount",
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
    },
    {
      title: "Bill Date",
      dataIndex: "billDate",
      key: "billDate",
      render: (billDate) => {
        const nepaliTime = convertToNepaliTime(billDate);
        return <span>{nepaliTime}</span>;
      },
    },
  ];

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="container max-w-8xl mx-auto" id="viewStaff">
        <div className="mb-4">
          <Card
            className="w-full mt-2"
            title={`Bill History of BillNo: ${fetchedData.billNo}`}
            bordered={false}
          >
            <p>Patient Name: {fetchedData.patient?.fullname}</p>
            <p>
              DOB:{" "}
              {fetchedData?.patient?.DOB
                ? fetchedData.patient?.DOB.split("T")[0]
                : "-"}
            </p>
            <p>
              Contact/ Address: {fetchedData.patient?.mobile}/{" "}
              {fetchedData.patient?.address}
            </p>

            <div className="flex flex-col mt-4">
              <Table
                dataSource={details}
                columns={columns}
                rowKey="_id"
                pagination={{
                  className: "pe-3",
                  defaultPageSize: 10,
                }}
                loading={{
                  indicator: <Spin size="large" />,
                  spinning: loading,
                }}
                summary={() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={4}>
                      <strong>Total</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <strong>{fetchedData.totalGross?.toFixed(2)}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <strong>{fetchedData.totalNet?.toFixed(2)}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                  </Table.Summary.Row>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewDetailedBill;